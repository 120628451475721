import React from 'react'

// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
import _ from 'lodash'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSelectedAddOnView from 'x/modules/subscription/BaseSelectedAddOnView'
import * as util from 'x/utils/util'
import * as xFmt from 'x/utils/formatter'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import { TouchableOpacity } from 'react-native'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import { ISelectedAddOn } from 'x/modules/subscription/BasePaymentSubscriptionView'
import { SubscriptionPackageListItems } from 'x/index'

export default abstract class BaseUISelectedAddOnView extends BaseSelectedAddOnView {
  _renderHeader = () => {
    const title = 'เลือกแพ็กเสริมออเดอร์'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderFooter = () => (
    <XButton mb='1' mx='1' onPress={() => this._onPressFooter()}>
      ยืนยัน
    </XButton>
  )

  _renderSelectedAddOnByListView = () => {
    const { addOnList, selectedAddOnlist } = this.state
    const addOn: ISelectedAddOn | null = !_.isNil(selectedAddOnlist) && selectedAddOnlist.length > 0 ? selectedAddOnlist[0] : null
    const key = addOnList[0]?.item_subtype
    let unitLabel = 'ออเดอร์'
    if (!_.isNil(key)) {
      unitLabel = this._getLabelUnit(key)
    }

    const selectedAddOn: SubscriptionPackageListItems = this._getSelectedAddOn(
      !_.isNil(addOn) && _.has(addOn, 'sku') && !_.isNil(addOn.sku) ? addOn.sku : null
    )
    let textLabel = `เลือกแพ็กเสริมเพิ่ม${unitLabel}`
    if (!_.isNil(selectedAddOn) && _.has(selectedAddOn, 'sku') && !_.isNil(selectedAddOn.sku)) {
      textLabel = `${selectedAddOn.name} (${xFmt.formatCurrency(selectedAddOn.price_with_vat)}/รอบบิล)`
    }
    return (
      <VStack w='full'>
        <XText fontSize='lg' bold>
          {`เลือกแพ็กเสริมเพิ่ม${unitLabel}....`}
        </XText>
        <TouchableOpacity
          onPress={() => this._navToSelectedAddOnListView()}
          style={{ borderRadius: 90, backgroundColor: COLORS.APP_MAIN, alignItems: 'center', justifyContent: 'center' }}>
          <HStack py='2' alignItems='center' justifyContent='center' w='full'>
            <HStack flex={1} justifyContent='center' alignItems='center'>
              <XText color={COLORS.WHITE} bold>
                {textLabel}
              </XText>
            </HStack>
            <HStack w='10'>
              <XIcon name='arrowright' family='AntDesign' color={COLORS.WHITE} />
            </HStack>
          </HStack>
        </TouchableOpacity>
      </VStack>
    )
  }

  _renderSelectedAddOnByBtn = () => {
    const { selectedAddOnlist } = this.state
    if (_.isNil(selectedAddOnlist) || selectedAddOnlist.length === 0) {
      return null
    }

    return (
      <VStack w='full'>
        {selectedAddOnlist.map((addOn, idx) => {
          const { sku, qty } = addOn
          const selectedAddOn: SubscriptionPackageListItems = this._getSelectedAddOn(sku)
          if (_.isNil(selectedAddOn)) {
            return null
          }
          const isCanMinus = qty > 0
          const isCanPlus = true
          return (
            <VStack w='full' mt='2' p='2' key={addOn.sku}>
              <XText variant='active' bold>
                {selectedAddOn.name}
              </XText>
              <VStack mt='2'>
                <HStack flex={1} justifyContent='flex-start' alignItems='center'>
                  <TouchableOpacity
                    disabled={!isCanMinus}
                    onPress={() => {
                      if (!isCanMinus) {
                        return
                      }
                      this._onPressMinusBtnByAddOnPackage(idx)
                    }}>
                    <XIcon name='minuscircle' family='AntDesign' opacity={0.3} color={isCanMinus ? COLORS.RED : COLORS.TEXT_INACTIVE} />
                  </TouchableOpacity>

                  <XText w='20' textAlign='center' bold>
                    {addOn.qty}
                  </XText>
                  <TouchableOpacity
                    disabled={false}
                    onPress={() => {
                      if (!isCanPlus) {
                        return
                      }
                      this._onPressPlusBtnByAddOnPackage(idx)
                    }}>
                    <XIcon name='pluscircle' family='AntDesign' opacity={0.3} color={isCanPlus ? COLORS.GREEN : COLORS.TEXT_INACTIVE} />
                  </TouchableOpacity>
                </HStack>
                {/* <HStack w='full'>
                  <HStack flex={1}>
                    <XText>{`จำนวน ${addOn.billing_cycle} รอบบิล`}</XText>
                  </HStack>
                  <VStack w='24' justifyContent='flex-end'>
                    <XText bold textAlign='right'>
                      {xFmt.formatCurrency(selectedAddOn.price_with_vat * addOn.billing_cycle)}
                    </XText>
                  </VStack>
                </HStack> */}
              </VStack>
            </VStack>
          )
        })}
      </VStack>
    )
  }

  _renderSelectedAddOn = () => {
    const { addOnList } = this.state
    if (_.isNil(addOnList)) {
      return null
    }
    // addOnList[0].sku is order_quota
    if (addOnList[0].sku === null) {
      return this._renderSelectedAddOnByListView()
    }
    return this._renderSelectedAddOnByBtn()
  }

  _renderBillingCycle = () => {
    const { selectedPackage, subscriptionPackageList, selectedAddOnlist } = this.state
    if (_.isNil(selectedPackage) || _.isNil(subscriptionPackageList)) {
      return null
    }
    const addOn = !_.isNil(selectedAddOnlist) && selectedAddOnlist.length > 0 ? selectedAddOnlist[0] : null
    const selectedAddOn = this._getSelectedAddOn(!_.isNil(addOn) && _.has(addOn, 'sku') && !_.isNil(addOn.sku) ? addOn.sku : null)

    const startBillingCycle = !_.isNil(addOn) && _.has(addOn, 'billing_cycle') ? addOn.billing_cycle : selectedPackage.billing_cycle
    const maxBillingCycle = selectedPackage.billing_cycle
    const billingCycleLabel = util.getStartAndEndBillingCycle(subscriptionPackageList, startBillingCycle)

    const isLifeTime = !_.isNil(selectedAddOn) && _.has(selectedAddOn, 'item_type') && selectedAddOn.item_type === 'addon_lifetime'
    const isCanMinus = !isLifeTime && startBillingCycle > 1
    const isCanPlus = !isLifeTime && startBillingCycle < maxBillingCycle
    return (
      <VStack bgColor='#D9EAF3' w='full' mt='2' p='2'>
        <XText variant='active' bold>
          จำนวนรอบบิล
        </XText>
        <VStack mt='2'>
          <HStack flex={1} justifyContent='flex-start' alignItems='center'>
            {isLifeTime ? null : (
              <TouchableOpacity
                disabled={!isCanMinus}
                onPress={() => {
                  if (!isCanMinus) {
                    return
                  }
                  this._onPressMinusBtn()
                }}>
                <XIcon name='minuscircle' family='AntDesign' opacity={0.3} color={isCanMinus ? COLORS.RED : COLORS.TEXT_INACTIVE} />
              </TouchableOpacity>
            )}
            <XText w='20' textAlign={isLifeTime ? 'left' : 'center'} bold>
              {`${startBillingCycle}/${maxBillingCycle}`}
            </XText>
            {isLifeTime ? null : (
              <TouchableOpacity
                disabled={false}
                onPress={() => {
                  if (!isCanPlus) {
                    return
                  }
                  this._onPressPlusBtn()
                }}>
                <XIcon name='pluscircle' family='AntDesign' opacity={0.3} color={isCanPlus ? COLORS.GREEN : COLORS.TEXT_INACTIVE} />
              </TouchableOpacity>
            )}
          </HStack>
          <VStack mt='1'>
            {/* <XText variant='inactive'>{`จำนวน ${selectedBillingCycle} รอบบิล`}</XText> */}
            <XText variant='inactive'>{`ตั้งแต่วันที่ (${billingCycleLabel})`}</XText>
          </VStack>
        </VStack>
      </VStack>
    )
  }

  _renderResultAddOn = (addOn: ISelectedAddOn) => {
    const selectedAddOn: SubscriptionPackageListItems = this._getSelectedAddOn(addOn.sku)
    const price = selectedAddOn.price_with_vat * addOn.billing_cycle * addOn.qty
    return (
      <VStack w='full' key={addOn.sku} borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' py='2'>
        <XText variant='active'>{selectedAddOn.name}</XText>
        <XText variant='inactive'>{`${xFmt.formatCurrency(selectedAddOn.price_with_vat)}/รอบบิล`}</XText>
        <HStack w='full'>
          <HStack flex={1}>
            <XText>{`${addOn.qty} คำสั่งซื้อ x ${addOn.billing_cycle} รอบบิล`}</XText>
          </HStack>
          <VStack w='24' justifyContent='flex-end'>
            <XText bold textAlign='right'>
              {xFmt.formatCurrency(price)}
            </XText>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  _renderResult = () => {
    const { selectedAddOnlist } = this.state
    if (_.isNil(selectedAddOnlist) || selectedAddOnlist.length === 0 || selectedAddOnlist[0].sku === null) {
      return null
    }
    const result = this._getSumResult()
    return (
      <VStack bgColor={COLORS.BG_LIGHT_GREY} p='2' w='full'>
        <XText variant='active' bold>
          สรุป
        </XText>
        {selectedAddOnlist.map((addOn) => this._renderResultAddOn(addOn))}
        <HStack w='full'>
          <HStack flex={1} />
          <VStack w='24' justifyContent='flex-end'>
            <XText bold fontSize='xl' textAlign='right'>
              {xFmt.formatCurrency(result)}
            </XText>
            <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='1' />
            <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='3' mt='1' />
          </VStack>
        </HStack>
      </VStack>
    )
  }

  _renderBody = () => {
    const { selectedPackage } = this.state
    if (_.isNil(selectedPackage)) {
      return null
    }
    return (
      <XCard p='2' w='full'>
        <VStack w='full'>
          {this._renderSelectedAddOn()}
          {this._renderBillingCycle()}
          {this._renderResult()}
        </VStack>
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='1' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderBody()}
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
