// import Promise from 'bluebird'
import axios from 'axios'
import _ from 'lodash'
import { getAuthenticationToken, setAuthenticationToken } from 'x/utils/authentication'
import { IApiOptions } from 'x/index'
import { ApiRequestType, IXSellyError, IXSellyErrorResponse } from 'x/types'
import { Linking } from 'react-native'
import p from 'x/config/platform-specific'
// import React from 'react'
import CONS from 'x/config/constants'
import { MODE } from 'x/config/mode'
import { getConfiguration } from 'x/utils/configuration'
import {
  log,
  getErrMessageObj,
  getUnprocessableEntityMesssages,
  isXSellyErrorResponse,
  isXSellyErrorV1,
  isXSellyErrorV2,
  parseXSellyErrorToMessageText,
  parseXSellyErrorToTitleText,
  parseXSellyErrorToURL,
  parseXSellyErrorToURLText,
} from 'x/utils/util'
// import { getCreateToken } from './file'
// import * as util from './util'
/**
 * Custom define constants and warpper functions
 */
// import { Toast } from 'native-base'
// import {
//   Alert,
//   NetInfo,
// } from 'react-native'
// Import from local
// import * as auth from 'x/utils/authentication'

// import * as indicator from 'x/services/indicator'
// import * as NavActions from 'x/services/navigation'
// import store from './../redux/store'

const EventEmitter = require('event-emitter')

const CONTACT_TO_HLEP_TXT = 'กรุณาติดต่อทีมงาน'

const DEFAULT_TIMEOUT = 20000 // In first time.

// // Ref: https://github.com/axios/axios/issues/164#issuecomment-327837467
// // Add interceptors of axios for handling timeout (retry)
// axios.interceptors.response.use(undefined, (err) => {
//   // log('axios.interceptors.response err => ', err)
//   // log('axios.interceptors.response err.request => ', err.request)
//   // log('axios.interceptors.response err.response => ', err.response)
//   const config = err.config

//   // ถ้า มี response data มาอยู่แล้ว ก็ resolve promise ไปทำ error handling
//   const errorStatus = err.response && err.response.status ? err.response.status : 0 // for detect error response
//   if ((err.response && err.response.data) || errorStatus > 0) {
//     return Promise.resolve(err.response)
//   }

//   // If config does not exist or the retry option is not set, reject
//   if (!config || !config.retry) return Promise.reject(err)

//   // Set the variable for keeping track of the retry count
//   config.__retryCount = config.__retryCount || 0
//   // Check if we've maxed out the total number of retries
//   if (config.__retryCount > config.retry) {
//     const enegySavingWarning = p.op.isAndroid() ? '\n\n(กรุณาปิดโหมดประหยัดพลังงาน ถ้าเปิดอยู่)' : ''
//     p.op.alert('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้', 'กรุณาเช็คสัญญาณอินเตอร์เน็ต และลองใหม่อีกครั้ง' + enegySavingWarning, () => {
//       p.op.hideLoadingIndicator()
//     })
//     p.op.aLogEvent(CONS.ALOG.RETRY_FAILED, { count: config.__retryCount, hr: getHoursNow() })
//     // Reject with the error
//     // return Promise.reject(err)
//     return Promise.resolve(null)
//   }

//   // Increase the retry count
//   config.__retryCount += 1

//   // Create new promise to handle exponential backoff
//   const backoff = new Promise((resolve) => {
//     setTimeout(() => {
//       p.op.aLogEvent(CONS.ALOG.RETRY, { count: config.__retryCount, hr: getHoursNow() })
//       resolve(null)
//       // }, config.retryDelay || 1)
//     }, 1000 + config.__retryCount * (config.retryDelay || 1)) // 1 + (1x, 2x, 3x, ...) = 8, 9, 10 sec
//   })

//   // Return the promise in which recalls axios to retry the request
//   return backoff.then(() => {
//     // delay = DEFAULT_TIMEOUT + (retryTimes * 2 seconds)
//     return axios(config)
//     // return axios({ ...config, timeout: DEFAULT_TIMEOUT + (config.__retryCount * 1000) })
//   })
// })

// interface IExpotedApi {
//   GET_FETCH_INITIALIZE_DATA: string
//   GET_CUSTOMERS: string
//   GET_PROFILE: string
//   GET_REFRESH: string
//   GET_SELLER_STORES: string
//   GET_STORES: string
//   GET_VERIFICATION_EMAIL: string
//   GET_SUBSCRIPTION: string
//   // GET_BANKACCOUNTS: string
//   // POST
//   POST_LOGIN: string
//   POST_LOGOUT: string

//   POST_FORGOT_PASSWORD: string
//   POST_RESET_PASSWORD: string

//   POST_FB_LOGIN: string
//   POST_FB_SIGNUP: string
//   POST_SIGNUP: string
//   POST_STORE: string
//   POST_STORE_LEAVE: string
//   POST_BANKACCOUNTS: string
//   POST_PRODUCT: string
//   POST_PRODUCTS: string
//   POST_SELLER_PRODUCTS: string

//   POST_MEMBERS: string
//   POST_MEMBERS_PRE_APPROVED: string
//   POST_APPLY_INVITE_CODE: string
//   POST_USER_GROUP_USER_LIST: string
//   // POST_RESELLER_PENDING_LIST: string
//   POST_CHANGE_PENDING_USERS_STATUS: string
//   POST_UG_ASSIGN_PGS: string
//   POST_ACCEPT_ALL_PENDING_USERS: string

//   POST_RESELLER_MEMBER_LIST: string

//   POST_CUSTOMER: string
//   POST_ORDERS: string
//   POST_ORDERS_QUERY: string
//   POST_ORDER: string
//   POST_ORDER_CONFIRM: string
//   POST_ORDER_CANCEL: string
//   POST_ORDER_PAY: string
//   POST_SHIPPING_COST_CALC: string
//   // POST_ORDER_SHIP: string
//   POST_PAYMENT_CONFIRM: string
//   POST_PAYMENT_CANCEL: string
//   POST_PAYMENTS: string
//   POST_NOTIFICATION: string
//   POST_SHIPPING_AUTO_COMPLETE: string
//   POST_SHIPPING_CANCEL: string
//   POST_REPORT_DAILY_SALES: string
//   POST_FETCH_USER_GROUPS: string
//   POST_FETCH_PRODUCT_GROUPS: string
//   POST_RESELLER_CHANGE_USER_GROUP: string
//   POST_GENERATE_USER_GROUP_INVITE_LINK: string

//   POST_COPE_LINK_ORDER: string
//   POST_CANCEL_TACKING_CODE: string

//   POST_FETCH_ORDER_NOTES: string

//   POST_LOAD_ADDRESS_PROFILE: string
//   POST_FIND_ADDRESSES: string

//   POST_ADD_CUSTOM_ORDER: string

//   // PUT
//   PUT_CUSTOMER: string
//   PUT_ADDRESS: string
//   PUT_CREATE_STORE: string
//   PUT_CREATE_PRODUCT_GROUP: string
//   PUT_CREATE_USER_GROUP: string
//   PUT_BANKACCOUNTS: string
//   PUT_PRODUCT_ADD: string
//   PUT_ORDER_CREATE: string
//   PUT_RESELLER: string
//   PUT_RESELLER_PREAPPROVE_FB: string
//   PUT_PRODUCT_PULL: string
//   PUT_PAYMENT: string
//   PUT_ORDER_SHIP: string
//   PUT_ONBOARD: string
//   PUT_NEW_USER_TO_UG: string
//   PUT_CREATE_VOLUME_DISCOUNT: string

//   PUT_ADD_ORDER_NOTE: string

//   PUT_ADD_PRINTING: string

//   // PATCH
//   PATCH_USERNAME: string
//   PATCH_PROFILE: string
//   PATCH_ADDRESS: string
//   PATCH_PRODUCT: string
//   PATCH_PRODUCT_GROUP: string
//   PATCH_USER_GROUP: string
//   PATCH_SORT_PRIORITIES_PRODUCT_GROUPS: string
//   PATCH_USER_GROUP_RESET_INVITE_CODE: string
//   PATCH_CUSTOMER: string
//   PATCH_MY_STORE: string
//   // PATCH_ORDER_RECEIVER_ADDRESS: string
//   PATCH_ORDER_ADDRESS: string
//   PATCH_BANKACCOUNTS: string
//   PATCH_CHANGE_EMAIL: string
//   PATCH_ORDER_SETTING: string
//   PATCH_SHIPPING_TYPE: string
//   PATCH_VOLUME_DISCOUNT: string

//   PATCH_EDIT_PRINTING: string

//   // DEL
//   DELETE_PRODUCT: string
//   DELETE_PRODUCT_GROUP: string
//   DELETE_USER_GROUP: string
//   DELETE_BANKACCOUNT: string
//   DELETE_CUSTOMER: string
//   DELETE_ADDRESS: string
//   DELETE_RESELLER: string
//   DELETE_VOLUME_DISCOUNT: string

//   // POST VD
//   LOAD_VOLUME_DISCOUNTS: string
//   PAIR_VOLUME_DISCOUNT: string
//   UNPAIR_VOLUME_DISCOUNT: string

//   get: (path: string, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   post: (path: string, body: { [key: string]: any }, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   put: (path: string, body: { [key: string]: any }, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   patch: (path: string, body: { [key: string]: any }, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   del: (path: string, body: { [key: string]: any }, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   postV2: (path: string, body: { [key: string]: any }, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   putV2: (path: string, body: { [key: string]: any }, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   patchV2: (path: string, body: { [key: string]: any }, options: IApiOptions, suppressRedBox?: boolean) => Promise<any>
//   url: (path: string) => string
//   getRefreshToken: (options: IApiOptions, suppressRedBox?: boolean) => Promise<{ [key: string]: any; token: string } | null>

//   [key: string]: any
// }

const inittialToastMessage = { successMsg: null, errorMsg: null }
const initialOptions: IApiOptions = {
  messages: inittialToastMessage,
  showSpinner: false,
  isApiV2: false,
  axiosOptions: {},
  fetchOptions: {},
}

// export function goBackToLoginScreen() {
//   return store.dispatch(NavActions.resetToLogin)
// }

// const mockOptions = {
//   messages: {
//     successMsg: 'โหลดห้องสำเร็จ',
//     errorMsg: 'ไม่สามารถดาวรายชื่อห้องได้ กรุณาตรวจสอบการเชื่อมต่ออินเตอร์เน็ต'
//   },
//   showSpinner: true
// }

// async function checkToken(reqPromise) {
//   return await reqPromise.then(res => {
//     // log('checkToken')
//     //log('checkToken reqPromise => ', res)
//     // var response = {}
//     if (res.headers.map.authorization) {
//       let fullToken = res.headers.map.authorization[0]
//       let token = fullToken.split(' ')[1]
//       // ถ้าได้ token มาเซ็ตลง app และ ส่ง body กลับไป อาจเป็น {} ก็ได้
//       return auth.setAuthenticationToken(token).then(() => bodyOf(reqPromise))
//       // return auth.setAuthenticationToken(token).then(() => true)
//     } else {
//       // ถ้าไม่มี token มาเคลียร์ token เดิมและส่ง false กลับไป
//       return auth.clearAuthenticationToken().then(() => false)
//       // return auth.clearAuthenticationToken().then(() => false)
//     }
//     // if (res.body && res.body.missing) {
//     //   response.missing = res.body.missing
//     // }
//     // return response
//   }).catch(error => error)
// }

/**
 * No Return Function
 * ใช้สำหรับ Refresh Token ใหม่ (ไม่ต้องการ parameter อะไรเลย)
 * @param {Object}  messages = {successMsg: 'การดำเนินการเสร็จสิ้น', errorMsg: 'การดำเนินการล้มเหลว'}
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 */
export async function getRefreshToken(options: IApiOptions = initialOptions): Promise<{ [key: string]: any; token: string } | null> {
  // return checkToken(sendFetchRequest('get', api.GET_REFRESH, null, options, suppressRedBox))
  // const currentToken: string | null = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
  const currentToken: string | null = await getAuthenticationToken()
  if (!currentToken || currentToken.length === 0) {
    // return Error('No available token.')
    return null
  }
  try {
    // const res: any = await sendFetchRequest('get', api.GET_REFRESH, null, options)
    const headers = await getRequestHeaders(options)
    const res: any = await axios({
      url: url(api.GET_REFRESH),
      method: 'get',
      headers,
    })
    // Change to axios headers
    // log('getRefreshToken response => ', res)
    if (res && res.headers && _.isString(res.headers.authorization)) {
      const token = res.headers.authorization.replace(/Bearer/g, '').trim()
      await setAuthenticationToken(token)
      return res.data ? { token, ...res.data } : { token }
    }
  } catch (err) {
    // เมื่อ token blacklist หรือ expired ให้ signout ออกเอง
    await p.op.signOut()
    log('getRefreshToken failed err => ', err)
  }
  return null
}

/**
 * API
 * GET/POST/PUT/DELETE a path relative to API root url.
 * @param {String}  path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify (POST,PUT)
 * @param {Object}  options = {
 *   messages: { //ในที่นี้คือ ToastMessage ถ้าไม่มีคือไม่โชว์ Toast
 *     successMsg: 'การดำเนินการเสร็จสิ้น',
 *     errorMsg: 'การดำเนินการล้มเหลว'}
 *   },
 *   showSpinner: true,
 *   }
 * @returns {Promise} of response body
 */

type XApiWithoutBodyFuncType = <ReturnType>(path: string, options?: IApiOptions) => Promise<ReturnType>
type XApiFuncType = <BodyType = { [key: string]: any }, ReturnType = any>(
  path: string,
  body: BodyType,
  options?: IApiOptions
) => Promise<ReturnType>

export const axiosGet: XApiWithoutBodyFuncType = async (path, options = initialOptions) => sendFetchRequest('get', path, null, options)

export const axiosPost: XApiFuncType = async (path, body, options = initialOptions) => sendFetchRequest('post', path, body, options)

export const axiosPut: XApiFuncType = (path, body, options = initialOptions) => sendFetchRequest('put', path, body, options)

export const axiosPatch: XApiFuncType = (path, body, options = initialOptions) => sendFetchRequest('patch', path, body, options)

export const axiosDel: XApiFuncType = (path, body, options = initialOptions) => sendFetchRequest('delete', path, body, options)

export const fetchGet: XApiWithoutBodyFuncType = async (endpoint, options = initialOptions) =>
  sendNativeFetchRequest('get', endpoint, null, options)

export const fetchPost: XApiFuncType = async (endpoint, body, options = initialOptions) =>
  sendNativeFetchRequest('post', endpoint, body, options)

export const fetchPut: XApiFuncType = (endpoint, body, options = initialOptions) => sendNativeFetchRequest('put', endpoint, body, options)

export const fetchPatch: XApiFuncType = (endpoint, body, options = initialOptions) =>
  sendNativeFetchRequest('patch', endpoint, body, options)

export const fetchDel: XApiFuncType = (endpoint, body, options = initialOptions) =>
  sendNativeFetchRequest('delete', endpoint, body, options)

function isAxiosApiOptions(options?: IApiOptions): boolean {
  // return false
  return !options || !_.isEmpty(options.axiosOptions) || !options.fetchOptions || !_.isEmpty(options.fetchOptions)
}

export const get: XApiWithoutBodyFuncType = async (path, options = initialOptions) =>
  isAxiosApiOptions(options) ? sendFetchRequest('get', path, null, options) : sendNativeFetchRequest('get', path, null, options)

export const post: XApiFuncType = async (path, body, options = initialOptions) =>
  isAxiosApiOptions(options) ? sendFetchRequest('post', path, body, options) : sendNativeFetchRequest('post', path, body, options)

export const put: XApiFuncType = (path, body, options = initialOptions) =>
  isAxiosApiOptions(options) ? sendFetchRequest('put', path, body, options) : sendNativeFetchRequest('put', path, body, options)

export const patch: XApiFuncType = (path, body, options = initialOptions) =>
  isAxiosApiOptions(options) ? sendFetchRequest('patch', path, body, options) : sendNativeFetchRequest('patch', path, body, options)

export const del: XApiFuncType = (path, body, options = initialOptions) =>
  isAxiosApiOptions(options) ? sendFetchRequest('delete', path, body, options) : sendNativeFetchRequest('delete', path, body, options)
// function apiShowToast(message, msgType) {
//   Toast.show({
//     type: msgType,
//     text: message,
//     // duration: 1000, //ปรับ 1 วิก่อน เพื่อ Dev
//     duration: 3000,
//     position: 'bottom',
//     buttonText: 'X',
//   })
// }

/**
 * Customize with Spinner
 * Make arbitrary fetch request to a path relative to API root url
 * @param {String} method One of: get|post|put|delete
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 */

// // For debugging
// axios.interceptors.request.use((request) => {
//   console.log('Starting Request', JSON.stringify(request, null, 2))
//   return request
// })
// axios.interceptors.response.use((response) => {
//   console.log('Response:', JSON.stringify(response, null, 2))
//   return response
// })

// Wrapped for open indicator
async function sendFetchRequest(
  method: ApiRequestType,
  path: string,
  body?: { [key: string]: any },
  options: IApiOptions = initialOptions
) {
  // console.log('sendFetchRequest...')

  // Injecting path to ctoken if have
  if (body && body.ctoken) {
    body.ctoken += path.replace('/', '-')
    // log('sendFetchRequest new body => ', body)
  }

  // Destructure api options
  const {
    showSpinner = false,
    isApiV2 = false,
    axiosOptions = {},
    fetchOptions = {},
    messages, // toast messages
  } = options

  // เปิด Spinner หน้า App (default)
  if (showSpinner) {
    p.op.showLoadingIndicator() // <----- Add indicator Loading
  }

  // ดูว่า endpoint เป็น laravel หรือ go
  let endpoint = url(path)
  if (isApiV2) {
    endpoint = urlV2(path)
  }

  // สร้าง header ที่อาจจะมี token แนบมา หรือ ไม่แนบมา ก็ได้
  const headers = await getRequestHeaders(options)

  // ถ้ามี body ให้ส่งไปบอก backend ว่าข้อมูลเป็น json
  if (_.isObject(body)) {
    headers['Content-Type'] = 'application/json'
  }

  // อันนี้ดูว่าควรแนบ body ไปหรือไม่ โดย axios จะใช้ field ชื่อ data
  // const axiosComputedOptions = _.isObject(body) ? { method, headers, data: JSON.stringify(body) } : { method, headers }
  const axiosComputedOptions = !_.isNil(body) ? { method, headers, data: body } : { method, headers }

  // init custom options
  let retryLimit = _.has(axiosOptions, 'retry') ? axiosOptions.retry : 3
  if (retryLimit === 0) {
    // ถ้าใส่ retry = 3 มาหมายความว่า fetch ทั้งหมด 3 ครั้ง
    // ถ้าใส่ 0 หรือ 1 มา หมายถึง fetch ครั้งเดียว
    retryLimit = 1
  }
  const retryDelay = _.has(axiosOptions, 'retryDelay') ? axiosOptions.retryDelay : 1000

  // axios config ทุกอย่างที่ใช้ในการ fetch รวมอยู่ในนี้
  const axiosCombinedParams = {
    url: endpoint,
    // retry: 3,
    // retryDelay: 1000,
    // timeout: 60000,
    timeout: DEFAULT_TIMEOUT,
    // ...options,
    ...axiosComputedOptions,
    ...axiosOptions,
    // timeout: 5000,
  }

  // เป็น nested try-catch
  // ชั้นนอกเป็น try ปกติเพื่อส่งค่าออกไปข้างนอก
  // ชั้นในสุดเป็๋น retry loop เพื่อควบคุมการ retry ของการ fetch เฉพาะ error ที่เกิดขึ้นจาก timeout เท่านั้น
  try {
    // console.log('sendRequest axios axiosCombinedParams => ', axiosCombinedParams)
    // return axios(axiosCombinedParams)

    const resMap = {}
    let n = 0

    while (n < retryLimit) {
      n++

      // timeout จะเพิ่มขึ้นทีละ 5000ms ต่อครั้งที่ retry เริ่มจาก n = 2
      const timeoutByN = (n - 1) * 5000 + axiosCombinedParams.timeout
      // console.log(`do fetch #${n}`)
      try {
        // resMap[n] = await axios({ ...axiosCombinedParams, timeout: timeoutByN })
        resMap[n] = await axios({ ...axiosCombinedParams, timeout: timeoutByN })
        await logResponseToReactotron(resMap[n], method, path, body)
        // console.log(`fetch #${n} ${path} with timeout=${timeoutByN} res => `, resMap[n])
        break
      } catch (err) {
        // @ts-ignore
        const responseErr = err.response
        await logResponseToReactotron(responseErr, method, path, body)
        // console.log('sendRequest axios responseErr => ', responseErr)
        const isTimeoutresponseError = responseErr && responseErr.request ? responseErr.request._timedOut : false
        if (isTimeoutresponseError) {
          await delay(retryDelay)
          continue
        }
        throw err
      }

      // if (!isError(resMap[n])) {
      //   break
      // }
      // const isTimeoutError = resMap[n] && resMap[n].request ? resMap[n].request._timedOut : false
      // if (isTimeoutError) {
      //   await delay(retryDelay)
      //   continue
      // }
      // throw { ...resMap[n] }
    }

    // Safety logic ถ้าหากว่า api success แต่ response เป็น error ทำการ throw error
    if (resMap[n] && resMap[n].data && resMap[n].data.error) {
      throw resMap[n]
    }

    // console.log('sendRequest axios resMap[n] => ', resMap[n])
    // console.log('resMap[n] axios resMap[n] => ', JSON.stringify(resMap[n]))
    if (showSpinner) {
      p.op.hideLoadingIndicator() // <----- Remove indicator Loading
    }
    if (messages && messages.successMsg) {
      p.op.showToast(messages.successMsg, 'success')
    }
    // console.log(`do fetch return #${n} resMap[n] => `, resMap[n])
    return resMap[n] && resMap[n].data ? resMap[n].data : {}
  } catch (e) {
    // เป็น error ที่มาจาก axios ตรงๆ จะปกติแล้วควรมี { response, request, data? }
    // แต่ก็ควร handle ไว้เผื่อ case ไม่คาดฝัน
    // console.log('error axios e => ', e)

    // ถอด error response จาก axios
    let errData
    // @ts-ignore
    if (e && e.data) {
      // @ts-ignore
      errData = e.data
      // @ts-ignore
    } else if (e && e.response && e.response.data) {
      // @ts-ignore
      errData = e.response.data
    } else {
      errData = e
    }

    // console.log('error axios errData => ', errData)

    await handleGlobalApiError(errData, options)
    // console.log('error axios e => ', e.request)
    // if (e.response) {
    //   // The request was made and the server responded with a status code
    //   // that falls out of the range of 2xx
    //   // console.log('e.response.data => ', e.response.data)
    //   // console.log('e.response.status. => ', e.response.status)
    //   // console.log('e.response.headers => ', e.response.headers)
    // } else if (e.request) {
    //   // The request was made but no response was received
    //   // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
    //   // http.ClientRequest in node.js
    //   // console.log('e.request => ', e.request)
    // } else {
    //   // Something happened in setting up the request that triggered an Error
    //   // console.log('Error', e.message)
    // }
    if (showSpinner) {
      p.op.hideLoadingIndicator() // <----- Remove indicator Loading
    }
    if (messages && messages.errorMsg) {
      p.op.showToast(messages.errorMsg, 'danger')
    }

    throw errData
  }
}

// ใช้ fetch แทน axios
async function sendNativeFetchRequest(method: ApiRequestType, path: string, body?: any, options: IApiOptions = initialOptions) {
  // console.log('sendFetchRequest...')

  // Injecting path to ctoken if have
  if (body && body.ctoken) {
    body.ctoken += path.replace('/', '-')
    // log('sendFetchRequest new body => ', body)
  }

  // Destructure api options
  const {
    showSpinner = false,
    isApiV2 = false,
    fetchOptions = {},
    messages, // toast messages
  } = options

  const apiTimeout = fetchOptions.timeout ? fetchOptions.timeout : DEFAULT_TIMEOUT

  // เปิด Spinner หน้า App (default)
  if (showSpinner) {
    p.op.showLoadingIndicator() // <----- Add indicator Loading
  }

  // ดูว่า endpoint เป็น laravel หรือ go
  let endpoint = url(path)
  if (isApiV2) {
    endpoint = urlV2(path)
  }

  // สร้าง header ที่อาจจะมี token แนบมา หรือ ไม่แนบมา ก็ได้
  let headers = await getRequestHeaders(options)

  // ถ้ามี body ให้ส่งไปบอก backend ว่าข้อมูลเป็น json
  if (_.isObject(body)) {
    headers['Content-Type'] = 'application/json'
  }

  const fetchOptionsWithoutHeaders = { ...fetchOptions }
  if (fetchOptionsWithoutHeaders.headers) {
    delete fetchOptionsWithoutHeaders.headers
  }

  if (fetchOptions && fetchOptions.headers) {
    headers = { ...headers, ...fetchOptions.headers }
  }

  // อันนี้ดูว่าควรแนบ body ไปหรือไม่ โดย axios จะใช้ field ชื่อ data
  const fetchComputedOptions: RequestInit = { method, headers }
  if (!_.isNil(body)) {
    if (body instanceof FormData) {
      fetchComputedOptions.body = body
      // } else if (body instanceof OtherType) {
      // // other compute-op
    } else {
      fetchComputedOptions.body = JSON.stringify(body)
    }
  }

  // axios config ทุกอย่างที่ใช้ในการ fetch รวมอยู่ในนี้
  const fetchCombinedOptions = {
    ...fetchComputedOptions,
    ...fetchOptionsWithoutHeaders,
  }

  try {
    // const res = await fetch(endpoint, fetchCombinedOptions)
    const res = await fetchWithTimeout(endpoint, fetchCombinedOptions, apiTimeout)

    let parsedResBody
    const contentType = res.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      parsedResBody = await res.json()
    } else {
      parsedResBody = res.text()
    }

    await logFetchResponseToReactotron(res.status, parsedResBody, method, path, body)

    // console.log('sendNativeFetchRequest parsedResBody => ', parsedResBody)
    // Safety logic ถ้าหากว่า api success แต่ response เป็น error ทำการ throw error
    if (parsedResBody && parsedResBody.error) {
      throw parsedResBody
    }

    if (showSpinner) {
      p.op.hideLoadingIndicator() // <----- Remove indicator Loading
    }
    if (messages && messages.successMsg) {
      p.op.showToast(messages.successMsg, 'success')
    }
    return parsedResBody
  } catch (err) {
    // console.log('sendNativeFetchRequest err => ', err)
    await handleGlobalApiError(err, options)
    if (showSpinner) {
      p.op.hideLoadingIndicator() // <----- Remove indicator Loading
    }
    if (messages && messages.errorMsg) {
      p.op.showToast(messages.errorMsg, 'danger')
    }

    throw err
  }
}

function fetchWithTimeout(url: string, options: RequestInit, timeoutMs = 7000) {
  return Promise.race([
    fetch(url, options),
    new Promise<Response>((accept, reject) =>
      setTimeout(() => reject(new Error(`Exceeded timeout of ${timeoutMs} ms for ${url}`)), timeoutMs)
    ),
  ])
}

export async function handleGlobalApiError(err: IXSellyErrorResponse, options: IApiOptions) {
  const {
    isErrorAlertDisabled = false,
    axiosOptions,
    onUserCloseAlert,
    //
  } = options
  // console.log('handleGlobalApiError err => ', err)

  // ถ้าปิดการ alert จาก apiOptions
  if (isErrorAlertDisabled) {
    return
  }

  // ถ้าไม่ใช่ XSelly Error Response
  if (!isXSellyErrorResponse(err)) {
    return
  }
  const xErr: IXSellyError = err.error
  // console.log('handleGlobalApiError xErr => ', xErr)

  const doAlertWithPromise = async (tt, mm, cu = null, ct = null) => {
    // cu = clip url && ct = clip text // Ref : https://app.clickup.com/t/860qa274d
    if (!_.isNil(cu) && !_.isNil(ct)) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      await p.op.showConfirmationPromise(
        tt,
        mm,
        () => Linking.openURL(cu),
        () => null,
        ct,
        'ปิด'
      )
    } else {
      await p.op.alertPromise(tt, mm)
    }
    if (_.isFunction(onUserCloseAlert)) {
      await onUserCloseAlert(xErr)
    }
  }
  const {
    error_code,
    status_code,
    attach,
    message,
    //
  } = xErr

  // ------- Special Specific Cases ----------
  const isUnAuth = status_code === 401 || error_code === CONS.ERR_CODE.TOKEN_INVALID || error_code === CONS.ERR_CODE.TOKEN_EXPIRED
  if (isUnAuth) {
    // Un-Authorized
    await p.op.signOut()
    // await p.op.alertPromise('aaaaaa xErr => ' + err)
    p.op.showToast('ไม่มีรหัสเข้าใช้งาน หรือรหัสเข้าใช้งานหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง', 'warning')
    return
  }

  if (status_code === 403) {
    let msg
    switch (error_code) {
      case CONS.ERR_CODE.PERMISSION_DENIED:
        msg = 'ไม่สามารถดำเนินการต่อได้ เนื่องจากขาดสิทธิ์ในการใช้งาน'
        break
      case CONS.ERR_CODE.LOGIN_ACCESS_DENIED:
        msg = 'ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบอีเมลและรหัสผ่านอีกครั้ง'
        break
      default:
        msg = message
    }
    await doAlertWithPromise('กรุณาลองใหม่อีกครั้ง', msg)
    p.op.aLogEvent(CONS.ALOG.API_ERROR_PERM_DENIED, { message })
    return
  }

  // ------- Parameter Missing Case ----------
  if (status_code === 422) {
    // alertUnprocessableEntity(message, path)
    const uemObj = getUnprocessableEntityMesssages(err)
    let title = uemObj && uemObj.title ? uemObj.title : null
    let msg = uemObj && uemObj.msg ? uemObj.msg : null
    if (msg) {
      msg = msg.trim()
    } else {
      msg = null
    }
    if (msg === 'The receiver.0.postal_code field is required.') {
      msg = 'กรุณาระบุรหัสไปรษณีย์ของผู้รับ'
    } else if (msg === 'The receiver.0.name field is required.') {
      msg = 'กรุณาระบุชื่อผู้รับ'
    } else if (msg === 'The new_address.0.address3 field is required.') {
      msg = 'กรุณาระบุที่อยู่ผู้รับ'
    } else if (msg === 'The new_address.0.postal_code field is required.') {
      msg = 'กรุณาระบุรหัสไปรษณีย์'
    } else if (msg === CONS.ERR_CODE.EMAIL_DUPLICATE) {
      title = 'อีเมลนี้ถูกใช้งานไปแล้ว'
      msg =
        'หากคุณเป็นเจ้าของอีเมลและต้องการเข้าสู่ระบบด้วยอีเมล กรุณากลับไปที่หน้าแรกของแอป (หน้าล็อกอิน) แล้วกดปุ่ม “เข้าสู่ระบบด้วยอีเมล“ แทน'
    } else if (msg === '422 Unprocessable Entity') {
      msg = 'กรุณาระบุข้อมูลให้ครบถ้วน'
    } else if (msg === `The new_address.0.postal_code field is required.`) {
      msg = 'กรุณาระบุรหัสไปรษณีย์'
    } else if (msg === `The receiver.0.postal_code must be integer.`) {
      msg = 'กรุณาระบุรหัสไปรษณีย์ในรูปแบบที่ถูกต้อง'
    }

    if (msg) {
      await doAlertWithPromise(title, msg)
    } else {
      p.op.aLogEvent(CONS.ALOG.API_ERROR_UNPROCESSABLE_ENTITY, xErr)
      await doAlertWithPromise('เกิดข้อผิดพลาด', `กรุณาติดต่อทีมงาน ${JSON.stringify(xErr)}`)
    }
    return
  }

  // Specific case from Artid
  if (error_code === CONS.ERR_CODE.LEAST_ONE_PRINTING_TYPE_NEED_ACTIVE) {
    // No Action Hadel From Feil Respone
    return
  }

  // ------- Common Cases (ระหว่าง 400 - 500 นอกเหนือจากเคสด้านบนทั้งหมด) ----------
  if (status_code >= 400) {
    let tt = null
    let mm = null

    const cu = parseXSellyErrorToURL(xErr)
    const ct = parseXSellyErrorToURLText(xErr)

    // cu = parseXSellyErrorToURL(xErr)
    // ct = parseXSellyErrorToURLText(xErr)

    if (isXSellyErrorV2(err)) {
      // console.log('Check Error V2 xErr => ', xErr)
      // ถอด generic eror V1
      tt = parseXSellyErrorToTitleText(xErr)
      mm = parseXSellyErrorToMessageText(xErr)
    }

    // ถ้ามี V2 error code จะ alert แล้ว return ออกไปก่อน
    if (mm) {
      // console.log('Return Error V2  xErr => ', xErr)
      await doAlertWithPromise(tt, mm, cu, ct)
      return
    }

    if (isXSellyErrorV1(err)) {
      // console.log('Check Error V1 xErr => ', xErr)
      // ถอด generic error V1
      // FIXME: ใช้ประโยชน์จากฟังก์ชั่นเก่าโดยไม่สนใจ path (เนื่องจากมี specific case ด้านบนมากพอแล้ว)
      //  @ts-ignore
      const { title = null, msg = null } = getErrMessageObj(err, '') || {}
      tt = title
      mm = msg
    }

    // ถ้าสามารถถอด generic error code ได้ V1+V2
    if (mm) {
      await doAlertWithPromise(tt, mm, cu, ct)
      return
    }

    // Worst Unknown case กรณีที่ถอด error อะไรไม่ได้เลย
    p.op.aLogEvent(CONS.ALOG.API_CANNOT_PARSE_ERROR, xErr)
    await doAlertWithPromise('เกิดข้อผิดพลาด', `${CONTACT_TO_HLEP_TXT} (${error_code})`)
  }
}

// function isError(e) {
//   return e && e.stack && e.message && typeof e.stack === 'string' && typeof e.message === 'string'
// }

// // fetchWithAxios เป็นแค่ wrapper function ทางผ่านเพื่อใช้ทำ retry
// // ถ้าไม่จำเป็นไม่ควรแก้ เพราะไป handling error ที่ปลายทางเอาได้
// async function fetchWithAxios(axiosParams) {
//   return new Promise((resolve) => {
//     axios(axiosParams)
//       .then(resolve)
//       .catch((error) => {
//         resolve((error.response || {}).data)
//       })
//   })
//   // try {
//   //   return await axios(axiosParams)
//   // } catch (err) {
//   //   console.log('fetchWithAxios err => ', err)
//   //   return err
//   // }
// }

/** (Default)
 * All HTTP errors are emitted on this channel for interested listeners
 */
// export const errors = new EventEmitter()

// /**
//  * GET a path relative to API root url.
//  * @param {String}  path Relative path to the configured API endpoint
//  * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
//  * @returns {Promise} of response body
//  */
// export async function get(path, suppressRedBox) {
//   return bodyOf(request('get', path, null, suppressRedBox))
// }

// /**
//  * POST JSON to a path relative to API root url
//  * @param {String} path Relative path to the configured API endpoint
//  * @param {Object} body Anything that you can pass to JSON.stringify
//  * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
//  * @returns {Promise}  of response body
//  */
// export async function post(path, body, suppressRedBox) {
//   return bodyOf(request('post', path, body, suppressRedBox))
// }

// /**
//  * PUT JSON to a path relative to API root url
//  * @param {String} path Relative path to the configured API endpoint
//  * @param {Object} body Anything that you can pass to JSON.stringify
//  * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
//  * @returns {Promise}  of response body
//  */
// export async function put(path, body, suppressRedBox) {
//   return bodyOf(request('put', path, body, suppressRedBox))
// }

// /**
//  * DELETE a path relative to API root url
//  * @param {String} path Relative path to the configured API endpoint
//  * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
//  * @returns {Promise}  of response body
//  */
// export async function del(path, suppressRedBox) {
//   return bodyOf(request('delete', path, null, suppressRedBox))
// }

// /**
//  * Make arbitrary fetch request to a path relative to API root url
//  * @param {String} method One of: get|post|put|delete
//  * @param {String} path Relative path to the configured API endpoint
//  * @param {Object} body Anything that you can pass to JSON.stringify
//  * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
//  */
// export async function request(method, path, body, suppressRedBox) {
//   try {
//     const response = await sendRequest(method, path, body, suppressRedBox)
//     return handleResponse(
//       path,
//       response,
//     )
//   }
//   catch (error) {
//     if (!suppressRedBox) {
//       logError(error, url(path), method)
//     }
//     throw error
//   }
// }

/**
 * Takes a relative path and makes it a full URL to API server
 */
export function url(path: string): string {
  const apiRoot = getConfiguration('API_ROOT')
  return path.indexOf('/') === 0 ? apiRoot + path : `${apiRoot}/${path}`
}

export function urlV2(path) {
  const apiRoot = getConfiguration('GO_API_ROOT')
  return path.indexOf('/') === 0 ? apiRoot + path : `${apiRoot}/${path}`
}

// /**
//  * Constructs and fires a HTTP request
//  */
// // async function sendRequest(method, path, body, suppressRedBox) {
// async function sendRequest(
//   method: string,
//   path: string,
//   body?: { [key: string]: any },
//   options: IApiOptions = initialOptions,
//   suppressRedBox?: boolean
// ) {
//   try {
//     let endpoint = url(path)
//     if (options.isApiV2) {
//       endpoint = urlV2(path)
//     }

//     const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
//     log('sendRequest token => ', token)
//     const noTokenFetching = _.has(options, 'noToken')
//     if (method !== 'get' && !_.includes([api.POST_LOGIN, api.POST_FB_LOGIN], path) && (_.isNull(token) || _.isUndefined(token))) {
//       if (!noTokenFetching) {
//         throw new Error('No token to send request API')
//       }
//     }

//     const headers = noTokenFetching ? getRequestHeaders(body, null) : getRequestHeaders(body, token)
//     // const options = body
//     //   ? { method, headers, body: JSON.stringify(body) }
//     //   : { method, headers }
//     // return timeout(fetch(endpoint, options), DEFAULT_TIMEOUT)
//     // const options = body
//     const axiosOptions = body ? { method, headers, data: JSON.stringify(body) } : { method, headers }
//     // any mean axios config
//     const reqParams: any = {
//       url: endpoint,
//       retry: 3,
//       retryDelay: 1000,
//       timeout: DEFAULT_TIMEOUT,
//       // ...options,
//       ...axiosOptions,
//       ...options.axiosOptions,
//     }
//     // console.log('sendRequest axios reqParams => ', reqParams)
//     // return axios(reqParams)
//     const res = await axios(reqParams)
//     p.op.hideLoadingIndicator()
//     // console.log('sendRequest axios res => ', res)
//     return res
//   } catch (e) {
//     // console.log('error axios e => ', e)
//     if (e.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       // console.log('e.response.data => ', e.response.data);
//       // console.log('e.response.status. => ', e.response.status);
//       // console.log('e.response.headers => ', e.response.headers);
//     } else if (e.request) {
//       // The request was made but no response was received
//       // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
//       // http.ClientRequest in node.js
//       // console.log('e.request => ', e.request);
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       // console.log('Error', e.message);
//     }
//     // console.log('e.config => ', e.config);
//     p.op.hideLoadingIndicator() // <----- Off indicator when error
//     // throw new Error(e)
//     throw e
//   }
// }

function delay(t) {
  return new Promise((resolve) => setTimeout(resolve, t))
}

// /**
//  * Receives and reads a HTTP response
//  */
// export async function handleResponse(
//   path: string,
//   response: AxiosResponse,
//   // toastMessages: IToastMessage = inittialToastMessage,
//   options: IApiOptions = initialOptions,
//   debug?: any
// ) {
//   try {
//     log('handleResponse => ', {
//       path,
//       response,
//       // toastMessages,
//       options,
//       debug,
//     })

//     const status = response.status
//     const statusText = response.statusText || undefined
//     let errorCode = null
//     // let status_code = response.status
//     if (response.data && response.data.error) {
//       errorCode = response.data.error.error_code
//       // status_code = error.status_code
//     }
//     // log('handleResponse.status: ' + status)
//     // log(response)
//     // XSelly แทรกการบังคับให้กลับไปหน้า Login ถ้าหาก unauthorized

//     if (MODE === 'dev' && status > 201) {
//       // FIXME: Dev only => throw to Error page
//       // const m = await getErrorMessageSafely(response)
//       const m = statusText
//       const e = new HttpError(status, m)

//       if (!p.op.isWeb()) {
//         // Inject reactotron in dev mode
//         logResponseToReactotron(
//           {
//             status,
//             body: response && response.data ? response.data : {},
//             message: m,
//             error: e,
//           },
//           debug.method,
//           path,
//           debug.body
//         )
//         // TODO: Enable this for native?
//         // store.dispatch(NavActions.navToErrorView({ message: e.message }))
//       }
//     }

//     // `fetch` promises resolve even if HTTP status indicates failure. Reroute
//     // promise flow control to interpret error responses as failures
//     if (status >= 400) {
//       // const message = await getErrorMessageSafely(response)
//       // const error = new HttpError(status, message)
//       const message = statusText
//       const error = new HttpError(status, message, response.data)

//       // emit events on error channel, one for status-specific errors and other for all errors
//       errors.emit(status.toString(), { path, message: error.message })
//       errors.emit('*', { path, message: error.message }, status)

//       // keng
//       // log('handleResponse message => ', message)
//       // const resJson = JSON.parse(message)
//       // // log(resJson)
//       //
//       // let errorCode
//       // // let status_code
//       // if (resJson.error) {
//       //   errorCode = resJson.error.error_code
//       //   // status_code = resJson.error.status_code
//       // }

//       // log('handleResponse.error_code: ' + error_code)

//       // Clear token if Unauthorized
//       if (options && options.isErrorAlertDisabled) {
//         // no-op (bypass alert)
//       } else if (status === 400) {
//       } else if (status === 401 || errorCode === CONS.ERR_CODE.TOKEN_INVALID) {
//         // errorCode === CONS.ERR_CODE.USER_NOT_FOUND ||
//         p.op.signOut()
//         p.op.aLogEvent(CONS.ALOG.API_ERROR_UNAUTH, { path, message })
//         p.op.alert('กรุณาล็อกอินใหม่อีกครั้ง')
//         // auth.clearAuthenticationToken().then(() => {
//         //   return goBackToLoginScreen()
//         // })
//       } else if (status === 403) {
//         let msg
//         switch (errorCode) {
//           case CONS.ERR_CODE.PERMISSION_DENIED:
//             msg = 'ไม่สามารถดำเนินการต่อได้ เนื่องจากขาดสิทธิ์ในการใช้งาน'
//             break
//           case CONS.ERR_CODE.LOGIN_ACCESS_DENIED:
//             msg = 'ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบอีเมลและรหัสผ่านอีกครั้ง'
//             break
//           default:
//             msg = message
//         }
//         // log('403 msg: ' + msg + ' errorCode: ' + error_code + ' ERR: ' + CONS.ERR_CODE.PERMISSION_DENIED)
//         p.op.alert(msg)
//         p.op.aLogEvent(CONS.ALOG.API_ERROR_PERM_DENIED, { message })
//       } else if (status === 404) {
//         if (errorCode === CONS.ERR_CODE.NOT_FOUND) {
//           // No Action
//         } else if (errorCode === CONS.ERR_CODE.NOT_TEAM) {
//           p.op.showConfirmationOkOnly(
//             '"รหัสลูกทีม" ไม่ถูกต้อง',
//             `
//           หากต้องการสั่งให้ส่งให้ตัวเอง กรุณาลบข้อมูลในช่อง "เปิดให้ลูกทีม" ให้ว่างไว้
//           หากต้องการเปิดออเดอร์ในนามลูกทีม กรุณาตรวจสอบว่า ลูกทีมนี้อยู่ในสายงานของคุณ และกรอกรหัสลูกทีมถูกต้อง (${errorCode})`
//           )
//         } else if (errorCode === CONS.ERR_CODE.ADDRESS_NOT_FOUND) {
//           p.op.showConfirmationOkOnly('', `ที่อยู่ผู้รับไม่ถูกต้อง กรุณาตรวจสอบ แล้วลองอีกครั้ง`)
//         } else if (errorCode === CONS.ERR_CODE.STORE_NOT_FOUND) {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `ไม่พบร้านค้า (${errorCode})`)
//         } else if (errorCode === CONS.ERR_CODE.PAYMENT_ACCOUNT_NOT_FOUND) {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `ไม่พบบีญชีเพื่อชำระ (${errorCode})`)
//         } else {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${errorCode}`)
//         }
//       } else if (status === 422) {
//         // alertUnprocessableEntity(message, path)
//         const err = new HttpError(status, message, response.data)
//         const uemObj = getUnprocessableEntityMesssages(err)
//         let title = uemObj && uemObj.title ? uemObj.title : null
//         let msg = uemObj && uemObj.msg ? uemObj.msg : null
//         if (msg) {
//           msg = msg.trim()
//         } else {
//           msg = ''
//         }
//         if (msg === 'The receiver.0.postal_code field is required.') {
//           msg = 'กรุณาระบุรหัสไปรษณีย์ของผู้รับ'
//         } else if (msg === 'The receiver.0.name field is required.') {
//           msg = 'กรุณาระบุชื่อผู้รับ'
//         } else if (msg === 'The new_address.0.address3 field is required.') {
//           msg = 'กรุณาระบุที่อยู่ผู้รับ'
//         } else if (msg === 'The new_address.0.postal_code field is required.') {
//           msg = 'กรุณาระบุรหัสไปรษณีย์'
//         } else if (msg === CONS.ERR_CODE.EMAIL_DUPLICATE) {
//           title = 'อีเมลนี้ถูกใช้งานไปแล้ว'
//           msg =
//             'หากคุณเป็นเจ้าของอีเมลและต้องการเข้าสู่ระบบด้วยอีเมล กรุณากลับไปที่หน้าแรกของแอป (หน้าล็อกอิน) แล้วกดปุ่ม “เข้าสู่ระบบด้วยอีเมล“ แทน'
//         } else if (msg === '422 Unprocessable Entity') {
//           msg = 'กรุณาระบุข้อมูลให้ครบถ้วน'
//         } else if (msg === `The new_address.0.postal_code field is required.`) {
//           msg = 'กรุณาระบุรหัสไปรษณีย์'
//         } else if (msg === `The receiver.0.postal_code must be integer.`) {
//           msg = 'กรุณาระบุรหัสไปรษณีย์ในรูปแบบที่ถูกต้อง'
//         }
//         p.op.alert(title, msg)
//         p.op.aLogEvent(CONS.ALOG.API_ERROR_UNPROCESSABLE_ENTITY, { message })
//       } else if (status === 500) {
//         if (errorCode === CONS.ERR_CODE.CREATE_KAFKA_EVENT_FAIL) {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${CONTACT_TO_HLEP_TXT} (${errorCode})`)
//         } else if (errorCode === CONS.ERR_CODE.JSON_DECODE_FAIL) {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${CONTACT_TO_HLEP_TXT} (${errorCode})`)
//         } else if (errorCode === CONS.ERR_CODE.PROFILE_INVALID) {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${CONTACT_TO_HLEP_TXT} (${errorCode})`)
//         } else if (errorCode === CONS.ERR_CODE.PAYLOAD_INVALID) {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${CONTACT_TO_HLEP_TXT} (${errorCode})`)
//         } else if (errorCode === CONS.ERR_CODE.INTERNAL_SERVER_ERROR) {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${CONTACT_TO_HLEP_TXT} (${errorCode})`)
//         } else {
//           p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${CONTACT_TO_HLEP_TXT} (${errorCode})`)
//         }
//       } else if (response.data.error && errorCode !== CONS.ERR_CODE.TOKEN_EXPIRED) {
//         const errMessageObj = getErrMessageObj(response.data, path) || {}
//         // @ts-ignore temp fix เวลาไม่สามารถหา error message ได้ เนื่องจากโครงสร้าง error ของหลังบ้านไม่เหมือนเดิม
//         const { title = null, msg = null } = errMessageObj
//         if (msg && msg.length > 0) {
//           p.op.alert(title, msg)
//         }
//       } else if (options.messages && options.messages.errorMsg) {
//         p.op.showToast(options.messages.errorMsg, 'danger')
//         p.op.aLogEvent(CONS.ALOG.API_ERROR_UNKNOWN, { message })
//         // if (toastMessages.errorMsg) {
//         //   // apiShowToast(toastMessages.errorMsg, MSG_TYPE.WARN)
//         //   // showToast(toastMessages.errorMsg, 'warning')
//         //   p.op.showToast(toastMessages.errorMsg, 'danger')
//         // }
//       } else {
//         p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `${CONTACT_TO_HLEP_TXT} (${status})`)
//       }

//       throw error
//     }

//     // XSelly แทรก showToast
//     if (options.messages && options.messages.successMsg) {
//       // apiShowToast(toastMessages.successMsg, MSG_TYPE.OK)
//       p.op.showToast(options.messages.successMsg, 'success')
//     }

//     // parse response text
//     // let responseBody = await response.text()

//     const responseBody = response && response.data ? response.data : null
//     // log('handleResponse responseBody => ', responseBody)
//     // if (responseBody === 'ok') {
//     //   responseBody = '{ "ok": "true" }'
//     // }

//     // log('handleResponse responseBody => ', responseBody)
//     if (!p.op.isWeb()) {
//       // Inject reactotron in dev mode
//       logResponseToReactotron(
//         {
//           status,
//           headers: response.headers,
//           body: responseBody ? responseBody : null,
//         },
//         debug.method,
//         path,
//         debug.body
//       )
//     }

//     if (!responseBody || _.isEmpty(responseBody)) {
//       throw new Error('Empty response from server.')
//     }

//     // return {
//     //   status: response.status,
//     //   headers: response.headers,
//     //   body: responseBody ? JSON.parse(responseBody) : null,
//     // }
//     return {
//       status: response.status,
//       headers: response.headers,
//       body: responseBody ? responseBody : null,
//     }
//   } catch (e) {
//     log('Error in handleResponse in api.ts e => ', e)
//     // FIXME: Dev only => throw to Error page
//     // store.dispatch(NavActions.navToErrorView({ message: JSON.stringify(e) }))
//     throw e
//   }
// }

// TODO: Make it available only to web
async function logResponseToReactotron(response, method, path, body = null) {
  // if (p.op.isWeb()) {
  //   // it's native. Do nothing
  //   return
  // }
  // if (process.env.NODE_ENV !== 'production' || !response) {
  if (MODE === 'dev') {
    // @ts-ignore ถ้าเปิดใช้งาน Reactotron ได้ append tron เข้าไปใน console
    if (console.tron && _.isFunction(console.tron.display)) {
      const name = response && response.status === 200 ? `${method} :: API RESPONSE`.toUpperCase() : `${method} :: API ERROR`.toUpperCase()
      const preview = `Log from ${method} :: ${path}`
      // @ts-ignore
      console.tron.display({
        name,
        preview,
        value: { response, requestBody: body },
        important: response && response.status !== 200,
        // image: 'http://placekitten.com/g/400/400'
      })
    }
  }
}

async function logFetchResponseToReactotron(responseStatus = 0, responseBody, method, path, body = null) {
  // if (p.op.isWeb()) {
  //   // it's native. Do nothing
  //   return
  // }
  // if (process.env.NODE_ENV !== 'production' || !response) {
  if (MODE === 'dev') {
    // @ts-ignore ถ้าเปิดใช้งาน Reactotron ได้ append tron เข้าไปใน console
    if (console.tron && _.isFunction(console.tron.display)) {
      try {
        const name = responseStatus === 200 ? `${method} :: API RESPONSE`.toUpperCase() : `${method} :: API ERROR`.toUpperCase()
        const preview = `Log from ${method} :: ${path}`
        // @ts-ignore
        console.tron.display({
          name,
          preview,
          value: {
            responseStatus,
            response: responseBody,
            requestBody: body,
          },
          important: responseStatus !== 200,
          // image: 'http://placekitten.com/g/400/400'
        })
      } catch (err) {
        console.log('logFetchResponseToReactotron err => ', err)
      }
    }
  }
}

async function getRequestHeaders(options: IApiOptions = initialOptions) {
  const headers = {
    Accept: 'application/json',
  }

  if (options && options.noToken) {
    return headers
  }

  const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)

  if (!token) {
    return headers
  }

  let tokenWithBearer = token
  tokenWithBearer = tokenWithBearer.replace(/Bearer/g)
  tokenWithBearer = tokenWithBearer.trim()
  tokenWithBearer = `Bearer ${token}`

  return { ...headers, Authorization: tokenWithBearer }
}

// try to get the best possible error message out of a response
// without throwing errors while parsing
async function getErrorMessageSafely(response) {
  try {
    const body = await response.text()
    if (!body) {
      return ''
    }

    // Optimal case is JSON with a defined message property
    const payload = JSON.parse(body)
    if (payload && payload.message) {
      return payload.message
    }

    // Should that fail, return the whole response body as text
    return body
  } catch (e) {
    // Unreadable body, return whatever the server returned
    return response._bodyInit
  }
}

// /**
//  * Rejects a promise after `ms` number of milliseconds, it is still pending
//  * modified version ให้ try 2 ครั้ง ครั้งแรก 4 วิ ครั้งสองเพิ่มอีก 2 วิ ถ้าไม่เสร็จ Alert message
//  */
// function timeout(promise, ms = 4000, maxTries = 2) {
//   const tryNumber = maxTries - 1
//   // log('++++ START timeout maxTries => ', maxTries)
//   return new Promise((resolve, reject) => {
//     const timer = setTimeout(() => {
//       if (tryNumber <= 0) {
//         // log('Reject promise => ', promise, ' maxTries => ', maxTries)
//         p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้ หรือไม่ได้เชื่อมต่ออินเตอร์เน็ต')
//         clearTimeout(timer)
//         reject(new Error('timeout'))
//       } else if (tryNumber > 0) {
//         // log('Try fetch again with promise => ', promise, ' maxTries => ', maxTries)
//         clearTimeout(timer)
//         resolve(timeout(promise, ms + 2000, tryNumber))
//       } else {
//         clearTimeout(timer)
//         reject(new Error('timeout'))
//       }
//       // reject(new Error('timeout'))
//     }, ms)
//     promise
//       .then((response) => {
//         clearTimeout(timer)
//         resolve(response)
//       })
//       .catch((rejectAgain) => {
//         log(rejectAgain)
//         clearTimeout(timer)
//       })
//   })
// }

// function timeout(promise, ms, maxTries = 2) {
//   return new Promise((resolve, reject) => {
//     const timer = setTimeout(() => {
//       p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้ หรือไม่ได้เชื่อมต่ออินเตอร์เน็ต')
//       reject(new Error('timeout'))
//     }, ms)
//     promise.then(response => {
//       clearTimeout(timer)
//       resolve(response)
//     }).catch(reject)
//   })
// }

// async function bodyOf(requestPromise) {
//   try {
//     const response = await requestPromise
//     return response && response.data ? response.data : {}
//   } catch (e) {
//     throw e
//   }
// }

/**
 * Make best effort to turn a HTTP error or a runtime exception to meaningful error log message
 */
function logError(error, endpoint, method) {
  // if (error.status) {
  //   const summary = `(${error.status} ${error.statusText}): ${error._bodyInit}`
  //   console.error(`API request ${method.toUpperCase()} ${endpoint} responded with ${summary}`)
  // } else {
  //   console.error(`API request ${method.toUpperCase()} ${endpoint} failed with message "${error.message}"`)
  // }
  if (error.status) {
    const summary = `(${error.status} ${error.statusText}): ${error._bodyInit}`
    log(`API request ${method.toUpperCase()} ${endpoint} responded with ${summary}`)
  } else {
    log(`API request ${method.toUpperCase()} ${endpoint} failed with message "${error.message}"`)
  }
}

const api = {
  // GET
  GET_FETCH_INITIALIZE_DATA: 'init',
  GET_CUSTOMERS: 'customers',
  GET_PROFILE: 'user',
  GET_REFRESH: 'refresh',
  GET_SELLER_STORES: 'sellers', // Get Owned Rooms (ร้านที่เป็นตัวแทน) Body: -
  GET_STORES: 'stores', // Get Owned Rooms (ร้านที่เป็นเจ้าของ) Body: -
  GET_VERIFICATION_EMAIL: 'user/email/request',
  GET_SUBSCRIPTION: 'subscription',
  GET_GO_SUBSCRIPTION_DETAIL: 'subscription/detail',
  GET_EXCEL_PRODUCT_IMPORT_TEMPLATE: 'excel/template/product_add',

  GET_FLASH_LINK_ACCOUNT: 'flash/get_link_account_url',
  // GET_BANKACCOUNTS: 'accounts',
  // POST
  POST_LOGIN: 'auth/login', // Body => email:text, password:text
  POST_LOGOUT: 'auth/logout', // Body => email:text, password:text

  POST_FORGOT_PASSWORD: 'auth/recovery',
  POST_RESET_PASSWORD: 'auth/reset/app',

  POST_FB_LOGIN: 'auth/login/facebook', // Body => accessToken:text
  POST_FB_SIGNUP: 'auth/signup/facebook', // Body => accessToken:text / email:text
  POST_APPLE_LOGIN: 'auth/login/apple', // Body => accessToken:text
  POST_SIGNUP: 'auth/signup', // Body => email:text, password:text
  POST_STORE: 'store', // get store Detail Body => store_id
  POST_STORE_INFO: 'store_info', // get store info by info_fields
  POST_STORE_LEAVE: 'store/leave', // exit from this store { store_id }
  POST_BANKACCOUNTS: 'accounts',
  POST_PRODUCT: 'product', // get product    Body => store_id, product_id
  POST_PRODUCTS: 'products', // get products   Body => store_id
  POST_SELLER_PRODUCTS: 'products/pull', // get seller's products   Body => store_id, seller_store_id

  POST_MEMBERS: 'members', // เป็นตัวแทนแล้ว
  POST_MEMBERS_PRE_APPROVED: 'members/preapprove', // อนุมัติล่วงหน้าแล้วจาก facebook
  POST_APPLY_INVITE_CODE: 'user/apply/invite_code',
  POST_USER_GROUP_USER_LIST: 'user_group/user_list',
  // POST_RESELLER_PENDING_LIST: 'user_group/pending',
  POST_CHANGE_PENDING_USERS_STATUS: 'user_group/pending/change_status',
  POST_UG_ASSIGN_PGS: 'user_group/sync_product_groups',
  POST_ACCEPT_ALL_PENDING_USERS: 'user_group/accept_all_pending_users',

  POST_RESELLER_MEMBER_LIST: 'user_group/member',

  POST_CUSTOMER: 'customer', // get Customer   Body => profile_id
  POST_ORDERS: 'orders', // get Orders. Check API for body's attributes
  POST_ORDERS_QUERY: 'orders/query', // Advance order search
  POST_ORDER: 'order', // get OrderDetail
  POST_ORDER_CONFIRM: 'order/confirm',
  POST_ORDER_CANCEL: 'order/cancel',
  POST_ORDER_PAY: 'order/pay',
  POST_ORDER_MARKETPLACE: 'mkp/v2/order',
  POST_SHIPPING_COST_CALC: 'shipping_cost',
  // POST_ORDER_SHIP: 'order/ship',
  POST_PAYMENT_CONFIRM: 'payment/confirm',
  POST_PAYMENT_CANCEL: 'payment/cancel',
  POST_PAYMENTS: 'payments',
  POST_NOTIFICATION: 'noti',
  POST_SHIPPING_AUTO_COMPLETE: 'shipping/auto_complete',
  POST_SHIPPING_CANCEL: 'shipping/cancel',
  POST_REPORT_DAILY_SALES: 'report/dailysales',
  POST_FETCH_USER_GROUPS: 'user_groups',
  POST_FETCH_PRODUCT_GROUPS: 'product_groups',
  POST_RESELLER_CHANGE_USER_GROUP: 'user_group/joined/change_group',
  POST_GENERATE_USER_GROUP_INVITE_LINK: 'user_group/invite_link',

  POST_COPE_LINK_ORDER: 'order/public_link',
  POST_CANCEL_TACKING_CODE: 'shipjung/cancel_tracking',
  POST_CREATE_TACKING_CODE: 'order/xshipping',
  POST_UPDATE_TACKING_CODE: 'shipjung/update_tracking',
  POST_REQUEST_TRACKING: 'order/xshipping',
  // POST_AUTO_REQUEST_TRACKING: 'order/setting',

  POST_FETCH_ORDER_NOTES: 'order/note/options',
  POST_SELECT_ORDER_NOTES: 'order/note/option',
  POST_GET_SHIPPING_STATUS: 'order/shipping_status',

  POST_FIND_ADDRESSES: 'addresses',

  POST_STORE_USER: 'store_user',
  POST_ADD_CUSTOM_ORDER: 'store_user',
  POST_UPDATE_XSHIPPING_BALANCE: 'xshipping/balance',
  POST_GET_PERMISSION_MEMBER_LUST: 'permissions/helpers',
  POST_GET_PERMISSION_LIST: 'permissions',
  POST_REQUEST_CATEGORY_LIST: 'product_category',

  POST_GET_CUSTOMER: 'customer',
  POST_FETCH_PROFILE_LIST: 'organization/reseller_lists',
  POST_IMPORT_PROFILES: 'wrapper/import/org_members_create',
  POST_APPROALS_RESELLER: 'wrapper/user/accept_all_pending_users',
  POST_FETCH_PROFILE_DETAILS: 'org_member/member',
  POST_GET_RESEELER_ADDRESSES: 'order/org_member_addresses',
  POST_ORG_MEMBER_SIGNUP: 'org_member/signup',
  POST_SHOPEE_AUTH_URL: 'shopee/auth_url',
  POST_MKP_RE_AUTH_URL: 'mkp/v2/auth_url/reauth',
  POST_DELETE_MKP_CHANNEL: 'mkp/v2/channel/delete',
  POST_CHANGE_PARENT: 'org_member/reseller_change_parent',
  POST_COUNT_RESELLERS: 'org/count_all_my_resellers',
  POST_GET_CHANNEL: 'mkp/v2/channel',
  POST_GET_CHANNELS: 'mkp/v2/channels',
  POST_SET_PREF_TIMESLOT_: 'mkp/v2/timeslot/set',
  POST_GET_WAREHOUSE: 'warehouse',

  POST_FETCH_MKP_PRODUCT_BY_PT: 'mkp/product_by_pt_id',
  POST_FETCH_MKP_PRODUCT_BY_ITEM_UUID: 'mkp/product_by_item_uuid',
  POST_COMFIRM_ORDERS_MKP: 'mkp/order/confirm',
  POST_BACKGROUND_JOB_LIST: 'bg/jobs',
  POST_BACKGROUND_TASK_LIST: 'bg/tasks',
  POST_AUTOCOMPLETE_PAYMENT: 'bgjob/payment/autocomplete',
  POST_AUTOCOMPLETE_SHIPMENT: 'bgjob/shipping/autocomplete',

  POST_UN_AUTH_MKP: 'mkp/v2/cancel_auth_url',
  POST_UN_AUTH_SHOPIFY_MKP: 'mkp/v2/shopify/auth/cancel',
  POST_UN_AUTH_LINE: 'mkp/v2/line_shopping/auth/cancel',

  POST_SYNC_SHOP_INFO: 'mkp/v2/sync/info',
  POST_SYNC_MKP_ORDER: 'mkp/v2/sync/order',
  POST_SYNC_MKP_PRODUCT: 'mkp/v2/sync/product',

  POST_VALIDATE_QTY: 'custom_packing/validate_qty',

  POST_ORDER_COUNT: 'bg/job/order/count',
  POST_AUTO_COMPLETE_BY_SEGMENT: 'bg/job/order/auto_complete_by_segment',

  POST_SUNC_ALL_NEW_MKP_PRODUCT: 'mkp/v2/sync/product',

  POST_FIND_DUP_BY_RECEIVER_ADDRESS: 'order/find_dup_by_receiver_addr',
  POST_FETCH_SENDER_ADDRESSES: 'xshipping/sender_addresses',

  POST_REQUEST_AUTH_URL: 'mkp/v2/auth_url',
  POST_UPDATE_MKP_CHANNEL_DETAIL: 'mkp/v2/channel/update',

  POST_UPGRADE_SHOPEE_AUTH_TO_V_2: 'mkp/v2/auth_url/upgrade',

  POST_REQUEST_LINE_AUTH_URL: 'mkp/v2/line_shopping/auth/new',
  POST_REQUEST_LAZADA_AUTH_URL: 'mkp/v2/lazada/auth/new',
  POST_REQUEST_SHOPIFY_AUTH_URL: 'mkp/v2/shopify/auth/new',

  POST_MULTIPARCEL_LIST: 'multiparcel/list',
  POST_MULTIPARCEL_CREATE: 'multiparcel/create',
  POST_MULTIPARCEL_DELETE: 'multiparcel/delete',

  POST_SUBSCRIPTION_PACKAGE_LIST: 'subscription/package/list',
  POST_SUBSCRIPTION_ORDER_CREATE_APPSTORE: 'subscription/order/create/appstore',
  POST_ERP_CHANNELS: 'erp/channel/lists',
  POST_SUBSCRIPTION_ORDER_VAILIDATE: 'subscription/order/validate',

  POST_SPX_CREATE_ACCOUNT: 'xshipping/spx/create_account',
  XSHIPPING_DELETE_SYSTEM_ACCOUNT: 'xshipping/system_account/delete',

  // PUT
  PUT_CUSTOMER: 'customer', // Body => {customer profile + addresses}
  PUT_ADDRESS: 'address',
  PUT_CREATE_STORE: 'store', // Body => name (unique), description
  PUT_CREATE_PRODUCT_GROUP: 'product_group',
  PUT_CREATE_USER_GROUP: 'user_group',
  PUT_BANKACCOUNTS: 'account',
  PUT_PRODUCT_ADD: 'product',
  PUT_ORDER_CREATE: 'order',
  PUT_RESELLER: 'member',
  PUT_RESELLER_PREAPPROVE_FB: 'members/preapprove_fb',
  PUT_PRODUCT_PULL: 'product/pull', // ดึงสินค้าเข้าร้านฉัน required store_id, product_id เป็นหลัก
  PUT_PAYMENT: 'payment',
  PUT_ORDER_SHIP: 'order/ship',
  PUT_ONBOARD: 'onboard', // FIXME: ยังไม่มีจริง
  PUT_NEW_USER_TO_UG: 'user_group/member',
  PUT_CREATE_VOLUME_DISCOUNT: 'volume_discount',

  PUT_ADD_ORDER_NOTE: 'order/note',

  PUT_ADD_PRINTING: 'store/printing',

  PUT_ADD_HELPER: 'permissions/helpers',
  PUT_ADD_PERMISSION: 'permissions',

  PUT_ADD_GATEGORY: 'product_category',

  PUT_ORG_ADDRESS: 'org_member/address',
  PUT_CREATE_WAREHOUSE: 'warehouse',

  PUT_STORE_SETTING: 'store/settings',

  // PATCH
  PATCH_USERNAME: 'user/username',
  PATCH_PROFILE: 'user',
  PATCH_ADDRESS: 'address',
  PATCH_PRODUCT: 'product',
  PATCH_PRODUCT_GROUP: 'product_group',
  PATCH_USER_GROUP: 'user_group',
  PATCH_SORT_PRIORITIES_PRODUCT_GROUPS: 'product_groups/priorities',
  PATCH_USER_GROUP_RESET_INVITE_CODE: 'user_group/invite_code',
  PATCH_CUSTOMER: 'customer',
  PATCH_MY_STORE: 'store', // แก้ไขข้อมูลร้าน (รวม setting)
  // PATCH_ORDER_RECEIVER_ADDRESS: 'order/receiver', // แก้ไขข้อมูลร้าน (รวม setting)
  PATCH_ORDER_ADDRESS: 'order/address', // v2 of patch order/receiver
  PATCH_BANKACCOUNTS: 'account',
  PATCH_CHANGE_EMAIL: 'user/email',
  PATCH_ORDER_SETTING: 'order/setting',
  PATCH_SHIPPING_TYPE: 'order/shipping',
  PATCH_VOLUME_DISCOUNT: 'volume_discount',

  PATCH_EDIT_ORDER_NOTE: 'order/note',

  PATCH_EDIT_PRINTING: 'store/printing',

  PATCH_AUTO_REQUEST_TRACKING: 'order/setting',

  PATCH_EDIT_ADDRESS: 'org_member/address',

  PATCH_CHANGE_PERMISSION: 'permissions/helpers',
  PATCH_EDIT_PERMISSION: 'permissions',
  PATCH_EDIT_CATEGORY: 'product_category',
  PATCH_CHANGE_CATEGORY: 'product_category/change_parent',
  PATCH_SORT_CATEGORY: 'product_category/sort',
  PATCH_UPDATE_ORG_MEMBER_PROFILE_DETAILE: 'org_member/member',
  PATCH_UPDATE_LOGIN_EMAIL: 'org_member/change_login_email',

  PATCH_CHANGE_RESELLER_TYPE: 'org_member/reseller_type',
  PATCH_UPDATE_WAREHOUSE: 'warehouse',
  PATCH_UPDATE_MKP_CHANNEL_SETTING: 'mkp/channel',

  // DEL
  DELETE_PRODUCT: 'product',
  DELETE_PRODUCT_GROUP: 'product_group',
  DELETE_USER_GROUP: 'user_group',
  DELETE_BANKACCOUNT: 'account',
  DELETE_CUSTOMER: 'customer',
  DELETE_ADDRESS: 'address',
  DELETE_RESELLER: 'member/reseller',
  DELETE_VOLUME_DISCOUNT: 'volume_discount',

  DELETE_ORDER_NOTE: 'order/note',

  DELETE_HELPER_FROM_PERMISSION: 'permissions/helpers',
  DELETE_PERMISSION: 'permissions',
  DELETE_CATEGORY: 'product_category',

  DELETE_ORG_ADDRESS: 'org_member/address',
  DELETE_WATEHOUSE: 'warehouse',

  // POST VD
  LOAD_VOLUME_DISCOUNTS: 'volume_discounts',
  PAIR_VOLUME_DISCOUNT: 'volume_discount/pair',
  UNPAIR_VOLUME_DISCOUNT: 'volume_discount/unpair',

  XSHIPPING_CONFIG: 'xshipping/config',

  XSHIPPING_ADDRESSES: 'xshipping/addresses',
  XSHIPPING_JT_NON_VIP_REGISTRATION: 'xshipping/jt/non_vip_registration',

  // METHOD
  // get: (path, options?, suppressRedBox?) => get(path, options, suppressRedBox),
  // post: (path, body, options?, suppressRedBox?) => post(path, body, options, suppressRedBox),
  // put: (path, body, options?, suppressRedBox?) => put(path, body, options, suppressRedBox),
  // patch: (path, body, options?, suppressRedBox?) => patch(path, body, options, suppressRedBox),
  // del: (path, body, options?, suppressRedBox?) => del(path, body, options, suppressRedBox),
  // postV2: (path, body, options?, suppressRedBox?) => {
  //   const newOptions = options ? options : {}
  //   newOptions.isApiV2 = true
  //   return post(path, body, newOptions, suppressRedBox)
  // },
  // url: path => url(path),
  get,
  post,
  put,
  patch,
  del,
  postV2: (path, body, options?) => {
    const newOptions = options || {}
    newOptions.isApiV2 = true
    return post(path, body, newOptions)
  },
  putV2: (path, body, options?) => {
    const newOptions = options || {}
    newOptions.isApiV2 = true
    return put(path, body, newOptions)
  },
  patchV2: (path, body, options?) => {
    const newOptions = options || {}
    newOptions.isApiV2 = true
    return patch(path, body, newOptions)
  },
  delV2: (path, body, options?) => {
    const newOptions = options || {}
    newOptions.isApiV2 = true
    return del(path, body, newOptions)
  },
  getV2: (path, options?) => {
    const newOptions = options || {}
    newOptions.isApiV2 = true
    return get(path, newOptions)
  },

  fetchGet,
  fetchPost,
  fetchPut,
  fetchPatch,
  fetchDel,

  url,
  getRefreshToken,
  // goBackToLoginScreen: goBackToLoginScreen,
}

export default api
