import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'

import { getSelectedStore, getXShippingConfigFromRedux, getXshippingSystemAccountFromRedux } from 'x/redux/selectors'
import * as XShippingActions from 'x/modules/xshipping/XShippingState'
import StoreSettingSpxView from './StoreSettingSpxView'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    getXShippingConfigFromRedux: getXShippingConfigFromRedux(state),
    getXshippingSystemAccountFromRedux: getXshippingSystemAccountFromRedux(state),
  }),
  (dispatch) => ({
    addNewStoreSetting: bindActionCreators(StoreState.addNewStoreSetting, dispatch),
    setXShippingConfig: bindActionCreators(XShippingActions.setXShippingConfig, dispatch),
    setXShippingSystemAccount: bindActionCreators(XShippingActions.setXShippingSystemAccount, dispatch),
    // addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),
    // loadPrefUIOrderView: bindActionCreators(PreferenceState.loadPrefUIOrderView, dispatch),
  })
  // @ts-ignore
)(StoreSettingSpxView)
