import AWS from 'aws-sdk'
import { PutObjectRequest } from 'aws-sdk/clients/s3'
import _ from 'lodash'

import settings from '../config/settings'
import { genRandomFileName } from './file'
import { IXSellyFile } from '../types'

const Buffer = global.Buffer || require('buffer').Buffer

const ENDPOINTS = {
  // 1st
  PRIMARY: `${settings.s3.options.region}.digitaloceanspaces.com`,
  // PRIMARY_CDN: `${settings.s3.options.region}.cdn.digitaloceanspaces.com`,
  // REPLACEABLE_PRIMARY: `${settings.s3.options.region}.digitaloceanspaces.com`,
  PRIMARY_CDN: settings.s3.options.cdnSubDomain,
  REPLACEABLE_PRIMARY: `${settings.s3.options.bucket}.${settings.s3.options.region}.digitaloceanspaces.com`,

  // 2nd
  SECONDARY: `${settings.s3_2nd.options.region}.digitaloceanspaces.com`,
  SECONDARY_CDN: settings.s3_2nd.options.cdnSubDomain,
  // SECONDARY_CDN: `${settings.s3.options.region}.cdn.digitaloceanspaces.com`,
  REPLACEABLE_SECONDARY: `${settings.s3_2nd.options.bucket}.${settings.s3_2nd.options.region}.digitaloceanspaces.com`,

  // 3rd
  TERTIARY: `${settings.s3_3rd.options.region}.digitaloceanspaces.com`,
  TERTIARY_CDN: settings.s3_3rd.options.cdnSubDomain,
  // TERTIARY_CDN: `${settings.s3_2nd.options.region}.cdn.digitaloceanspaces.com`,
  REPLACEABLE_TERTIARY: `${settings.s3_3rd.options.bucket}.${settings.s3_3rd.options.region}.digitaloceanspaces.com`,
}

const s3PrimaryClient = new AWS.S3({
  endpoint: ENDPOINTS.PRIMARY,
  credentials: new AWS.Credentials({
    accessKeyId: settings.s3.options.accessKey,
    secretAccessKey: settings.s3.options.secretAccessKey,
  }),
  signatureVersion: 'v2',
})

const s3SecondaryClient = new AWS.S3({
  endpoint: ENDPOINTS.SECONDARY,
  credentials: new AWS.Credentials({
    accessKeyId: settings.s3.options.accessKey,
    secretAccessKey: settings.s3.options.secretAccessKey,
  }),
  signatureVersion: 'v2',
})

const s3TertiaryClient = new AWS.S3({
  endpoint: ENDPOINTS.TERTIARY,
  credentials: new AWS.Credentials({
    accessKeyId: settings.s3.options.accessKey,
    secretAccessKey: settings.s3.options.secretAccessKey,
  }),
  signatureVersion: 'v2',
})

interface IImgFile {
  mime: string // file type
  path?: string // path of file   // MUST have at least one of path or data or both
  data?: string // base64 string
  size?: number
  width?: number
  height?: number
}

interface IImageOptions {
  imageName?: string
  imageNamePrefix?: string
}

function convertNormalUrlToCdnUrl(url: string, normalEndpoint: string, cdnEndpoint: string): string {
  // pKeng on 2023-11-10 Stop using CDN endpoint for now as there is problem on DigitalOceans CDN
  // return url.replace(normalEndpoint, cdnEndpoint)
  return url
}

function manualGenerateUrl(endpoint: string, keyPath: string) {
  return `https://${endpoint}/${keyPath}`
}

interface IS3Options {
  bucket: string
  region: string
  cdnSubDomain?: string
}

type PreparePutObjectRequestType = Pick<PutObjectRequest, Exclude<keyof PutObjectRequest, 'Body' | 'ContentType'>>

export function generateS3PreparedUploadParams(
  fileName: string,
  s3Opts: IS3Options,
  uploadOptions: Partial<PutObjectRequest> = { ACL: 'public-read' }
): PreparePutObjectRequestType {
  // Init s3 config
  // //  const s3Opts = settings.s3.options
  // const endpoint = `${s3Opts.region}.digitaloceanspaces.com`
  // const endpointCdn = s3Opts.cdnSubDomain ? s3Opts.cdnSubDomain : `${s3Opts.region}.cdn.digitaloceanspaces.com`

  // ใส่ root folder ที่ขึ้นกับ mode เข้าไป production => xselly / dev => dev
  fileName = settings.s3.options.keyPrefix + fileName

  const s3PreparedPutObject: PreparePutObjectRequestType = {
    ...uploadOptions,
    Bucket: s3Opts.bucket,
    Key: fileName,
    // ContentType: mime,
    ContentEncoding: 'base64',
  }
  return s3PreparedPutObject
  // log('uploadImageToSpaces uploadParams => ', uploadParams)
}

function getBufferImageDataFromImageFile(imageFile: IImgFile) {
  const splitedBase64Data = imageFile.data.split(',')
  const imageData = splitedBase64Data.length > 1 ? splitedBase64Data[1] : imageFile.data
  // Ref: https://github.com/aws/aws-amplify/issues/576
  const bufferedImageData = new Buffer(imageData, 'base64')
  return bufferedImageData
}

export async function uploadImageToSpaces(
  imageFile: IImgFile,
  imageOptions: IImageOptions = { imageName: genRandomFileName() },
  uploadOptions: Partial<PutObjectRequest> = { ACL: 'public-read' }
): Promise<{ key: string; url: string }> {
  // log('uploadImageToSpaces imageFile => ', imageFile, imageOptions)
  const mime = imageFile.mime ? imageFile.mime : null
  if (!mime || mime.split('/').length !== 2) {
    throw new Error('Invalid mime in imageFile object.')
  }

  const fileExt = mime.split('/')[1]
  let fileName = `.${fileExt}`
  if (_.isString(imageOptions.imageName)) {
    fileName = imageOptions.imageName + fileName
  }

  // ใส่ folder ที่จะจำแนก เช่น slip, รูปสินค้า, รูปโปรไฟล์, อื่นๆ
  if (_.isString(imageOptions.imageNamePrefix)) {
    fileName = imageOptions.imageNamePrefix + fileName
  }

  // Primary Upload
  try {
    const s3PrimaryPutObject: PutObjectRequest = {
      ...generateS3PreparedUploadParams(fileName, settings.s3.options, uploadOptions),
      ContentType: mime,
      Body: getBufferImageDataFromImageFile(imageFile),
    }
    const sendData = await s3PrimaryClient.upload(s3PrimaryPutObject).promise()
    // console.log('Primary upload::response = ', sendData)
    if (sendData && sendData.Key && sendData.Location) {
      // const cdnLink = convertNormalUrlToCdnUrl(sendData.Location, ENDPOINTS.REPLACEABLE_PRIMARY, ENDPOINTS.PRIMARY_CDN)
      const cdnLink = manualGenerateUrl(ENDPOINTS.REPLACEABLE_PRIMARY, sendData.Key)
      return { key: sendData.Key, url: cdnLink }
    }
    throw new Error('Primary upload::No uploaded location from image server.')
  } catch (err) {
    // console.log(`Primary upload::error = `, err)
  }

  // Secondary Upload
  try {
    const s3SecondPutObject: PutObjectRequest = {
      ...generateS3PreparedUploadParams(fileName, settings.s3_2nd.options, uploadOptions),
      ContentType: mime,
      Body: getBufferImageDataFromImageFile(imageFile),
    }
    const sendData = await s3SecondaryClient.upload(s3SecondPutObject).promise()
    // console.log('Secondary upload::response = ', sendData)
    if (sendData && sendData.Key && sendData.Location) {
      // const cdnLink = convertNormalUrlToCdnUrl(sendData.Location, ENDPOINTS.REPLACEABLE_SECONDARY, ENDPOINTS.SECONDARY_CDN)
      const cdnLink = manualGenerateUrl(ENDPOINTS.REPLACEABLE_SECONDARY, sendData.Key)
      // console.log('Secondary upload::cdnLink = ', cdnLink)
      return { key: sendData.Key, url: cdnLink }
    }
    throw new Error('Secondary upload::No uploaded location from image server.')
  } catch (err) {
    // console.log(`Secondary upload::error = `, err)
  }

  // Tertiary Upload
  try {
    const s3ThirdPutObject: PutObjectRequest = {
      ...generateS3PreparedUploadParams(fileName, settings.s3_3rd.options, uploadOptions),
      ContentType: mime,
      Body: getBufferImageDataFromImageFile(imageFile),
    }
    const sendData = await s3TertiaryClient.upload(s3ThirdPutObject).promise()
    // console.log('Tertiary upload::response = ', sendData)
    if (sendData && sendData.Key && sendData.Location) {
      // const cdnLink = convertNormalUrlToCdnUrl(sendData.Location, ENDPOINTS.REPLACEABLE_TERTIARY, ENDPOINTS.TERTIARY_CDN)
      const cdnLink = manualGenerateUrl(ENDPOINTS.REPLACEABLE_TERTIARY, sendData.Key)
      return { key: sendData.Key, url: cdnLink }
    }
    throw new Error('Tertiary upload::No uploaded location from image server.')
  } catch (err) {
    // console.log(`Tertiary upload::error = `, err)
    throw err
  }

  // call S3 to retrieve upload file to specified bucket
  // return new Promise((resolve, reject) => {
  //   try {
  //     s3.upload(uploadParams, (err, data) => {
  //       if (err) {
  //         // log('uploadImageToSpaces.Promise.Error', err)
  //         reject(err)
  //       }

  //       if (data) {
  //         const { Key, Location } = data
  //         if (!Key || !Location) {
  //           reject('No uploaded location from image server.')
  //         }
  //         const cdnLink = convertNormalUrlToCdnUrl(Location)
  //         // log('Upload Success data => ', data)
  //         // log('Upload Success data.Location => ', data.Location)
  //         // log('Upload Success data.Location (CDN) => ', convertNormalUrlToCdnUrl(data.Location))
  //         resolve({ key: Key, url: cdnLink })
  //       }
  //       return new Error('TEST to upload image to S3')
  //     })
  //   } catch (error) {
  //     log('Upload error', error)
  //     reject(error)
  //   }
  // })
}

interface IUploadFileOptions {
  /**
   * fileName - ใส่ชื่อไฟล์ที่ต้องการ ถ้าไม่ใส่จะ gen random ให้
   * โดยไม่ต้องใส่นามสกุลไฟล์ เช่น 'test', 'balloon', 'cat', 'dog'
   */
  fileName?: string
  /**
   * folderPath - ใส่ prefix ของชื่อไฟล์ ถ้าไม่ใส่จะเป็น 'test/'
   * ใส่ folder ที่จะจำแนก เช่น slip, รูปสินค้า, รูปโปรไฟล์, อื่นๆ
   * เช่น 'slip/' หรือ 'product/'
   */
  folderPath?: string
}

function getBufferDataFromXSellyFile(file: IXSellyFile) {
  const splitedBase64Data = file.data.split(',')
  const imageData = splitedBase64Data.length > 1 ? splitedBase64Data[1] : file.data
  // Ref: https://github.com/aws/aws-amplify/issues/576
  const bufferedImageData = new Buffer(imageData, 'base64')
  return bufferedImageData
}

export async function uploadFile(
  file: IXSellyFile,
  fileOptions: IUploadFileOptions = { fileName: genRandomFileName(), folderPath: 'test/' },
  uploadOptions: Partial<PutObjectRequest> = { ACL: 'public-read' }
): Promise<{ key: string; url: string }> {
  console.log('uploadFile file => ', file)
  console.log('uploadFile fileOptions => ', fileOptions)
  console.log('uploadFile uploadOptions => ', uploadOptions)

  if (!file.mime) {
    throw new Error('mime is required in file object.')
  }

  const fileExt = file.name.split('.').pop()
  // let fileName = `.${fileExt}`
  let fileName = ''
  if (_.isString(fileOptions.fileName)) {
    fileName = `${fileOptions.fileName}.${fileExt}`
  }

  // ใส่ folder ที่จะจำแนก เช่น slip, รูปสินค้า, รูปโปรไฟล์, อื่นๆ
  if (_.isString(fileOptions.folderPath)) {
    const folderPath = fileOptions.folderPath.endsWith('/') ? fileOptions.folderPath : `${fileOptions.folderPath}/`
    fileName = folderPath + fileName
  }

  // Primary Upload
  try {
    const s3PrimaryPutObject: PutObjectRequest = {
      ...generateS3PreparedUploadParams(fileName, settings.s3.options, uploadOptions),
      ContentType: file.mime,
      Body: getBufferDataFromXSellyFile(file),
    }
    const sendData = await s3PrimaryClient.upload(s3PrimaryPutObject).promise()
    // console.log('Primary upload::response = ', sendData)
    if (sendData && sendData.Key && sendData.Location) {
      // const cdnLink = convertNormalUrlToCdnUrl(sendData.Location, ENDPOINTS.REPLACEABLE_PRIMARY, ENDPOINTS.PRIMARY_CDN)
      const cdnLink = manualGenerateUrl(ENDPOINTS.REPLACEABLE_PRIMARY, sendData.Key)
      return { key: sendData.Key, url: cdnLink }
    }
    throw new Error('Primary upload::No uploaded location from image server.')
  } catch (err) {
    // console.log(`Primary upload::error = `, err)
  }

  // Secondary Upload
  try {
    const s3SecondPutObject: PutObjectRequest = {
      ...generateS3PreparedUploadParams(fileName, settings.s3_2nd.options, uploadOptions),
      ContentType: file.mime,
      Body: getBufferDataFromXSellyFile(file),
    }
    const sendData = await s3SecondaryClient.upload(s3SecondPutObject).promise()
    // console.log('Secondary upload::response = ', sendData)
    if (sendData && sendData.Key && sendData.Location) {
      // const cdnLink = convertNormalUrlToCdnUrl(sendData.Location, ENDPOINTS.REPLACEABLE_SECONDARY, ENDPOINTS.SECONDARY_CDN)
      const cdnLink = manualGenerateUrl(ENDPOINTS.REPLACEABLE_SECONDARY, sendData.Key)
      // console.log('Secondary upload::cdnLink = ', cdnLink)
      return { key: sendData.Key, url: cdnLink }
    }
    throw new Error('Secondary upload::No uploaded location from image server.')
  } catch (err) {
    // console.log(`Secondary upload::error = `, err)
  }

  // Tertiary Upload
  const s3ThirdPutObject: PutObjectRequest = {
    ...generateS3PreparedUploadParams(fileName, settings.s3_3rd.options, uploadOptions),
    ContentType: file.mime,
    Body: getBufferDataFromXSellyFile(file),
  }
  const sendData = await s3TertiaryClient.upload(s3ThirdPutObject).promise()
  // console.log('Tertiary upload::response = ', sendData)
  if (sendData && sendData.Key && sendData.Location) {
    // const cdnLink = convertNormalUrlToCdnUrl(sendData.Location, ENDPOINTS.REPLACEABLE_TERTIARY, ENDPOINTS.TERTIARY_CDN)
    const cdnLink = manualGenerateUrl(ENDPOINTS.REPLACEABLE_TERTIARY, sendData.Key)
    return { key: sendData.Key, url: cdnLink }
  }

  throw new Error('Tertiary upload::No uploaded location from image server.')
}
