/* eslint-disable react/no-unused-state */
import React from 'react'
import { IPackageDetails, IProfile, IProfileMap, ISelectedStoreMap, ISubscriptionPackageList, SubscriptionPackageListItems } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import api from 'x/utils/api'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import { Alert, EmitterSubscription } from 'react-native'
import { IOptionsCapsuleSegment } from 'xui/components/XCapsuleSegment'

interface IBaseInAppPurchaseViewProps {
  profile: IProfileMap
  selectedStore: ISelectedStoreMap
  subscription: any
  myStores: any
}

interface IBaseInAppPurchaseViewState {
  isVertical: boolean
  autoPlay: boolean
  pagingEnabled: boolean
  snapEnabled: boolean
  progressValue: number

  openPackageIndex: number

  subscriptionPackageList: ISubscriptionPackageList

  receipt: string
  // productList: (Product | Subscription)[]
  productList: any
  availableItemsMessage: string

  profile: IProfile
  packageDetails: IPackageDetails

  contentHight: number

  isLoading: boolean

  mode: 'upgrade' | 'continue_package' | 'buy_package'

  selecredCapsuleSegmentRangeIndex: number
}

export default abstract class BaseInAppPurchaseView extends React.Component<IBaseInAppPurchaseViewProps, IBaseInAppPurchaseViewState> {
  abstract _fetchSubcriptionIap: (index: number, type: 'month' | 'year') => void

  abstract _endConnection: () => void

  inProcess: boolean

  purchaseUpdate: EmitterSubscription

  purchaseError: EmitterSubscription

  promotedProduct: EmitterSubscription

  // eslint-disable-next-line react/no-unused-class-component-methods
  // @ts-ignore
  carouselRef: React.useRef<any>

  PACKAGE_SKU_CYCLE: string[]

  PACKAGE_SKU_CYCLE_1: string[]

  PACKAGE_SKU_CYCLE_12: string[]

  OPTIONS_CAPSULE_SEGMENT: IOptionsCapsuleSegment[]

  constructor(props) {
    super(props)
    this.state = {
      isVertical: false,
      autoPlay: false,
      pagingEnabled: true,
      snapEnabled: true,
      progressValue: 0,
      openPackageIndex: 0,
      receipt: '',
      productList: [],
      availableItemsMessage: '',
      subscriptionPackageList: null,
      profile: null,
      packageDetails: null,
      contentHight: 0,
      isLoading: true,
      mode: 'upgrade',
      selecredCapsuleSegmentRangeIndex: 0,
    }
    this.purchaseUpdate = null
    this.purchaseError = null
    this.promotedProduct = null
    this.carouselRef = React.createRef()
    this.PACKAGE_SKU_CYCLE = ['PACKAGE-BRONZE-CYCLE-', 'PACKAGE-SILVER-CYCLE-', 'PACKAGE-GOLD-CYCLE-', 'PACKAGE-PLATINUM-CYCLE-']
    this.PACKAGE_SKU_CYCLE_1 = ['PACKAGE-BRONZE-CYCLE-1', 'PACKAGE-SILVER-CYCLE-1', 'PACKAGE-GOLD-CYCLE-1', 'PACKAGE-PLATINUM-CYCLE-1']
    this.PACKAGE_SKU_CYCLE_12 = CONS.PACKAGE_SKU_CYCLE_12
    this.inProcess = false

    this.OPTIONS_CAPSULE_SEGMENT = [
      {
        text: '1 เดือน',
      },
      {
        text: '3 เดือน',
        // labelView: <XImage source={IMG_DISCOUNT} w={50} h={50} />,
      },
      {
        text: '6 เดือน',
        // labelView: <XImage source={SILVER_BG} w={20} h={30} mt='2' />,
      },
      {
        text: '12 เดือน',
        // labelView: <XImage source={PLATINUM_BG} w={20} h={30} />,
      },
    ]
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    // const params = this._getParams()
    // const {} = params
    await this._setProfile()
    await this._setMode()
    // await this._setPackageDetails()
    // await this._fetchSubcriptionIap()
    await this._fetchSubscriptionPackageList()
    // await this._getPackageDetailJson()
    await util.delay(1000)
  }

  _setMode = async () => {
    const params = this._getParams()
    const { mode } = params
    await util.setStatePromise(this, {
      mode,
    })
  }

  _getCountPackageByKey = (keyInput: string) => {
    const { openPackageIndex, subscriptionPackageList } = this.state

    if (_.isNil(subscriptionPackageList)) {
      return '-'
    }

    const { items } = subscriptionPackageList
    const { PACKAGE_SKU_CYCLE_12 } = this
    const sku = PACKAGE_SKU_CYCLE_12[openPackageIndex]
    let dataJson = null
    items.forEach((item) => {
      if (item.sku === sku) {
        dataJson = item.data_json
      }
    })
    // console.log('dataJson => ', dataJson)
    if (_.isNil(dataJson)) {
      return '-'
    }
    return dataJson[keyInput]
  }

  // _getPackageDetailJson = async () => {
  //   await util.setStatePromise(this, {
  //     packageDetails: JSON_PACKAGE_DETAIL_DATA,
  //   })
  // }

  _setProfile = async () => {
    const { profile } = this.props
    const profileJS = _.isNil(profile) ? null : profile.toJS()
    await util.setStatePromise(this, {
      profile: profileJS,
    })
  }

  // _setPackageDetails = async () => {
  //   console.log('_setPackageDetails 01 ')
  //   const packageDetailsData = {
  //     package_detail: {
  //       sections: [
  //         {
  //           section_name: 'ระบบจัดการคำสั่งซื้อ',
  //           item_keys: ['m_o_count', 'online_bill'],
  //         },
  //         {
  //           section_name: 'ระบบจัดการช่องทางขาย',
  //           item_keys: ['mkp_quota', 'mkp', 'real_time_warehouse'],
  //         },
  //       ],
  //       item_details: {
  //         m_o_count: {
  //           name: 'จำนวนคำสั่งซื้อ',
  //           // desc: 'ออเดอร์ขายที่ฉันเปิดขาย+ออเดอร์ขายที่ตัวแทนเปิดขายให้ฉัน',
  //           unit: 'ออเดอร์',
  //         },
  //         online_bill: {
  //           name: 'ลิงก์บิลออนไลน์ ให้ลูกค้าชำระและติดตามสถานะจัดส่ง',
  //           available_types: [1, 2, 3, 4, 5],
  //         },
  //         auto_tracking_number: {
  //           name: 'ขอเลขพัสดุอัตโนมัติ',
  //           desc: 'สามารถขอเลขพัสดุอัตโนมัติจากขนส่งได้ทันที',
  //           available_types: [2, 3, 4, 5],
  //         },
  //         mkp_quota: {
  //           name: 'จำนวนการเชื่อมต่อช่องทางขายและระบบบัญชี',
  //           unit: 'ช่องทาง',
  //         },
  //         mkp: {
  //           name: 'เชื่อมต่อ Shopee Lazada TikTok และ LINE Shopping และอื่นๆ',
  //           available_types: [2, 3, 4, 5],
  //         },
  //         real_time_warehouse: {
  //           name: 'แชร์สต๊อก คลังเดียว ทุกช่องทาง แบบเรียลไทม์',
  //           available_types: [2, 3, 4, 5],
  //         },
  //       },
  //     },
  //   }

  //   await util.setStatePromise(this, { packageDetails: packageDetailsData })
  //   console.log('_setPackageDetails 02 => ', packageDetailsData)
  // }

  _fetchSubscriptionPackageList = async () => {
    const apiOptions = {
      showSpinner: true,
    }
    let PURCHASABLES_ID = CONS.PURCHASABLES_ID.SCB
    if (p.op.isIOS()) {
      PURCHASABLES_ID = CONS.PURCHASABLES_ID.IOS
    }
    const body = {
      lang: 'th',
      purchase_channel_id: PURCHASABLES_ID,
    }
    try {
      const res = await api.postV2(api.POST_SUBSCRIPTION_PACKAGE_LIST, body, apiOptions)
      // console.log('_fetchSubscriptionPackageList res => ', res)
      await util.setStatePromise(this, {
        subscriptionPackageList: res,
        isLoading: false,
      })
      // if (!res || _.isEmpty(res)) {
      // }
    } catch (err) {}
  }

  // initConnection().catch(() => {
  //   console.log('error connecting to store')
  // }).then(() => {
  //   console.log('contected to store ...')
  //   // getSubscriptions({ skus: subscriptionSkus })
  //   getSubscriptions(subscriptionSkus).catch(() => {
  //     console.log('error finding Subscriptions')
  //   }).then((res) => {
  //     console.log('res Subscriptions => ', res)
  //   })
  // })
  // console.log('connected => ', connected)

  componentWillUnmount() {
    // this.purchaseUpdate?.remove()
    // this.purchaseError?.remove()
    // this.promotedProduct?.remove()

    this._endConnection()
  }

  goNext = () => {
    Alert.alert('Receipt', this.state.receipt)
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _setAutoPlay = (newAutoPlay: boolean) => {
    this.setState({ autoPlay: newAutoPlay })
  }

  _setIsVertical = (newVertical: boolean) => {
    this.setState({ isVertical: newVertical })
  }

  _setPagingEnabled = (newPagingEnabled: boolean) => {
    this.setState({ pagingEnabled: newPagingEnabled })
  }

  _setSnapEnabled = (newSnapEnabled: boolean) => {
    this.setState({ snapEnabled: newSnapEnabled })
  }

  _getItemPackage = (): SubscriptionPackageListItems => {
    const { subscriptionPackageList, openPackageIndex } = this.state
    let packageSku = 'PACKAGE-BRONZE-CYCLE-1'
    if (openPackageIndex === 1) {
      packageSku = 'PACKAGE-SILVER-CYCLE-1'
    }
    if (openPackageIndex === 2) {
      packageSku = 'PACKAGE-GOLD-CYCLE-1'
    }
    if (openPackageIndex === 2) {
      packageSku = 'PACKAGE-PLATINUM-CYCLE-1'
    }
    if (_.isNil(subscriptionPackageList)) {
      return null
    }
    const { items } = subscriptionPackageList
    let SelectedPackage = null

    items.forEach((itam) => {
      if (itam.sku === packageSku) {
        SelectedPackage = itam
      }
    })

    if (_.isNil(SelectedPackage)) {
      return null
    }

    return SelectedPackage
  }

  // true = pass
  _checkPackageQuota = async (index: number, type: 'month' | 'year'): Promise<boolean> => {
    const { selectedStore, myStores } = this.props

    const warningPackage = (text: string) =>
      p.op.showConfirmationOkOnly('', `แพ็กเกจที่คุณเลือกไม่สามารถใช้งานระบบ "${text}" ได้ตามที่ใช้งานอยู่ปัจจุบัน  กรุณาเลือกแพ็กเกจอื่น`)

    const packageData = this._getPackageDetail(index, type)
    const jsonPackageData = packageData.data_json
    const numberMkpChannelsUseSize = selectedStore.has('channels') ? selectedStore.get('channels').size : 0
    const numberErpChannelsUseSize = selectedStore.has('erp_channels') ? selectedStore.get('erp_channels').size : 0

    const numberHelpersUseSize = selectedStore.has('helpers') ? selectedStore.get('helpers').size : 0
    const numberChannelsQuotaUse = numberMkpChannelsUseSize + numberErpChannelsUseSize
    const numberProductGroupsUseSize = selectedStore.has('product_groups') ? selectedStore.get('product_groups').size : 0
    const numberStoreUseSize = !_.isNil(myStores) ? myStores.size : 0
    const numberUserGroupsUseSize = selectedStore.has('user_groups') ? selectedStore.get('user_groups').size : 0
    const numberVdsUseSize = selectedStore.has('vds') ? selectedStore.get('vds').size : 0
    const numberWarehousesUseSize = selectedStore.has('warehouses') ? selectedStore.get('warehouses').size : 0

    if (jsonPackageData.helper_count < numberHelpersUseSize) {
      warningPackage('ผู้ช่วย')
      return false
    }
    if (jsonPackageData.mkp_quota < numberChannelsQuotaUse) {
      warningPackage('ระบบภายนอก')
      return false
    }
    if (jsonPackageData.pg_quota < numberProductGroupsUseSize) {
      warningPackage('รายการราคา')
      return false
    }
    if (jsonPackageData.store_count < numberStoreUseSize) {
      warningPackage('ร้านค้า')
      return false
    }
    if (jsonPackageData.ug_quota < numberUserGroupsUseSize) {
      warningPackage('กลุ่มสมาชิก')
      return false
    }
    if (jsonPackageData.vd_quota < numberVdsUseSize) {
      warningPackage('ส่วนลดขายส่ง')
      return false
    }
    if (jsonPackageData.wh_quota < numberWarehousesUseSize) {
      warningPackage('คลังสินค้า')
      return false
    }

    return true
  }

  _onSelectedPackage = (index: number) => {
    const { selecredCapsuleSegmentRangeIndex } = this.state
    // console.log('index => ', index)
    const billingCycleMap = {
      0: 1,
      1: 3,
      2: 6,
      3: 12,
    }
    const selectedBillingCycle = billingCycleMap[selecredCapsuleSegmentRangeIndex] || 12
    // console.log('selectedBillingCycle => ', selectedBillingCycle)
    const sku = `${this.PACKAGE_SKU_CYCLE[index]}${selectedBillingCycle}`
    // console.log('sku => ', sku)
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionPackageList } = this.state
    navigation.dispatch(
      NavActions.navToPaymentSubscriptionView({
        subscriptionPackageList,
        sku,
      })
    )
    this.inProcess = false
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _onPressPackageBtn = async (index: number, type: 'month' | 'year') => {
    const { subscription } = this.props
    const { mode } = this.state
    if (_.isNil(mode)) {
      return
    }
    if (this.inProcess) {
      return
    }
    if (!p.op.isIOS()) {
      return
    }
    // ถ้าต่อแพ็กหรือเป็นแพ็กทดลองใช้  หากจะจ่ายแพ็กต้องเช็กก่อนว่าสามารถซื้อได้หรือไม่
    if (mode === 'continue_package' || subscription.get('type') === 999) {
      const isCheckPackageQuotaPass = await this._checkPackageQuota(index, type)
      if (!isCheckPackageQuotaPass) {
        this.inProcess = false
        await util.setStatePromise(this, { isLoading: false })
        return
      }
    }

    this.inProcess = true
    await util.setStatePromise(this, { isLoading: true })
    const packageData = this._getPackageDetail(index, type)
    const subcriptionOrderValidate = await util.orderSubcriptionOrderValidate(packageData)
    if (subcriptionOrderValidate === 'ok') {
      this._fetchSubcriptionIap(index, type)
      return
    }
    this.inProcess = false
    this.setState({ isLoading: false })
  }

  _getPackageDetail = (index: number, type: 'month' | 'year') => {
    const { subscriptionPackageList } = this.state
    // console.log('get subscriptionPackageList => ', subscriptionPackageList)
    if (_.isNil(subscriptionPackageList)) {
      return null
    }
    const { items } = subscriptionPackageList
    const PACKAGE_SKU = type === 'month' ? this.PACKAGE_SKU_CYCLE_1 : this.PACKAGE_SKU_CYCLE_12
    const sku = PACKAGE_SKU[index]
    let packageData = null
    items.forEach((item) => {
      if (item.sku === sku) {
        packageData = item
      }
    })
    return packageData
  }

  _getMonth = () => {
    const { selecredCapsuleSegmentRangeIndex } = this.state
    switch (selecredCapsuleSegmentRangeIndex) {
      case 0:
        return 1
      case 1:
        return 3
      case 2:
        return 6
      case 3:
        return 12

      default:
        return 0
    }
  }

  _getPackagePrice = (index: number): number => {
    const { subscriptionPackageList } = this.state
    // console.log('get subscriptionPackageList => ', subscriptionPackageList)
    if (_.isNil(subscriptionPackageList)) {
      return 0
    }
    const { items } = subscriptionPackageList
    const PACKAGE_SKU = this.PACKAGE_SKU_CYCLE
    const sku = PACKAGE_SKU[index] + this._getMonth()
    let price = 0
    items.forEach((item) => {
      if (item.sku === sku) {
        price = item.price_with_vat
      }
    })
    return price
  }

  _getPackageOneMinthPrice = (index: number): number => {
    const { subscriptionPackageList } = this.state
    // console.log('get subscriptionPackageList => ', subscriptionPackageList)
    if (_.isNil(subscriptionPackageList)) {
      return 0
    }
    const { items } = subscriptionPackageList
    const PACKAGE_SKU = this.PACKAGE_SKU_CYCLE
    const sku = PACKAGE_SKU[index] + 1
    let price = 0
    items.forEach((item) => {
      if (item.sku === sku) {
        price = item.price_with_vat
      }
    })
    return price
  }
}
