// import { isImmutable, Map, List, fromJS } from 'immutable'
import { Map } from 'immutable'

import { ActionApiParams } from 'x/index'
import actions from 'x/config/actions'
// import CONS from 'x/config/constants'
// import moment from 'moment'

// Initial state
// interface in x/index :: ILoadingInProgress
const initialState = Map({
  xshippingConfig: null,
})

// Actions
// export const ACTION_INITIALIZE = 'AddressState/INITIALIZE'

// payload = xshipping config
export function setXShippingConfig(payload) {
  // console.log('setWarningReAuthMKPState => ', payload)
  // const newDate = new Date()
  // const DateTimeNow = moment(newDate).format(CONS.SERVER_DATETIME_FORMAT)
  return { type: actions.SET_XSHIPPING_CONFIG, payload }
}

export function setXShippingSystemAccount(payload) {
  // console.log('setXShippingSystemAccount => ', payload)
  // const newDate = new Date()
  // const DateTimeNow = moment(newDate).format(CONS.SERVER_DATETIME_FORMAT)
  return { type: actions.SET_XSHIPPING_SYSTEM_ACCOUNT, payload }
}

// export function unSetLoadingChannels() {
//   return { type: actions.UN_SET_LOADING_CHANNEL }
// }

// Reducer
export default function XShippingStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  const newState = state

  switch (type) {
    case actions.SET_XSHIPPING_CONFIG:
      // console.log('payload => ', payload)
      return newState.set('xshippingConfig', payload)

    case actions.SET_XSHIPPING_SYSTEM_ACCOUNT:
      // console.log('payload => ', payload)
      return newState.set('xshippingSystemAccount', payload)

    default:
      return state
  }
}
