/* eslint-disable react/no-unused-state */
import React from 'react'
import { SubscriptionPackageListItems } from 'x/index'
import _ from 'lodash'
import * as util from 'x/utils/util'

interface IBaseSelectedAddOnListViewProps {}

interface IBaseSelectedAddOnListViewState {
  addOnList: SubscriptionPackageListItems[]
}

export default abstract class BaseSelectedAddOnListView extends React.Component<
  IBaseSelectedAddOnListViewProps,
  IBaseSelectedAddOnListViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      addOnList: [],
    }
  }

  async componentDidMount() {
    await this._initData()
  }

  _initData = async () => {
    const params = this._getParams()
    const { addOnList } = params
    await util.setStatePromise(this, { addOnList })
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _getLabelUnit = (key: string) => {
    let label = ''
    switch (key) {
      case 'order_count_quota':
        label = 'ออเดอร์'
        break
      case 'mkp_quota':
        label = 'ช่องทางขาย'
        break
      case 'ug_quota':
        label = 'กลุ่มสมาชิก'
        break
      case 'pg_quota':
        label = 'รายการราคา'
        break
      case 'helper_count':
        label = 'ผู้ช่วยร้าน'
        break
      case 'store_count':
        label = 'ร้านค้า'
        break
      default:
        label = ''
        break
    }
    return label
  }

  _onPressSelectedAddOn = (selectedAddOn: SubscriptionPackageListItems) => {
    const { callBackFormSelectedAddOn } = this._getParams()
    if (_.isFunction(callBackFormSelectedAddOn)) {
      callBackFormSelectedAddOn(selectedAddOn)
    }

    this.goBack()
  }
}
