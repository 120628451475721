// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUISelectedAddOnListView from 'xui/modules/subscription/BaseUISelectedAddOnListView'

export default class SelectedAddOnListView extends BaseUISelectedAddOnListView {
  static displayName = 'SelectedAddOnListView'
}
