import React from 'react'
import { connect } from 'react-redux'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import {
  BackgroundJobTypeType,
  IAnyObject,
  IApiOptions,
  IAutoCompleteBackgroundJobResponse,
  IBackgroundJobListResponse,
  IFetchOrdersRequestBody,
  IOrderListItem,
  IOrderListViewProps,
  IOrderListViewState,
} from 'x/index'
import HStack from 'xui/components/HStack'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import CONS from 'x/config/constants'

import { FAQ_KEYS } from 'x/config/FAQ'

import api from 'x/utils/api'
import FaqHelpButton from '../../components/FaqHelpButton'
import XText from '../../components/XText'
import XSwitch from '../../components/XSwitch'

import BaseUIOrderListView from './BaseUIOrderListView'

interface IAutoShipmentOrderListViewState extends IOrderListViewState {
  isForceCreateShippingSwitchOpen?: boolean
}

class AutoShipmentOrderListView extends BaseUIOrderListView<IOrderListViewProps, IAutoShipmentOrderListViewState> {
  static displayName = 'AutoShipmentOrderListView'

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
    // navigation.dispatch(
    //   NavActions.navToOrderListView({
    //     // order_id: od.id,
    //     store_id: this.getStoreId(),
    //     onPressOrderListItem: this._mockOnPressOrderListItem,
    //     onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
    //   })
    // )
    // this.toggleCheckOrderListItem(od.id)
  }

  getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => ({
    isCheckable: true,
    availableTabKeys: [
      'myTasks_confirmOrder',
      'myTasks_pay',
      'myTasks_confirmGettingPaid',
      'myTasks_ship',
      'custTasks_pay',
      'resellerTasks_confirmOrder',
      'resellerTasks_pay',
      'resellerTasks_confirmGettingPaid',
      'sellerTasks_confirmOrder',
      'sellerTasks_confirmGettingPaid',
      'sellerTasks_pay',
    ],
    // ordersFilterSetting: {
    //   order_segment: 'my_unshipped_only_and_not_mkp',
    // },
    displayMode: 'page',
    displayPageLimit: 30,
  })

  getOverrideRequestBody = (): Partial<IFetchOrdersRequestBody> => ({
    order_segment: 'my_unshipped_only_and_not_mkp',
  })

  getHeaderTitle = () => {
    const defaultTitle = this.getTabLabelWithCount(this.state.tabName)
    return `บันทึกจัดส่งอัตโนมัติ\n${defaultTitle}`
  }

  getSubmitCheckOrdersButtonText = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length
    return checkedCount > 0 ? 'บันทึกจัดส่งอัตโนมัติ' : 'กรุณาเลือกออเดอร์ที่ต้องการบันทึกจัดส่งอัตโนมัติ\nโดยแตะปุ่มกลมทางซ้าย'
  }

  isSubmitCheckOrdersButtonDisabled = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length
    return checkedCount === 0
  }

  // onPressSubmitCheckedOrders = async (order_ids: number[]) => {
  onPressSubmitCheckedOrders = async (checkedOrders: IOrderListItem[]) => {
    const { isForceCreateShippingSwitchOpen = false } = this.state
    // const orderCheckedCount = order_ids.length
    const orderCheckedCount = checkedOrders.length
    if (!orderCheckedCount) {
      return
    }

    const order_ids = checkedOrders.map((codr) => codr.id)
    // const order_ids = this.getCheckedOrderIds()
    const selectedStoreId = this.getStoreId()
    if (isForceCreateShippingSwitchOpen) {
      const onfirmForceCreateShipping = await util.confirmForceCreateShipping()
      if (!onfirmForceCreateShipping) {
        return
      }
    }
    const bgJobType = CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE
    const { isAvailable, bgJobResponse } = await util.checkAvailableBackgroundJobType({ bgJobType, store_id: selectedStoreId })
    if (!isAvailable) {
      this._navToBackgroundJobInProcessByTypeView({
        bgJobType,
        initBgJobResponse: bgJobResponse,
      })
      return
    }

    const isUserConfirm = await p.op.isUserConfirm(
      `มี ${orderCheckedCount} ออเดอร์ออเดอร์ที่ถูกเลือก`,
      'กดปุ่ม ดำเนินการ เพื่อยืนยันสร้างคิวคำสั่งงานบันทึกจัดส่งอัตโนมัติทันที',
      'ดำเนินการ',
      'ยกเลิก'
    )
    if (!isUserConfirm) {
      return
    }

    const reqBody: IAnyObject = {
      store_id: selectedStoreId,
      order_ids,
    }
    if (isForceCreateShippingSwitchOpen) {
      reqBody.force_create = isForceCreateShippingSwitchOpen
    }
    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isErrorAlertDisabled: true,
      isApiV2: true,
    }

    try {
      const res: IAutoCompleteBackgroundJobResponse = await api.post(api.POST_AUTOCOMPLETE_SHIPMENT, reqBody, apiOptions)
      if (res.bgjob_uuid) {
        // this._navToBackgroundJobInProcessByTypeView({
        //   bgJobType,
        //   initBgJobResponse: { jobs: [res.bgjob_detail], count: 1 },
        //   warningText: 'สร้างคำสั่งงานสำเร็จ กรุณารอให้ระบบดำเนินงานให้เสร็จสิ้นก่อนเพิ่มคำสั่งถัดไป',
        //   warningTextBgColor: COLORS.FORM_SUCCESS_BG,
        // })
        // this._goBackToMyStore()
        // p.op.showToast('สร้างคิวคำสั่งงาน "บันทึกจัดส่งอัตโนมัติ" สำเร็จ', 'success', 10000)
        await util.alertWithNavToBgJobListView({
          title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
          message:
            'คิวคำสั่งงาน สำหรับการ "บันทึกจัดส่งอัตโนมัติ" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์',
          dispatch: this.props.navigation.dispatch,
          selectedStoreId,
          initBgJobUUID: res.bgjob_uuid,
          onUserConfirm: this._quitAutoShipmentOrderListView,
        })
      }
      // console.log('_handlePressMultipleAutoCompletePayment res => ', res)
    } catch (err) {
      // console.log('_handlePressMultipleAutoCompletePayment err => ', err)
      if (util.isXSellyErrorV2(err)) {
        // @ts-ignore
        util.alertWithXSellyErrorV2(err.error)
      }
    }
  }

  _quitAutoShipmentOrderListView = () => {
    this.goBack()
  }

  _navToBackgroundJobInProcessByTypeView = (params: {
    bgJobType: BackgroundJobTypeType
    initBgJobResponse: IBackgroundJobListResponse
    warningText?: string
    warningTextBgColor?: string
  }) => {
    const { navigation } = this.props
    const selectedStoreId = this.getStoreId()
    navigation.dispatch(
      NavActions.navToBackgroundJobInProcessByTypeView({
        storeId: selectedStoreId,
        // bgJobType: CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE,
        // initBgJobResponse,
        // warningText: '',
        ...params,
      })
    )
  }

  _onChangeForceCreateShippingSwitch = (nextIsOpen: boolean) => {
    this.setState({ isForceCreateShippingSwitchOpen: nextIsOpen })
  }

  renderExtraHeader = () => {
    const { isInitialized, isForceCreateShippingSwitchOpen = false } = this.state
    if (!isInitialized) {
      return null
    }
    return (
      <HStack w='full' p='2' alignItems='center' justifyContent='space-between'>
        <HStack alignItems='center' flexWrap='wrap'>
          <XText variant={isForceCreateShippingSwitchOpen ? 'active' : 'inactive'}>บันทึกจัดส่งแม้จำนวนสินค้ามีไม่พอ</XText>
          <FaqHelpButton
            faqKey={FAQ_KEYS.FORCE_CREATE_SHIPPING}
            headerTitle='บันทึกจัดส่งแม้จำนวนสินค้ามีไม่พอ'
            // containerStyle={S.PADDING_4}
          />
        </HStack>
        <XSwitch value={isForceCreateShippingSwitchOpen} onValueChange={this._onChangeForceCreateShippingSwitch} />
      </HStack>
    )
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(AutoShipmentOrderListView)
