import BaseUIStoreSettingSpxView from 'xui/modules/xshipping/BaseUIStoreSettingSpxView'
// import { WebView } from 'react-native-webview'

class StoreSettingSpxView extends BaseUIStoreSettingSpxView {
  static displayName = 'StoreSettingSpxView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingSpxView
