/* eslint-disable @typescript-eslint/no-misused-promises */
// import EntypoIcon from 'react-native-vector-icons/Entypo'
import p from 'x/config/platform-specific'
import BaseUIInAppPurchaseView from 'xui/modules/subscription/BaseUIInAppPurchaseView'
// import { Subscriptions } from 'xui/modules/subscription/Subscriptions'
import VStack from 'xui/components/VStack'
import { ScaledSize, Dimensions, TouchableOpacity, View } from 'react-native'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XRedDiscount from 'xui/components/XRedDiscount'
import * as xFmt from 'x/utils/formatter'
import { COLORS } from 'x/config/styles'
import CommonLogo from 'xui/components/CommonLogo'

const BLUE_TAG = require('../../xui/x/img/discount/tag_discount.png')

// export const screens = [
//   {
//     name: 'Subscriptions',
//     title: 'Subscriptions',
//     component: withIAPContext(Subscriptions),
//     section: 'Context',
//     color: '#cebf38',
//   },
//   {
//     name: 'Home',
//     component: Home,
//     section: 'Context',
//     color: '#cebf38',
//   },
// ]

// const Stack = createStackNavigator()

// export const StackNavigator = () => {
//   return (
//     <Stack.Navigator screenOptions={{ title: 'MainlyPaleo Subscriptions' }}>
//       {screens.map(({ name, component, title }) => (
//         <Stack.Screen
//           key={name}
//           name={name}
//           component={component}
//           //hide the header on these screens
//           options={{
//             title: title,
//             headerShown: name === 'Home' || name === 'Subscriptions' ? false : true,
//           }}
//         />
//       ))}
//     </Stack.Navigator>
//   )
// }

// const errorLog = ({ message, error }: { message: string; error: unknown }) => {
//   console.error('An error happened', message, error)
// }

const window: ScaledSize = p.op.isWeb()
  ? {
      ...Dimensions.get('window'),
      width: 700,
    }
  : Dimensions.get('window')

// const PAGE_HIGHT = window.height
const PAGE_WIDTH = window.width

const packageLabelList = ['Bronze', 'Silver', 'Gold', 'Platinum']

export default class InAppPurchaseView extends BaseUIInAppPurchaseView {
  static displayName = 'InAppPurchaseView'

  _fetchSubcriptionIap = async () => {
    // console.log('_fetchSubcriptionIap =>')
  }

  _purchase = async (index: number, type: 'month' | 'year') => {
    // this._nextCarouselPage()
  }

  _subscribe = async (sku: string) => {
    //
  }

  _endConnection = () => {
    // endConnection()
  }

  _renderDiscount = (discount: number) => (
    <VStack style={{ position: 'absolute', top: 15, right: 0 }}>
      <XRedDiscount number={Math.round(discount)} />
    </VStack>
  )

  _renderDiscontBlueTag = (packageType: number) => {
    if (packageType !== 5) {
      return null
    }
    return (
      <View
        style={{
          position: 'absolute',
          right: 30,
          top: 9,
          zIndex: 70,
        }}>
        <VStack justifyContent='cenetr' alignItems='center' w={70} h={70}>
          <VStack style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
            <CommonLogo source={BLUE_TAG} width={70} height={70} />
          </VStack>
          <VStack alignItems='center' justifyContent='center' w={70} h={70}>
            <XText color={COLORS.WHITE}>ยอด</XText>
            <XText color={COLORS.WHITE}>นิยม</XText>
          </VStack>
        </VStack>
      </View>
    )
  }

  _renderCarousel = () => {
    const { openPackageIndex, selecredCapsuleSegmentRangeIndex } = this.state
    const price = this._getPackagePrice(openPackageIndex)
    // const priceYearly = this._getPackageOneYearPrice(openPackageIndex)

    const priceOneMonth = this._getPackageOneMinthPrice(openPackageIndex)
    // console.log('priceOneMonth => ', priceOneMonth)
    const oneMonthPriceInOneSelectedMonth = price / this._getMonth()
    const differencePrice = priceOneMonth - oneMonthPriceInOneSelectedMonth
    const discount = (differencePrice / priceOneMonth) * 100
    const averagePrice = price / this._getMonth()
    // return null
    // const textRange = selecredCapsuleSegmentRangeIndex === 0 ? 'เดือน' : 'ปี'
    // const priceRender = selecredCapsuleSegmentRangeIndex === 0 ? price : priceYearly
    return (
      <VStack flex={1}>
        {this._renderAbsolutePackageTag(openPackageIndex + 2)}
        {this._renderDiscontBlueTag(openPackageIndex + 2)}
        <XCard flex={1} mx='2' mt='6' h={400} p='2' borderRadius={30}>
          <VStack w='full' alignItems='center' justifyContent='center'>
            {/* // รายเดือน */}
            <VStack alignItems='center' justifyContent='center' mt='8' w='full'>
              {selecredCapsuleSegmentRangeIndex === 0 ? null : this._renderDiscount(Math.round(discount))}
              <VStack alignItems='center'>
                <XText bold fontSize='2xl'>
                  {this.OPTIONS_CAPSULE_SEGMENT[selecredCapsuleSegmentRangeIndex].text}
                </XText>
                <HStack alignItems='center'>
                  <XText bold fontSize='4xl'>
                    {`${xFmt.formatCurrency(price)}`}
                  </XText>
                </HStack>
                {selecredCapsuleSegmentRangeIndex !== 0 ? (
                  <XText fontSize='sm' variant='inactive'>
                    {`(${averagePrice} บาท/เดือน)`}
                  </XText>
                ) : null}
              </VStack>
              <TouchableOpacity
                onPress={() => this._onSelectedPackage(openPackageIndex)}
                style={{
                  backgroundColor: COLORS.APP_MAIN,
                  marginTop: 4,
                  borderRadius: 90,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 180,
                }}>
                <XText my='4' bold fontSize='lg' color={COLORS.WHITE}>
                  เลือก
                </XText>
              </TouchableOpacity>
            </VStack>
          </VStack>
        </XCard>
      </VStack>
    )
  }

  // _renderCarousel = () => {
  //   const { openPackageIndex } = this.state
  //   const price = this._getPackageOneMonthPrice(openPackageIndex)
  //   const priceYearly = this._getPackageOneYearPrice(openPackageIndex)
  //   const oneMonthPriceInOneYear = price * 12
  //   const differencePrice = oneMonthPriceInOneYear - priceYearly
  //   const discount = (differencePrice / oneMonthPriceInOneYear) * 100
  //   const averagePrice = priceYearly / 12
  //   // return null
  //   return (
  //     <VStack flex={1}>
  //       {this._renderAbsolutePackageTag(openPackageIndex + 2)}
  //       <XCard flex={1} mx='2' mt='6' h={400} p='2' borderRadius={30}>
  //         <VStack w='full' alignItems='center' justifyContent='center'>
  //           {/* // รายเดือน */}
  //           <VStack
  //             alignItems='center'
  //             justifyContent='center'
  //             mt='8'
  //             pb='4'
  //             w='full'
  //             borderBottomColor={COLORS.TEXT_INACTIVE}
  //             borderBottomWidth='2'>
  //             <VStack alignItems='center'>
  //               <XText bold fontSize='lg'>
  //                 รายเดือน
  //               </XText>
  //               <HStack alignItems='center'>
  //                 <XText bold fontSize='2xl'>
  //                   {`${xFmt.formatCurrency(price)}`}
  //                 </XText>
  //                 <XText bold fontSize='lg'>
  //                   {' / เดือน'}
  //                 </XText>
  //               </HStack>
  //             </VStack>
  //             <TouchableOpacity
  //               onPress={() => this._onPressPackageBtn(openPackageIndex, 'month')}
  //               style={{
  //                 backgroundColor: COLORS.WHITE,
  //                 borderColor: COLORS.APP_MAIN,
  //                 borderWidth: 1,
  //                 marginTop: 4,
  //                 borderRadius: 90,
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 width: 180,
  //               }}>
  //               <XText my='4' bold fontSize='lg' color={COLORS.APP_MAIN}>
  //                 ซื้อแพ็กเกจ
  //               </XText>
  //             </TouchableOpacity>
  //           </VStack>

  //           {/* // รายปี */}
  //           <VStack alignItems='center' justifyContent='center' mt='2' w='full'>
  //             {this._renderDiscount(Math.round(discount))}
  //             <VStack alignItems='center'>
  //               <XText bold fontSize='lg'>
  //                 รายปี
  //               </XText>
  //               <HStack alignItems='center'>
  //                 <XText bold fontSize='2xl'>
  //                   {`${xFmt.formatCurrency(priceYearly)}`}
  //                 </XText>
  //                 <XText bold fontSize='lg'>
  //                   {' / เดือน'}
  //                 </XText>
  //               </HStack>
  //             </VStack>
  //             {/* <XText bold fontSize={'lg'} color={COLORS.RED}>
  //               {`(ประหยัดขึ้น ${Math.round(discount)}%)`}
  //             </XText> */}
  //             <XText bold variant='inactive'>
  //               {`เฉลี่ย ${xFmt.formatCurrency(averagePrice)}/เดือน`}
  //             </XText>
  //             <TouchableOpacity
  //               onPress={() => this._onPressPackageBtn(openPackageIndex, 'year')}
  //               style={{
  //                 backgroundColor: COLORS.APP_MAIN,
  //                 marginTop: 4,
  //                 borderRadius: 90,
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 width: 180,
  //               }}>
  //               <XText my='4' bold color='white' fontSize='lg'>
  //                 ซื้อแพ็กเกจ
  //               </XText>
  //             </TouchableOpacity>
  //             {/* <VStack mt='2'>
  //               <XText bold variant='inactive'>{`(เฉลี่ย ${averagePrice} บาท/เดือน)`}</XText>
  //             </VStack> */}
  //           </VStack>
  //         </VStack>
  //       </XCard>
  //     </VStack>
  //   )
  // }
}
