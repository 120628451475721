import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'

import { getSelectedStore, getXShippingConfigFromRedux } from 'x/redux/selectors'
import * as XShippingActions from 'x/modules/xshipping/XShippingState'
import * as StoreActions from 'x/modules/store/StoreState'
import StoreSettingXShippingView from './StoreSettingXShippingView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    getXShippingConfigFromRedux: getXShippingConfigFromRedux(state),
  }),
  (dispatch) => ({
    addNewStoreSetting: bindActionCreators(StoreState.addNewStoreSetting, dispatch),
    setXShippingConfig: bindActionCreators(XShippingActions.setXShippingConfig, dispatch),
    // setMyStores: bindActionCreators(StoreState.setMyStores, dispatch),
    submitEditingXShipping: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    setXShippingSystemAccount: bindActionCreators(XShippingActions.setXShippingSystemAccount, dispatch),
  })
  // @ts-ignore
)(StoreSettingXShippingView)
