import BaseUIStoreSettingXShippingSpxView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingSpxView'
// import { WebView } from 'react-native-webview'

class StoreSettingXShippingSpxView extends BaseUIStoreSettingXShippingSpxView {
  static displayName = 'StoreSettingXShippingSpxView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }

  // renderWebView = () => {
  //   const { isFlashLinkAccount, isRenderWebView } = this.state
  //   // console.log(`WEB VIEW !! `, urlWebView)
  //   // `https://reactnative.dev/`
  //   if (_.isNil(isFlashLinkAccount) || isFlashLinkAccount === '' || !isRenderWebView) {
  //     return null
  //   }
  //   return null
  //   // eslint-disable-next-line consistent-return
  //   // return (
  //   //   <WebView
  //   //     ref={this.webview}
  //   //     source={{ uri: isFlashLinkAccount }}
  //   //     onNavigationStateChange={this.handleWebViewNavigationStateChange}
  //   //     style={{ flex: 1 }}
  //   //     scalesPageToFit
  //   //   />
  //   // )
  // }

  // handleWebViewNavigationStateChange = (newNavState) => {
  //   // newNavState looks something like this:
  //   // {
  //   //   url?: string;
  //   //   title?: string;
  //   //   loading?: boolean;
  //   //   canGoBack?: boolean;
  //   //   canGoForward?: boolean;
  //   // }
  //   // console.log(`newNavState !! `, newNavState)
  //   const { url } = newNavState
  //   // if (!url) {
  //   // }
  //   // console.log('url => ', url)
  //   // handle certain doctypes
  //   // if (url.includes('.pdf')) {
  //   //   // @ts-ignore webview แดง
  //   //   this.webview.stopLoading()
  //   //   // open a modal with the PDF viewer
  //   // }

  //   // AUTH //
  //   // if (url.includes('https://authresult.xselly.com/?status=fail') || url.includes('https://authresult.xselly.com?status=fail')) {
  //   //   util.setStatePromise(this, { isRenderWebView: false })
  //   //   p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', ` กรุณาติดต่อทีมงาน (${url}) `)
  //   //   return
  //   // }
  // }
}

export default StoreSettingXShippingSpxView
