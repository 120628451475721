// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUISelectedAddOnView from 'xui/modules/subscription/BaseUISelectedAddOnView'

export default class SelectedAddOnView extends BaseUISelectedAddOnView {
  static displayName = 'SelectedAddOnView'
}
