const Actions = {
  ABOUT_SET_FCM_TOKEN: 'about/ABOUT_SET_FCM_TOKEN',

  AUTOMATIC_SUBMIT_PAYMENT: 'automatic/AUTOMATIC_SUBMIT_PAYMENT',

  ADD_CUSTOM_ORDER: 'PreferenceState/ADD_CUSTOM_ORDER',

  CODEPUSH_SYNC_NOW: 'codepush/CODEPUSH_SYNC_NOW',
  CODEPUSH_SYNC_STATUS: 'codepush/CODEPUSH_SYNC_STATUS',

  NOTIFICATION_NEW_MESSAGE_COMING: 'notification/NEW_MESSAGE_COMING',
  NOTIFICATION_MESSAGE_TRAY_PRESS: 'notification/MESSAGE_TRAY_PRESS',

  NOTIFICATION_SHOULD_FETCH: 'notification/SHOULD_FETCH',
  NOTIFICATION_LOADED: 'notification/LOADED',

  MY_STORE_CREATE: 'stores/MY_STORE_CREATE',
  MY_STORES_FETCH: 'stores/MY_STORES_FETCH',
  MY_STORE_FETCH: 'stores/MY_STORE_FETCH',
  MY_STORE_INFO_FETCH: 'stores/MY_STORE_INFO_FETCH',
  MY_STORE_SUBMIT_EDITING_FAILED: 'stores/MY_STORE_SUBMIT_EDITING_FAILED',
  MY_STORES_REQUEST_FAILED: 'stores/MY_STORES_REQUEST_FAILED',
  MY_STORES_INFO_REQUEST_FAILED: 'stores/MY_STORES_INFO_REQUEST_FAILED',
  SET_MY_STORES_STATE: 'stores/SET_MY_STORES_STATE',
  SET_MY_STORES_IS_FETCHING: 'stores/SET_MY_STORES_IS_FETCHING',
  SET_MY_STORES_SHOULD_FETCH: 'stores/SET_MY_STORES_SHOULD_FETCH',
  FIND_STORE: 'stores/FIND_STORE',
  SET_SELECTED_STORE_ORDERS_DATES: 'stores/SET_SELECTED_STORE_ORDERS_DATES',

  ADD_PRINTING: 'stores/ADD_PRINTING',
  EDIT_PRINTING: 'stores/EDIT_PRINTING',

  // Add for common use web
  MY_STORES_IS_FETCH_STATE: 'stores/MY_STORES_IS_FETCH_STATE',
  SET_MY_STORE_DETAIL_STATE: 'stores/SET_MY_STORE_DETAIL_STATE',
  CLEAR_MY_STORE_DETAIL_STATE: 'stores/CLEAR_MY_STORE_DETAIL_STATE',

  SET_SHOULD_FETCH_ORDER_SUMMARY: 'stores/SET_SHOULD_FETCH_ORDER_SUMMARY',
  GET_SHOULD_FETCH_ORDER_SUMMARY: 'stores/GET_SHOULD_FETCH_ORDER_SUMMARY',

  MERGE_MY_STORE_INFO: 'stores/MERGE_MY_STORE_INFO',

  SET_SELECTED_STORE_STATE: 'stores/SET_SELECTED_STORE_STATE',
  SELECTED_STORE_SHOULD_FETCH: 'stores/SELECTED_STORE_SHOULD_FETCH',
  CLEAR_SELECTED_STORE_STATE: 'stores/CLEAR_SELECTED_STORE_STATE',
  RESET_EDITING_STORE_STATE: 'stores/RESET_EDITING_STORE_STATE',
  CHANGE_EDITING_STORE_STATE: 'stores/CHANGE_EDITING_STORE_STATE',

  APPLY_INVITE_CODE: 'stores/APPLY_INVITE_CODE',
  ERROR_APPLY_INVITE_CODE: 'stores/ERROR_APPLY_INVITE_CODE',

  FETCH_PRODUCT_GROUPS: 'stores/product_groups/FETCH_PRODUCT_GROUPS',
  ERROR_FETCH_PRODUCT_GROUPS: 'stores/product_groups/ERROR_FETCH_PRODUCT_GROUPS',
  CREATE_PRODUCT_GROUP: 'stores/product_groups/CREATE',
  UPDATE_PRODUCT_GROUP: 'stores/product_groups/UPDATE',
  DELETE_PRODUCT_GROUP: 'stores/product_groups/DELETE',
  SORT_PRIORITIES_PRODUCT_GROUPS: 'stores/product_groups/SORT_PRIORITIES',
  ERROR_CREATE_PRODUCT_GROUP: 'stores/product_groups/ERROR_CREATE',
  ERROR_UPDATE_PRODUCT_GROUP: 'stores/product_groups/ERROR_UPDATE',
  ERROR_DELETE_PRODUCT_GROUP: 'stores/product_groups/ERROR_DELETE',
  LOAD_PRODUCT_GROUPS: 'stores/product_groups/LOAD_PRODUCT_GROUPS',
  LOAD_PRODUCT_GROUP_UPDATED_AT: 'stores/product_groups/LOAD_PRODUCT_GROUP_UPDATED_AT',
  LOAD_NEW_PRODUCT_GROUP: 'stores/product_groups/LOAD_NEW_PRODUCT_GROUP',
  LOAD_UPDATED_PRODUCT_GROUP: 'stores/product_groups/LOAD_UPDATED_PRODUCT_GROUP',
  CLEAR_REMOVED_PRODUCT_GROUP: 'stores/product_groups/CLEAR_REMOVED_PRODUCT_GROUP',
  SET_PRODUCTS_FROM_VOLUME_DISCOUNT: '/SET_PRODUCTS_FROM_VOLUME_DISCOUNT',
  LOND_PRODUCTS_FROM_VOLUME_DISCOUNT: '/LOND_PRODUCTS_FROM_VOLUME_DISCOUNT',

  FETCH_USER_GROUPS: 'stores/user_groups/FETCH_USER_GROUPS',
  ERROR_FETCH_USER_GROUPS: 'stores/user_groups/ERROR_FETCH_USER_GROUPS',
  CREATE_USER_GROUP: 'stores/user_groups/CREATE',
  UPDATE_USER_GROUP: 'stores/user_groups/UPDATE',
  DELETE_USER_GROUP: 'stores/user_groups/DELETE',
  RESET_USER_GROUP_INVITE_CODE: 'stores/user_groups/RESET_INVITE_CODE',
  GENERATE_USER_GROUP_INVITE_LINK: 'stores/user_groups/GENERATE_USER_GROUP_INVITE_LINK',
  ERROR_CREATE_USER_GROUP: 'stores/user_groups/ERROR_CREATE',
  ERROR_UPDATE_USER_GROUP: 'stores/user_groups/ERROR_UPDATE',
  ERROR_DELETE_USER_GROUP: 'stores/user_groups/ERROR_DELETE',
  ERROR_RESET_USER_GROUP_INVITE_CODE: 'stores/user_groups/ERROR_RESET_INVITE_CODE',
  ERROR_GENERATE_USER_GROUP_INVITE_LINK: 'stores/user_groups/ERROR_GENERATE_USER_GROUP_INVITE_LINK',
  LOAD_USER_GROUPS: 'stores/user_groups/LOAD_USER_GROUPS',
  LOAD_NEW_USER_GROUP: 'stores/user_groups/LOAD_NEW_USER_GROUP',
  LOAD_UPDATED_USER_GROUP: 'stores/user_groups/LOAD_UPDATED_USER_GROUP',
  CLEAR_REMOVED_USER_GROUP: 'stores/user_groups/CLEAR_REMOVED_USER_GROUP',
  SHOULD_FETCH_USER_GROUPS: 'stores/user_groups/SHOULD_FETCH_USER_GROUPS',

  CREATE_VOLUME_DISCOUNT: 'stores/volume_discount/CREATE',
  UPDATE_VOLUME_DISCOUNT: 'stores/volume_discount/UPDATE',
  UPDATE_NEW_VOLUME_DISCOUNT: 'stores/volume_discount/UPDATE_NEW_VD',
  DELETE_VOLUME_DISCOUNT: 'stores/volume_discount/DELETE_VOLUME_DISCOUNT',
  CLEAR_REMOVED_VOLUME_DISCOUNT: 'stores/user_volume_discounts/CLEAR_REMOVED_VOLUME_DISCOUNT',
  LOAD_VOLUME_DISCOUNTS: 'stores/volume_discount/LOAD_VOLUME_DISCOUNTS',
  FETCH_VOLUME_DISCOUNTS: 'stores/volume_discount/FETCH_VOLUME_DISCOUNTS',
  LOAD_NEW_VOLUME_DISCOUNTS: 'stores/user_volume_discounts/LOAD_NEW_VOLUME_DISCOUNTS',
  PAIR_VOLUME_DISCOUNT: 'stores/volume_discount/PAIR_VOLUME_DISCOUNT',
  UNPAIR_VOLUME_DISCOUNT: 'stores/volume_discount/UNPAIR_VOLUME_DISCOUNT',

  CHANGE_PENDING_USERS_STATUS: 'stores/user_groups/CHANGE_PENDING_USERS_STATUS',
  PREPARE_CHANGE_PENDING_USER_STATUS: 'stores/user_groups/PREPARE_CHANGE_PENDING_USER_STATUS',
  ERROR_CHANGE_PENDING_USERS_STATUS: 'stores/user_groups/ERROR_CHANGE_PENDING_USERS_STATUS',
  SET_APPROVED_OR_REJECED_USER_STATUS_IN_PENDING_LIST: 'stores/user_groups/SET_APPROVED_OR_REJECED_USER_STATUS_IN_PENDING_LIST',
  SET_IS_KICKED_USER_IN_JOINED_LIST: 'stores/user_groups/SET_IS_KICKED_USER_IN_JOINED_LIST',
  SET_IS_CHANGED_GROUP_USER_IN_JOINED_LIST: 'stores/user_groups/SET_IS_CHANGED_GROUP_USER_IN_JOINED_LIST',
  ADD_NEW_USER_TO_UG: 'stores/user_groups/ADD_NEW_USER_TO_UG',
  ERROR_ADD_NEW_USER_TO_UG: 'stores/user_groups/ERROR_ADD_NEW_USER_TO_UG',
  KICK_JOINED_USER_FROM_STORE: 'stores/user_groups/KICK_JOINED_USER_FROM_STORE',
  ERROR_KICK_JOINED_USER_FROM_STORE: 'stores/user_groups/ERROR_KICK_JOINED_USER_FROM_STORE',
  CHANGE_USER_GROUP_IN_JOINED_USERS: 'stores/user_groups/CHANGE_USER_GROUP_IN_JOINED_USERS',
  ERROR_CHANGE_USER_GROUP_IN_JOINED_USERS: 'stores/user_groups/ERROR_CHANGE_USER_GROUP_IN_JOINED_USERS',
  ASSIGN_PGS_TO_USER_GROUP: 'stores/user_groups/ASSIGN_PGS_TO_USER_GROUP',
  DONE_ASSIGN_PGS_TO_USER_GROUP: 'stores/user_groups/DONE_ASSIGN_PGS_TO_USER_GROUP',
  ACCEPT_ALL_PENDING_USERS: 'stores/user_groups/ACCEPT_ALL_PENDING_USERS',
  ERROR_ACCEPT_ALL_PENDING_USERS: 'stores/user_groups/ERROR_ACCEPT_ALL_PENDING_USERS',
  DONE_ACCEPT_ALL_PENDING_USERS: 'stores/user_groups/DONE_ACCEPT_ALL_PENDING_USERS',
  SHOULD_FETCH_USERS_BY_PREFIX_KEY: 'stores/user_groups/SHOULD_FETCH_USERS_BY_PREFIX_KEY',

  MY_STORE_SUBMIT_EDITING: 'stores/MY_STORE_SUBMIT_EDITING',
  setMyStore: 'stores/setMyStore',
  setSelectedStore: 'stores/setSelectedStore',
  /// ///

  SELLER_STORES_FETCH: 'stores/SELLER_STORES_FETCH',
  SELLER_STORES_REQUEST_FAILED: 'stores/SELLER_STORES_REQUEST_FAILED',
  SET_SELLER_STORES_STATE: 'stores/SET_SELLER_STORES_STATE',
  SET_SELLER_STORES_IS_FETCHING: 'stores/SET_SELLER_STORES_IS_FETCHING',
  SET_PENDING_STORES_STATE: 'stores/SET_PENDING_STORES_STATE',
  APPEND_PENDING_STORE_STATE: 'stores/APPEND_PENDING_STORE_STATE',

  CLEAR_MY_STORE_STATE: 'stores/CLEAR_MY_STORE',
  CLEAR_SELLER_STORE_STATE: 'stores/CLEAR_SELLER_STORE_STATE',
  SELLER_STORES_IS_FETCH_STATE: 'stores/SELLER_STORES_IS_FETCH_STATE',
  SELLER_STORES_SHOULD_FETCH_STATE: 'stores/SELLER_STORES_SHOULD_FETCH_STATE',

  SELLER_STORES_LEAVE: 'stores/LEAVE_FROM_SELLER_STORE',

  // CONTACT_LIST_FETCH: 'contacts/CONTACT_LIST_FETCH',
  // CONTACT_LIST_FETCH_FAILED: 'contacts/CONTACT_LIST_FETCH_FAILED',
  // CONTACT_ADD_FETCH: 'contacts/CONTACT_ADD_FETCH',
  // CONTACT_ADD_FETCH_FAILED: 'contacts/CONTACT_ADD_FETCH_FAILED',

  // TODO:
  // ORDERS_FETCH: 'orders/ORDERS_FETCH',
  // SET_ORDERS_IS_FETCHING: 'orders/SET_ORDERS_IS_FETCHING',
  // SET_ORDERS_STATE: 'orders/SET_ORDERS_STATE',

  STORE_ORDERS_FETCH: 'orders/STORE_ORDERS_FETCH',
  ORDERS_SUMMARY_FETCH: 'orders/ORDERS_SUMMARY_FETCH',
  UPDATE_ORDER_SUMMARY: 'orders/UPDATE_ORDER_SUMMARY',
  // STORE_SET_ORDERS_IS_FETCHING: 'orders/STORE_SET_ORDERS_IS_FETCHING',
  STORE_SET_ORDERS_STATE: 'orders/STORE_SET_ORDERS_STATE',

  SEARCH_ORDERS_INIT: 'orders/SEARCH_ORDERS_INIT',
  SEARCH_ORDERS_FETCH: 'orders/SEARCH_ORDERS_FETCH',
  SEARCH_ORDERS_SET_STATE: 'orders/SEARCH_ORDERS_SET_STATE',

  // STORE_ORDERS_QUERY: 'orders/STORE_ORDERS_QUERY',
  // STORE_SET_ORDERS_QUERY_STATE: 'orders/STORE_SET_ORDERS_QUERY_STATE',

  STORE_ORDERS_RESET_STATE: 'orders/STORE_ORDERS_RESET_STATE',
  PAYMENT_ORDERS_RESET_STATE: 'orders/PAYMENT_ORDERS_RESET_STATE',

  PAYMENT_ORDERS_FETCH: 'orders/PAYMENT_ORDERS_FETCH',
  // PAYMENT_SET_ORDERS_IS_FETCHING: 'orders/PAYMENT_SET_ORDERS_IS_FETCHING',
  PAYMENT_SET_ORDERS_STATE: 'orders/PAYMENT_SET_ORDERS_STATE',

  STORE_ORDERS_TAB_STATUS_RESET: 'orders/STORE_ORDERS_TAB_STATUS_RESET',
  PAYMENT_ORDERS_TAB_STATUS_RESET: 'orders/PAYMENT_ORDERS_TAB_STATUS_RESET',

  STORE_ORDERS_FILTER_SETTING_RESET: 'orders/STORE_ORDERS_FILTER_SETTING_RESET',
  STORE_ORDERS_FILTER_SETTING_SET: 'orders/STORE_ORDERS_FILTER_SETTING_SET',
  PAYMENT_ORDERS_FILTER_SETTING_RESET: 'orders/PAYMENT_ORDERS_FILTER_SETTING_RESET',
  PAYMENT_ORDERS_FILTER_SETTING_SET: 'orders/PAYMENT_ORDERS_FILTER_SETTING_SET',

  STORE_ORDERS_TOGGLE_IS_CHECK_ORDER_ID: 'orders/STORE_ORDERS_TOGGLE_IS_CHECK_ORDER_ID',
  STORE_ORDERS_CHECK_ALL_ORDER_IDS: 'orders/STORE_ORDERS_CHECK_ALL_ORDER_IDS',
  STORE_ORDERS_UNCHECK_ALL_ORDER_IDS: 'orders/STORE_ORDERS_UNCHECK_ALL_ORDER_IDS',

  STORE_ORDERS_SHOULD_FETCH_ALL: 'orders/STORE_ORDERS_SHOULD_FETCH_ALL',
  STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY: 'orders/STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY',
  STORE_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY: 'orders/STORE_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY',
  PAYMENT_ORDERS_SHOULD_FETCH_ALL: 'orders/PAYMENT_ORDERS_SHOULD_FETCH_ALL',
  PAYMENT_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY: 'orders/PAYMENT_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY',
  PAYMENT_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY: 'orders/PAYMENT_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY',

  ORDER_FETCH: 'order/FETCH_ORDER_DETAIL',
  ORDER_OPERATE_CONFIRM: 'order/OPERATION_CONFIRM',
  ORDER_OPERATE_PAY: 'order/OPERATION_PAY',

  ORDERS_TOGGLE_PAYMENT_SELECTION: 'orders/ORDERS_TOGGLE_PAYMENT_SELECTION',
  SHIPPING_AUTO_COMPLETE: 'shipping/SHIPPING_AUTO_COMPLETE',
  SHIPPING_CANCEL: 'shipping/SHIPPING_CANCEL',

  ORDERS_SHIPPING_TYPE_CHANGE: 'order/ORDERS_SHIPPING_TYPE_CHANGE',
  ORDERS_SHIPPING_COST_CALC: 'order/ORDERS_SHIPPING_COST_CALC',
  ORDER_DATE_DELIVERY_CHANGE: 'order/ORDER_DATE_DELIVERY_CHANGE',
  ORDER_DATE_TIME_EXPIRATION_DATE_CHANGE: 'order/ORDER_DATE_TIME_EXPIRATION_DATE_CHANGE',

  SHIPMENT_ORDER_FETCH: 'shipment/FETCH_ORDER_DETAIL',
  SHIPMENT_ORDER_OPERATE_SHIP: 'shipment/order/OPERATION_SHIP',

  SESSION_LOADING: 'session/LOADING',
  SESSION_READY: 'session/READY',
  SESSION_SET_ACTIVE_ROUTE_NAME: 'session/SET_ACTIVE_ROUTE_NAME',

  ORDERS_TO_BE_PAID_INIT: 'payment/ORDERS_TO_BE_PAID_INIT',

  STORE_ORDERS_SELECT_SUB_TAB_FOR_KEY: 'orders/STORE_ORDERS_SELECT_SUB_TAB_FOR_KEY',
  PAYMENT_ORDERS_SELECT_SUB_TAB_FOR_KEY: 'orders/PAYMENT_ORDERS_SELECT_SUB_TAB_FOR_KEY',

  FETCH_REPORT_DAILY_SALES: 'report/FETCH_REPORT_DAILY_SALES',
  SET_REPORT_DAILY_SALES: 'report/SET_REPORT_DAILY_SALES',

  LOGIN_WITH_FACEBOOK: 'AUTH/LOGIN_WITH_FACEBOOK',
  SIGNUP_WITH_FACEBOOK: 'AUTH/SIGNUP_WITH_FACEBOOK',
  LOGIN_WITH_FACEBOOK_INVALID: 'AUTH/LOGIN_WITH_FACEBOOK_INVALID',
  SIGNUP_WITH_FACEBOOK_INVALID: 'AUTH/SIGNUP_WITH_FACEBOOK_INVALID',

  LOGIN_WITH_ACCOUNT: 'AUTH/LOGIN_WITH_ACCOUNT',
  LOGIN_WITH_ACCOUNT_INVALID: 'AUTH/LOGIN_WITH_ACCOUNT_INVALID',
  LOGOUT: 'AUTH/LOGOUT',
  LOGOUT_INVALID: 'AUTH/LOGOUT_INVALID',

  FORGOT_ACCOUNT_PASSWORD: 'AUTH/FORGOT_ACCOUNT_PASSWORD',
  FORGOT_ACCOUNT_PASSWORD_INVALID: 'AUTH/FORGOT_ACCOUNT_PASSWORD_INVALID',

  RESET_ACCOUNT_PASSWORD: 'AUTH/RESET_ACCOUNT_PASSWORD',
  RESET_ACCOUNT_PASSWORD_INVALID: 'AUTH/RESET_ACCOUNT_PASSWORD_INVALID',

  LOGIN_REFRESH_APP_TOKEN: 'AUTH/LOGIN_REFRESH_APP_TOKEN',
  LOGIN_REFRESH_APP_TOKEN_INVALID: 'AUTH/LOGIN_REFRESH_APP_TOKEN_INVALID',

  FETCH_INITIALIZE_DATA: 'AUTH/FETCH_INITIALIZE_DATA',
  FETCH_INITIALIZE_DATA_FAILED: 'AUTH/FETCH_INITIALIZE_DATA_FAILED',

  SUBSCRIPTION_LOAD: 'SUBSCRIPTION/LOAD',

  MY_SUBSCRIPTION_FETCH: 'MY_SUBSCRIPTION/FETCH',
  MY_SUBSCRIPTION_FETCH_FAILED: 'MY_SUBSCRIPTION/FETCH_FAILED',
  MY_SUBSCRIPTION_LOAD: 'MY_SUBSCRIPTION/LOAD',

  MY_SUBSCRIPTION_WARNING_SHOW: 'MY_SUBSCRIPTION/SHOW_WARNING',
  MY_SUBSCRIPTION_WARNING_HIDE: 'MY_SUBSCRIPTION/HIDE_WARNING',

  MY_SUBSCRIPTION_QUOTA_WARNING_SHOW: 'MY_SUBSCRIPTION_QUOTA/SHOW_WARNING',
  MY_SUBSCRIPTION_QUOTA_WARNING_HIDE: 'MY_SUBSCRIPTION_QUOTA/HIDE_WARNING',

  USER_PROFILE_FETCH: 'PROFILE/USER_PROFILE_FETCH',
  USER_PROFILE_FETCH_INVALID: 'PROFILE/USER_PROFILE_FETCH_INVALID',
  USER_PROFILE_EDIT: 'PROFILE/USER_PROFILE_EDIT',
  USER_PROFILE_EDIT_INVALID: 'PROFILE/USER_PROFILE_EDIT_INVALID',

  USER_PROFILE_CHANGE_EMAIL: 'PROFILE/USER_PROFILE_CHANGE_EMAIL',
  USER_PROFILE_CHANGE_EMAIL_INVALID: 'PROFILE/USER_PROFILE_CHANGE_EMAIL_INVALID',
  USER_PROFILE_GET_VERIFICATION_EMAIL: 'PROFILE/USER_PROFILE_GET_VERIFICATION_EMAIL',
  USER_PROFILE_GET_VERIFICATION_EMAIL_INVALID: 'PROFILE/USER_PROFILE_GET_VERIFICATION_EMAIL_INVALID',
  LOAD_PROFILE_LIST: 'ProfileState/LOAD_PROFILE_LIST',
  IMPORT_PROFILES: 'ProfileState/IMPORT_PROFILES',
  APPROVALS_RESELLER: 'ProfileState/APPROVALS_RESELLER',
  FETCH_PROFILE_DETAILS: 'ProfileState/FETCH_PROFILE_DETAILS',
  UPDATE_ORG_MEMBER_PROFILE_DETAILE: 'ProfileState/UPDATE_ORG_MEMBER_PROFILE_DETAILE',
  UPDATE_LOGIN_EMAIL: 'ProfileState/UPDATE_LOGIN_EMAIL',
  ORG_MEMBER_SIGNUP: 'ProfileState/ORG_MEMBER_SIGNUP',
  CHANGE_PARENT: 'ProfileState/CHANGE_PARENT',
  CHANGE_RESELLER_TYPE: 'ProfileState/CHANGE_RESELLER_TYPE',
  COUNT_RESELLERS: 'ProfileState/COUNT_RESELLERS',

  RESELLER_LIST_GENERIC_FETCH: 'RESELLER/RESELLER_LIST_GENERIC_FETCH',
  RESELLER_LIST_GENERIC_FETCH_INVALID: 'RESELLER/RESELLER_LIST_GENERIC_FETCH_INVALID',

  RESELLER_ADD: 'RESELLER/ADD',
  RESELLER_ADD_FAILED: 'RESELLER/ADD_FAILED',
  RESELLER_DELETE: 'RESELLER/DELETE',
  RESELLER_DELETE_FAILED: 'RESELLER/DELETE_FAILED',
  RESELLER_DELETE_FROM_LIST: 'RESELLER/DELETE_FROM_LIST',

  RESELLER_PENDING_FETCH: 'reseller/user_group/RESELLER_PENDING_FETCH',
  RESELLER_JOINED_FETCH: 'reseller/user_group/RESELLER_JOINED_FETCH',
  RESELLER_BLOCKED_FETCH: 'reseller/user_group/RESELLER_BLOCKED_FETCH',

  RESELLER_IS_FETCHING: 'reseller/user_group/RESELLER_IS_FETCHING',
  RESELLER_LIST_LOAD: 'reseller/user_group/RESELLER_LIST_LOAD',

  ERROR_RESELLER_FETCH: 'reseller/user_group/ERROR_RESELLER_FETCH',

  UPDATA_DATA_VOLUME_DISCOUNT_AND_PRODUCT_GOUP: 'UPDATA_DATA_VOLUME_DISCOUNT_AND_PRODUCT_GOUP',

  UPDATE_CONT_IN_VOLUME_DISCOUNTS: 'UPDATE_CONT_IN_VOLUME_DISCOUNTS',
  UPDATE_PRINTING_PAPERANG: 'UPDATE_PRINTING_PAPERANG',
  UPDATE_PRINTING_PDF: 'UPDATE_PRINTING_PDF',
  UPDATE_PRINTING_BILL_PAPERANG: 'UPDATE_PRINTING_BILL_PAPERANG',
  UPDATE_PRINTING_STICKER_PDF: 'UPDATE_PRINTING_STICKER_PDF',
  UPDATE_PRINTING_BARCODE: 'UPDATE_PRINTING_BARCODE',

  GET_COPY_LINK_ORDER: 'OrderState/GET_COPY_LINK_ORDER',
  CANCEL_TACKING_CODE: 'OrderState/CANCEL_TACKING_CODE',
  CREATE_TACKING_CODE: 'OrderState/CREATE_TACKING_CODE',
  UPDATE_TACKING_CODE: 'OrderState/UPDATE_TACKING_CODE',
  REQUEST_TRACKING: 'OrderState/REQUEST_TRACKING',
  AUTO_REQUEST_TRACKING: 'OrderState/AUTO_REQUEST_TRACKING',

  UPDATE_XSHIPPING_BALANCE: 'UPDATE_XSHIPPING_BALANCE',

  GET_PERMISSION_MEMBER_LUST: 'GET_PERMISSION_MEMBER_LUST',
  GET_PERMISSION_LIST: 'GET_PERMISSION_LIST',
  CHANGE_PERMISSION: 'CHANGE_PERMISSION',
  DELETE_HELPER_FROM_PERMISSION: 'DELETE_HELPER_FROM_PERMISSION',
  ADD_HELPER: 'ADD_HELPER',
  ADD_PERMISSION: 'ADD_PERMISSION',
  EDIT_PERMISSION: 'EDIT_PERMISSION',
  DELETE_PERMISSION: 'DELETE_PERMISSION',

  GET_RESELLER_ADDRESSES: `GET_RESELLER_ADDRESSES`,

  SHOPEE_AUTH_URL: 'StoreState/SHOPEE_AUTH_URL',
  MKP_RE_AUTH_URL: 'StoreState/MKP_RE_AUTH_URL',

  UPDATE_NEW_CHANNEL_LIST: 'StoreState/UPDATE_NEW_CHANNEL_LIST',
  GET_CHANNEL: 'StoreState/GET_CHANNEL',
  GET_CHANNELS: 'StoreState/GET_CHANNELS',
  GET_WAREHOOUSE: 'StoreState/GET_WAREHOOUSE',
  CREATE_WAREHOOUSE: 'StoreState/CREATE_WAREHOOUSE',
  UPDATE_WAREHOOUSE: 'StoreState/UPDATE_WAREHOOUSE',
  DELETE_WAREHOOUSE: 'StoreState/DELETE_WAREHOOUSE',
  UPDATE_REDUX_NEW_WARHOUSE_LIST: 'StoreState/UPDATE_REDUX_NEW_WARHOUSE_LIST',

  UPDATE_MKP_CHANNEL: 'stores/channel/UPDATE',
  DELETE_DELETE: 'stores/channel/DELETE',
  FETCH_CHANNEL_PRODUCT_BY_PT: 'stores/FETCH_CHANNEL_PRODUCT_BY_PT',

  SET_LOADING_CHANNEL: 'LoadingInProgress/SET_LOADING_CHANNEL',
  UN_SET_LOADING_CHANNEL: 'LoadingInProgress/UN_SET_LOADING_CHANNEL',

  SET_PREF_TIMESLOT: 'StoreState/SET_PREF_TIMESLOT',

  ACTION_COMFIRM_ORDERS_MKP: 'OrderState/ACTION_COMFIRM_ORDERS_MKP',
  ACTION_FETCH_MARKETPLACE_ORDER: 'OrderState/ACTION_FETCH_MARKETPLACE_ORDER',

  ACTION_SYNC_MKP_BG_JOB: 'StoreState/ACTION_SYNC_MKP_BG_JOB',
  ACTION_SYNC_MKP_BACKGROUNG_TASKS: 'StoreState/ACTION_SYNC_MKP_BACKGROUNG_TASKS',

  ACTION_SYNC_SHOP_INFO: 'StoreState/ACTION_SYNC_SHOP_INFO',
  ACTION_SYNC_MKP_PRODUCT: 'StoreState/ACTION_SYNC_MKP_PRODUCT',
  ACTION_SYNC_MKP_ORDER: 'StoreState/ACTION_SYNC_MKP_ORDER',

  ACTION_ORDER_COUNT: 'StoreState/ACTION_ORDER_COUNT',
  ACTION_AUTO_COMPLETE_BY_SEGMENT: 'StoreState/ACTION_AUTO_COMPLETE_BY_SEGMENT',

  SET_MARKETPLACE_CHANNEL: 'MarketplaceState/SET_MARKETPLACE_CHANNEL',
  UN_SET_MARKETPLACE_CHANNEL: 'MarketplaceState/UN_SET_MARKETPLACE_CHANNEL',

  SET_XSHIPPING_CONFIG: 'XShippingState/SET_XSHIPPING_CONFIG',
  SET_XSHIPPING_SYSTEM_ACCOUNT: 'XShippingState/SET_XSHIPPING_SYSTEM_ACCOUNT',

  SET_EDITING_STORE_GO_API: 'StoreState/SET_EDITING_STORE_GO_API',
  ADD_STORE_SETTING_GO_API: 'StoreState/ADD_STORE_SETTING_GO_API',

  GET_ERP_CHANNELS: 'StoreState/GET_ERP_CHANNELS',
  UPDATE_NEW_ERP_CHANNEL_LIST: 'StoreState/UPDATE_NEW_ERP_CHANNEL_LIST',
  UPDATE_SELECTED_STORE_BY_KEY: 'StoreState/UPDATE_SELECTED_STORE_BY_KEY',

  GET_PRODUCT_COUNT: 'StoreState/GET_PRODUCT_COUNT',
  UPDATE_PRODUCT_COUNT: 'StoreState/UPDATE_PRODUCT_COUNT',
}

export default Actions
