import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'

// import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import { COLORS } from 'x/config/styles'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XIconButton from 'xui/components/XIconButton'
import { ISearchProductVariantListViewProps, ISearchProductVariantListViewState, ISearchProductItem } from 'x/types'
import { FlatList, ListRenderItemInfo, TextInput } from 'react-native'
import XCard from 'xui/components/XCard'
import * as api from 'x/utils/api'
import XImage from 'xui/components/XImage'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'
import ErrorListItem from 'xui/components/ErrorListItem'
import XIcon from 'xui/components/XIcon'

// const { NAME, COST, PRICE, PRICE_RETAIL, QTY, AVAILABLE_QTY, WEIGHT, H, SKU, UPC, CATEGORY } = CONS.PRODUCT_ATTR

class SearchProductVariantListView extends React.Component<ISearchProductVariantListViewProps, ISearchProductVariantListViewState> {
  static displayName = 'SearchProductVariantListView'

  // NodeJs.Timeout
  searchProductTimeoutId?: string | number | any

  inProcess?: boolean

  isFetchingMore?: boolean

  searchInputRef: React.RefObject<TextInput>

  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      isInitFetched: false,

      products: [],
      productCount: 0,
      fetchErrorMessage: null,

      isFetchingInit: false,
      isFetchingMore: false,
    }

    this.searchProductTimeoutId = null

    this.searchInputRef = React.createRef()
  }

  async componentDidMount() {
    const initialData = util.getNavParam(this.props, 'initialData')
    if (initialData && initialData.products && initialData.products.length > 0) {
      await util.setStatePromise(this, { ...initialData, isInitFetched: true })
    }
  }

  _onSearchChangeText = (searchText: string) => {
    this.setState({ searchText, isInitFetched: false }, () => {
      if (searchText === '') {
        this._clearSearch()
        return
      }

      if (this.searchProductTimeoutId) {
        clearTimeout(this.searchProductTimeoutId)
      }

      this.searchProductTimeoutId = setTimeout(this.fetchInit, 600)
    })
  }

  isHelper = () => {
    const { selectedStore } = this.props
    return selectedStore.has('role_id') ? selectedStore.get('role_id') === CONS.STORE_ROLE.HELPER : false
  }

  fetchInit = async () => {
    await util.setStatePromise(this, { isFetchingInit: true })

    await util.setStatePromise(this, { productCount: 0, products: [], fetchErrorMessage: null })
    await this.fetchProducts({ offset: 0 })
    await util.delay(100)

    await util.setStatePromise(this, { isInitFetched: true, isFetchingInit: false })
  }

  fetchMore = async () => {
    if (this.isFetchingMore || this.state.isFetchingMore) {
      return
    }
    this.isFetchingMore = true

    if (!this.state.isInitFetched || this.state.isFetchingInit) {
      this.isFetchingMore = false
      return
    }

    if (!this.hasLoadMore()) {
      this.isFetchingMore = false
      return
    }

    await util.setStatePromise(this, { isFetchingMore: true })

    const { products } = this.state
    // console.log('SearchProductVariantListView::fetchMore products.length => ', products.length)
    await this.fetchProducts({ offset: products.length })
    await util.delay(100)

    await util.setStatePromise(this, { isFetchingMore: false })
    this.isFetchingMore = false
  }

  hasLoadMore = () => {
    const { products = [], productCount = 0 } = this.state
    const currentItemCount = products.length
    if (currentItemCount >= productCount) {
      return false
    }
    return true
  }

  getOnPressListItem = (item: ISearchProductItem) => () => {
    // this.onPressListItem(item)

    const onProductItemPressed = util.getNavParam(this.props, 'onProductItemPressed')

    if (_.isNil(onProductItemPressed) || !_.isFunction(onProductItemPressed)) {
      throw new Error('onProductItemPressed is not a function')
    }

    onProductItemPressed(item)
  }

  fetchProducts = async (extraParams: any) => {
    const { searchText } = this.state
    // console.log('fetchProducts extraParams => ', extraParams)
    // console.log('fetchProducts searchText => ', searchText)

    const store_id = util.getNavParam(this.props, 'store_id')
    // const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    // const ug_id = util.getNavParam(this.props, 'ug_id')
    // const pg_ids = util.getNavParam(this.props, 'pg_ids')

    // const queryTextArrayFiltered = searchText.split(/(\s+)/g).filter((str) => str.trim().length > 0)
    // const queryTextArray = queryTextArrayFiltered.slice(0, 12)

    const reqBody = {
      store_id,
      // store_id: seller_store_id || store_id,
      offset: 0,
      limit: CONS.PRODUCTS_FETCH_LIMIT_MORE,
      queryText: searchText,
    }

    if (_.isNumber(extraParams.offset)) {
      reqBody.offset = extraParams.offset
    }

    if (reqBody.offset === 0) {
      // @ts-ignore c คือขอ total count
      reqBody.c = 1
    }

    // if (this.isHelper() && !seller_store_id) {
    //   // @ts-ignore
    //   reqBody.is_helper = true
    // }

    try {
      const res = await api.searchInventoryProducts(reqBody)

      console.log('fetchProducts res => ', res)
      // this.isFetching = false
      // return res

      // if (res.c) {
      //   await util.setStatePromise(this, { productCount: res.c })
      // }

      let productCount
      let newProducts
      const newState: Partial<ISearchProductVariantListViewState> = {}

      if (res.count) {
        // await util.setStatePromise(this, { productCount: res.count })
        productCount = res.count
        newState.productCount = productCount
      }

      if (res.products) {
        const { products } = this.state
        // await util.setStatePromise(this, { products: products.concat(res.products) })
        newProducts = products.concat(res.products)
        newState.products = newProducts
      }

      await util.setStatePromise(this, newState)

      const onSearchSuccess = util.getNavParam(this.props, 'onSearchSuccess')
      if (_.isFunction(onSearchSuccess)) {
        onSearchSuccess(searchText, newProducts, productCount)
      }
    } catch (err) {
      console.log('fetchProducts err => ', err)
      if (reqBody.offset === 0) {
        await util.setStatePromise(this, { fetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
      }
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _keyExtractor = (item: ISearchProductItem, index: number) => `p_${index}_${item.id}`

  _renderListItem = (info: ListRenderItemInfo<ISearchProductItem>) => {
    const { item, index } = info
    // const { n, vn, t } = item
    const { n, v: vs, t } = item
    const v = vs[0]
    const productName = n || ''
    const variantName = v.n || ''
    // const priceText = v.p ? v.p : v.s[0].p
    const sku = v.sku || null
    const upc = v.upc || null
    let imgUri = t[0]
    if (vs[0].img_url) {
      imgUri = vs[0].img_url
    }

    const currentQty = _.isNil(v.q) ? 0 : v.q

    return (
      <XCard w='full' onPress={this.getOnPressListItem(info.item)}>
        <XCard.Body>
          <HStack w='full' space='1'>
            <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
            <VStack p='1' flex={1}>
              <XText variant='inactive' numberOfLines={2}>
                {productName}
              </XText>

              <XText numberOfLines={2}>{variantName}</XText>

              {sku ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`SKU: ${sku}`}</XText> : null}
              {upc ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`UPC: ${upc}`}</XText> : null}

              {/* <XText>{`฿${priceText}`}</XText> */}
              <XText variant='inactive'>
                {p.op.t('qty')}: <XText variant='active'>{currentQty}</XText>
              </XText>
              {/* <XText>{JSON.stringify(info)}</XText> */}
            </VStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderEmptyList = () => {
    const { isInitFetched, searchText, isFetchingInit, fetchErrorMessage } = this.state

    if (isFetchingInit) {
      return (
        <VStack w='full' p='2' py='4' alignItems='center' justifyContent='center'>
          <XText>กำลังค้นหา...</XText>
        </VStack>
      )
    }

    if (searchText === '' && !isInitFetched) {
      return (
        <VStack w='full' p='2' pt='4' alignItems='center' justifyContent='center'>
          <XText>พิมพ์เพื่อค้นหาสินค้าจากข้อมูลเหล่านี้</XText>
          <VStack w='full' p='2' px='6'>
            <XText>- ชื่อสินค้า</XText>
            <XText>- ชื่อตัวเลือกสินค้า</XText>
            <XText>- SKU</XText>
            <XText>- UPC</XText>
          </VStack>
          <VStack w='full' p='2' px='4'>
            <XText>คุณสามารถ*เคาะวรรค*เพื่อกรองสินค้าที่มีทุกคำที่ระบุมาได้เช่น "กางเกงทะเล แดง 36"</XText>
            <XText pt='1.5'>
              ก็จะแสดงผลการค้นหาสินค้าที่มีทั้ง 3 คำนี้ "กางเกงทะเล" "แดง" และ "36" อยู่ใน ชื่อสินค้า ชื่อตัวเลือกสินค้า SKU หรือ UPC
              เป็นต้น
            </XText>
          </VStack>
        </VStack>
      )
    }

    if (!isInitFetched) {
      return null
    }

    if (_.isObject(fetchErrorMessage) && !_.isEmpty(fetchErrorMessage)) {
      return this._renderFetchErrorMessage()
    }

    const emptyWarningText = `ไม่พบรายการค้นหา "${searchText}"`

    return (
      <HStack w='full' p='2' py='4' alignItems='center' justifyContent='center' flexWrap='wrap'>
        <XText w='full' textAlign='center' textAlignVertical='center'>
          {emptyWarningText}
        </XText>
      </HStack>
    )
  }

  _renderListFooter = () => {
    const { isFetchingMore, isInitFetched, productCount } = this.state

    if (!isInitFetched) {
      return null
    }

    if (isInitFetched && productCount === 0) {
      return null
    }

    if (isFetchingMore) {
      return (
        <VStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XSpinner size='sm' color={COLORS.TEXT_INACTIVE} />
        </VStack>
      )
    }

    return (
      <VStack w='full' p='2' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>-- สิ้นสุดรายการ --</XText>
      </VStack>
    )
  }

  _renderListItemSeparator = () => <Box h='8px' w='full' />

  _renderListItems = () => {
    const { products = [] } = this.state
    return (
      <FlatList
        data={products}
        keyExtractor={this._keyExtractor}
        renderItem={this._renderListItem}
        // style={{ backgroundColor: 'rgba(0,188,0,0.5)' }}
        contentContainerStyle={{
          padding: 8,
          // backgroundColor: 'rgba(188,0,0,0.5)',
        }}
        ItemSeparatorComponent={this._renderListItemSeparator}
        ListFooterComponent={this._renderListFooter()}
        ListEmptyComponent={this._renderEmptyList()}
        onEndReached={this.fetchMore}
      />
    )
  }

  _clearSearch = () => {
    this.setState({ productCount: 0, products: [], searchText: '', isInitFetched: false }, () => {
      if (this.searchProductTimeoutId) {
        clearTimeout(this.searchProductTimeoutId)
      }

      try {
        this.searchInputRef.current.focus()
      } catch (err) {
        console.log('_clearSearch err => ', err)
      }
    })
  }

  _renderCloseSearchButton = () => {
    const { searchText, isFetchingInit } = this.state
    if (searchText.length === 0) {
      return null
    }

    if (isFetchingInit) {
      return (
        <HStack h='9' w='32px' alignItems='center' justifyContent='center' position='absolute' right='0' top='0' bottom='0'>
          <XSpinner size='sm' />
        </HStack>
      )
    }

    return (
      <HStack h='9' w='32px' alignItems='center' justifyContent='center' position='absolute' right='0' top='0' bottom='0'>
        <XIconButton name='close' onPress={this._clearSearch} />
      </HStack>
    )
  }

  // แสดง error message จากการ fetch
  _renderFetchErrorMessage = () => {
    const { fetchErrorMessage } = this.state
    const handleshowAlertFetchError = () => util.showAlertFetchError(fetchErrorMessage)

    return <ErrorListItem onPressShowAlertInfo={handleshowAlertFetchError} onPressDoRefresh={this.fetchInit} />
  }

  _renderSearchInput = () => {
    const { searchText } = this.state
    return (
      <XInput
        ref={this.searchInputRef}
        flex={1}
        h='9'
        w='full'
        // px='50px'
        autoFocus
        placeholder='พิมพ์เพื่อค้นหาสินค้า...'
        value={searchText}
        maxLength={200}
        onChangeText={this._onSearchChangeText}
        style={{ paddingLeft: 18, paddingRight: 18 }}
      />
    )
  }

  _renderTitleText = () => {
    // const { selectedStore } = this.props
    // let targetStoreName = selectedStore.get('name')
    const titleFromNavParam = util.getNavParam(this.props, 'title', 'ค้าหาสินค้า')

    return (
      <XText bold textAlign='center' textAlignVertical='center' numberOfLines={2}>
        {titleFromNavParam}
      </XText>
    )
  }

  _renderSearchInfoBar = () => {
    const { searchText = '', productCount, isInitFetched, isFetchingInit, fetchErrorMessage } = this.state
    if (searchText === '') {
      return null
    }

    if (fetchErrorMessage && !_.isEmpty(fetchErrorMessage)) {
      return null
    }

    let txtSearchResult = ''

    if (isFetchingInit) {
      txtSearchResult = `กำลังค้นหาสินค้าที่มีชื่อประกอบด้วย "${searchText}" ...`
    }

    if (isInitFetched && productCount === 0) {
      txtSearchResult = `ไม่พบสินค้าที่มีชื่อประกอบด้วย "${searchText}"`
    }

    if (isInitFetched && productCount > 0) {
      txtSearchResult = `${productCount} รายการตรงกับการค้นหา "${searchText}"`
    }

    return (
      <HStack w='full' h='9' p='2' alignItems='center' bg='white'>
        <XText fontSize='xs' variant='inactive' numberOfLines={2}>
          {txtSearchResult}
        </XText>
      </HStack>
    )
  }

  render() {
    return (
      <VStack w='full' flex={1}>
        <HStack w='full' px='2' py='1' space='2' bg='white'>
          <XIconButton name='arrow-back' onPress={this._goBack} />
          <HStack flex={1} alignItems='center' justifyContent='center'>
            {this._renderTitleText()}
          </HStack>
        </HStack>
        <HStack w='full' px='2' py='1' space='2' bg='white'>
          <HStack flex={1}>
            {this._renderSearchInput()}
            <HStack h='9' w='32px' alignItems='center' justifyContent='center' position='absolute' left='0' top='0' bottom='0'>
              <XIcon inactive name='search' />
            </HStack>
            {this._renderCloseSearchButton()}
          </HStack>
        </HStack>
        {this._renderSearchInfoBar()}
        {/* <HStack w='full' p='1' bg='white'>
          <XText>Search Text: {searchText}</XText>
        </HStack> */}
        <VStack w='full' flex={1} bg='muted.200'>
          {this._renderListItems()}
        </VStack>
      </VStack>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(SearchProductVariantListView)
