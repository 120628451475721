import React from 'react'

import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'
import _ from 'lodash'

import { FlatList, ListRenderItemInfo, TouchableOpacity } from 'react-native'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import {
  IInventoryQtyAdjustRequestBody,
  IQtyAdjustmentConfirmationViewProps,
  IQtyAdjustmentConfirmationViewState,
  ISearchProductItem,
} from 'x/index'

// import BackIcon from 'xui/components/BackIcon'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import XCard from 'xui/components/XCard'
import XIcon from 'xui/components/XIcon'

class QtyAdjustmentConfirmationView extends React.Component<IQtyAdjustmentConfirmationViewProps, IQtyAdjustmentConfirmationViewState> {
  static displayName = 'QtyAdjustmentConfirmationView'

  _inProcess?: boolean

  _isSubmitting?: boolean

  constructor(props: IQtyAdjustmentConfirmationViewProps) {
    super(props)
    this.state = {
      isInitialized: false,
      isSubmitting: false,
    }
  }

  async componentDidMount() {
    await this._initializeView()
  }

  _getMode = () => util.getNavParam(this.props, 'mode', 'add')

  // _getColorScheme = () => {
  //   const mode = this._getMode()
  //   if (mode === 'add') {
  //     return 'success'
  //   }

  //   if (mode === 'deduct') {
  //     return 'danger'
  //   }

  //   if (mode === 'set') {
  //     return 'purple'
  //   }

  //   if (mode === 'count') {
  //     return 'info'
  //   }

  //   return 'primary'
  // }

  _initializeView = async () => {
    await util.setStatePromise(this, { isInitialized: false })
    // Init activeStatesMap
    const newState: Partial<IQtyAdjustmentConfirmationViewState> = {}
    // get ready to render
    newState.isInitialized = true

    await util.setStatePromise(this, newState)
  }

  _resetView = async () => {
    await util.setStatePromise(this, { isInitialized: false })
    await util.delay(200)
    await this._initializeView()
  }

  _handlePressBackBtn = () => {
    // conditionally go back

    this._navBack()
  }

  _navBack = () => {
    util.navGoBack(this.props)
  }

  _renderCustomHeader = () => (
    // const title = util.getNavParam(this.props, 'title', 'ตรวจสอบ')
    // const title = util.getNavParam(this.props, 'title', `ยืนยันปรับจำนวน${p.op.t('qty')}`)
    // const modifiedTitle = `ตรวจสอบ${title}`
    <XCustomHeader
      // title={title}
      title='ตรวจสอบ'
      // headerLeftProps={isGoBackButtonHidden ? undefined : { backIcon: true, onPressItem: this._handlePressBackBtn }}
      headerLeftProps={{ backIcon: true, onPressItem: this._handlePressBackBtn }}
      // renderHeaderRight={this._renderSettingIcon}
    />
  )

  _renderListItem = (info: ListRenderItemInfo<ISearchProductItem>) => {
    const mode = this._getMode()
    const { item, index } = info

    const ppQtyChanged = util.getNavParam(this.props, 'ppQtyChanged', {})
    // const { item, index } = info
    // const { n, vn, t } = item
    const { n, v: vs, t, id } = item
    const v = vs[0]
    const productName = n || ''
    const variantName = v.n || ''
    const priceText = v.p ? v.p : v.s[0].p
    const sku = v.sku || null
    const upc = v.upc || null
    let imgUri = t[0]
    if (vs[0].img_url) {
      imgUri = vs[0].img_url
    }

    const availableQty = _.isNil(v.a) ? 0 : v.a
    const currentQty = _.isNil(v.q) ? 0 : v.q
    const changedQty = _.isNumber(ppQtyChanged[v.i]) ? ppQtyChanged[v.i] : currentQty
    const changedDiff = changedQty - currentQty

    return (
      <VStack
        key={`${index}_${id}`}
        w='full'
        // onPress={this.getOnPressListItem(info.item)}
        borderTopWidth={index === 0 ? '0' : '1'}
        // borderTopWidth='1'
        bg={index % 2 === 0 ? undefined : 'gray.100'}
        borderTopColor='muted.300'>
        <VStack w='full' p='2' space='1'>
          <HStack w='full' space='1.5'>
            <VStack w='34px' space='2'>
              <XText variant='inactive'>{`#${index + 1}`}</XText>
            </VStack>

            <HStack flex={1} space='1'>
              <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
              <VStack flex={1} p='1'>
                <XText variant='inactive' numberOfLines={2}>
                  {productName}
                </XText>

                <XText numberOfLines={2}>{variantName}</XText>

                {sku ? <XText variant='inactive' numberOfLines={1}>{`SKU: ${sku}`}</XText> : null}
                {upc ? <XText variant='inactive' numberOfLines={1}>{`UPC: ${upc}`}</XText> : null}

                {/* <XText>{`฿${priceText}`}</XText> */}
                {/* <XText>{JSON.stringify(info)}</XText> */}
              </VStack>
            </HStack>
          </HStack>

          <HStack w='full' space='1.5'>
            {/* Column 1 */}
            <VStack w='80px'>
              <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                {/* <XText variant='inactive'>เดิม</XText> */}
              </HStack>

              <HStack h='44px' alignItems='center'>
                <XText w='120px' variant='inactive'>
                  {p.op.t('qty')}
                </XText>
              </HStack>

              <HStack h='34px' alignItems='center'>
                <XText w='120px' variant='inactive'>
                  {p.op.t('available_qty')}
                </XText>
              </HStack>
            </VStack>

            {/* Column 2 */}
            {mode !== 'count' && (
              <VStack flex={1} minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>เดิม</XText>
                </HStack>

                <HStack h='44px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${currentQty}`}</XText>
                  {changedDiff !== 0 && (
                    <XText bold variant={changedDiff > 0 ? 'success' : 'danger'}>{` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}</XText>
                  )}
                </HStack>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${availableQty}`}</XText>
                  {changedDiff !== 0 && (
                    <XText bold variant={changedDiff > 0 ? 'success' : 'danger'}>{` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}</XText>
                  )}
                </HStack>
              </VStack>
            )}

            {/* Column 3 */}
            {mode !== 'count' && (
              <VStack maxW='120px' minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>ใหม่</XText>
                </HStack>

                <HStack h='44px' alignItems='center' justifyContent='flex-end'>
                  <XText bold variant='primary'>{`${changedQty}`}</XText>
                </HStack>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>{`${availableQty + changedDiff}`}</XText>
                </HStack>
              </VStack>
            )}

            {/* Column 2 - for count */}
            {mode === 'count' && (
              <VStack flex={1} minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>นับได้</XText>
                </HStack>

                <HStack h='44px' alignItems='center' justifyContent='flex-end'>
                  <XText bold variant='primary'>{`${changedQty}`}</XText>
                </HStack>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>{`${availableQty + changedDiff}`}</XText>
                </HStack>
              </VStack>
            )}

            {/* Column 3 - for count */}
            {mode === 'count' && (
              <VStack maxW='120px' minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>ปัจจุบันมี</XText>
                </HStack>

                <HStack h='44px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${currentQty}`}</XText>
                </HStack>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${availableQty}`}</XText>
                </HStack>
              </VStack>
            )}
          </HStack>
        </VStack>
      </VStack>
    )

    // return (
    //   <VStack key={`${index}_${id}`} w='full' bg={index % 2 === 0 ? 'white' : 'muted.100'} borderTopWidth='1' borderTopColor='muted.300'>
    //     <HStack w='full' p='2' space='1'>
    //       <VStack w='34px' space='2'>
    //         <XText variant='inactive'>{`#${index + 1}`}</XText>

    //         {/* <XIconButton name='trash' variant='outline' colorScheme='danger' onPress={() => this._onDeleteProductItem(index)} /> */}
    //       </VStack>

    //       <HStack flex={1} space='1'>
    //         <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
    //         <VStack flex={1} p='1'>
    //           <XText variant='inactive' numberOfLines={2}>
    //             {productName}
    //           </XText>

    //           <XText numberOfLines={2}>{variantName}</XText>

    //           {sku ? <XText variant='inactive'>{`SKU: ${sku}`}</XText> : null}
    //           {upc ? <XText variant='inactive'>{`UPC: ${upc}`}</XText> : null}

    //           {/* <XText>{`฿${priceText}`}</XText> */}
    //           {/* <XText>{JSON.stringify(info)}</XText> */}
    //         </VStack>
    //       </HStack>

    //       <VStack w='160px' alignItems='flex-end' space='1.5'>
    //         <HStack w='160px'>
    //           <HStack w='80px' justifyContent='flex-end'>
    //             <XText>{`${currentQty}`}</XText>
    //           </HStack>
    //           <HStack w='80px' justifyContent='flex-end'>
    //             <XText>{`${changedQty}`}</XText>
    //           </HStack>
    //         </HStack>

    //         {/* <HStack w='160px' justifyContent='flex-end'>
    //           <TouchableOpacity onPress={() => this._onRequestOpenEditQty(index)}>
    //             <XIcon name='edit' family='FontAwesome' />
    //           </TouchableOpacity>
    //         </HStack> */}
    //       </VStack>
    //     </HStack>
    //   </VStack>
    // )
  }

  _totalQtyChange = () => {
    const products = util.getNavParam(this.props, 'products', [])
    const ppQtyChanged = util.getNavParam(this.props, 'ppQtyChanged', {})

    let totalQtyChanged = 0
    for (const item of products) {
      const pp_id = item.v[0].i
      const currentQty = item.v[0].q || 0
      const changedQty = ppQtyChanged[pp_id]

      totalQtyChanged += changedQty - currentQty
    }

    return totalQtyChanged
  }

  _totalQtyIncreased = () => {
    const products = util.getNavParam(this.props, 'products', [])
    const ppQtyChanged = util.getNavParam(this.props, 'ppQtyChanged', {})

    let totalQtyIncreased = 0
    for (const item of products) {
      const pp_id = item.v[0].i
      const currentQty = item.v[0].q || 0
      const changedQty = ppQtyChanged[pp_id]

      if (changedQty > currentQty) {
        totalQtyIncreased += changedQty - currentQty
      }
    }

    return totalQtyIncreased
  }

  _totalQtyDecreased = () => {
    const products = util.getNavParam(this.props, 'products', [])
    const ppQtyChanged = util.getNavParam(this.props, 'ppQtyChanged', {})

    let totalQtyDecreased = 0
    for (const item of products) {
      const pp_id = item.v[0].i
      const currentQty = item.v[0].q || 0
      const changedQty = ppQtyChanged[pp_id]

      if (changedQty < currentQty) {
        totalQtyDecreased += currentQty - changedQty
      }
    }

    return totalQtyDecreased
  }

  _renderListHeader = () => {
    const title = util.getNavParam(this.props, 'title')
    const mode = util.getNavParam(this.props, 'mode')
    const colorScheme = util.getNavParam(this.props, 'colorScheme')
    const note = util.getNavParam(this.props, 'note')
    const refCode = util.getNavParam(this.props, 'refCode')
    const selectedReason = util.getNavParam(this.props, 'selectedReason')
    const products = util.getNavParam(this.props, 'products', [])
    const productCount = products.length
    // const totalQtyChanged = this._totalQtyChange()
    const totalQtyIncreased = this._totalQtyIncreased()
    const totalQtyDecreased = this._totalQtyDecreased()
    // const colorScheme = this._getColorScheme()

    const titleIcon = util.getNavParam(this.props, 'titleIcon')

    return (
      <VStack w='full' p='2'>
        <XCard bg={`${colorScheme}.100`}>
          <VStack w='full' px='3' py='2'>
            <HStack w='full' pt='1' pb='2' alignItems='center' justifyContent='center' space='1'>
              {titleIcon}
              <XText pt='2px' color={`${colorScheme}.700`} bold>
                {title}
              </XText>
            </HStack>
            <HStack w='full' space='1'>
              <XText w='220px' fontSize='md' variant='inactive'>
                {'รวมรายการสินค้าที่ถูกแก้ไข: '}
              </XText>
              <XText flex={1} fontSize='md' textAlign='right' variant='active' bold>
                {productCount}
              </XText>
            </HStack>

            {/* <HStack w='full' space='1'>
              <XText w='220px' fontSize='md' variant='inactive'>
                {'รวมจำนวนสินค้าที่ถูกแก้ไข: '}
              </XText>
              <XText flex={1} fontSize='md' textAlign='right' variant='active' bold>
                {totalQtyChanged}
              </XText>
            </HStack> */}

            {mode !== 'deduct' && (
              <HStack w='full' space='1'>
                <XText w='220px' fontSize='md' variant='inactive'>
                  รวมจำนวนสินค้าที่
                  <XText fontSize='md' variant='success' bold>
                    เพิ่มขึ้น
                  </XText>
                  :
                </XText>
                <XText flex={1} fontSize='md' textAlign='right' variant='success' bold>
                  {totalQtyIncreased > 0 ? `+${totalQtyIncreased}` : totalQtyIncreased}
                </XText>
              </HStack>
            )}

            {mode !== 'add' && (
              <HStack w='full' space='1'>
                <XText w='220px' fontSize='md' variant='inactive'>
                  รวมจำนวนสินค้าที่
                  <XText fontSize='md' variant='danger' bold>
                    ลดลง
                  </XText>
                  :
                </XText>
                <XText flex={1} fontSize='md' textAlign='right' variant='danger' bold>
                  {totalQtyDecreased > 0 ? `-${totalQtyDecreased}` : totalQtyDecreased}
                </XText>
              </HStack>
            )}

            {selectedReason && _.isString(selectedReason.label) && (
              <HStack w='full' space='1'>
                <XText w='180px' fontSize='md' variant='inactive'>
                  {'เหตุผล: '}
                </XText>
                <XText flex={1} fontSize='md' textAlign='right' variant='active'>
                  {selectedReason.label}
                </XText>
              </HStack>
            )}
            {_.isString(refCode) && refCode.length > 0 && (
              <HStack w='full' space='1'>
                <XText w='180px' variant='inactive'>
                  {'รหัสอ้างอิง: '}
                </XText>
                <XText flex={1} fontSize='md' textAlign='right' variant='active'>
                  {refCode}
                </XText>
              </HStack>
            )}
            {_.isString(note) && note.length > 0 && (
              <HStack w='full' space='1'>
                <XText fontSize='md' variant='inactive'>
                  {'หมายเหตุ: \n'}
                  <XText fontSize='md' variant='active'>
                    {note}
                  </XText>
                </XText>
              </HStack>
            )}
          </VStack>
        </XCard>
      </VStack>
    )
  }

  _renderProductListContent = () => {
    const products = util.getNavParam(this.props, 'products', [])

    // if (products.length === 0) {
    //   return null
    // }

    // const isEmptyList = products.length === 0

    return (
      <VStack flex={1} w='full'>
        {/* <VStack w='full' h='40px'>
          <HStack w='full' p='2' alignItems='center' space='1'>
            <HStack flex={1} space='1'>
              <XText numberOfLines={2}>สินค้า</XText>
            </HStack>
            <HStack w='80px' justifyContent='flex-end'>
              <XText fontSize='xs'>คลังปัจจุบัน</XText>
            </HStack>
            <HStack w='80px' justifyContent='flex-end'>
              <XText fontSize='xs'>คลังใหม่</XText>
            </HStack>
          </HStack>
        </VStack> */}

        <FlatList<ISearchProductItem>
          style={{ flex: 1 }}
          data={products}
          renderItem={this._renderListItem}
          ListHeaderComponent={this._renderListHeader()}
          keyExtractor={(item, index) => `${index}_${item.id}`}
        />
      </VStack>
    )
  }

  _renderContent() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this._renderProductListContent()

    return (
      <VStack flex={1} w='full'>
        {this._renderProductListContent()}
        {/* <View style={{ height: 144 }} /> */}
      </VStack>
    )
  }

  _onPressSubmit = async () => {
    if (this._isSubmitting) {
      return
    }
    this._isSubmitting = true
    await util.setStatePromise(this, { isSubmitting: true })
    await util.delay(200)

    console.log('onPressSubmit')

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const products = util.getNavParam(this.props, 'products', [])
      const ppQtyChanged = util.getNavParam(this.props, 'ppQtyChanged', {})
      const refCode = util.getNavParam(this.props, 'refCode')
      const note = util.getNavParam(this.props, 'note')
      const modeParam = util.getNavParam(this.props, 'mode')
      const selectedReason = util.getNavParam(this.props, 'selectedReason')

      let mode
      if (modeParam === 'add') {
        mode = 'a'
      } else if (modeParam === 'deduct') {
        mode = 'd'
      } else if (modeParam === 'set') {
        mode = 's'
      } else if (modeParam === 'count') {
        mode = 'c'
      }

      const items = []
      for (const item of products) {
        const pp_id = item.v[0].i

        const currentQty = item.v[0].q || 0
        const changedQty = ppQtyChanged[pp_id]

        let qty
        if (mode === 'a') {
          qty = changedQty - currentQty
        } else if (mode === 'd') {
          qty = currentQty - changedQty
        } else if (mode === 's') {
          qty = changedQty
        } else if (mode === 'c') {
          qty = changedQty
        }

        items.push({ pp_id, qty })
      }
      // Always set save_history to true for all these modes
      const save_history = true
      const reqParams: IInventoryQtyAdjustRequestBody = { store_id, mode, items, save_history }

      if (selectedReason && _.isString(selectedReason.value) && selectedReason.value !== 'not_specified') {
        reqParams.reason = selectedReason.value
      }

      if (_.isString(refCode)) {
        reqParams.ref_code = refCode
      }

      if (_.isString(note)) {
        reqParams.note = note
      }

      const res = await api.inventoryQtyAdjust(reqParams)
      // console.log('_onPressSubmit res => ', res)

      const onSubmitSuccess = util.getNavParam(this.props, 'onSubmitSuccess')
      if (_.isFunction(onSubmitSuccess)) {
        onSubmitSuccess()

        this._navBack()
      }
    } catch (error) {
      console.log('_onPressSubmit error => ', error)
    }

    await util.delay(200)
    await util.setStatePromise(this, { isSubmitting: false })
    this._isSubmitting = false
  }

  _renderFooter = () => {
    const { isSubmitting } = this.state
    // const title = util.getNavParam(this.props, 'title', 'เพิ่มจำนวนสินค้า')
    const confirmationTitle = util.getNavParam(this.props, 'confirmationTitle', 'บันทึก')
    const submitText = isSubmitting ? 'กำลังบันทึก...' : confirmationTitle

    const isSubmitButtonDisabled = isSubmitting
    return (
      <HStack w='full' p='2' bg='gray.200' minH='44px' borderTopWidth='1' borderTopColor='gray.400'>
        <TouchableOpacity disabled={isSubmitButtonDisabled} style={{ flex: 1 }} onPress={this._onPressSubmit}>
          <HStack
            h='44px'
            w='full'
            alignItems='center'
            justifyContent='center'
            borderWidth='1'
            borderRadius='lg'
            borderColor={isSubmitButtonDisabled ? 'gray.400' : 'primary.500'}
            bg={isSubmitButtonDisabled ? 'gray.300' : 'primary.500'}>
            <HStack w='44px' alignItems='center' justifyContent='center'>
              <XIcon name='save' family='FontAwesome' variant='activeDark' />
            </HStack>
            <HStack flex={1} alignItems='center' justifyContent='center'>
              <XText fontSize='md' bold variant={isSubmitButtonDisabled ? 'inactive' : 'activeDark'} textAlign='center'>
                {submitText}
              </XText>
            </HStack>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        {this._renderContent()}
        {this._renderFooter()}
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(QtyAdjustmentConfirmationView)
