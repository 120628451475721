/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import CONS from 'x/config/constants'
import { ISelectedStoreMap, IImportExcelMenuItem, IXScreenProps } from 'x/index'

import * as NavActions from 'x/utils/navigation'
import { Image } from 'react-native'

export interface BaseImportExcelListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

export interface BaseImportExcelListViewState {
  // isReportProductDailySales: boolean
}

// interface IImportExcelMenuItem {
//   label: string
//   iconName?: string
//   iconFamily?: 'Ionicons' | 'FontAwesome' | 'MaterialIcons' | 'MaterialCommunityIcons' | 'AntDesign' | 'Foundation'
//   iconStyle?: Object
//   nav?: () => void
//   perm?: string | string[]
//   isHeader?: boolean
//   permBitIndex?: number
// }

export default abstract class BaseImportExcelListView extends Component<BaseImportExcelListViewProps, BaseImportExcelListViewState> {
  abstract renderCustomImgCOD?: () => JSX.Element

  abstract renderCustomImgShipjung?: () => JSX.Element

  MENU_ITRMS: IImportExcelMenuItem[]

  constructor(props) {
    super(props)
    this.state = {
      // isReportProductDailySales: false,
    }
    // const { selectedStore } = props
    // const { navigate } = navigation
    // const { state } = navigation
    // if (!state.params || !state.params.store_id) {
    //   return
    // }

    // const store_id = selectedStore.get(`id`)
    // if (_.isNil(store_id)) {
    //   p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `ไม่พบร้านค้า กรุณาปิดและลองใหม่อีกครั้ง (store_id of null)`)
    //   return
    // }
    // this._alertComingSoon = this._alertComingSoon.bind(this)
    // this._navToReportStock = this._navToReportStock.bind(this)
    this.MENU_ITRMS = [
      {
        label: 'ลูกค้า',
        isHeader: true,
        isShowMenu: () => this._isValidationPaymentHeader(),
      },
      {
        label: 'ลูกค้า',
        iconName: 'group',
        iconFamily: 'MaterialIcons',
        onclick: () => this._navToImportExcelCustomerView(),
        isShowMenu: () => this._isValidationImportExcelCOD(),
      },
      /// ///
      {
        label: 'การชำระ',
        isHeader: true,
        isShowMenu: () => this._isValidationPaymentHeader(),
      },
      {
        label: 'กระทบยอด COD',
        // iconName: 'barcode-scan',
        // iconFamily: 'MaterialCommunityIcons',
        customIcon: () =>
          p.op.isWeb() ? (
            this.renderCustomImgCOD()
          ) : (
            <Image source={require('../../../../xui/x/images/cod.png')} style={{ flex: 0, width: 26, height: 18 }} />
          ),
        onclick: () => this._navToImportExcelCODView(),
        isShowMenu: () => this._isValidationImportExcelCOD(),
      },
      /// ///
      {
        label: 'สินค้า',
        isHeader: true,
        isShowMenu: () => this._isValidationProductHeader(),
      },
      {
        label: 'เพิ่มสินค้าแบบชุด',
        iconName: 'add-shopping-cart',
        iconFamily: 'MaterialIcons',
        onclick: () => this._navToImportAddProductView(),
        isShowMenu: () => this._isValidationAddProduct(),
      },
      {
        label: 'แก้ไขรหัสสินค้า SKU/UPC แบบชุด',
        iconName: 'barcode-scan',
        iconFamily: 'MaterialCommunityIcons',
        onclick: () => this._navToImportProductBarcodeView(),
        isShowMenu: () => this._isValidationEditProductBarcode(),
      },
      /// ///
      {
        // label: 'คลัง',
        label: `สินค้า${p.op.t('qty')}`,
        isHeader: true,
        isShowMenu: () => this._isValidationWarehouseHeader(),
      },
      {
        label: `ปรับจำนวน${p.op.t('qty')}แบบเห็นรายการสินค้าทั้งร้าน`,
        iconName: 'equal-box',
        iconFamily: 'MaterialCommunityIcons',
        iconStyle: { color: '#2F75B5', fontSize: 25 },
        onclick: () => this._navToRImportProductQTYView(),
        isShowMenu: () => this._isValidationEditProductQTY(),
      },
      {
        label: `ปรับจำนวน${p.op.t('qty')}ตาม SKU ที่ระบุเอง`,
        iconName: 'pause-circle',
        iconFamily: 'Ionicons',
        iconStyle: { color: '#2F75B5', transform: [{ rotate: '90deg' }], fontSize: 25, marginRight: 2 },
        onclick: () => this._navToImportEditProductWarehouseBySKU(),
        isShowMenu: () => this._isValidationImportEditProductWarehouseBySKU(),
      },
      {
        label: `ลดจำนวน${p.op.t('qty')}ตาม SKU ที่ระบุเอง`,
        iconName: 'minus-circle',
        iconFamily: 'FontAwesome',
        iconStyle: { color: '#D9534F', fontSize: 25 },
        onclick: () => this._navToImportReduceProductWarehouseBySKU(),
        isShowMenu: () => this._isValidationImportReduceProductWarehouseBySKU(),
      },
      {
        label: `เพิ่มจำนวน${p.op.t('qty')}ตาม SKU ที่ระบุเอง`,
        iconName: 'plus-circle',
        iconFamily: 'FontAwesome',
        iconStyle: { color: '#5CB85C', fontSize: 25 },
        onclick: () => this._navToImportAddProductWarehouseBySKU(),
        isShowMenu: () => this._isValidationImportAddProductWarehouseBySKU(),
      },
      {
        label: `เพิ่มจำนวน${p.op.t('qty')}ด้วย Excel จาก Shipjung`,
        // iconName: 'equal-box',
        // iconFamily: 'MaterialCommunityIcons',
        customIcon: () =>
          p.op.isWeb() ? (
            this.renderCustomImgShipjung()
          ) : (
            <Image source={require('../../../../xui/x/images/shipjung_logo.png')} style={{ flex: 0, width: 26, height: 18 }} />
          ),
        onclick: () => this._navToAddWarehouseExcelFromShipjung(),
        isShowMenu: () => this._isValidationAddWarehouseExcelFromShipjung(),
      },
      {
        label: p.op.t('StoreSetting.HeaderOrder'),
        isHeader: true,
        isShowMenu: () => this._isValidationOrderHeader(),
      },
      {
        label: 'สร้างออเดอร์',
        iconName: 'profile',
        iconFamily: 'AntDesign',
        onclick: () => this._navToImportExcelCreateOrderrView(),
        isShowMenu: () => this._isValidationImportExcelOrder(),
      },
    ]
  }

  _isValidationProductHeader = () => {
    const showHeader = this._isValidationAddProduct() || this._isValidationEditProductBarcode()
    return showHeader
  }

  _isValidationWarehouseHeader = () => {
    const showHeader =
      this._isValidationEditProductQTY() ||
      this._isValidationAddWarehouseExcelFromShipjung() ||
      this._isValidationImportEditProductWarehouseBySKU() ||
      this._isValidationImportReduceProductWarehouseBySKU() ||
      this._isValidationImportAddProductWarehouseBySKU()
    return showHeader
  }

  _isValidationPaymentHeader = () => {
    const showHeader = this._isValidationImportExcelCOD()
    return showHeader
  }

  _isValidationOrderHeader = () => {
    const showHeader = this._isValidationImportExcelOrder()
    return showHeader
  }

  _isValidationEditProductQTY = () =>
    // const permProductStockEdit = util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)
    util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)

  _isValidationImportEditProductWarehouseBySKU = () =>
    // const permProductStockEdit = util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)
    util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)

  _isValidationImportReduceProductWarehouseBySKU = () => util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)

  _isValidationImportAddProductWarehouseBySKU = () => util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)

  _isValidationEditProductBarcode = () => {
    const permProductEditSKU = util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU)
    const permProductEditUPC = util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC)
    return permProductEditSKU || permProductEditUPC
  }

  _isValidationAddWarehouseExcelFromShipjung = () => {
    // if (!p.op.isWeb()) {
    //   return false
    // }
    const permProductEditWarehouse = util.hasHelperPermission(CONS.PERM_STORE_HELPER.WH_EDIT)
    return permProductEditWarehouse
  }

  _isValidationAddProduct = () =>
    // if (!p.op.isWeb()) {
    //   return false
    // }
    util.hasHelperPermission(CONS.PERM_STORE_HELPER.PRODUCT_ADD)

  _isValidationImportExcelCOD = () => {
    const permOrderEditSKU = util.hasHelperPermission(CONS.PERM_STORE_HELPER.ORDER_EDIT)
    return permOrderEditSKU
  }

  _isValidationImportExcelOrder = () => {
    const permOrderEditSKU = util.hasHelperPermission(CONS.PERM_STORE_HELPER.ORDER_ADD)
    return permOrderEditSKU
  }

  _navToRImportProductQTYView() {
    const { navigation } = this.props
    const { dispatch } = navigation
    // if (!state.params || !state.params.store_id || !isImmutable(sellerStores)) {
    //   return
    // }
    // const storeId = selectedStore.get(`id`)
    // p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_RESELLER, { store_id })
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportProductQTYView())
  }

  _navToImportEditProductWarehouseBySKU() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportEditProductWarehouseBySKU())
  }

  _navToImportReduceProductWarehouseBySKU() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportReduceProductWarehouseBySKU())
  }

  _navToImportAddProductWarehouseBySKU() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportAddProductWarehouseBySKU())
  }

  _navToImportProductBarcodeView() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportProductBarcodeView())
  }

  _navToImportAddProductView() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportAddProductView())
  }

  _navToImportExcelCODView() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportExcelCODView())
  }

  _navToAddWarehouseExcelFromShipjung() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportWarehouseExcelFromShipjung())
  }

  _navToImportExcelCustomerView() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportExcelCustomerView())
  }

  _navToImportExcelCreateOrderrView() {
    const { navigation } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
    }
    dispatch(NavActions.navToImportExcelCreateOrderView())
  }
}
