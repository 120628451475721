import React from 'react'

import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'

import { TouchableOpacity } from 'react-native'

import * as util from 'x/utils/util'
import { IQtyAdjustmentListViewProps, IQtyAdjustmentListViewState } from 'x/index'

// import BackIcon from 'xui/components/BackIcon'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import * as NavActions from 'x/utils/navigation'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import ForwardIcon from 'xui/components/ForwardIcon'
import { IIconType } from 'xui/components/Icon'

class QtyAdjustmentListView extends React.Component<IQtyAdjustmentListViewProps, IQtyAdjustmentListViewState> {
  static displayName = 'QtyAdjustmentListView'

  _inProcess?: boolean

  constructor(props: IQtyAdjustmentListViewProps) {
    super(props)
    this.state = {
      isInitialized: false,
    }
  }

  async componentDidMount() {
    await this._initializeView()
  }

  _initializeView = async () => {
    // Init activeStatesMap
    const newState: Partial<IQtyAdjustmentListViewState> = {}

    // get ready to render
    newState.isInitialized = true

    await util.setStatePromise(this, newState)
  }

  _handlePressBackBtn = () => {
    // conditionally go back

    this._navBack()
  }

  _navBack = () => {
    util.navGoBack(this.props)
  }

  _navToQtyAdjustmentView = (mode: 'add' | 'deduct' | 'set' | 'count') => {
    const { navigation } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    const title = this._getModeTitle(mode)
    const colorScheme = this._getModeColorScheme(mode)
    const confirmationTitle = this._getModeConfirmationTitle(mode)

    navigation.dispatch(
      NavActions.navToQtyAdjustmentView({
        store_id,
        mode,
        title,
        confirmationTitle,
        colorScheme,
        titleIcon: this._renderTitleIcon(mode, colorScheme),
      })
    )
  }

  _getModeTitle = (mode) => {
    let title = ''
    if (mode === 'add') {
      title = 'เพิ่มจำนวนสินค้า'
    }

    if (mode === 'deduct') {
      title = 'ลดจำนวนสินค้า'
    }

    if (mode === 'set') {
      title = 'ปรับจำนวนสินค้า'
    }

    if (mode === 'count') {
      title = 'นับแล้วปรับจำนวนสินค้า'
    }

    return title
  }

  _getModeConfirmationTitle = (mode) => {
    let title = ''
    if (mode === 'add') {
      title = 'บันทึกการเพิ่มจำนวนสินค้า'
    }

    if (mode === 'deduct') {
      title = 'บันทึกการลดจำนวนสินค้า'
    }

    if (mode === 'set') {
      title = 'บันทึกการปรับจำนวนสินค้า'
    }

    if (mode === 'count') {
      title = 'บันทึกการปรับจำนวนสินค้า'
    }

    return title
  }

  _getModeDescription = (mode) => {
    let description = ''
    if (mode === 'add') {
      description = `ใช้เพื่อเพิ่มจำนวนสินค้าคงเหลือทีละหลายรายการ เช่น การเติมสต๊อก และ พัสดุตีกลับ\n(เพิ่ม 1 จากยอดคงเหลือเดิม เมื่อสแกนหรือเลือกสินค้า)`
    }

    if (mode === 'deduct') {
      description = `ใช้เพื่อลดจำนวนสินค้าคงเหลือทีละหลายรายการ เช่น สินค้าเสียหาย สูญหาย หรือเบิกออกไปใช้การอื่น\n(ลด 1 จากยอดคงเหลือเดิม เมื่อสแกนหรือเลือกสินค้า)`
    }

    if (mode === 'set') {
      description = `ใช้เพื่อปรับจำนวนสินค้าคงเหลือทีละหลายรายการ ได้ทั้งปรับเพิ่ม และ/หรือ ลดจำนวน\n(เพิ่มสินค้าในรายการตรวจสอบเพื่อปรับยอดคงเหลือเอง เมื่อสแกนหรือเลือกสินค้า)`
    }

    if (mode === 'count') {
      description = `ใช้ในการตรวจนับสต๊อกแล้วปรับใหม่ตามที่นับได้ เช่น การเช็กสต๊อกประจำเดือน\n(เพิ่ม 1 ในการนับเปรียบเทียบกับยอดคงเหลือปัจจุบัน เมื่อสแกนหรือเลือกสินค้า)`
    }

    return description
  }

  _getModeColorScheme = (mode) => {
    let colorScheme = 'primary'
    if (mode === 'add') {
      colorScheme = 'success'
    }

    if (mode === 'deduct') {
      colorScheme = 'danger'
    }

    if (mode === 'set') {
      colorScheme = 'purple'
    }

    if (mode === 'count') {
      colorScheme = 'info'
    }

    return colorScheme
  }

  _renderTitleIcon = (mode: 'add' | 'deduct' | 'set' | 'count', colorScheme) => {
    let iconName = 'add'
    let iconFamily: IIconType = 'Ionicons'
    switch (mode) {
      case 'add':
        iconName = 'add-circle'
        iconFamily = 'Ionicons'
        break
      case 'deduct':
        iconName = 'remove-circle'
        iconFamily = 'Ionicons'
        break
      case 'set':
        iconName = 'edit'
        iconFamily = 'FontAwesome'
        break
      case 'count':
        iconName = 'archive-check'
        iconFamily = 'MaterialCommunityIcons'
        break
    }

    return <XIcon name={iconName} family={iconFamily} color={`${colorScheme}.700`} />
  }

  _renderCommonCard = (mode) => {
    const title = this._getModeTitle(mode)
    const description = this._getModeDescription(mode)
    const colorScheme = this._getModeColorScheme(mode)
    return (
      <TouchableOpacity onPress={() => this._navToQtyAdjustmentView(mode)}>
        <VStack
          w='full'
          borderWidth='1'
          borderLeftColor={`${colorScheme}.500`}
          borderTopColor={`${colorScheme}.500`}
          borderRightColor={`${colorScheme}.700`}
          borderBottomColor={`${colorScheme}.700`}
          bg={`${colorScheme}.100`}
          borderRadius='lg'>
          <HStack w='full' p='4' alignItems='center' space='1.5'>
            <VStack flex={1} space='1'>
              <HStack w='full' alignItems='center' space='1'>
                {this._renderTitleIcon(mode, colorScheme)}

                <XText bold color={`${colorScheme}.700`}>
                  {title}
                </XText>
              </HStack>

              <HStack w='full' pl='28px' alignItems='center' space='1'>
                <XText variant='inactive'>{description}</XText>
              </HStack>
            </VStack>

            <ForwardIcon />
          </HStack>
        </VStack>
      </TouchableOpacity>
    )
  }

  _renderAddQtyAdjustmentCard = () => this._renderCommonCard('add')

  _renderDeductQtyAdjustmentCard = () => this._renderCommonCard('deduct')

  _renderSetQtyAdjustmentCard = () => this._renderCommonCard('set')

  _renderCountQtyAdjustmentCard = () => this._renderCommonCard('count')

  _renderContent() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XContent>
        <VStack w='full' p='2' space='2'>
          {this._renderAddQtyAdjustmentCard()}
          {this._renderDeductQtyAdjustmentCard()}
          {this._renderSetQtyAdjustmentCard()}
          {this._renderCountQtyAdjustmentCard()}
        </VStack>
      </XContent>
    )
  }

  _renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)

    return (
      <XCustomHeader
        title='จัดการจำนวนสินค้า'
        headerLeftProps={isGoBackButtonHidden ? undefined : { backIcon: true, onPressItem: this._handlePressBackBtn }}
        // renderHeaderRight={this._renderSettingIcon}
      />
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        {this._renderContent()}
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(QtyAdjustmentListView)
