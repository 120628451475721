import api from './api'

export * from './address'
export * from './api'
export * from './contact'
export * from './product'
export * from './dashboard'
export * from './erp'
export * from './inventory'
export * from './payment'
export * from './store'
export * from './subscription'
export * from './website'

// เวลานำไปใช้
// import * as api from 'x/utils/api'
// api.x.fetchGet(...)
const x = api
export { x }

export default api
