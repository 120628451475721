const _TH = {
  VerifySlip: {
    TitleFailedSlipValidation: 'สลิปไม่ผ่านการตรวจสอบ',
    InsufficientCredit: 'ไม่มีเครดิตตรวจสลิปอัตโนมัติคงเหลือ',
    InvalidReceivingPaymentAccount: 'ไม่สามารถอ่านข้อมูลบัญชีรับชำระจากสลิปได้',
    InvalidQRCode: 'ไม่มี QR Code ที่ถูกต้องบนสลิป',
    MismatchAmount: 'ยอดสลิปไม่ตรงกับยอดชำระ',
    ReceivingPaymentAccountNotFound: 'บัญชีรับชำระในสลิปไม่ตรงกับบัญชีที่ตั้งค่าร้านไว้',
    RemoteServerError: 'เกิดข้อผิดพลาดจากเซิร์ฟเวอร์ของธนาคาร กรุณาลองใหม่อีกครั้ง',
    SlipAlreadyUsed: 'สลิปถูกใช้ในการบันทึกชำระอื่นไปแล้ว',
    SlipExpired: 'สลิปหมดอายุ (ตามที่ตั้งค่าร้านหรือที่ทางธนาคารกำหนดไว้)',
    TransactionRejected: 'สลิปถูกปฏิเสธการตรวจสอบจากทางธนาคาร',
    VerifySlipCampaignUsed: 'สิทธิ์ทดลองใช้ได้ถูกกดรับไปแล้ว',
    InvalidTotalAmount: 'ยอดชำระไม่ตรงกับยอดสลิป',
  },
}

const th = {
  greeting_test: 'สวัสดี !!!',
  app_name: 'XSelly',
  add: 'เพิ่ม',
  edit: 'แก้ไข',
  change: 'เปลี่ยน',
  cancel: 'ยกเลิก',
  save: 'บันทึก',
  create: 'สร้าง',
  done: 'ตกลง',
  choose: 'เลือก',
  enabled: 'เปิดใช้งาน',
  default: 'ค่าเริ่มต้น',
  product_list: 'รายการสินค้า',
  product_name: 'ชื่อสินค้า',
  product_barcode: 'ป้ายบาร์โค้ดสินค้า',
  others: 'อื่นๆ',
  print_doc: 'พิมพ์เอกสาร',
  barcode_code_font: 'รหัสบาร์โค้ด (ตัวหนังสือ)', // fix name if name is not true
  ShowTheFollowingInformation: 'แสดงข้อมูลต่อไปนี้',
  showMordDetailInCoverSheet: 'แสดงข้อมูลเพิ่มเติมบนใบปะหน้าพัสดุ (เลือกที่ต้องการ)',
  detailInShippingLabelPDF: 'เลือกรูปแบบใบปะหน้า (เลือกที่ต้องการ)',
  image: 'รูปภาพ',
  paper_header: 'หัวกระดาษ',
  paper_footer: 'ท้ายกระดาษ',
  message: 'ข้อความ',

  ShippingLabel: 'ใบปะหน้าพัสดุ',
  receipt: 'ใบเสร็จรับเงิน',
  coverSheetTypeLabel: 'รูปแบบใบปะหน้า',
  ShippingLabelImg: 'รูปแทนใบปะหน้าพัสดุ',
  ShippingLabelNote: 'หมายเหตุบนใบปะหน้า',
  linkOrder: 'ลิงก์ออเดอร์',

  customer: 'ลูกค้า',
  show_name: 'แสดงชื่อ',
  show_address: 'แสดงที่อยู่',
  show_phone: 'แสดงเบอร์โทร',

  dashboard: 'แดชบอร์ด',

  qty: 'คงเหลือ',
  available_qty: 'พร้อมขาย',

  store_my_view: {
    header: {
      title: 'การขาย',
    },
  },
  about_view: {
    slogan: 'ออเดอร์ สต็อก ตัวแทน\nง่ายเป็นระบบ\nจบในแอปเดียว',
  },
  printing: {
    SHOW_SHIPPING_LABLE: `แสดงรายการสินค้า สำหรับออเดอร์ที่มีการแนบรูปใบปะหน้า`,
    warning: {
      WARNING_CANT_CLOSS_LAST_PRINTING: {
        title: 'ไม่สามารถปิดการใช้งานได้',
        message: 'กรุณาเปิดการใช้งานการพิมพ์ใบปะหน้า รูปแบบอื่น อย่างน้อย 1 อย่าง',
      },
    },
    showProductListWhenNoShippingLabelImage: 'รายการสินค้า (หากออเดอร์*ไม่มี*รูปแนบไม่ปะหน้า)',
    showProductListWhenHasShippingLabelImage: 'รายการสินค้า (หากออเดอร์*มี*รูปแนบใบปะหน้า)',
    showBarcodeSticker: 'แสดงบาร์โค้ดเลขออเดอร์ XSelly',
  },

  showPriceAndTransactionCost: 'แสดงราคาและยอด',
  tracking_number: 'เลขพัสดุ',
  use_tracking_form_system: 'ใช้เลขพัสดุจากระบบ',
  mkp_channel: {
    confirmation_create_shopee: `เพื่อดำเนินการเชื่อมต่อกับ Shopee กรุณาทำ 2 ขั้นตอนนี้ หลังจากที่กดปุ่ม "ดำเนินการต่อ" ด้านล่างแล้ว\n
    1. เลือกภูมิภาค TH (Thailand) แล้วล็อกอินด้วย Username และ Password ของ Shopee\n
    2. กดปุ่ม "Confirm Authorization"`,
    confirmation_create_lazada: `เพื่อดำเนินการเชื่อมต่อกับ Lazada กรุณาทำ 2 ขั้นตอนนี้หลังจากที่กดปุ่ม "ดำเนินการต่อ" แล้ว\n
    1. เลือก Site ให้เป็น "Thailand" แล้วกรอก email และ password\n
    2. กดปุ่ม Submit`,
    confirmation_create_tiktok: `เพื่อดำเนินการเชื่อมต่อกับ Tiktok Shop กรุณากดปุ่ม "ดำเนินการต่อ" ด้านล่าง`,
  },
  mkp_auth_status: {
    connection_feil: 'การเชื่อมต่อล้มเหลว',
    authsuccess: 'เชื่อมต่อสำเร็จ',
    cancelauthsuccess: 'ยกเลิกการเชื่อมต่อสำเร็จ',
    cancelauthfailed: 'ยกเลิกการเชื่อมต่อล้มเหลว กรุณาลองใหม่อีกครั้ง',
    ER001: 'การดำเนินการผิดพลาด (ER001) กรุณาติดต่อทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650',
    ER002: 'การดำเนินการผิดพลาด (ER002) กรุณาติดต่อทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650',
    ER003: 'การดำเนินการผิดพลาด (ER003) กรุณาติดต่อทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650',
    ER003_01:
      'มีการเชื่อมต่อซ้ำกับร้านจากช่องทางขายนี้ กรุณายกเลิกการเชื่อมต่อเดิมก่อนเชื่อมอีกครั้ง หรือติดต่อทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650 (ER003_01)',
    ER003_02: 'แพ็กเกจคุณไม่สามารถเชื่อมช่องทางขายเพิ่มได้ กรุณาซื้อแพ็กเกจเสริม หรือลบการเชื่อมต่อช่องทางเดิมที่ไม่ใช้แล้ว (ER003_02)',
    ER003_03: 'การตั้งค่ารายการราคาไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง (ER003_03)',
  },
  mkp_un_auth: {
    title: 'ยกเลิกการเชื่อมต่อช่องทางขาย',
    tiktok:
      'ในการยกเลิกการเชื่อมต่อกับ Tiktop Shop นี้ ให้ดำเนินการที่หน้าเว็บ seller center ของ Tiktok  Shop โดยเลือก เมนู "บริการ" > "จัดการสมัครสมาชิก" > กด "ยกเลิก" สำหรับ ชื่อบริการ XSelly\n\nเมื่อดำเนินการแล้ว ช่องทางขาย Tiktop Shop ใน XSelly นี้จะถูกลบโดยอัตโนมัติ (ซึ่งจะไม่มีการซิงค์ข้อมูลกับ Tiktok Shop อีก แต่ข้อมูลสินค้าและออเดอร์ที่เคยถูกซิงค์มาใน XSelly แล้วจะยังคงอยู่ใน XSelly)',
  },
  xshippingStatus: {
    pending: 'รอดำเนินการ',
    feil: 'มีข้อผิดพลาด',
    have_tracking: 'มีเลขพัสดุแล้ว (ขนส่งยังไม่ยิงเข้าระบบ)',
    cancelling: 'อยู่ระหว่างการยกเลิก',
    auto_request_if_order_ready_to_ship: 'รอขอเลขอัตโนมัติ\nเมื่อออเดอร์พร้อมจัดส่ง',
    auto_request_if_order_ready_to_ship_one_line: 'รอขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
    feil_waiting_me_to_check: 'ขอเลขไม่สำเร็จ\nรอฉันตรวจสอบ',
    feil_waiting_me_to_check_one_line: 'ขอเลขไม่สำเร็จรอฉันตรวจสอบ',
    xshipping_cancel_failed: 'ยกเลิกเลขไม่สำเร็จ\nรอฉันตรวจสอบ',
    xshipping_cancel_failed_one_line: 'ยกเลิกเลขไม่สำเร็จรอฉันตรวจสอบ',
    pending_request_manual: 'รอฉันกดขอเลขเอง',
    requesting_tracking: 'กำลังขอเลขพัสดุ',
    awaiting_order_pack: 'รอส่งใบปะหน้าพัสดุจากช่องทางขาย',
    processing_order_pack: 'กำลังส่งใบปะหน้าพัสดุจากช่องทางขาย',
    mkp_shipping_label_received: 'คลังสินค้าได้รับใบปะหน้าพัสดุจากช่องทางขายแล้ว',
    waiting_for_courier_pickup: 'รอพนักงานขนส่งเข้ารับ',
    awaiting_confirmation_to_redelivery: 'รอยืนยันการจัดส่งอีกครั้ง',
    awaiting_cancellation: 'รอดำเนินการตามการรอขอให้ยกเลิก',
    refused_by_courier: 'พนักงานขนส่งปฏิเสธการจัดส่ง',
    awaiting_return_to_warehouse: 'รอพัสดุตีกลับไปที่คลังสินค้า',
    received_return_at_warehouse: 'คลังสินค้าได้รับพัสดุตีกลับแล้ว',
    inspected_return_at_warehouse: 'คลังสินค้าได้ตรวจสอบพัสดุตีกลับแล้ว',
    error_occur_please_call_support: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSelly',
    cancel_tracking: 'กำลังยกเลิกเลขพัสดุ',
    request_after_edit: 'กรุณากด ”ขอเลขพัสดุทันที” เมื่อแก้ไขแล้ว',
    picking: 'กำลังเข้ารับ',
    on_the_way_to_drop_off: 'กำลังไป drop-off',
    out_for_delivery: 'ออกจัดส่งแล้ว',
    re_delivery: 'รอจัดส่งอีกครั้ง',
    departed: 'กำลังไปจัดส่ง',
    received_at_sorting_facility: 'รับเข้า ณ ศูนย์คัดแยก',
    failed_to_delivery_due_to_invalid_phone_number: 'ไม่สามารถติดต่อเลขโทรศัพท์หมายปลายทางได้',
    failed_to_delivery_due_to_recipient_absent: 'ผู้รับปลายทางไม่อยู่ตามสถานที่จัดส่ง',
    delivering_to_new_address: 'พัสดุกำลังถูกจัดส่งไปที่อยู่ใหม่',
    hold_in_station: 'พัสดุอยู่ที่สถานี',
    in_the_payment: 'อยู่ระหว่างการชำระ',
    failed_to_deliver: 'นำจ่ายไม่สำเร็จ',

    arrived_at_departure_transit_facility: 'ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก',
    arrived_at_arrival_transit_facility: 'ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาเข้า',
    departed_from_departure_transit_facility: 'ส่งออกจากที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก',
    export_cancelled: 'ยกเลิกการส่งออก',
    import_cancelled: 'ยกเลิกการนำเข้า',
    handed_over_to_airline: 'ส่งมอบให้สายการบิน',
    received_by_airline: 'สายการบินรับมอบ',

    processing_at_warehouse: 'ดำเนินการอยู่ที่คลังสินค้า',
    ready_to_ship_at_warehouse: 'พร้อมส่งที่คลังสินค้า',
    cross_border_processing: 'อยู่ระหว่างการเตรียมส่งออก',
    departed_at_origin_facility: 'ส่งออกจากคลังสินค้าแล้ว',
    cross_border_transit: 'กำลังส่งออกระหว่างประเทศ',
    awaiting_to_sourcing: 'รอการแพ็ก',
    received_at_origin_facility: 'รับเข้าคลังสินค้าแล้ว',
    customs_onhold: 'อยู่ที่ศุลกากร',
    customs_cleared: 'ผ่านการตรวจสอบจากศุลกากร',
    received_at_desitination_facility: 'ถึงปลายทางแล้ว',
    departed_at_desitination_facility: 'ออกจากปลายทางแล้ว',
    awaiting_to_return: 'อยู่ระหว่างจัดส่ง',
    return_processing: 'อยู่ระหว่างดำเนินการส่งกลับ',
    return_approved: 'การส่งกลับได้รับอนุมัติแล้ว',
    returning: 'กำลังส่งกลับ',
    out_of_return: 'เกินพื้นที่ส่งกลับ',
    return_refused: 'การส่งกลับถูกปฏิเสธ',
    awaiting_receive_at_facility: 'รอการรับสินค้า',
    return_to_orgin_facility: 'กำลังส่งกลับไปที่จุดเริ่มต้น',
    returned_at_orgin_facility: 'ส่งกลับไปที่จุดเริ่มต้นแล้ว',
    returned_at_warehouse: 'พัสดุตีกลับไปที่คลังสินค้าเสร็จสิ้น',
    cod_reconciled_by_courier_balance_transfer: 'ขนส่งโอนยอด COD ให้แล้ว',
    cod_reconciled_as_the_package_is_received: 'บันทึกรับชำระ COD อัตโนมัติแล้วเมื่อจัดส่งสำเร็จ',
    pending_for_audit: 'รอการตรวจสอบ',
    cancelled_by_sellers: 'ยกเลิกโดยฉัน',
    cancelled_by_operator: 'ยกเลิกโดยผู้ให้บริการ',
    cancelled_by_partner: 'ยกเลิกโดยพาร์ทเนอร์',
    cancelled_by_system_3rd_party: 'ยกเลิกโดยระบบที่เชื่อมต่อ',
    cancelled_by_app_backend: 'ยกเลิกโดยระบบ XSelly',
    cancelled_at_warehouse: 'ยกเลิกโดยคลังสินค้า',
    cancelled_by_lost_item: 'ยกเลิกเนื่องจากพัสดุสูญหาย',
    delivered: 'จัดส่งสำเร็จ',
    fulfilled: 'ดำเนินการเสร็จสิ้น',
    returned: 'ส่งคืนโกดังสำเร็จ',
    destroy_parcel: 'ทำลายพัสดุสำเร็จ',
    cod_amount_has_been_successfully: 'ได้รับชำระ COD แล้ว',
    unknown_state: 'ไม่สามารถระบุสถานะได้ (9999)',
    cent_get_tracking_code: 'ไม่สามารถขอเลขพัสดุได้',
    cent_cancel_tracking_code: 'ไม่สามารถยกเลิกเลขพัสดุได้',
    request_repeatedly: 'เกิดข้อผิดพลาด กรุณาขอเลขพัสดุอีกครั้ง หลังจาก 4 นาที',
    invalid_address: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ข้อมูลที่อยู่ผู้รับไม่ถูกต้อง"',
    invalid_zipcode: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"รหัสไปรษณีย์ผู้รับไม่ถูกต้อง" (X_4104)',
    invalid_phone_number:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"เบอร์โทรศัพท์ผู้รับไม่ถูกต้อง" ควรมีเพียง 1 หมายเลขโทรศัพท์ และไม่เกิน 10 ตัวเลข (X_4105)',
    invalid_matchterm: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ที่อยู่ผู้รับไม่ถูกต้อง กรุณาตรวจสอบ เขตและรหัสไปรษณีย์"',
    invalid_xshipping: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"การตั้งค่าไม่ถูกต้อง"',
    invalid_system_account: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"การตั้งค่าในระบบไม่ถูกต้อง"',
    invalid_order: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ออเดอร์ไม่ถูกต้อง"',
    invalid_order_state: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"สถานะออเดอร์ไม่ถูกต้อง"',
    invalid_shipment: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ไม่มีสินค้าที่ต้องจัดส่ง"',
    invalid_shipping_type: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"รูปแบบการจัดส่งไม่ถูกต้อง"',
    bad_network: 'ไม่สามารถขอเลขพัสดุได้เนื่องจากการเชื่อมต่อ กรุณาลองใหม่อีกครั้ง',
    system_address: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ที่อยู่ในระบบไม่ถูกต้อง"',
    no_undeleted_product_to_ship:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ไม่มีสินค้ารอจัดส่ง" หรือ "มีอย่างน้อย 1 สินค้าในออเดอร์ถูกลบไปแล้ว" (กรุณาเปลื่ยนสินค้า หรือติดต่อทีมงานเพื่อกู้สินค้ากลับคืนมา)',
    invalid_order_gen_tracking_codes: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก...',
    not_enough_money: 'กรุณาเติมเงินค่าส่ง',
    out_of_delivery_area: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ที่อยู่ผู้รับไม่ถูกต้อง"',
    receiver_name_must_be_not_empty: 'ไม่มีชื่อผู้รับ',
    thaipost_branch_close: 'ปิดประกาศ ณ ที่ทำการรับฝาก : กรุณาติดต่อ THP contact center 1545',
    remote_server_error: 'เกิดข้อผิดพลาดจากเซิร์ฟเวอร์ปลายทาง',
    unknown_error: 'เกิดข้อผิดพลาด (unknown)',
    pickup_failed: 'ไม่สามารถรับสินค้าได้',
    out_of_stock: 'สินค้าหมดสต๊อกจากโกดัง',
    cent_get_tracking_value: 'ไม่สามารถขอเลขพัสดุได้',
    Maximum_number_of_retries_to_fetch_tracking_code_reached: 'ขอเลขพัสดุไม่สำเร็จ ระบบกำลังขอเลขพัสดุใหม่อีกครั้ง',
    in_transit: 'อยู่ระหว่างจัดส่ง',
    order_has_been_canceled: 'ออเดอร์โดนยกเลิกไปแล้ว',
    request_repeatedly_auto_new_request: 'เกิดข้อผิดพลาด ออเดอร์นี้จะขอเลขอัตโนมัติใหม่ภายใน 4 นาที',
    sender_address_invalid_matchterm:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ที่อยู่ผู้ส่งไม่ถูกต้อง กรุณาตรวจสอบ เขต รหัสไปรษณีย์ และเบอร์โทรศัพท์ ของผู้ส่ง"',
    product_has_been_deleted: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "สินค้าถูกลบไปแล้ว" กรุณาปรับรายการสินค้าในออเดอร์แล้วขอใหม่',
    receiver_address_longer_than_250: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ที่อยู่ผู้รับ" มีจำนวนตัวอักษรเกิน 250 ตัวอักษร',
    bad_gateway: 'Bad Gateway กรุณาทำรายการใหม่ภายหลัง',
    invalid_address_pickup: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ที่อยู่ในการเข้ารับพัสดุไม่ถูกต้อง" กรุณาติดต่อทีมงาน',
    invalid_recipient_address: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ที่อยู่ผู้รับในระบบไม่ถูกต้อง" กรุณาติดต่อทีมงาน',

    customer_id_customerid_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มี cust id"',
    logistics_order_number_txlogisticid_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มี courier id"',
    order_type_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มี courier order type"',
    service_type_servicetype_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มี service type"',

    the_sender_name_sender_name_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีชื่อผู้ส่ง"',
    send_phone_sender_mobile_can_not_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีเบอร์โทรผู้ส่ง"',
    sender_sender_city_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีจังหวัดผู้ส่ง"',
    shipment_county_sender_area_can_not_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีเขต/อำเภอผู้ส่ง"',
    the_recipient_name_receiver_name_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีชื่อผู้รับ"',
    receiving_mobile_phone_receiver_mobile_can_not_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีเบอร์โทรผู้รับ"',
    receiver_receiver_city_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีจังหวัดผู้รับ"',
    the_recipient_area_county_area_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีเขต/อำเภอผู้รับ"',
    receiver_address_address_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีบ้านเลขที่ผู้รับ"',
    order_creation_time_createordertime_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่ได้ระบุเวลาสร้างออเดอร์"',
    logistics_company_pick_up_start_time_sendstarttime_can_not_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่ได้ระบุเวลาเริ่มเข้ารับ"',
    logistics_company_pick_up_end_time_sendendtime_can_not_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่ได้ระบุเวลาสิ้นสุดการเข้ารับ"',
    payment_method_paytype_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่ได้ระบุ Payment method"',
    total_number_of_totalquantity_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "จำนวนรวมสินค้าเป็นศูนย์"',
    the_collection_value_of_the_item_value_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ยอดเก็บเงินปลายทางเป็นศูนย์"',
    chinese_name_items_itemname_can_not_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีชื่อสินค้าภาษาจีน"',
    english_name_items_englishName_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีชื่อสินค้าภาษาอังกฤษ"',
    number_of_items_number_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "มีสินค้าจำนวนเป็นศูนย์"',
    declared_value_idr_items_itemvalue_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "มีสินค้ามูลค่าเป็นศูนย์"',
    item_url_items_itemurl_cannot_be_empty: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "มีสินค้าที่ไม่มี itemurl"',
    sender_sender_city_does_not_exist: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "จังหวัดผู้ส่ง"ไม่ถูกต้อง',
    sender_area_county_sender_area_does_not_exist: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "เขต/อำเภอผู้ส่ง"ไม่ถูกต้อง',
    the_recipient_area_county_area_does_not_exist: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "เขต/อำเภอผู้รับ"ไม่ถูกต้อง',
    the_receiving_city_province_does_not_exist: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "จังหวัดผู้รับ"ไม่ถูกต้อง',
    items_error_items_list_length_exceeds_10: 'ไม่สามารถขอเลขพัสดุได้เนื่องจากมีรายการสินค้าเกิน 10',
    order_operation_type_cannot_be_empty: 'เกิดข้อผิดพลาด (X_4227)',
    picked_up: 'รับพัสดุแล้ว',
    arrival: 'มาถึง',
    overnight: 'พัสดุตกค้าง',

    incorrect_shipping_address: 'ไม่สามารถยกเลิกเลขพัสดุได้เนื่องจาก "ข้อมูลการจัดส่งไม่ถูกต้อง" (X_4228)',
    incorrect_address: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ที่อยู่ไม่ถูกต้อง" กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4229)',
    weight_cannot_be_empty: 'ไม่สามารถยกเลิกเลขพัสดุได้เนื่องจาก "ไม่ได้ระบุน้ำหนักสินค้า"',
    Order_type_operation_is_illegal: 'เกิดข้อผิดพลาด (X_4231)',
    order_number_cannot_be_empty: 'เกิดข้อผิดพลาด (X_4232)',
    the_waybill_number_cannot_be_empty: 'เกิดข้อผิดพลาด (X_4233)',
    operational_waybill_does_not_match_urrent_e_commerce: 'เกิดข้อผิดพลาด (X_4234)',
    cancel_order_failed_record_not_found: 'ไม่สามารถยกเลิกเลขพัสดุได้เนื่องจาก "ไม่พบข้อมูล"',
    save_failed: 'เกิดข้อผิดพลาด (X_4236)',
    employee_number_does_not_exist: 'เกิดข้อผิดพลาด (X_4237)',
    order_file_creation_failed: 'เกิดข้อผิดพลาด (X_4238)',
    remark_longer_than_400: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "หมายเหตุเกิน 400 ตัว"',
    weight_over_50kg: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "น้ำหนักเกิน 50 กิโลกรัม"',
    incorrect_length_width_and_height: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ขนาดพัสดุ กxยxส ไม่ถูกต้อง"',
    api_restriction_does_not_pass: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4242)',
    cod_exceeds_the_maximum_limit:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก"ยอด COD เกินลิมิต" หรือ "คุณอาจจะต้องติดต่อทางสาขาของขนส่งเพื่อลงทะเบียนเปิดใช้งาน COD ก่อน"',
    shipping_information_cannot_be_empty: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4244)',
    receiving_information_cannot_be_empty: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4245)',
    the_face_sheet_type_cannot_be_empty: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4246)',
    registration_information_already_exists: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4247)',
    customer_id_is_too_long: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4248)',
    customer_information_does_not_exist: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4249)',
    registration_failed: 'เกิดข้อผิดพลาด กรุณาติดต่อทีมงานที่ LINE: @XSelly (X_4250)',
    the_outlet_information_does_not_exist: 'เกิดข้อผิดพลาด (X_4251)',
    customer_quote_is_not_maintained: 'เกิดข้อผิดพลาด (X_4252)',
    customerId_cannot_be_empty: 'เกิดข้อผิดพลาด (X_4253)',
    name_cannot_be_empty: 'กรุณาระบุชื่อแล้วกด "ขอเลขพัสดุทันที"',
    phone_cannot_be_empty: 'กรุณาระบุเบอร์โทรศัพท์แล้วกด "ขอเลขพัสดุทันที"',
    address_cannot_be_empty: 'กรุณาระบุที่อยู่แล้วกด "ขอเลขพัสดุทันที"',
    postcode_cannot_be_empty: 'กรุณาระบุ*รหัสไปรษณีย์* แล้วกด "ขอเลขพัสดุทันที"',
    source_cannot_be_empty: 'เกิดข้อผิดพลาด (X_4258)',
    invalid_sender_address: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ที่อยู่ผู้ส่งไม่ถูกต้อง" (X_4259)',
    invalid_sender_zipcode: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มีรหัสไปรษณีย์ผู้ส่ง" (X_4260)',
    incomplete_courier_registration: 'กรุณาติดต่อทีมงานที่ LINE: @XSelly เนื่องจากการตั้งค่าบัญชีขนส่งไม่ถูกต้อง (X_4401)',
    jt_vip_account_not_yet_linked:
      'การเชื่อมต่อกับรหัส VIP ของ J&T ไม่ถูกต้อง กรุณาคัดลอก Shop ID และ Shop name จาก หน้า "ตั้งค่าการลงทะเบียน" (ตั้งค่าร้าน > XShipping > J&T) จากในแอป XSelly แล้วนำไปให้สาขา J&T ที่มีรหัส VIP ที่คุณต้องการเชื่อมต่อ เมื่อทางสาขาเชื่อมต่อแล้ว คุณสามารถขอเลขพัสดุจากในแอป XSelly ได้เลย (X_4403)',
    jt_not_yet_registered_on_xselly:
      'กรุณากดปุ่ม "ตั้งค่าการลงทะเบียน" ที่หน้า "ตั้งค่าร้าน (icon รูปเฟือง) > J&T Express" เพื่อตั้งค่าที่อยู่ผู้ส่ง และดำเนินการเชื่อมต่อกับ J&T ตามขั้นตอนในหน้า',

    please_contact_the_team_due_to_failed_signature: 'กรุณาติดต่อทีมงานที่ LINE: @XSelly เนื่องจากการเซ็นชื่อลายมือล้มเหลว (X_4262)',
    this_order_could_not_be_found_in_the_system: 'ไม่พบออเดอร์นี้ในระบบ (X_4263)',
    cant_find_this_warehouse: 'ไม่พบคลังสินค้านี้ (X_4264)',
    not_yet_opened_COD_service: 'คุณยังไม่ได้เปิดบริการ COD กับทางขนส่ง (X_4265)',
    cash_on_delivery_COD_must_be_greater_than_0: 'ยอดเก็บเงินปลายทาง (COD) ต้องมากกว่า 0 (X_4266)',
    sender_information_and_warehouse_no_can_not_be_blank: 'ข้อมูลผู้ส่งและ warehouse No.ไม่สามารถเว้นว่าง ต้องกรอกแบบใดแบบหนึ่ง (X_4267)',
    pno_and_mchid_numbers_dont_match: 'เลข pno กับ mchid ไม่ตรงกัน (X_4268)',
    amount_of_products_that_need_insurance_must_be_greater_than_0: 'ยอดสินค้าที่ต้องการประกันต้องมากกว่า 0 (X_4269)',
    user_id_was_not_found_in_the_transport_system: 'ไม่พบ id ผู้ใช้งานในระบบขนส่ง (X_4270)',
    please_correct_the_recipient_name_to_be_no_more_than_50_characters: 'กรุณาแก้ไขชื่อผู้รับให้ไม่เกิน 50 ตัวอักษร (X_4271)',
    please_correct_the_sender_name_to_be_no_more_than_50_characters: 'กรุณาแก้ไขชื่อผู้ส่งให้ไม่เกิน 50 ตัวอักษร (X_4272)',
    incorrect_sender_subdistrict: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก*ที่อยู่ผู้ส่ง* "แขวงหรือตำบลไม่ถูกต้อง" (X_4273)',
    sender_subdistrict_is_required: 'กรุณาระบุแขวงหรือตำบลของ *ที่อยู่ผู้ส่ง* ให้ถูกต้อง (X_4274)',
    incorrect_recipient_subdistrict: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก*ที่อยู่ผู้รับ* "แขวงหรือตำบลไม่ถูกต้อง" (X_4282)',
    recipient_subdistrict_is_required: 'กรุณาระบุแขวงหรือตำบลของ *ที่อยู่ผู้รับ* ให้ถูกต้อง (X_4283)',
    refid_missing: 'ไม่สามารถขอเลขพัสดุ กรุณาติดต่อทีมงาน เนื่องจากไม่มี refid (X_4275)',
    json_unmarshall_error: 'ไม่สามารถขอเลขพัสดุ กรุณาติดต่อทีมงานที่ LINE: @XSelly (json unmarshall error - X4276)',
    cod_payment_account_required:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก คุณยัง"ไม่ได้ระบุบัญชีรับเงิน COD จากขนส่ง" กรุณาติดต่อทีมงานที่ LINE: @XSelly',
    wrong_cod_payment_account_config:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก การตั้งค่าบัญชีรับเงิน COD ไม่ถูกต้อง กรุณาติดต่อทีมงานที่ LINE: @XSelly',
    unsupported_bank_for_cod_payment_account:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ธนาคารที่ตั้งค่าไว้รับเงิน COD ไม่รองรับโดยขนส่ง" กรุณาติดต่อทีมงานที่ LINE: @XSelly',
    bank_account_name_required: 'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "ไม่มี ชื่อของบัญชีรับเงิน COD" กรุณาติดต่อทีมงานที่ LINE: @XSelly',
    receiver_courier_zipcode_mismatch:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "รหัสไปรษณีย์*ผู้รับ*ไม่ตรงกับข้อมูลในระบบขนส่ง" กรุณาลองค้นหารหัสไปรษณีย์ที่ใกล้เคียงในอินเตอร์เน็ต (ที่ถูกต้องส่วนใหญ่มักลงท้ายด้วยเลขศูนย์) จากนั้นเปลื่ยนรหัสไปรษณีย์ แล้ว "กดขอเลขพัสดุทันที" อีกครั้ง หากยังไม่สำเร็จกรุณาติดต่อทีมงานที่ LINE: @XSelly',
    sender_courier_zipcode_mismatch:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก "รหัสไปรษณีย์*ผู้ส่ง*ไม่ตรงกับข้อมูลในระบบขนส่ง" กรุณาลองค้นหารหัสไปรษณีย์ที่ใกล้เคียงในอินเตอร์เน็ต (ที่ถูกต้องส่วนใหญ่มักลงท้ายด้วยเลขศูนย์) จากนั้นเปลื่ยนรหัสไปรษณีย์ แล้ว "กดขอเลขพัสดุทันที" อีกครั้ง หากยังไม่สำเร็จกรุณาติดต่อทีมงานที่ LINE: @XSelly',
    cod_amount_must_be_a_round_number:
      'ไม่สามารถขอเลขพัสดุได้เนื่องจาก ยอด COD มีเศษสตางค์์ กรุณาแก้ไขยอด COD ให้เป็นจํานวนเต็ม แล้ว "กดขอเลขพัสดุทันที" อีกครั้ง',
  },

  bg_job: {
    title_create_bgjob_succeed: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
    message_bgjob_import_create_order:
      'คิวคำสั่งงาน สำหรับการ "สร้างออเดอร์" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะเสร็จสิ้น',
  },

  shippingLabelStype: {
    PaperSaving: 'แบบประหยัดกระดาษ',
    PaperProductList: 'แบบแยกรายการสินค้า',
    SeparateListOfProductsWithPrices: 'แบบแยกรายการสินค้าพร้อมราคา',
    SeparateListOfProductsWithPricesAndTatal: 'แบบแยกรายการสินค้าพร้อมราคาและยอด',
    PaPerProductImage: 'แบบมีรูปสินค้า',
    PaPerProductImageAndPrices: 'แบบมีรูปสินค้าพร้อมราคา',
    PaPerProductImageAndPricesAndTotal: 'แบบมีรูปสินค้าพร้อมราคาและยอด',
  },

  AddressList: {
    TitleByMode: {
      CUSTOMER_PICK: 'เลือกที่อยู่ลูกค้า',
      CUSTOMER_PICK_CHANGE: 'เปลี่ยนที่อยู่ลูกค้า',
      CUSTOMER_EDIT: 'ที่อยู่ลูกค้า',
      STORE_PICK: 'เลือกที่อยู่ร้านฉัน',
      STORE_EDIT: 'ที่อยู่ร้านฉัน',
      STORE_PICK_CHANGE: 'เปลี่ยนที่อยู่ผู้ส่ง',
      SENDER_NAME_AND_ADDRESS: 'ชื่อและที่อยู่ผู้ส่ง',
      PAPERANG_SHIPPING_LABLE_AUTO_SAVE: 'บันทึกรูปใบปะหน้าพัสดุอัตโนมัติเมื่อเปิดดู',
      PAPERANG_BILL_AUTO_SAVE: 'บันทึกรูปใบเสร็จอัตโนมัติเมื่อเปิดดู',
      SHOW_VAT: 'แสดง VAT และยอดก่อน VAT ท้ายใบเสร็จ',
      SHOW_VAT_TWO_LINE: 'แสดง VAT\nและยอดก่อน VAT ท้ายใบเสร็จ',
    },
  },
  AddressView: {
    TitleByMode: {
      CUSTOMER_EDIT: 'แก้ไขที่อยู่ลูกค้า',
      STORE_EDIT: 'แก้ไขที่อยู่ร้านฉัน',
      CUSTOMER_ADD: 'เพิ่มอยู่ลูกค้า',
      STORE_ADD: 'เพิ่มที่อยู่ร้านฉัน',
    },
  },
  OrdersSummaryDonut: {
    myTasks_confirmOrder: 'ยืนยันออเดอร์',
    myTasks_ship: 'จัดส่ง',
    myTasks_pay: 'ชำระ',
    myTasks_confirmGettingPaid: 'ยืนยันรับชำระ',
    myTasks_paySalesOrdersPaid: 'พร้อมชำระต่อ',
    myTasks_payOther: 'อื่นๆ',
    xShipping_HasTrackingNo: 'เลขพร้อมใช้งาน',
    xShipping_Requesting: 'อยู่ระหว่างการขอเลข',
    xShipping_PendingRequestAuto: 'รอขอเลขอัตโนมัติเมื่อออเดอร์พร้อมจัดส่ง',
    xShipping_PendingRequestManual: 'รอฉันกดขอเลขเอง',
    xShipping_RequestFailed: 'ขอเลขไม่สำเร็จรอฉันตรวจสอบ',
    xShipping_Cancelling: 'อยู่ระหว่างการยกเลิก',
    xShipping_CancelFailed: 'ยกเลิกเลขไม่สำเร็จรอฉันตรวจสอบ',
  },
  OrdersSummaryDonutMenu: {
    myTasks_confirmOrder: 'ยืนยันออเดอร์',
    myTasks_ship: 'จัดส่ง',
    myTasks_pay: 'ชำระ',
    myTasks_confirmGettingPaid: 'ยืนยันรับชำระ',
    myTasks_paySalesOrdersPaid: 'ชำระ > พร้อมชำระต่อ',
    myTasks_payOther: 'ชำระ > อื่นๆ',
    xShipping_HasTrackingNo: 'เลขพร้อมใช้งาน',
    xShipping_Requesting: 'อยู่ระหว่างการขอเลข',
    xShipping_PendingRequestAuto: 'รอขอเลขอัตโนมัติเมื่อออเดอร์พร้อมจัดส่ง',
    xShipping_PendingRequestManual: 'รอฉันกดขอเลขเอง',
    xShipping_RequestFailed: 'ขอเลขไม่สำเร็จรอฉันตรวจสอบ',
    xShipping_Cancelling: 'อยู่ระหว่างการยกเลิก',
    xShipping_CancelFailed: 'ยกเลิกเลขไม่สำเร็จรอฉันตรวจสอบ',
  },
  NavTitle: {
    // by routeName
    MyStores: 'ร้านฉัน',
    SellerStores: 'ร้านผู้ขายส่ง',
    Subscriptions: 'แพ็กเกจ',
    //
    stores_my: 'ร้านฉัน',
    stores_seller: 'ร้านผู้ขายส่ง',
    stores_seller_pull_product: 'เลือกร้านผู้ขายส่งเพื่อดึงสินค้า',
    stores_seller_pull_order: 'เลือกร้านผู้ขายส่ง',
    notifications: 'แจ้งเตือน',
    package: 'แพ็กเกจ',
    stocks: 'คลัง',
    others: 'อื่นๆ',
    store_my_reseller_add: 'เพิ่มตัวแทนเข้าร้าน',
    about_app: 'เกี่ยวกับ %{name}',
    store_reseller: 'ตัวแทน',
  },
  ButtonLabel: {
    ShowHideShippingTypes: 'ซ่อน/แสดง\nรูปแบบจัดส่ง',
    NavToXShippingSettings: 'ตั้งค่า XShipping\nเพื่อออกเลขพัสดุ',
  },
  Login: {
    emailPlaceholder: 'อีเมล       (ที่ลงทะเบียนไว้กับ XSelly)',
    passwordPlaceholder: 'รหัสผ่าน (ที่ลงทะเบียนไว้กับ XSelly)',
  },
  Store: {
    MyStores: {
      blankScreenText: 'ยังไม่มีร้านของฉัน\nกดปุ่ม + ด้านบนเพื่อสร้าง',
    },
    SellerStores: {
      blankScreenText:
        'ยังไม่มีร้านที่ฉันเป็นสมาชิก คุณสามารถสมัครสมาชิกได้จากช่องทางใดช่องทางหนึ่งดังนี้ (ทำเพียงวิธีเดียว)\n\n' +
        '1. (ง่ายที่สุด) กด"ลิงก์ขอเข้ากลุ่ม" (ถ้าเคยกดไปแล้วให้ กดอีกครั้ง)\n' +
        '2. สแกน QR จากปุ่มด้านบนเพื่อสมัคร\n' +
        '3. ขอรหัสเข้าร่วม 5 ตัว จากร้านขายส่ง ระบุลงช่องด้านบน แล้วกดปุ่มสมัคร\n' +
        '4. ไปที่เมนู อื่นๆ แตะที่ชื่อบัญชีผู้ใช้ แล้วนำชื่อบัญชีผู้ใช้ให้ร้านขายส่ง',
    },
    StoreCreateView: {
      NavTitle: 'ร้านใหม่ของฉัน',
      lblStoreName: 'ชื่อร้าน',
      lblStoreDescription: 'คำบรรยายร้าน',
      ttOK: 'สร้างร้านสำเร็จแล้ว',
      ttFail: 'เกิดข้อผิดพลาด: ไม่สามารถสร้างห้องได้ ชื่อร้านของท่านใช้อาจจะถูกใช้ไปแล้วกรุณาเปลี่ยนชื่อร้านแล้วลองใหม่อีกครั้ง',
    },
    StoreMyView: {
      NavTitle: 'ร้าน',
      createOrder: 'เปิดออเดอร์',
      addReseller: 'เพิ่มตัวแทน',
      addProductFromMyStock: 'สร้างสินค้า',
      addProductFromSeller: 'ดึงสินค้าจาก\nร้านผู้ขายส่ง',
    },
    ResellerAddView: {
      lblResellerUsername: 'กรุณาพิมพ์ชื่อบัญชีผู้ใช้ (username) ของตัวแทน',
      msgOK: 'เพิ่มตัวแทนสำเร็จแล้ว',
      msgFail: 'เกิดข้อผิดพลาด: ไม่สามารถเพิ่มตัวแทนได้ กรุณาลองใหม่อีกครั้ง',
    },
    ResellerPreApproveFBView: {
      btnTxt: 'อนุมัติสมาชิกทั้งหมดเป็นตัวแทนร้าน',
      msgOK: 'อนุมัติสำเร็จแล้ว',
      msgFail: 'เกิดข้อผิดพลาด: ไม่สามารถอนุมัติตัวแทนได้ กรุณาลองใหม่อีกครั้ง',
    },
    VolumeDiscountView: {
      volumeDiscount: 'ส่วนลดขายส่ง',
    },
  },
  Bank: {
    name: 'ชื่อ',
    surname: 'นามสกุล',
    homeTel: 'โทรศัพท์บ้าน',
    telephone: 'โทรศัพท์มือถือ',
    line: 'ไลน์',
    fb: 'เฟสบุ๊ค',
    ig: 'อินสตราแกรม',
    username: 'ชื่อผู้ใช้',
    email: 'อีเมล',

    bank: 'ธนาคาร',
    shortBank: 'ธ.',
    noselected: 'ยังไม่ได้เลือก',
    others: 'อื่นๆ',
    cash: 'เงินสด',
    kbank: 'ธ. กสิกรไทย',
    bay: 'ธ. กรุงศรีอยุธยา',
    ktb: 'ธ. กรุงไทย',
    scb: 'ธ. ไทยพาณิชย์',
    bkk: 'ธ. กรุงเทพ',
    tmb: 'ธ. ทหารไทย',
    gsb: 'ธ.ออมสิน',
    cimb: 'ธ.ซีไอเอ็มบีไทย',
    ibank: 'ธ.อิสลามแห่งประเทศไทย',
    kk: 'ธ.เกียรตินาคิน',
    tisco: 'ธ.ทิสโก้',
    tnc: 'ธ.ธนชาต',
    uob: 'ธ.ยูโอบี',
    scnb: 'ธ.สแตนดาร์ดชาร์เตอร์ด',
    trcbank: 'ธ.ไทยเครดิตเพื่อรายย่อย',
    lhbank: 'ธ.แลนด์ แอนด์ เฮาส์',
    smebank: 'ธ.พัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
    baac: 'ธ.เพื่อการเกษตรและสหกรณ์การเกษตร',
    exim: 'ธ.เพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
    ghb: 'ธ.อาคารสงเคราะห์',
    icbc: 'ธ.ไอซีบีซี',
    promptpay: 'พร้อมเพย์',
    rabbit_line_pay: 'Rabbit LINE Pay',
    gbpay: 'GB PRIME PAY',
    cod_deposit: 'COD',
    scbgateway: 'SCB QR Code',
    mkp: 'ช่องทางขาย',
    jt_cod_deposit: 'J&T COD',
    kerry_cod_deposit: 'Kerry COD',
    flash_cod_deposit: 'Flash COD',
    best_cod_deposit: 'BEST COD',
    ems_cod_deposit: 'EMS COD',
    ecopost_cod_deposit: 'eCo-post COD',
    beam: 'Beam',
    ttb: 'ธ.ทีเอ็มบีธนชาต',
    hsbc: 'ธ.HSBC',
    mizuho: 'ธ.Mizuho',
    citi: 'ธ.ซิตี้แบงก์',
    truemoney: 'True Money',
    creditcard: 'บัตรเครดิต',
  },
  OrderListHeaderOneLevelTab: {
    myTasks_ship: 'พร้อมจัดส่ง',
    myTasks_ship_not_ready: 'ไม่พร้อมจัดส่ง',
  },
  Order: {
    order: 'ออเดอร์',
    myTasks: 'รอฉัน',
    custTasks: 'รอลูกค้า',
    resellerTasks: 'รอตัวแทน',
    sellerTasks: 'รอผู้ขายส่ง',
    doneTasks: 'เสร็จสิ้น',
    process: 'ดำเนินการ',
    confirmOrder: 'ยืนยันออเดอร์',
    pay: 'ชำระ',
    autoComplete: 'ออเดอร์ขายที่รอปิดอัตโนมัติ',
    pay4Refund: 'คืนเงิน',
    confirmGettingPaid: 'ยืนยันรับชำระ',
    confirmGettingPaid4Refund: 'ยืนยันรับเงินคืน',
    notsOrder: 'บันทึกช่วยจำส่วนตัว',
    noteOrderToOther: 'ข้อความถึงผู้ซื้อ/ผู้ขาย',
    ship: 'จัดส่ง',
    shipped: 'จัดส่งแล้ว',
    ship_not_ready: 'ไม่พร้อมจัดส่ง',
    ship_all: 'ทั้งหมดที่ต้องจัดส่ง',
    autoCompleted: 'ออเดอร์ขายที่ปิดอัตโนมัติ',
    cancelled: 'ยกเลิก',
    status: 'สถานะ',
    is_cod: 'ชำระ [COD]',
    is_ship_bofore_pay: 'จัดส่งก่อนชำระ',

    trackingByApp: 'เลขพัสดุ %{shipping} โดย XSelly',

    orderNum: 'เลขที่ออเดอร์',
    customerName: 'ชื่อผู้รับสินค้า',
    totalAmount: 'ยอดรวม',
    createdAt: 'สร้างเมื่อ',
    completedAt: 'เสร็จสิ้นเมื่อ',
    cancelledAt: 'ยกเลิกเมื่อ',
    paymentCompletedAt: 'ชำระครบถ้วนเมื่อ',
    shippingCompletedAt: 'จัดส่งครบถ้วนเมื่อ',
    readyToShipAt: 'พร้อมส่งเมื่อ',
    printedAt: 'พิมพ์ใบปะหน้าเมื่อ',
    toBeCancelledWithIn: 'ครบกำหนด',
    overdue: 'เลยกำหนดชำระแล้ว',
    invalidEndTime: 'มีความผิดพลาดในการคำนวณ',
    noOrderForThisStatus: 'ไม่มีออเดอร์ในสถานะนี้',
    noOrderForThisSearch: 'ไม่พบออดอร์ตามเงื่อนไขที่กำหนด',
    loading: 'กำลังโหลดข้อมูล...',
    note: 'บันทึก',

    dispTaskSellToCustomer: 'ลูกค้าซื้อฉัน',
    dispTaskSellToReseller: 'ตัวแทนซื้อฉัน',
    dispTaskBuyFromSeller: 'ฉันซื้อผู้ขายส่ง',

    mainHeaderTab: {
      myTasks: 'รอฉัน',
      custTasks: 'รอลูกค้า',
      resellerTasks: 'รอตัวแทน',
      sellerTasks: 'รอผู้ขายส่ง',
      doneTasks: 'เสร็จสิ้น',
    },
    subHeaderTab: {
      myTasks_confirmOrder: 'ยืนยันออเดอร์',
      myTasks_pay: 'ชำระ',
      myTasks_confirmGettingPaid: 'ยืนยันรับชำระ',
      myTasks_ship: 'จัดส่ง',
      custTasks_pay: 'ชำระ',
      custTasks_autoComplete: 'ปิดออเดอร์อัตโนมัติ',
      resellerTasks_confirmOrder: 'ยืนยันออเดอร์',
      resellerTasks_pay: 'ชำระ',
      resellerTasks_confirmGettingPaid: 'ยืนยันรับเงินคืน',
      sellerTasks_confirmOrder: 'ยืนยันออเดอร์',
      sellerTasks_confirmGettingPaid: 'ยืนยันรับชำระ',
      sellerTasks_ship: 'จัดส่ง',
      sellerTasks_pay: 'คืนเงิน',
      doneTasks_shipped: 'จัดส่งแล้ว',
      doneTasks_autoCompleted: 'ปิดออเดอร์อัตโนมัติเสร็จสิ้น',
      doneTasks_cancelled: 'ยกเลิกแล้ว',
    },
    orderStates: {
      myTasks_confirmOrder: 'รอฉันยืนยันออเดอร์',
      myTasks_pay: 'รอฉันชำระ',
      myTasks_confirmGettingPaid: 'รอฉันยืนยันรับชำระ',
      myTasks_ship: 'รอฉันจัดส่ง',
      custTasks_ship: 'รอลูกค้าชำระ',
      // sellerTasks_process: 'รอผู้ขายส่งดำเนินการ',
      resellerTasks_confirmOrder: 'รอตัวแทนยืนยันออเดอร์',
      resellerTasks_pay: 'รอตัวแทนชำระ',
      resellerTasks_confirmGettingPaid: 'รอตัวแทนยืนยันรับเงินคืน',
      sellerTasks_confirmOrder: 'รอผู้ขายส่งยืนยันออเดอร์',
      sellerTasks_confirmGettingPaid: 'รอผู้ขายส่งยืนยันรับชำระ',
      sellerTasks_ship: 'รอผู้ขายส่งจัดส่ง',
      sellerTasks_pay: 'รอผู้ขายส่งคืนเงิน',
      doneTasks_shipped: 'จัดส่งแล้ว',
      doneTasks_cancelled: 'ยกเลิกแล้ว',

      // Add new for new OrderListView (04-04-2022)
      custTasks_pay: 'รอลูกค้าชำระ',
      custTasks_autoComplete: 'รอลูกค้าปิดออเดอร์อัตโนมัติ',
      doneTasks_autoCompleted: 'ปิดออเดอร์อัตโนมัติเสร็จสิ้น',

      myTasks_paySalesOrdersPaid: 'รอฉัน > ชำระ > พร้อมชำระต่อ',
      myTasks_payOther: 'รอฉัน > ชำระ > อื่นๆ',
      xShipping_HasTrackingNo: 'เลขพร้อมใช้งาน',
      xShipping_Requesting: 'อยู่ระหว่างการขอเลข',
      xShipping_PendingRequestAuto: 'รอขอเลขอัตโนมัติเมื่อออเดอร์พร้อมจัดส่ง',
      xShipping_PendingRequestManual: 'รอฉันกดขอเลขเอง',
      xShipping_RequestFailed: 'ขอเลขไม่สำเร็จรอฉันตรวจสอบ',
      xShipping_Cancelling: 'อยู่ระหว่างการยกเลิก',
      xShipping_CancelFailed: 'ยกเลิกเลขไม่สำเร็จรอฉันตรวจสอบ',

      myTasks_ship_not_ready: 'ไม่พร้อมจัดส่ง',
      myTasks_ship_all: 'ทั้งหมดที่ต้องจัดส่ง',
    },
    // for Donut-case
    orderDonutStates: {
      myTasks_confirmOrder: 'รอฉัน > ยืนยันออเดอร์',
      myTasks_pay: 'รอฉัน > ชำระ',
      myTasks_confirmGettingPaid: 'รอฉัน > ยืนยันรับชำระ',
      myTasks_ship: 'รอฉัน > จัดส่ง',
      myTasks_paySalesOrdersPaid: 'รอฉัน > ชำระ > พร้อมชำระต่อ',
      myTasks_payOther: 'รอฉัน > ชำระ > อื่นๆ',
      xShipping_HasTrackingNo: 'เลขพร้อมใช้งาน',
      xShipping_Requesting: 'อยู่ระหว่างการขอเลข',
      xShipping_PendingRequestAuto: 'รอขอเลขอัตโนมัติเมื่อออเดอร์พร้อมจัดส่ง',
      xShipping_PendingRequestManual: 'รอฉันกดขอเลขเอง',
      xShipping_RequestFailed: 'ขอเลขไม่สำเร็จรอฉันตรวจสอบ',
      xShipping_Cancelling: 'อยู่ระหว่างการยกเลิก',
      xShipping_CancelFailed: 'ยกเลิกเลขไม่สำเร็จรอฉันตรวจสอบ',
    },
    orderNumCard: 'ออเดอร์%{typeText} #%{num}',
    createOrder: 'สร้างออเดอร์',
    orderDetail: 'รายละเอียดออเดอร์',
    orderEditTitle: 'แก้ไขออเดอร์%{typeText} #%{num}',
    shippingType: 'รูปแบบจัดส่ง',
    shipBeforePay: 'จัดส่งก่อนชำระ',
    shippingTypeItems: {
      normal: 'ไปรษณีย์ธรรมดา',
      register: 'ไปรษณีย์ลงทะเบียน',
      register_cod: 'ไปรษณีย์ลงทะเบียน [COD]',
      ems: 'ไปรษณีย์ด่วนพิเศษ (EMS)',
      ems_cod: 'ไปรษณีย์ด่วนพิเศษ (EMS) [COD]',
      ems_world: 'EMS World (ระหว่างประเทศ)',
      ecopost: 'ไปรษณีย์ eCo-Post',
      ecopost_cod: 'ไปรษณีย์ eCo-Post [COD]',
      alpha: 'Alpha Fast',
      alpha_cod: 'Alpha Fast [COD]',
      aramex: 'aramex',
      best: 'BEST Express',
      best_cod: 'BEST Express [COD]',
      best_pickup: 'BEST Express [นัดรับ]',
      businessidea: 'Business Idea',
      cj: 'CJ Logistics',
      cj_cod: 'CJ Logistics [COD]',
      ctt: 'CTT Express',
      dhl: 'DHL Express',
      dhl_cod: 'DHL Express [COD]',
      dhl_pickup: 'DHL Express [นัดรับ]',
      dhl_bulky: 'DHL Express [Bulky]',
      fedex: 'FedEx',
      flash: 'Flash Express',
      flash_cod: 'Flash Express [COD]',
      flash_pickup: 'Flash Express [นัดรับ]',
      grab: 'Grab',
      get: 'Get',
      inter: 'Inter Express Logistics',
      ittransport: 'IT Transport',
      jt: 'J&T Express',
      jt_cod: 'J&T Express [COD]',
      jt_pickup: 'J&T Express [นัดรับ]',
      jt_cod_pickup: 'J&T Express [COD นัดรับ]',
      kerry: 'Kerry Express',
      kerry_cod: 'Kerry Express [COD]',
      kerry_pickup: 'Kerry Express [นัดรับ]',
      kerry_dropoff: 'Kerry Express [Drop-Off]',
      lalamove: 'Lalamove',
      lex: 'Lazada Express',
      lex_cod: 'Lazada Express [COD]',
      lineman: 'Lineman',
      makesend: 'MAKESEND',
      makesend_cod: 'MAKESEND [COD]',
      ninja: 'Ninja Van',
      ninja_cod: 'Ninja Van [COD]',
      ninja_pickup: 'Ninja Van [นัดรับ]',
      nim: 'NiM Express',
      nim_cod: 'NiM Express [COD]',
      pl: 'PL',
      scg: 'SCG Express',
      scg_cod: 'SCG Express [COD]',
      shopee_xpress: 'Shopee Xpress',
      shopee_xpress_cod: 'Shopee Xpress [COD]',
      shopee_xpress_bulky: 'Shopee Xpress [Bulky]',
      shopee_std_delivery: 'Shopee Standard Delivery',
      shopee_std_delivery_bulky: 'Shopee Standard Delivery Bulky',
      tp: 'TP Logistics',
      tp_cod: 'TP Logistics [COD]',
      true: 'True e-Logistics',
      true_cod: 'True e-Logistics [COD]',
      skootar: 'Skootar',
      speedd: 'SPEED-D',
      speedd_cod: 'SPEED-D [COD]',
      springbox: 'Spring Box Express',
      ups: 'ups (United Parcel Service)',
      zto: 'ZTO Express',
      zto_cod: 'ZTO Express [COD]',
      fuze: 'FUZE',
      fuze_cod: 'FUZE [COD]',
      // sunthai: 'ซันไทย',
      changbin: 'ช้างบิน',
      tanamai: 'ธนามัย',
      baramee: 'บารมีขนส่ง',
      bs: 'บี.เอส.',
      blueandwhite: 'บูลแอนด์ไวท์',
      piyawat: 'ปิยวัฒน์ทรานสปอร์ต',
      mango: 'มะม่วงขนส่ง',
      ksd: 'KSD ขนส่ง',
      store_front: 'ขายหน้าร้าน',
      buyer_pickup: 'ผู้ซื้อรับด้วยตนเอง',
      other: 'อื่นๆ',
      unknown: '-',
    },
    orderChannel: 'ช่องทางการขาย',
    channelItems: {
      noselected: 'ไม่ระบุ',
      resellers_not_yet_use_app: 'ตัวแทนที่ไม่ได้ใช้ XSelly',
      facebook: 'Facebook',
      facebook_page: 'Facebook Page',
      line: 'LINE',
      line_add: 'LINE @',
      line_shopping: 'LINE SHOPPING',
      line_man: 'LINE MAN',
      shopee: 'Shopee',
      shopee_food: 'ShopeeFood',
      lazada: 'Lazada',
      tiktok: 'Tiktok Shop',
      shopify: 'Shopify',
      vrich: 'V Rich',
      robinhood: 'Robinhood',
      japanesepartner: 'Japanese Partner',
      claim: 'เคลม',
      eleven_street: '11st',
      branch: 'สาขา',
      consignment: 'ฝากขาย',
      dealer: 'Dealer',
      distributor: 'Distributor',
      friend: 'เพื่อน',
      influencer: 'Influencer',
      marketing: 'Marketing',
      repurchase: 'Repurchase',
      shop: 'หน้าร้าน',
      telesales: 'Telesales',
      twitter: 'Twitter',
      website: 'Website',
      others: 'อื่นๆ',
      reseller_guest: 'ตัวแทนที่ใช้ XSelly',
      app_ecommerce_web: 'XSelly eCommerce Website',
      instagram: 'Instagram',
      event: 'Event',
      foodpanda: 'foodpanda',
      grab: 'Grab',
    },
    shippingTypeText: {
      _1: 'ไปรษณีย์ธรรมดา',
      _2: 'ไปรษณีย์ลงทะเบียน',
      _3: 'ไปรษณีย์ด่วนพิเศษ (EMS)',
      _4: 'Kerry Express',
      _5: 'อื่นๆ',
      _6: 'Alpha Fast',
      _7: 'Ninja Van',
    },
    receiver: 'ผู้รับ',
    sender: 'ผู้ส่ง',
    receiverAddress: 'ที่อยู่ผู้รับ',
    senderAddress: 'ที่อยู่ผู้ส่ง',
    price: 'ราคา',
    cost: 'ต้นทุน',
    qty: 'จำนวน',
    sum: 'รวม',
    available_qty: 'คลัง',
    productList: 'รายการสินค้า',
    shipmentInfo: 'การจัดส่ง',
    alertCancelOrderType12HasSubOrder:
      'ออเดอร์ขายนี้และออเดอร์ซื้อที่เกี่ยวข้องจะถูกยกเลิก จำนวนสินค้าของทุกออเดอร์ที่เกี่ยวข้องจะถูกปรับเป็นศูนย์ และไม่สามารถเพิ่มจำนวนได้อีก การจัดส่งก็จะถูกยกเลิกด้วย คุณตกลงที่จะยกเลิกออเดอร์หรือไม่',
    alertCancelOrderType12NoSubOrder:
      'ออเดอร์ขายนี้จะถูกยกเลิก จำนวนสินค้าของออเดอร์นี้จะถูกปรับเป็นศูนย์  และไม่สามารถเพิ่มจำนวนได้อีก การจัดส่งก็จะถูกยกเลิกด้วย คุณตกลงที่จะยกเลิกออเดอร์หรือไม่',
    alertCancelOrderType3:
      'ออเดอร์ซื้อนี้จะถูกยกเลิก จำนวนสินค้าของออเดอร์นี้และออเดอร์ขายจะถูกปรับเป็นศูนย์ และไม่สามารถเพิ่มจำนวนได้อีก การจัดส่งก็จะถูกยกเลิกด้วย คุณตกลงที่จะยกเลิกออเดอร์หรือไม่',

    OrderStatesText: {
      _0000: 'Invalid STATES',
      // order_states
      _1101: 'รอฉัน > ยืนยันออเดอร์',
      _2101: 'รอฉัน > ยืนยันออเดอร์',
      _3101: 'รอฉัน > ยืนยันออเดอร์',

      _1102: 'รอฉัน > ยืนยันออเดอร์',
      _2102: 'รอฉัน > ยืนยันออเดอร์',
      _3102: 'รอผู้ขายส่ง > ยืนยันออเดอร์',

      _1103: 'รอฉัน > ยืนยันออเดอร์',
      _2103: 'รอตัวแทน > ยืนยันออเดอร์',
      _3103: 'รอฉัน > ยืนยันออเดอร์',

      _1109: 'ยืนยันเสร็จสิ้น',
      _2109: 'ยืนยันเสร็จสิ้น',
      _3109: 'ยืนยันเสร็จสิ้น',
      // _1109: '_1109',
      // _2109: '_2109',
      // _3109: '_3109',

      // cancel_states
      _1181: 'ยกเลิก > โดยฉัน',
      _2181: 'ยกเลิก > โดยฉัน',
      _3181: 'ยกเลิก > โดยผู้ขายส่ง',

      _1182: 'ยกเลิก > โดยลูกค้า',
      _2182: 'ยกเลิก > โดยตัวแทน',
      _3182: 'ยกเลิก > โดยฉัน',

      _1183: 'ยกเลิก > โดยลูกค้า',
      _2183: 'ยกเลิก > โดยตัวแทน',
      _3183: 'ยกเลิก > โดยฉัน',

      _1184: 'ยกเลิก > โดยระบบ',
      _2184: 'ยกเลิก > โดยระบบ',
      _3184: 'ยกเลิก > โดยระบบ',

      // payment_states
      _1111: 'รอลูกค้า > ชำระ',
      _2111: 'รอตัวแทน > ชำระ',
      _3111: 'รอฉัน > ชำระ',

      _1112: 'รอฉัน > คืนเงินลูกค้า',
      _2112: 'รอฉัน > คืนเงินตัวแทน',
      _3112: 'รอผู้ขายส่ง > คืนเงินให้ฉัน',

      _1113: 'รอฉัน > ยืนยันรับชำระ',
      _2113: 'รอฉัน > ยืนยันรับชำระ',
      _3113: 'รอผู้ขายส่ง > ยืนยันรับชำระ',

      _1114: 'รอฉัน > ยืนยันรับชำระ',
      _2114: 'รอฉัน > ยืนยันรับชำระ',
      _3114: 'รอผู้ขายส่ง > ยืนยันรับชำระ',

      _1115: 'รอลูกค้า > ยืนยันรับเงินคืน',
      _2115: 'รอตัวแทน > ยืนยันรับเงินคืน',
      _3115: 'รอฉัน > ยืนยันรับเงินคืน',

      _1119: 'ชำระเสร็จสิ้น',
      _2119: 'ชำระเสร็จสิ้น',
      _3119: 'ชำระเสร็จสิ้น',
      // _1119: '_1119',
      // _2119: '_2119',
      // _3119: '_3119',

      // shipping_states
      _1121: 'รอฉัน > จัดส่ง',
      _2121: 'รอฉัน > จัดส่ง',
      _3121: 'รอผู้ขายส่ง > จัดส่ง',

      _1122: 'รอผู้ขายส่ง > จัดส่ง',
      _2122: 'รอผู้ขายส่ง > จัดส่ง',
      // _1122: '_1122',
      // _2122: '_2122',
      _3122: 'รอผู้ขายส่ง > จัดส่ง',

      _1129: 'จัดส่งครบถ้วน',
      _2129: 'จัดส่งครบถ้วน',
      _3129: 'จัดส่งครบถ้วน',
    },
    OrderConfirmButtonText: {
      _0: 'NULL', // Disable
      _1: 'ยืนยัน', // แบบสามารถแก้ QTY
      _2: 'ยืนยัน', // แบบไม่แก้ QTY
    },
    OrderPayButtonText: {
      _0: 'NULL', // Disable
      _1: 'แจ้งชำระ\nผู้ขายส่ง',
      _2: 'บันทึกรับชำระ\nจากลูกค้า', // เคสลูกค้าซื้อฉัน
      _3: 'ยืนยันรับชำระ',
      _4: 'แจ้งคืนเงิน\nลูกค้า',
      _5: 'แจ้งคืนเงิน\nตัวแทน',
      _6: 'ยืนยันรับเงินคืน\nจากผู้ขายส่ง',
      _7: 'บันทึกรับชำระ\nแทนผู้ซื้อ',
    },
    OrderShipButtonText: {
      _0: 'NULL', // Disable
      _1: 'จัดส่ง',
    },
    Warning: {
      pleaseEditThenConfirm: {
        t: 'คุณสามารถแก้ไขออเดอร์ได้เลย',
        m: 'จากนั้นกดปุ่ม "ยืนยัน" (สีส้มด้านซ้ายล่าง) เมื่อเสร็จสิ้น',
      },
    },
  },
  RateAsk: {
    title: 'เขียนรีวิวให้เรา',
    message:
      'หวังว่าคุณจะประทับใจในการใช้งาน XSelly ทีมงานจักขอบพระคุณยิ่งหากคุณ กด 5 ดาว หรือเขียนรีวิวสั้นๆ เพื่อเป็นกำลังใจให้เราในการพัฒนาแอปต่อไป',
    rate: 'รีวิว',
    later: 'ภายหลัง',
    never: 'ไม่ต้องถามฉันอีก',
  },
  StoreSetting: {
    HeaderMyStore: 'ร้านฉัน',
    HeaderOrder: 'ออเดอร์',
    HeaderProduct: 'สินค้า',
    HeaderPayment: 'การชำระ',
    HeaderShipping: 'การจัดส่ง',
    HeaderShippingLabel: 'รูปแบบใบปะหน้า',
    HeaderWarehouse: 'คลัง',
    HeaderOther: 'อื่นๆ',

    MenuGeneral: 'ข้อมูลทั่วไป',
    MenuStoreAddress: 'ที่อยู่ร้าน',
    MenuStorePaymentAccounts: 'บัญชีรับชำระ',
    MenuStoreShoppingAssistant: 'ผู้ช่วยร้าน',
    MenuCategory: 'หมวดหมู่',
    MenuSenderAddress: 'ที่อยู่ผู้ส่ง',
    MenuProductGroups: 'รายการราคา',
    MenuStoreSettingShippingView: 'ค่าขนส่ง',
    NoteMainStoreSettingShipping: 'คำนวณค่าขนส่งอัตโนมติได้ในหน้าออเดอร์',
    NoteTrueStoreSettingShipping: 'คำนวณค่าขนส่งตามประเภทการจัดส่ง และเรทค่าส่ง',
    NoteFlaseStoreSettingShipping: 'ไม่คำนวณค่าขนส่งอัตโนมัติ',
    MenuUserGroups: 'กลุ่มตัวแทน',
    // MenuOrderAutomation: 'ยืนยัน/ยกเลิก/ล็อก อัตโนมัติ',
    MenuOrderAutomation: 'ยืนยัน ออเดอร์อัตโนมัติ',
    MenuShipBeforePayAndCod: 'การชำระ (เก็บเงินปลายทาง/จัดส่งก่อนชำระ)',
    MenuOrderNote: 'หมายเหตุ/รูปแนบ บนใบปะหน้าพัสดุ',
    MenuVerifySlip: 'ตรวจสลิปอัตโนมัติ',
    MenuLockOrderAutomation: 'ล็อก ออเดอร์อัตโนมัติ',
    MenuStoreCancelOrderAutomation: 'ยกเลิก ออเดอร์อัตโนมัติเมื่อไม่ชำระ',
    AutoCancelOrderIfNotPaidOnTime: 'ยกเลิกออเดอร์ขายอัตโนมัติเมื่อไม่มีการบันทึกชำระในระบบตามเวลาที่กำหนด',
    MenuOrderTimeout: 'ระยะเวลาหมดอายุของออเดอร์',
    MenuStorePrice: 'ราคา',
    MenuStoreProductSN: 'รหัสสินค้า SKU/UPC',
    MenuVolumeDiscount: 'ส่วนลดขายส่ง',
    MenuPaperangShippingLabel: 'Paperang',
    HeaderPaperangShippingLabel: 'ตั้งค่าการพิมพ์ Paperang',
    MenuPDFA4ShippingLabel: 'A4 (PDF)',
    MenuPDFStickerShippingLabel: 'Sticker (PDF)',
    productLabel: 'Sticker (PDF)',
    HeaderPDFA4ShippingLabel: 'ตั้งค่าการพิมพ์ A4 (PDF)',
    HeaderBillPaperang: 'ตั้งค่าการพิมพ์ ใบเสร็จ Paperang',
    MenuPrintingAllShippingLabel: 'การพิมพ์เอกสาร',
    HeaderStickerShippingLabel: 'ตั้งค่าการพิมพ์ Sticker (PDF)',
    MenuWarehouseList: 'คลัง',
    MenuShowShippingType: 'ซ่อน/แสดง รูปแบบจัดส่ง',
    MenuStoreSettingXshipping: 'XShipping (ขอเลขพัสดุจากขนส่งชั้นนำ)',
    MenuPeakAccount: 'รายงาน Excel PEAK Account',

    MenuOrderBillOnlineShowAmount: 'ลิงก์ออเดอร์',
    LabelOrderBillOnlineShowAmount: 'ค่าตั้งต้นในการแสดงราคาสินค้าและยอดออเดอร์',
    NoteTrueLabelOrderBillOnlineShowAmount:
      'ออเดอร์ที่ถูกสร้างใหม่ จะ*เปิดสวิตท์*ให้แสดงราคาสินค้า ' +
      'ส่วนลด ค่าจัดส่ง ค่าธรรมเนียม COD และ ยอดต่างๆในลิงก์ออเดอร์' +
      '\n\nโดยการตั้งค่านี้ไม่มีผลย้อนหลังกับออเดอร์ที่ถูกสร้างไปแล้ว ' +
      'คุณสามารถเลือกเปิดหรือปิดการใช้งานฟีเจอร์นี้ในแต่ละออเดอร์ได้ตลอดเวลา',
    NoteFalseLabelOrderBillOnlineShowAmount:
      'ออเดอร์ที่ถูกสร้างใหม่ จะ*ปิดสวิตท์*เพื่อไม่แสดงราคาสินค้า ' +
      'ส่วนลด ค่าจัดส่ง ค่าธรรมเนียม COD และ ยอดต่างๆในลิงก์ออเดอร์' +
      '\n\nโดยการตั้งค่านี้ไม่มีผลย้อนหลังกับออเดอร์ที่ถูกสร้างไปแล้ว ' +
      'คุณสามารถเลือกเปิดหรือปิดการใช้งานฟีเจอร์นี้ในแต่ละออเดอร์ได้ตลอดเวลา',

    // order sender setting
    HeaderMyCreatedOrder: 'เมื่อฉันสร้างออเดอร์ที่มีสินค้าจากร้านผู้ขายส่ง',
    MenuMyOrderUseMyAddress: 'เมื่อฉันสร้างออเดอร์ที่มีสินค้าจากร้านผู้ขายส่ง ใช้ที่อยู่ของ "ร้านฉัน" เป็นที่อยู่ผู้ส่งเสมอ',
    NoteTrueMyOrderUseMyAddress:
      '* ในกรณีผู้ขายส่งอนุญาต ที่อยู่ผู้ส่งจะเป็นของ "ร้านฉัน" เสมอ แต่ถ้าหากผู้ขายส่งไม่อนุญาต ที่อยู่จะเป็นของ "ผู้ขายส่ง"',
    NoteFalseMyOrderUseMyAddress: '* ที่อยู่ของผู้ส่งจะเป็นของ "ผู้ขายส่ง"',

    HeaderResellerCreatedOrder: 'เมื่อตัวแทนของฉันสร้างออเดอร์',
    MenuSellerOrderUseMyAddress: 'เมื่อตัวแทนของฉันสร้างออเดอร์ ใช้ที่อยู่ของ "ร้านฉัน" เป็นที่อยู่ผู้ส่งเท่านั้น',
    NoteTrueSellerOrderUseMyAddress:
      '* สินค้าที่ฉันจัดส่ง ที่อยู่ผู้ส่งจะเป็นของ "ร้านฉัน" เสมอ แต่ถ้าหากเป็นสินค้าของร้านที่ฉันเป็นตัวแทน สิทธิ์การระบุที่อยู่จะขึ้นอยู่กับ "ผู้ขายส่ง"',
    NoteFalseSellerOrderUseMyAddress:
      '* ที่อยู่ผู้ส่งจะใช้ของ "ตัวแทนของฉัน" (ถ้าระบุมา) หรือใช้ของ "ผู้ขายส่ง" (ถ้าตัวแทนของฉันไม่ได้ระบุ)',

    // HeaderOrderUseRetailPrice: 'เปิดใช้ราคาปลีก',
    MenuStoreUseRetailPrice: 'การใช้งานราคาขายแบบหลายราคา',
    NoteTrueStoreUseRetailPrice: '* สินค้าในร้านจะมีทั้ง ราคาส่ง/ตัวแทน และราคาปลีก คุณสามารถเลือกราคาที่คุณต้องการได้ขณะสร้างออเดอร์',
    NoteFalseStoreUseRetailPrice: '* สินค้าในร้านจะมีราคาขายแบบเดียว',

    MenuStoreUseProductSKU: 'รหัสสินค้า SKU (Stock Keeping Unit / รหัสสินค้าภายใน)',
    NoteStoreUseProductSKU:
      '- เมื่อเปิดใช้งาน สินค้าในร้านของฉันจะสามารถบันทึกรหัส SKU ได้\n' +
      '- สามารถค้นหาสินค้า และเปิดออเดอร์ได้อย่างรวดเร็วด้วยการยิงบาร์โค้ด\n' +
      '- (ถ้ามี) ร้านตัวแทนของฉัน จะ "ไม่สามารถมองเห็น" รหัส SKU ของสินค้าในร้านฉันได้\n' +
      '- รหัส SKU ไม่สามารถซ้ำกันได้สำหรับสินค้าแต่ละรายการ\n' +
      '- รหัส SKU มักจะเป็นเลขบาร์โค้ดที่ใช้สำหรับการบริหารจัดการสินค้าภายในร้านฉัน ซึ่งช่วยในการค้นหา ดูรายงาน และทำบัญชี เป็นต้น เช่น A1-F1-112 อาจะหมายความว่า เป็นสินค้าหมวด A1 เก็บไว้ที่ชั้นวาง F1 หมายเลข 112 โดยฉันสามารถประยุกต์และตั้งรหัส SKU ให้เป็นอะไรก็ได้ ตามความเหมาะสม ดังนั้นหากเป็นสินค้าตัวเดียวกัน แต่ละร้านก็อาจจะมี รหัส SKU ต่างกันได้เนื่องจากเป็นเลขที่มีความหมายสำหรับแต่ละร้านเท่านั้น',
    MenuStoreUseProductUPC: 'รหัสสินค้า UPC (Universal Product Code / รหัสสากลของสินค้า)',
    NoteStoreUseProductUPC:
      '- เมื่อเปิดใช้งาน สินค้าทุกชิ้นในร้านของฉันจะสามารถบันทึกรหัส UPC ได้\n' +
      '- สามารถค้นหาสินค้า และเปิดออเดอร์ได้อย่างรวดเร็วด้วยการยิงบาร์โค้ด\n' +
      '- (ถ้ามี) ร้านตัวแทนของฉัน ก็จะมองเห็นรหัส UPC ด้วย\n' +
      '- รหัส UPC สามารถซ้ำกันได้ เช่น อาจจะเป็นสินค้าตัวเดียวกันที่มาจาก 2 ร้านผู้ขายส่ง\n' +
      '- รหัส UPC มักจะเป็นเลขบาร์โค้ดที่แสดงอยู่ข้างกล่องผลิตภัณฑ์หรือป้ายสินค้า ซึ่งเป็นเลขที่ถูกระบุมาจากโรงงานที่ผลิต ดังนั้นหากเป็นสินค้าตัวเดียวกัน ไม่ว่าจะขายจากที่ไหนก็จะเป็นเลขเดียวกัน',

    MenuStoreUesVolumeDiscount: 'ส่วนลดขายส่ง',
    NoteStoreUseVolumeDiscount: 'เมื่อเปิดใช้งานจะสามารถเพิ่มส่วนลดขายส่งให้กับสินค้าของท่านได้',

    // HeaderMyCreatedOrder: 'เมื่อฉันสร้างออเดอร์ที่มีสินค้าจากร้านผู้ขายส่ง',
    // MenuMyOrderUseMyAddress: 'ใช้ที่อยู่ของ "ร้านฉัน" เป็นที่อยู่ผู้ส่งเสมอ',
    // NoteTrueMyOrderUseMyAddress: '* ในกรณีผู้ขายส่งอนุญาต ที่อยู่ผู้ส่งจะเป็นของ "ร้านฉัน" เสมอ แต่ถ้าหากผู้ขายส่งไม่อนุญาต ที่อยู่จะเป็นของ "ผู้ขายส่ง"',
    // NoteFalseMyOrderUseMyAddress: '* ที่อยู่ของผู้ส่งจะเป็นของ "ผู้ขายส่ง"',
    //
    // HeaderResellerCreatedOrder: 'เมื่อตัวแทนของฉันสร้างออเดอร์',
    // MenuSellerOrderUseMyAddress: 'ใช้ที่อยู่ของ "ร้านฉัน" เป็นที่อยู่ผู้ส่งเท่านั้น',
    // NoteTrueSellerOrderUseMyAddress: '* สินค้าที่เป็นของร้านฉัน ที่อยู่ผู้ส่งจะเป็นของ "ร้านฉัน" เสมอ แต่ถ้าหากเป็นสินค้าของร้านที่ฉันเป็นตัวแทน สิทธิ์การระบุที่อยู่จะขึ้นอยู่กับ "ผู้ขายส่ง',
    // NoteFalseSellerOrderUseMyAddress: '* ที่อยู่ผู้ส่งจะใช้ของ "ตัวแทนของฉัน" (ถ้าระบุมา) หรือใช้ของ "ผู้ขายส่ง" (ถ้าตัวแทนของฉันไม่ได้ระบุ)',

    MenuAutoConfirmPurchaseOrder: 'ยืนยันออเดอร์อัตโนมัติเมื่อฉันซื้อ',
    NoteTrueAutoConfirmPurchaseOrder:
      'ระบบจะ "ยืนยันออเดอร์" ของออเดอร์ซื้อแทนฉันโดยอัตโนมัติเมื่อฉันสร้างออเดอร์ที่\n' +
      '1) มีที่อยู่ผู้รับ\n' +
      '2) ค่าจัดส่งถูกคำนวณโดยอัตโนมัติ',
    NoteFalseAutoConfirmPurchaseOrder: 'ฉันจะยืนยันออเดอร์ซื้อด้วยตัวเอง',

    MenuAutoConfirmSalesOrder: 'ยืนยันออเดอร์อัตโนมัติเมื่อฉันขาย',
    NoteTrueAutoConfirmSalesOrder:
      'ระบบจะ "ยืนยันออเดอร์" ของออเดอร์ขายแทนฉันโดยอัตโนมัติเมื่อผู้ซื้อ(ลูกค้า/ตัวแทน)สร้างออเดอร์ที่\n' +
      '1) มีที่อยู่ผู้รับ\n' +
      '2) ค่าจัดส่งถูกคำนวณโดยอัตโนมัติ',
    NoteFalseAutoConfirmSalesOrder: 'ฉันจะยืนยันออเดอร์ขายด้วยตัวเอง',

    MenuAutoAllowCoverSheet: 'ยืนยันออเดอร์อัตโนมัติถ้าตัวแทนมีการแนบรูปแทนใบปะหน้า',
    NoteTrueAutoAllowCoverSheet:
      'ระบบจะ "ยืนยันออเดอร์" ของออเดอร์ขายแทนฉันโดยอัตโนมัติเมื่อผู้ซื้อ(ลูกค้า/ตัวแทน)สร้างออเดอร์ที่\n' +
      'ตัวแทนแนบรูปแทนใบปะหน้ามาเสมอ',
    NoteFalseAutoAllowCoverSheet: 'ฉันจะยืนยันออเดอร์ขายด้วยตัวเอง',

    MenuAutoAllowNoteCoverSheet: 'ยืนยันออเดอร์อัตโนมัติถ้าตัวแทนมีการระบุหมายเหตุรูปแทนใบปะหน้า',
    NoteTrueAutoAllowNoteCoverSheet:
      'ระบบจะ "ยืนยันออเดอร์" ของออเดอร์ขายแทนฉันโดยอัตโนมัติเมื่อผู้ซื้อ(ลูกค้า/ตัวแทน)สร้างออเดอร์ที่\n' +
      'ตัวแทนระบุหมายเหตุรูปแทนใบปะหน้ามาเสมอ',
    NoteFalseAutoAllowNoteCoverSheet: 'ฉันจะยืนยันออเดอร์ขายด้วยตัวเอง',

    MenuAutoLockOrder: 'ล็อกออเดอร์อัตโนมัติ',
    ChoicesAutoLockOrder: {
      _0: 'ปิดการใช้งาน',
      // _1: 'ล็อกออเดอร์หลังจากยืนยันออเดอร์และบันทึกรับชำระเสร็จสิ้น',
      // _1: 'ล็อกออเดอร์หลังจากออเดอร์มีสถานะ "ยืนยันออเดอร์เสร็จสิ้น" หรือ "ชำระครบถ้วน" แล้ว',
      // _2: 'ล็อกออเดอร์หลังจากพิมพ์ใบปะหน้าพัสดุแล้ว',
      _1:
        'ล็อกออเดอร์ทันทีที่สถานะออเดอร์ได้รับการ "ยืนยัน" จากทั้งตัวแทนและฉัน และ ตัวแทน "ยืนยันการชำระ" ด้วยยอดชำระที่เท่ากับหรือมากกว่ายอดออเดอร์',
      _2: 'ล็อกออเดอร์ทันทีที่ดาวน์โหลดใบปะหน้าพัสดุ',
      _3: 'ล็อกออเดอร์ทันทีที่สถานะออเดอร์ได้รับการ "ยืนยัน" จากทั้งตัวแทนและฉัน',
    },
    NoteAutoLockOrder:
      'หากออเดอร์ถูกล็อก' +
      '\n1) ตัวแทนของฉันจะไม่สามารถแก้ไขราคาและจำนวนสินค้า หรือยกเลิกออเดอร์ได้' +
      '\n2) ฉันสามารถแก้ไขออเดอร์ได้ทุกเมื่อ แม้ว่าออเดอร์จะถูกล็อกอยู่' +
      '\n3) ฉันสามารถล็อกและปลดล็อกออเดอร์ได้เองทุกเมื่อ',
    // NotesAutoLockOrder: {
    //   _0: 'ไม่มีการล็อกออเดอร์อัตโนมัติ',
    //   _1: 'ระบบจะทำการล็อกรายการและจำนวนสินค้าให้อัตโนมัติ เมื่อออเดอร์ขายของฉัน "ยืนยันเสร็จสิ้น" ทั้งสองฝ่าย และ ออเดอร์อยู่ในสถานะรอฉันยืนยันรับชำระ หรือ ชำระครบถ้วนแล้ว',
    //   _2: 'ระบบจะทำการล็อกรายการและจำนวนสินค้าให้อัตโนมัติ เมื่อพิมพ์ใบปะหน้าพัสดุของออเดอร์แล้ว',
    //   // _3: 'ตัวเลือกที่สาม',
    // },

    MenuShipBeforePaySwitch: 'อนุญาตให้ผู้ซื้อ เปิดการใช้งาน "จัดส่งก่อนชำระ" ได้โดยอัตโนมัติขณะสร้างออเดอร์',
    NoteTrueShipBeforePay:
      'ฉัน(ผู้ขาย) "อนุญาต" ให้ เปิดการใช้งาน "จัดส่งก่อนชำระ" และ "ยืนยันออเดอร์" โดยอัตโนมัติ (หากเข้าเงื่อนไขที่ระบุไว้ในการตั้งค่า) ในกรณีที่ผู้ซื้อสร้างออเดอร์มาให้และเปิดการใชังาน "จัดส่งก่อนชำระ"',
    NoteFalseShipBeforePay:
      'ฉัน(ผู้ขาย) "ไม่อนุญาต" ให้ เปิดการใช้งาน "จัดส่งก่อนชำระ" และ "ไม่ยืนยันออเดอร์" โดยอัตโนมัติ (ถึงแม้จะเข้าเงื่อนไขที่ระบุไว้ในการตั้งค่า) ในกรณีที่ผู้ซื้อสร้างออเดอร์มาให้และเปิดการใชังาน "จัดส่งก่อนชำระ" (แต่ผู้ซื้อสามารถแก้ไขออเดอร์และเปลี่ยนค่าการใช้งานได้หลังจากสร้างออเดอร์เสร็จ)',
    MenuShipBeforePaySwitchCod: 'อนุญาตให้ผู้ซื้อ เปิดการใช้งาน "เก็บเงินปลายทาง" ได้โดยอัตโนมัติขณะสร้างออเดอร์',
    NoteTrueShipBeforePayCod:
      'ฉัน(ผู้ขาย) "อนุญาต" ให้ เปิดการใช้งาน "เก็บเงินปลายทาง" และ "ยืนยันออเดอร์" โดยอัตโนมัติ (หากเข้าเงื่อนไขที่ระบุไว้ในการตั้งค่า) ในกรณีที่ผู้ซื้อสร้างออเดอร์มาให้และเปิดการใชังาน "เก็บเงินปลายทาง"',
    NoteFalseShipBeforePayCod:
      'ฉัน(ผู้ขาย) "ไม่อนุญาต" ให้ เปิดการใช้งาน "เก็บเงินปลายทาง" และ "ไม่ยืนยันออเดอร์" โดยอัตโนมัติ (ถึงแม้จะเข้าเงื่อนไขที่ระบุไว้ในการตั้งค่า) ในกรณีที่ผู้ซื้อสร้างออเดอร์มาให้และเปิดการใชังาน "เก็บเงินปลายทาง" (แต่ผู้ซื้อสามารถแก้ไขออเดอร์และเปลี่ยนค่าการใช้งานได้หลังจากสร้างออเดอร์เสร็จ)',

    MenuOrderNotePassthruAutoConfirmedSuborderShpLblNote:
      'อนุญาตให้ผู้ขายส่งของฉันเห็น "หมายเหตุ" ใบปะหน้าพัสดุได้โดยอัตโนมัติ หากตัวแทนของฉันระบุมา',
    NoteTrueOrderNotePassthruAutoConfirmedSuborderShpLblNote:
      'ในกรณีที่ตัวแทนของฉันระบุ "หมายเหตุ" สำหรับใบปะหน้าพัสดุ ขณะสร้างออเดอร์ที่มีสินค้าที่ฉันดึงมาจากผู้ขายส่ง ฉัน "อนุญาต" ให้ผู้ขายส่งเห็น "หมายเหตุ" นั้น ได้โดยทันทีโดยที่ฉันไม่ต้องมาเลือกให้เห็นที่ออเดอร์ซื้อก่อน',
    NoteFalseOrderNotePassthruAutoConfirmedSuborderShpLblNote:
      'ในกรณีที่ตัวแทนของฉันระบุ "หมายเหตุ" สำหรับใบปะหน้าพัสดุ ขณะสร้างออเดอร์ที่มีสินค้าที่ฉันดึงมาจากผู้ขายส่ง ฉัน "ไม่อนุญาต" ให้ผู้ขายส่งเห็น "หมายเหตุ" นั้น ได้โดยทันทีโดยที่ฉันไม่ต้องมาเลือกให้เห็นที่ออเดอร์ซื้อก่อน',

    MenuOrderNotePassthruAutoConfirmedSuborderShpLblImg:
      'อนุญาตให้ผู้ขายส่งของฉันเห็น "รูป" ใบปะหน้าพัสดุได้โดยอัตโนมัติ หากตัวแทนของฉันระบุมา',
    NoteTrueOrderNotePassthruAutoConfirmedSuborderShpLblImg:
      'ในกรณีที่ตัวแทนของฉันระบุ "รูป" สำหรับใบปะหน้าพัสดุ ขณะสร้างออเดอร์ที่มีสินค้าที่ฉันดึงมาจากผู้ขายส่ง ฉัน "อนุญาต" ให้ผู้ขายส่งเห็น "รูป" นั้น ได้โดยทันทีโดยที่ฉันไม่ต้องมาเลือกให้เห็นที่ออเดอร์ซื้อก่อน',
    NoteFalseOrderNotePassthruAutoConfirmedSuborderShpLblImg:
      'ในกรณีที่ตัวแทนของฉันระบุ "รูป" สำหรับใบปะหน้าพัสดุ ขณะสร้างออเดอร์ที่มีสินค้าที่ฉันดึงมาจากผู้ขายส่ง ฉัน "ไม่อนุญาต" ให้ผู้ขายส่งเห็น "รูป" นั้น ได้โดยทันทีโดยที่ฉันไม่ต้องมาเลือกให้เห็นที่ออเดอร์ซื้อก่อน',

    MenuAcceptShipBeforePayIfCOD: 'อนุญาตให้ออเดอร์ เปิดการใช้งาน "จัดส่งก่อนชำระ" ได้หาก "เก็บเงินปลายทาง" *เปิด*การใช้งานอยู่',
    NoteTrueAcceptShipBeforePayIfCOD:
      'ในกรณีที่ผู้ซื้อสร้างออเดอร์แบบ*เก็บ*เงินปลายทางมาให้และ*เปิด*การใช้งาน "จัดส่งก่อนชำระ" ระบบจะ “ยืนยันออเดอร์โดยอัตโนมัติ หากเข้าเงื่อนไขอื่นๆที่ระบุไว้ในการตั้งค่าของร้านฉัน',
    NoteFalseAcceptShipBeforePayIfCOD:
      'ในกรณีที่ผู้ซื้อสร้างออเดอร์แบบ*เก็บ*เงินปลายทางมาให้และ*เปิด*การใช้งาน "จัดส่งก่อนชำระ" ระบบจะ "ปิด"การใช้งาน "จัดส่งก่อนชำระ" และ "ไม่ยืนยันออเดอร์ให้โดยอัตโนมัติ" รวมถึงการป้องกันไม่ให้แก้ไขการใช้งาน "จัดส่งก่อนชำระ" ของออเดอร์ในภายหลังด้วย',
    MenuAcceptShipBeforePayIfNotCOD: 'อนุญาตให้ออเดอร์ เปิดการใช้งาน "จัดส่งก่อนชำระ" ได้แม้ "เก็บเงินปลายทาง" *ปิด*การใช้งานอยู่',
    NoteTrueAcceptShipBeforePayIfNotCOD:
      'ในกรณีที่ผู้ซื้อสร้างออเดอร์แบบ*ไม่เก็บ*เงินปลายทางมาให้และเปิดการใช้งาน "จัดส่งก่อนชำระ" ระบบจะ "ยืนยันออเดอร์โดยอัตโนมัติ" หากเข้าเงื่อนไขอื่นๆที่ระบุไว้ในการตั้งค่าของร้านฉัน',
    NoteFalseAcceptShipBeforePayIfNotCOD:
      'ในกรณีที่ผู้ซื้อสร้างออเดอร์แบบ*ไม่เก็บ*เงินปลายทางมาให้และเปิดการใช้งาน "จัดส่งก่อนชำระ" ระบบจะ *ปิด*การใช้งาน "จัดส่งก่อนชำระ" และ "ไม่ยืนยันออเดอร์ให้โดยอัตโนมัติ" รวมถึงการป้องกันไม่ให้แก้ไขการใช้งาน "จัดส่งก่อนชำระ" ของออเดอร์ในภายหลังด้วย',

    MenuAcceptUseVerifySlip: 'ใช้งาน Verify Slip',
    NoteTrueUseVerifySlip: 'Desc เปิดใช้งาน Verify Slip',
    NoteFalseUseVerifySlip: 'Desc ปิดใช้งาน Verify Slip',

    AdditionLabelAutoLockOrder: 'ทำการล็อกออเดอร์เมื่อ (เลือกได้มากกว่า 1 ข้อ)...',
    CheckboxAutoLockOrders: {
      _1: 'ฉัน และ ตัวแทน "ยืนยัน" ออเดอร์แล้วทั้งสองฝ่าย',
      // _1: 'ล็อกออเดอร์หลังจากยืนยันออเดอร์และบันทึกรับชำระเสร็จสิ้น',
      // _1: 'ล็อกออเดอร์หลังจากออเดอร์มีสถานะ "ยืนยันออเดอร์เสร็จสิ้น" หรือ "ชำระครบถ้วน" แล้ว',
      // _2: 'ล็อกออเดอร์หลังจากพิมพ์ใบปะหน้าพัสดุแล้ว',
      _2: 'ออเดอร์ได้รับการชำระครบถ้วน',
      _3: 'พิมพ์ใบปะหน้าพัสดุแล้ว',
      _4: 'ฉัน "ยืนยันรับชำระ" แล้วอย่างน้อย 1 ครั้งในออเดอร์',
    },
  },
  Subscription: {
    main: {
      free: 'Free',
      bronze: 'Bronze',
      silver: 'Silver',
      gold: 'Gold',
      platinum: 'Platinum',
      trial: 'ทดลองใช้',
    },
    warning: {
      dateRangePlatinumTypeTitle: 'สามารถดูรายงานย้อนหลังได้ตั้งแต่เปิดร้าน',
      numOfDaysLimitInfoMsg: 'โดยสามารถดาวน์โหลดรายงานได้สูงสุด %{numOfDays} วันต่อหนึ่งครั้ง',
      insufficientTypeTitle: 'แพ็กเกจคุณไม่สามารถใช้งานฟีเจอร์นี้ได้',
      insufficientTypeMsg: 'หากสนใจใช้งาน กรุณาติดต่อทีมงาน XSelly',
      tooManyDaysInPastTitle: 'แพ็กเกจของคุณดูย้อนหลังได้ %{numOfDays} วัน',
      tooManyDaysInPastMsg: 'หากสนใจเพิ่มช่วงวัน กรุณาติดต่อทีมงาน XSelly',
      // freePackageWarningReportOnlyThreeDay: 'คุณสามารถดาวน์โหลดรายงานได้ 3 วันเท่านั้น',
      packageFull: 'แพ็กเกจของคุณไม่สามารถเพิ่มได้',
      iosNoobCheckMsg: 'กรุณาติดต่อทีมงานเพื่อตั้งค่าใช้งานฟีเจอร์นี้',
    },
    packageReportSeller: {
      1: '8',
      2: '39',
      3: '69',
      4: '99',
      5: '99',
    },
  },
  e_title_generals: 'เกิดข้อผิดพลาด',
  e_title_critical: 'เกิดความผิดพลาด',
  e_title_customs: {
    _07_052: 'ไม่สามารถแก้ไขออเดอร์',
    _07_056: 'สร้างออเดอร์ไม่สำเร็จ',
    _07_057: 'แก้ไขออเดอร์ไม่สำเร็จ',
    _07_058: 'แก้ไขที่อยู่ไม่สำเร็จ',
    _20_059: 'เปลี่ยนรหัสเข้าร่วมกลุ่มไม่สำเร็จ',
    _20_060: 'คุณถูกบล็อคจากร้านขายส่ง',
    _20_061: 'รหัสไม่ถูกต้อง', // By pKeng on 18 May 2022, the code here doesn't work. Seems not in use here.
    _20_070: 'ฉันเป็นสมาชิกร้านนี้แล้ว', // By pKeng on 25 Apr 2022, the code here doesn't work. Seems not in use here.
    // _10_018: 'ทดสอบ Custom Title',
  },
  e_msg_general: 'กรุณาลองใหม่อีกครั้ง',
  e: {
    _03_007: 'ไม่พบอีเมล์ หรือ บัญชีผู้ใช้งานนี้ในระบบ กรุณาลองใหม่อีกครั้ง',
    _15_100: 'ไม่มีสิทธิ์ในการเข้าถึงหรือใช้งาน',
    // user_not_found: 'กรุณาล็อกอินใหม่อีกครั้ง',
  },
  e_specifics: {
    _001: 'เกิดข้อผิดพลาดในการลงชื่อเข้าใช้ กรุณาลองใหม่อีกครั้ง',
    _003: 'มีการลงทะเบียนไปแล้ว',
    _006: 'ไม่สามารถแก้ไข "ชื่อผู้ใช้" ได้',
    _008: 'คุณไม่สามารถลบ "บัญชีผู้ใช้" ของคุณได้', // จริงๆ คือ Profile
    _011: 'มีออเดอร์ที่ยังดำเนินการอยู่ คุณไม่สามารถลบร้านค้าได้หากออเดอร์ไม่เสร็จสิ้นทั้งหมด',
    _012: 'ยังไม่ได้กำหนดบทบาทให้สมาชิก', // add member with unspecified role
    _013: 'กรุณาป้อน "ชื่อตัวเลือก" ของสินค้า', // Edit product variant found empty name
    _014: 'สินค้าชิ้นนี้อยู่ในร้านฉันแล้ว',
    _015: 'สินค้าชิ้นนี้ถูกใช้เปิดออเดอร์ไปแล้ว', // ?????
    _016: '"ตัวดำเนินการ" การแก้ไขสินค้าไม่ถูกต้อง กรุณาแจ้งทีมพัฒนาโปรแกรม', // Front-end Critical
    _024: 'กรุณาระบุข้อมูลของตัวเลือกสินค้า', // Front-end Missing
    // _025: '',
    _026: 'ไม่สามารถดึง "สินค้าของฉัน" เข้าสู่ "ร้านฉัน" ได้',
    _027: 'จำนวนแจ้งจัดส่ง ไม่สามารถมากกว่า จำนวนที่ยังไม่จัดส่งได้', // Front-end Missing
    // _028: 'กรุณาเพิ่ม "จำนวนสินค้า" ในคลัง เนื่องจากจำนวนสินค้าในคลังไม่เพียงพอจัดส่ง ',
    _028: 'กรุณาเพิ่ม "จำนวนสินค้า" เนื่องจากจำนวนสินค้าคงเหลือไม่เพียงพอจัดส่ง ',
    _029: 'กรุณาระบุ "จำนวนสินค้า" ใหม่ เนื่องจากจำนวนสินค้าใหม่ ไม่สามารถมีค่าน้อยกว่า จำนวนสินค้าที่จัดส่งแล้ว',
    _030: 'มีการแก้ไขข้อมูลตัวเลือกสินค้าซ้ำซ้อนกัน กรุณาแจ้งทีมพัฒนาโปรแกรม', // Front-end Critical
    _031: 'ยอดชำระไม่ถูกต้อง', // Front-end Missing
    _032: 'กรุณาปรับ "ออเดอร์รับชำระ" ใหม่ให้มีประเภทเดียวเท่านั้น ไม่อนุญาตให้มีออเดอร์ซื้อปนอยู่กับออเดอร์ขายในการชำระเงินหนึ่งครั้ง',
    _034: 'ไม่สามารถชำระเงิน ในฐานะผู้ขาย ได้', // Front-end Missing
    _035: 'ไม่สามารถคืนเงิน ในฐานะ ผู้ซื้อได้', // Front-end Missing
    _036: 'ออเดอร์ไม่อยู่ในประเภทที่จะยืนยันได้',
    _037: 'ออเดอร์นี้ได้ถูกยกเลิกไปแล้ว',
    _038: 'ออเดอร์นี้ได้ถูกจัดส่งครบถ้วนแล้ว',
    // _041: 'ออเดอร์ถูกแก้ไขจากผู้อื่นในระหว่างที่คุณดูอยู่ ข้อมูลล่าสุดจะถูกนำมาแสดงหลังจากกดตกลง กรุณาทำรายการใหม่อีกครั้ง',
    _042: 'อีเมลนี้ได้รับการยืนยันแล้ว',
    _043: 'กรุณาตรวจสอบรหัสผ่านใหม่ แล้วลองใหม่อีกครั้ง',
    _044: 'มีตัวแทนคนนี้อยู่ในรายชื่อตัวแทนแล้ว',
    _045: 'ไม่สามารถแก้ไขราคาปลีกได้ เนื่องจากร้านไม่ได้ถูกตั้งค่าให้ใช้หลายราคา',
    _046: 'ไม่สามารถสร้างออเดอร์ได้ เพราะราคาปลีกของสินค้ายังไม่ได้ถูกระบุ',
    _050: 'ออเดอร์ถูกยกเลิกไปแล้ว', // order has been cancelled
    _051: 'สินค้าเกิดความซ้ำซ้อนกันเกิดขึ้น', // create order // Same Products Origin
    // _052: 'กรุณาติดต่อผู้ขายให้ปลดล็อกหากต้องการดำเนินการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกโดยผู้ขายแล้ว', // locked
    _052: 'ออเดอร์ถูกล็อก กรุณาตรวจสอบออเดอร์ซื้อและออเดอร์ขายว่าถูกล็อกหรือไม่ ถ้าไม่ถูกล็อกกรุณาติดต่อผู้ขายให้ปลดล็อกออเดอร์', // locked
    _053: 'ผู้ใช้ไม่ได้เป็นตัวแทนของร้าน', // kick reseller and leave store
    _054: 'กรุณากดออกจากหน้านี้ แล้วลองใหม่อีกครั้ง เนื่องจากหมดเวลาเชื่อมต่อ หรือข้อมูลได้ถูกอัพเดทใหม่แล้ว', // double request (timeout)
    _055: 'กรุณาเปลี่ยนรหัส SKU ใหม่ เนื่องจากรหัสนี้ถูกใช้งานไปแล้ว', // Duplicate SKU
    _056: 'กรุณาติดต่อทีมงาน XSelly เพื่อเลือกแพ็กเกจใช้งานที่เหมาะสมกับคุณ เนื่องจากคุณใช้ออเดอร์เกินโควตาแล้ว', // out of order quota
    _057: 'กรุณาติดต่อทีมงาน XSelly เพื่อเลือกแพ็กเกจใช้งานที่เหมาะสมกับคุณ เนื่องจากคุณใช้ออเดอร์เกินโควตาแล้ว', // out of order quota
    _058: 'กรุณาติดต่อตัวแทนเพื่อแก้ไขที่อยู่ของออเดอร์นี้ เนื่องจากคุณไม่มีสิทธิ์ในการแก้ไขที่อยู่', // แก้ไขที่อยู่ ผู้รับ / ผู้ส่ง ไม่ได้ เพราะไม่ใช่เจ้าของ
    _059: 'กรุณารอสักครู่แล้วดำเนินการอีกครั้ง เนื่องจากรหัสขอเข้าร่วมอันใหม่ได้ถูกเปลี่ยนเมื่อนาทีที่ผ่านมา', // code_created_at < 1 minute
    _060: 'กรุณาติดต่อผู้ขายส่งโดยตรง', // block user จาก store
    _061: 'กรุณาระบุรหัสเข้าร้านที่ถูกต้อง เพื่อสมัครเป็นสมาชิก', // Code Not Found 061
    _062: 'กรุณาระบุรหัสเข้าร้านอื่น เนื่องจากคุณอยู่ในร้านนี้อยู่แล้ว', // Reseller in Group 062
    _063: 'กรุณาย้ายสมาชิกออกจากกลุ่มให้หมดก่อนที่จะลบกลุ่มสมาชิกออก', // Cannot delete UG cuz have Resellers  063
    _064: 'กรุณาจัดการตอบรับคำขอเข้าร่วมกลุ่มให้หมดก่อนที่จะลบกลุ่มสมาชิกนี้ออก', // 064 User Group Failed on Delete / delete user_group : pending approval user count > 0 or ug_pg count > 0 /	มีคนขอเข้ากลุ่มร้านฉันมาแล้ว หรือ ug มีการ assign pg แล้ว
    _065: 'กรุณาระบุช่องทางติดต่ออย่างน้อย 1 ช่องทาง ได้แก่ Facebook หรือ LINE',
    _066:
      'ไม่สามารถแก้ไขออเดอร์ได้ เนื่องจากออเดอร์มีการใช้ส่วนลดของกลุ่มสมาชิก แล้วมีสินค้าที่เพิ่มเข้ามาใหม่ซึ่งไม่ได้มีส่วนลดที่ร่วมรายการอยู่ในกลุ่มสมาชิกนี้',
    _067: 'คุณไม่สามารถขอเข้ากลุ่มของตัวเองได้ แต่สามารถแชร์ให้สมาชิกใช้ขอเข้ากลุ่มของคุณได้', // กรณีร้านขายส่ง submit qr code ตัวเอง
    _068: 'กรุณาสร้างลิงก์ใหม่อีกครั้งภายหลัง', // Firebase Dynamic Link Failed on Create
    _069: 'กรุณาขอลิงก์ใหม่จากร้านขายส่ง เนื่องจากลิงก์นี้ไม่สามารถใช้งานได้', // Link Not Found (or inactive)
    _070: 'กรุณาใช้ลิงก์อื่นสมัครสมาชิก', // I'm already member.
    _100: 'ไม่สามารถดำเนินการได้ เนื่องจากขาดสิทธิ์ในการเข้าถึงหรือใช้งาน',
  },
  e_attach_specifics: {
    _056: 'กรุณาติดต่อผู้ขายส่ง "%{store_name}" เนื่องจากร้านปิดใช้งานชั่วคราว', // seller is out of order quota
    _057: 'กรุณาติดต่อผู้ขายส่ง "%{store_name}" เนื่องจากร้านปิดใช้งานชั่วคราว', // seller is out of order quota
  },
  e_generals: {
    _002: 'มีความผิดพลาดในดำเนินการเกี่ยวกับ %{module} กรุณาลองใหม่อีกครั้ง',
    _004: 'เซิร์ฟเวอร์ปฏิเสธการ%{module} กรุณาตรวจสอบข้อมูลแล้วลองใหม่อีกครั้ง',
    _005: 'กรุณาระบุข้อมูล%{module}ที่จำเป็นให้ครบถ้วน', // e.g. edit store with no input
    _007: 'ไม่พบข้อมูล %{module} กรุณาลองใหม่อีกครั้ง',
    _009: '%{module} มีรูปแบบไม่ถูกต้อง',
    _010: 'กรุณาแก้ไข %{module} เนื่องจาก มีชื่อซ้ำกัน',
    _017: 'การเพิ่มข้อมูล %{module} ไม่ถูกต้อง',
    _018: 'การแก้ไขข้อมูล %{module} ไม่ถูกต้อง',
    _019: 'การลบข้อมูล %{module} ไม่ถูกต้อง',
    _020: 'คุณไม่มีสิทธิ์ในการเพิ่มข้อมูล %{module}',
    _021: 'คุณไม่มีสิทธิ์ในการแก้ไขข้อมูล %{module}',
    _022: 'คุณไม่มีสิทธิ์ในการลบข้อมูล %{module}',
    _033: '%{module} ไม่ถูกต้อง', // Unknown Invalid
    _039: '%{module} มี จำนวน ที่ไม่ถูกต้อง', // qty Invalid
    _040: '%{module} มี การเข้าถึง ที่ไม่ถูกต้อง', // token Invalid
    _041: '%{module} ถูกแก้ไขจากผู้อื่นในระหว่างที่คุณดูอยู่ ข้อมูลล่าสุดจะถูกนำมาแสดงหลังจากกดตกลง กรุณาทำรายการใหม่อีกครั้ง',
    _047: '%{module} ไม่ถูกต้อง', // input Invalid
    _048: '%{module} สถานะไม่ถูกต้อง', // state Invalid
    _049: '%{module} ถูกใช้แล้ว', // already use
  },
  e_modules: {
    _00: 'อื่นๆ',
    _01: 'สมัครสมาชิก',
    _02: 'ลงชื่อเข้าใช้',
    _03: 'ผู้ใช้',
    _04: 'ข้อมูลส่วนตัว',
    _05: 'ที่อยู่',
    _06: 'ร้านค้า',
    _07: 'ออเดอร์',
    _08: 'บัญชีรับชำระ',
    _09: 'สินค้า',
    _10: 'สินค้า',
    _11: 'การชำระ',
    _12: 'การจัดส่ง',
    _13: 'การแจ้งเตือน',
    _14: 'การตั้งรหัสผ่านใหม่',
    _15: 'สิทธิ์การใช้งาน',
    _16: 'รายงาน', // report
    _17: 'ค่าจัดส่ง', // sipping_cost
    _18: 'ฐานข้อมูล', // redis
    _19: 'ข้อมูลสมาชิก', // subscription
    _20: 'กลุ่มตัวแทน', // UG
    _21: 'รายการราคา', // PG
  },
  e_perm: {
    DENIED_BLUETOOTH_TITLE: 'ไม่สามารถใช้ Bluetooth ได้',
    DENIED_BLUETOOTH_MSG:
      'กรุณาอนุญาตสิทธิ์ในการใช้งาน Bluetooth เพื่อใช้งานเกี่ยวกับเชื่อมต่อกับ Paperang และการสั่งพิมพ์เอกสารผ่าน Paperang',
    DENIED_CAMERA_TITLE: 'ไม่สามารถใช้งานกล้องได้',
    DENIED_CAMERA_MSG: 'กรุณาอนุญาตสิทธิ์ในการใช้งานกล้อง เพื่อใช้งานเกี่ยวกับการถ่ายรูปสินค้า และสแกนบาร์โค้ด',
    DENIED_PHOTO_TITLE: 'ไม่สามารถใช้งานรูปภาพได้',
    DENIED_PHOTO_MSG: 'กรุณาอนุญาตสิทธิ์ในการใช้งานรูปภาพ เพื่อใช้งานเกี่ยวกับเลือกรูปสินค้า และการแชร์รูปสินค้า',
    DENIED_OLD_DEVICE:
      'มีบางแอปพลิเคชั่นขัดขวางการใช้งานกล้องของ XSelly กรุณาติดตั้งแอปใหม่โดยเช็คการอนุญาตสิทธิ์' +
      'ในการใช้งานกล้อง เพื่อใช้งานเกี่ยวกับการถ่ายรูปสินค้า และสแกนบาร์โค้ด หรือคุณสามารถใช้งานแอปได้โดย' +
      'ไม่สามารถใช้ฟังก์ชันเกี่ยวกับกล้องได้',

    DENIED_STORAGE_TITLE: 'ไม่สามารถใช้งานที่เก็บข้อมูลได้',
    DENIED_STORAGE_MSG: 'กรุณาอนุญาตสิทธิ์ในการใช้งานที่เก็บข้อมูล เพื่อใช้งานเกี่ยวกับการถ่ายแชร์รูปสินค้า และอื่นๆ',
    DENIED_STORAGE_OLD_DEVICE:
      'มีบางแอปพลิเคชั่นขัดขวางการใช้งานที่เก็บข้อมูลของ XSelly กรุณาติดตั้งแอปใหม่โดยเช็คการอนุญาตสิทธิ์' +
      'ในการใช้งานที่เก็บข้อมูล เพื่อใช้งานเกี่ยวกับการแชร์รูปสินค้า และอื่นๆ หรือคุณสามารถใช้งานแอปได้โดย' +
      'ไม่สามารถใช้ฟังก์ชันเกี่ยวกับแชร์ได้',

    GRANTED_UNKNOWN_DEVICE_TITLE: 'ไม่สามารถตรวจสอบอุปกรณ์',
    GRANTED_UNKNOWN_DEVICE_MSG:
      'อุปกรณ์ของคุณเป็นรุ่นที่ XSelly ไม่รู้จัก หากเกิดปัญหาในการใช้งานแอปพลิเคชั่น ' +
      'กรุณานำปัญหามาติดต่อผู้พัฒนา ขออภัยในความไม่สะดวก',

    SETTING_GUIDE_CAMERA_TITLE: 'การอนุญาตการใช้งานกล้อง',
    SETTING_GUIDE_CAMERA_ANDROID:
      'หากต้องการใช้งานเกี่ยวกับการถ่ายรูปสินค้า และสแกนบาร์โค้ด คุณสามารถให้สิทธิการใช้กล้องให้ XSelly โดยเข้าไปตั้งค่าโทรศัพท์มือถือของคุณได้ที่\nการตั้งค่า > แอปพลิเคชัน > XSelly > การอนุญาต',
    SETTING_GUIDE_CAMERA_IOS:
      'หากต้องการใช้งานเกี่ยวกับการถ่ายรูปสินค้า และสแกนบาร์โค้ด คุณสามารถให้สิทธิการใช้กล้องให้ XSelly โดยเข้าไปตั้งค่าโทรศัพท์มือถือของคุณได้ที่\nการตั้งค่า > แอปพลิเคชัน > XSelly > การอนุญาต',

    SETTING_GUIDE_PHOTO_TITLE: 'การอนุญาตการใช้รูปภาพ',
    SETTING_GUIDE_PHOTO_ANDROID:
      'หากต้องการใช้งานเกี่ยวกับเลือกรูปสินค้า และการแชร์รูปสินค้า คุณสามารถให้สิทธิการเข้าถึงรูปภาพแก่ XSelly โดยเข้าไปตั้งค่าโทรศัพท์มือถือของคุณได้ที่\nการตั้งค่า > แอปพลิเคชัน > XSelly > การอนุญาต',
    SETTING_GUIDE_PHOTO_IOS:
      'หากต้องการใช้งานเกี่ยวกับเลือกรูปสินค้า และการแชร์รูปสินค้า คุณสามารถให้สิทธิการเข้าถึงรูปภาพแก่ XSelly โดยเข้าไปตั้งค่าโทรศัพท์มือถือของคุณได้ที่\nการตั้งค่า > แอปพลิเคชัน > XSelly > การอนุญาต',

    SETTING_GUIDE_STORAGE_TITLE: 'การอนุญาตการใช้งานที่เก็บข้อมูล',
    SETTING_GUIDE_STORAGE_ANDROID:
      'หากต้องการบันทึกรูปสินค้า ใบปะหน้าพัสดุ และอื่นๆ คุณสามารถให้สิทธิการใช้ที่เก็บข้อมูลให้ XSelly โดยเข้าไปตั้งค่าโทรศัพท์มือถือของคุณได้ที่\nการตั้งค่า > แอปพลิเคชัน > XSelly > การอนุญาต',
    SETTING_GUIDE_STORAGE_IOS:
      'หากต้องการบันทึกรูปสินค้า ใบปะหน้าพัสดุ และอื่นๆ คุณสามารถให้สิทธิการใช้ที่เก็บข้อมูลให้ XSelly โดยเข้าไปตั้งค่าโทรศัพท์มือถือของคุณได้ที่\nการตั้งค่า > แอปพลิเคชัน > XSelly > การอนุญาต',
  },
  perm_store_helper: {
    order_list: 'ดู',
    order_add: 'สร้าง',
    order_edit: 'แก้ไข',
    custom_order_view: 'กำหนดหน้าออเดอร์เอง',
    order_cancel: 'ยกเลิก',
    order_print_shipping: 'พิมพ์ใบปะหน้าพัสดุและใบเสร็จ',
    order_visible_only_profile_id: '**จำกัดให้เห็นออเดอร์ได้เฉพาะที่ผู้ช่วยสร้างเองเท่านั้น',
    product_list: 'ดูรายการสินค้า',
    product_add: 'สร้าง',
    product_pull: 'ดึงสินค้าจากร้านผู้ขายส่ง',
    product_edit: 'แก้ไข',
    product_delete: 'ลบ',
    product_cost: 'เห็นและแก้ต้นทุน',
    product_price: 'เห็นราคาขาย',
    // product_stock_view: 'ดูจำนวนคลัง',
    // product_stock_edit: 'แก้จำนวนคลัง',
    product_stock_view: 'ดูจำนวนคงเหลือ',
    product_stock_edit: 'แก้จำนวนคงเหลือ',
    product_shipping_qty_rate: 'แก้ค่าส่งตามชิ้น (ในกรณีที่ตั้งค่าจัดส่งให้คำนวณตามชิ้น)',
    product_weight: 'แก้น้ำหนัก',
    report_view: 'ดู',
    member_list: 'ดู',
    member_add: 'เพิ่ม',
    member_edit: 'แก้ไข',
    member_delete: 'ลบ',
    ug_list: 'ดู',
    ug_add: 'เพิ่ม',
    ug_edit: 'แก้ไข',
    ug_delete: 'ลบ',
    pg_list: 'ดู',
    pg_add: 'เพิ่ม',
    pg_edit: 'แก้ไข',
    pg_delete: 'ลบ',
    vd_list: 'ดู',
    payment_list: 'ดูรายการชำระ',
    payment_add: 'บันทึกรับชำระ',
    payment_edit: 'ยืนยัน/ปฎิเสธ',
    payment_delete: 'ยกเลิก',
    shipping_list: 'ดู',
    shipping_add: 'แจ้งจัดส่ง',
    shipping_edit: 'แก้ไขการจัดส่ง',
    shipping_delete: 'ยกเลิก',
    // TODO: Binary code [2 = sku / 1 = upc] get from 'product_code_edit'
    product_code_edit: 'sku or upc',
    product_code_edit_sku: 'แก้รหัส SKU',
    product_code_edit_upc: 'แก้รหัส UPC',
    // report
    report_daily_sales: 'ยอดขายประจำวัน',
    report_complete_sales: 'ยอดขายอย่างละเอียด',
    report_sales_by_channels: 'ยอดขายจำแนกตามช่องทาง',
    report_stock: 'สินค้าคงคลัง',
    report_stock_history: 'ปรับคลังสินค้า',
    report_product_daily_sales: 'การขายสินค้ารายวัน',
    report_stock_card: 'ประวัติการเคลื่อนไหวสินค้า',
    report_shipped: 'ออเดอร์ที่จัดส่งแล้ว',
    report_requisition: 'ออเดอร์ที่ยังไม่ได้จัดส่ง',
    report_reseller: 'ยอดขายตัวแทน',
    report_helper: 'ยอดขายผู้ใช้งานและผู้ช่วย',
    report_payment: 'การรับชำระตามบัญชี',
    report_peak_account: 'PEAK Account',

    wh_list: 'ดู',
    wh_add: 'เพิ่ม',
    wh_edit: 'แก้ไข',
    wh_delete: 'ลบ',
    mkp_list: 'ดู',
    mkp_add: 'เพิ่ม',
    mkp_edit: 'แก้ไข',
    mkp_delete: 'ลบ',

    bin_dashboard: 'ยอดขายรายวัน',

    erp_channel_add: 'เพิ่มการเชื่อมต่อระบบบัญชี',
    erp_channel_delete: 'ลบการเชื่อมต่อระบบบัญชี (ต้องเปิดสิทธิ แก้ไขการตั้งค่า คู่กันด้วย',
    erp_channel_edit: 'แก้ไขการตั้งค่าบัญชี',
    erp_channel_list: 'ดูรายการเชื่อมต่อของระบบบัญชี',
    erp_channel_set_active: 'เปิด/ปิดการใช้งาน (ต้องเปิดสิทธิ แก้ไขการตั้งค่า คู่กันด้วย)',
    erp_doc_cancel: 'ยกเลิกเอกสาร',
    erp_doc_create: 'สร้างเอกสาร',

    contact_list: 'ดู',
    contact_add: 'เพิ่ม',
    contact_edit: 'แก้ไข',
    contact_delete: 'ลบ',
  },
  // e_title_translated: {
  //   InvalidSegment
  // }

  marketplace_channels: {
    warning: {
      auth_running_out_of_day: 'อีก %{day} วัน การเชื่อมต่อจะหมดอายุการใช้งาน',
      auth_running_out_of_hr: 'อีก %{hr} ชั่วโมง การเชื่อมต่อจะหมดอายุการใช้งาน',
      connection_expired_press_button: 'การเชื่อมต่อหมดอายุ กรุณากดปุ่มด้านล่างเพื่อเชื่อมต่อ',
      auth_running_out_of_time: 'อีก %{day} วัน การเชื่อมต่อจะหมดอายุการใช้งาน',
      mkp_not_is_active: 'ปิดการใช้งานชั่วคราว กรุณาติดต่อทีมงาน',
      mkp_not_is_active_is_not_auth:
        'หากต้องการเชื่อมต่อช่องทางขายเดิมอีกครั้งกรุณากดปุ่ม "เชื่อมต่ออีกครั้ง"หรือหากต้องการลบข้อมูลการเชื่อมต่อออเดอร์และสินค้าระหว่าง ช่องทางขาย และ XSelly กรุณากดปุ่ม "ลบช่องทางขาย"',
    },
  },

  hleper: {
    you_are_not_authorized_to_use_this_feature: 'คุณไม่มีสิทธิ์ใช้งานฟีเจอร์นี้',
  },

  VerifySlip: {
    UsageActionId: {
      _201: 'ใช้ 1 เครดิตตรวจสลิป',
      _202: 'ใช้ 1 เครดิตตรวจสลิปจากลิงก์ออเดอร์',
      _203: 'ใช้ 1 เครดิตตรวจสลิปจาก Sale Page',
      _204: 'ใช้ 1 เครดิตตรวจสลิปจากเว็บหน้าร้าน',
      _205: 'ใช้ 1 เครดิตตรวจสลิปจากแชท LINE ',
      _206: 'ใช้ 1 เครดิตตรวจสลิปจากแชท Facebook',
    },
  },

  xshipping: {
    faq: {
      // xshipping
      what_is_xshipping: 'XShipping คืออะไร',
      what_is_xshipping_answer:
        'XShipping คือฟีเจอร์ที่อำนวยความสะดวกในการขอเลขพัสดุจากขนส่งชั้นนำต่างๆ เช่น EMS Flash J&T Kerry และ อื่นๆ เพื่อให้คุณสามารถพิมพ์เลขติดตามพัสดุจากขนส่งออกมาบนใบปะหน้าจาก XSelly ได้เลย',
      // XSHIPPING_AGREEMENT_SHIPJUNG_COMMON
      shipping_rate: 'เรทค่าส่งเท่าไหร่',
      shipping_rate_answer: 'ค่าส่งและโปรโมชั่นตามที่ Shipjung กำหนด',

      how_to_deliver: 'นำส่งพัสดุอย่างไร',
      how_to_deliver_answer: 'พนักงานจะเข้ารับให้ตามที่อยู่ที่คุณระบุไว้ในเว็บชิปจัง (Pickup address)',

      how_do_enable: 'เปิดใช้งานต้องทำอย่างไร',
      how_do_enable_answer:
        'สามารถเปิดใช้งานได้เลยผ่านเว็บ สะดวก ไม่ต้องเดินทาง พร้อมใช้งานเลย ดังนี้' +
        '\n1. ไปที่ https://oms.boxme.asia (เลือก Thailand)' +
        '\n2. กดปุ่ม "ลงทะเบียนตอนนี้" (ใต้ปุ่ม เข้าสู่ระบบ)' +
        '\n3. กรอกข้อมูลตามหน้าไปเรื่อยๆ (*สำคัญมาก* การระบุที่อยู่เข้ารับ ต้องไม่ติ๊กเลือก “กำหนดบริการ Fulfillment ใบสั่งซื้อ” โดยให้ปล่อยช่องว่างไว้)' +
        '\n4. ส่ง email และ password ที่ใช้ใน Shipjung มาให้ที่ LINE: @XSelly เพื่อการตั้งค่าเชื่อมต่อ (password ที่ส่งมาให้ไม่ใช่ที่ใช้เช็กอีเมล โดยต้องเป็น password ที่กรอกใหม่ใน Shipjung)',

      enabled_how_to_use: 'ออกเลขพัสดุอย่างไรเมื่อเปิดใช้งานแล้ว',
      enabled_how_to_use_answer:
        'ในออเดอร์ เลือกรูปแบบขนส่งให้ตรงตามที่แจ้งไว้ด้านบน' +
        '\nจากนั้น ที่แต่ละออเดอร์ คุณสามารถขอเลขพัสดุได้ด้วยขั้นตอนดังนี้' +
        '\n1) ในหน้ารายการ หรือ รายละเอียดออเดอร์ กดปุ่ม “ขอเลขพัสดุทันที”' +
        '\nหรือ' +
        '\n2) เมื่อออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ระบบจะขอเลขพัสดุให้อัตโนมัติ หากคุณเปิดการใช้งาน “ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” ไว้' +
        '\nสำหรับออเดอร์ที่สร้างไว้ก่อนเปิดการใช้งานฟีเจอร์นี้ คุณสามารถทำตามข้อ 1) เพื่อขอเลขพัสดุแต่ละออเดอร์ได้',

      how_to_pay_for_shipping: 'ชำระค่าส่งอย่างไร',
      how_to_pay_for_shipping_answer:
        'ค่าส่งจะถูกหักออกจากเครดิตค่าส่งเมื่อออกเลขพัสดุ โดยเติมเครดิตค่าส่งได้ที่เว็บชิปจัง\n(https://oms.boxme.asia เลือก ประเทศไทย) จากนั้น' +
        '\n1) กดเมนู เติมเงิน' +
        '\n2) เลือกยอดเงินที่ต้องการเติม' +
        '\n3) เลือก “ชำระเงินออนไลน์ด้วย QR Code” > กดรับ QR Code ' +
        '\n(แนะนำให้เติมด้วย QR Code เนื่องจากเงินเข้าทันทีไม่ต้องรอตรวจสอบ)',

      when_is_shipping_credit_cut_off: 'เครดิตค่าส่งตัดตอนไหน',
      when_is_shipping_credit_cut_off_answer: 'ทันทีที่ได้รับเลขพัสดุ (ซึ่งก็คือเมื่อเห็นเลขพัสดุในแอป XSelly)',

      cancel_shipping_how_to_refund: 'ยกเลิกเลขพัสดุแล้วได้เงินคืนไหม',
      cancel_shipping_how_to_refund_answer: 'ได้ครบ! ชิปจังจะคืนเครดิตค่าส่งให้อัตโนมัติภายใน 3 วันทำการ',

      how_receive_payment_for_cash_on_delivery_cod: 'รับชำระยอดเก็บเงินปลายทางอย่างไร',
      how_receive_payment_for_cash_on_delivery_cod_answer:
        'ทุกวัน อังคาร และ ศุกร์ Shipjung จะโอนเงินที่เก็บ COD มาได้เข้ามาให้ที่บัญชีธนาคารที่คุณผูกไว้' +
        '\n• พัสดุที่จัดส่งสำเร็จ ในวันจันทร์ - พุธ  เงินเข้าวันศุกร์ ' +
        '\n• พัสดุที่จัดส่งสำเร็จ ในวันพฤหัสบดี - อาทิตย์  เงินเข้าวันอังคาร',

      how_to_link_bank_account_for_cod: 'ผูกบัญชีธนาคารรับชำระยอดเก็บเงินปลายทางอย่างไร',
      how_to_link_bank_account_for_cod_answer:
        '1) ไปที่ https://oms.boxme.asia เลือก ประเทศไทย' +
        '\n2) กดเมนู “ตั้งค่าผู้ใช้งาน”' +
        '\n3) กดเมนู “ชำระเงิน”' +
        '\n4) กดปุ่ม “เพิ่มเลขที่บัญชี” และกรอกข้อมูลบัญชีธนาคาร' +
        '\n5) *สำคัญมาก* เปิดอีเมล์เพื่อกดปุ่มยืนยันความถูกต้องของบัญชี',

      how_good_is_to_use_shipjung_cod_via_xshipping: 'ใช้ Shipjung COD ผ่าน XShipping ดียังไง',
      how_good_is_to_use_shipjung_cod_via_xshipping_answer:
        '✅ หมดปัญหา พนักงานขนส่งคีย์ข้อมูลพลาดหรือไม่ได้ระบุให้เก็บเงินปลายทาง ที่ทำให้คุณไม่ได้รับชำระจากลูกค้า' +
        '\n✅ หมดปัญหา รับยอดเก็บเงินปลายทางไม่ครบ เพราะเมื่อยอดเก็บเงินปลายทาง โอนเข้าบัญชีคุณแล้ว XSelly จะกระทบยอดให้อัตโนมัติออเดอร์ต่อออเดอร์เลย ทำให้คุณไม่ต้องมาคอยเช็กทีละออเดอร์เอง สามารถดูใน XSelly ได้เลยว่าออเดอร์ไหนยังไม่ได้รับชำระบ้าง',

      how_are_cod_fees_calculated: 'ค่าธรรมเนียม COD คำนวณยังไง',
      how_are_cod_fees_calculated_answer:
        'ค่าธรรมเนียม COD คือ 2.5% (+ VAT 7%) โดยมีขั้นต่ำที่ 25 บาท ตัวอย่างเช่น' +
        '\n====ตัวอย่าง 1====' +
        '\nยอดเก็บเงินปลายทาง ฿500 ' +
        '\nจะมีค่าธรรมเนียม COD ฿25 + ฿1.25 (VAT) = ฿26.25' +
        '\nดังนั้นถ้าค่าส่งตามจริง ฿25 พัสดุนี้จะมียอดเรียกเก็บ ฿26.25 + ฿25 = ฿51.25' +
        '\n' +
        '\n====ตัวอย่าง 2====' +
        '\nยอดเก็บเงินปลายทาง ฿1,200 ' +
        '\nจะมีค่าธรรมเนียม COD +฿30 (2.5%) + ฿2.1 (VAT) = ฿32.10' +
        '\nดังนั้นถ้าค่าส่งตามจริง ฿25 พัสดุนี้จะมียอดเรียกเก็บ ฿32.10 + ฿25 = ฿57.10',

      how_to_change_addredd_or_phone: 'เปลี่ยนที่อยู่เข้ารับพัสดุ(หรือเบอร์โทร)ทำอย่างไร',
      how_to_change_addredd_or_phone_answer:
        'คุณสามารถเพิ่มที่อยู่ในการเข้ารับใหม่ได้ที่เว็บชิปจัง จากนั้น' +
        '\n1. กดเมนู “ตั้งค่าผู้ใช้งาน”' +
        '\n2. กดเมนู “รับสินค้าที่”' +
        '\n3. กดปุ่ม “เพิ่มที่อยู่มารับสินค้า” และกรอกข้อมูล (*สำคัญมาก* ต้องไม่ติ๊กเลือก “กำหนดบริการ Fulfillment ใบสั่งซื้อ” โดยให้ปล่อยช่องว่างไว้)' +
        '\n4. เมื่อเพิ่มที่อยู่เสร็จ ให้นำ Pickup ID ของที่อยู่ใหม่ (เป็นตัวเลข 5-6 หลัก) มาแจ้งที่ LINE: @XSelly เพื่อดำเนินการเปลี่ยนที่อยู่ให้สมบูรณ์' +
        '\nเลขพัสดุที่ออกใหม่หลังจากทางทีมงาน XSelly อัพเดทให้แล้วจะใช้ที่อยู่เข้ารับที่แก้ไขใหม่ โดยเลขพัสดุที่ออกก่อนหน้านี้จะยังคงเข้ารับที่ที่อยู่เดิม',
      how_to_check_actual_shipping_cost_or_refund_amount: 'เช็กค่าส่งตามจริงหรือยอดเงินคืนอย่างไร',
      how_to_check_actual_shipping_cost_or_refund_amount_answer:
        'คุณสามารถตรวจสอบได้ที่เว็บชิปจัง (https://oms.boxme.asia) จากนั้น' +
        '\n1. กดเมนู “ใบสั่งซื้อ”' +
        '\n2. กดที่ใบสั่งซื้อที่ต้องการตรวจสอบ' +
        '\n3. ตรวจสอบรายละเอียดค่าใช้จ่ายและยอดต่างๆได้ในส่วนล่างของหน้า',
    },
    // เลือก shipping
    shipping: {
      can_use_xshipping: 'ยังไม่ได้ ตั้งค่า XShipping เพื่อออกเลขพัสดุ',
      use_xshipping_j_and_t_from_shipjung: 'ออกเลขพัสดุ J&T Express ผ่าน Shipjung ได้',
      use_xshipping_j_and_t_direct: 'ออกเลขพัสดุผ่าน J&T Express ได้',
      use_xshipping_kerry_from_shipjung: 'ออกเลขพัสดุ Kerry Express ผ่าน Shipjung ได้',
      use_xshipping_kerry_direct: 'ออกเลขพัสดุผ่าน Kerry Express ได้',
      use_xshipping_dhl_from_shipjung: 'ออกเลขพัสดุ DHL Express ผ่าน Shipjung ได้',
      use_xshipping_dhl_direct: 'ออกเลขพัสดุผ่าน DHL Express ได้',
      use_xshipping_ems_from_shipjung: 'ออกเลขพัสดุ EMS ผ่าน Shipjung ได้',
      use_xshipping_ems_direct: 'ออกเลขพัสดุผ่าน EMS ได้',
      use_xshipping_flash_from_shipjung: 'ออกเลขพัสดุ Flash Express ผ่าน Shipjung ได้',
      use_xshipping_flash_direct: 'ออกเลขพัสดุผ่าน Flash Express ได้',
      use_xshipping_ninja_from_shipjung: 'ออกเลขพัสดุ Ninja Van ผ่าน Shipjung ได้',
      use_xshipping_ninja_direct: 'ออกเลขพัสดุผ่าน Ninja Van ได้',
      use_xshipping_scg_from_shipjung: 'ออกเลขพัสดุ SCG Express ผ่าน Shipjung ได้',
      use_xshipping_scg_direct: 'ออกเลขพัสดุผ่าน SCG Express ได้',
      use_xshipping_best_direct: 'ออกเลขพัสดุผ่าน Best Express ได้',
      use_xshipping_best_from_shipjung: 'ออกเลขพัสดุ Best Express ผ่าน Shipjung ได้',
      use_xshipping_eco_post: 'ออกเลขพัสดุผ่าน eCo-Post ได้',
    },
    warning: {
      how_to_use_xshipping: 'หากต้องการใช้งาน กรุณากด "ดูข้อตกลงและวิธีการใช้งาน" เลือกหัวข้อ "เปิดใช้งานต้องทำอย่างไร"',
      how_to_un_use_xshipping: 'หากต้องการปิดใช้งาน กรุณาติดต่อทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650',
    },
  },

  e_msg_by_error_code: {
    // --------------------------------------------
    // Errors V1 Specific case

    // UPC_CLOSE_FLAG
    '03_002': {
      t: 'ไม่สามารถดำเนินการได้',
      m: 'กรุณาตรวจสอบบัญชีผู้ใช้หรืออีเมล ว่าไม่ซ้ำและถูกต้อง',
    },
    '03_007': {
      t: 'อีเมล์ไม่ถูกต้อง',
      m: 'ไม่พบอีเมล์นี้ในระบบ กรุณาแก้ไขและลองใหม่อีกครั้ง',
    },
    '03_044': {
      t: 'มีรายชื่อผู้ใช้นี้อยู่แล้ว',
      m: 'กรุณารีโหลดหน้าหรือปิดแอปแล้วเปิดใหม่ แล้วตรวจสอบอีกครั้ง',
    },
    '03_143': {
      t: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับ 4 รหัสล่าสุด',
      m: 'กรุณาระบุรหัสผ่านใหม่ที่ไม่ซ้ำกับ 4 รหัสผ่านล่าสุดที่เคยใช้มา',
    },
    '06_071': {
      t: 'ไม่สามารถดำเนินการต่อได้',
      m: 'กรุณาเปิดการใช้งาน UPC บาร์โคด ที่ตั้งค่าร้านก่อน แล้วลองอีกครั้ง',
    },
    // SKU_CLOSE_FLAG
    '06_072': {
      t: 'ไม่สามารถดำเนินการต่อได้',
      m: ' กรุณาเปิดการใช้งาน SKU บาร์โคด ที่ตั้งค่าร้านก่อน แล้วลองอีกครั้ง',
    },
    '06_554': {
      t: 'ไม่สามารถดำเนินการต่อได้',
      m: 'ฟีเจอร์ SKU จำเป็นต้องเปิดไว้ในขณะที่มีการเชื่อมช่องทางขายอยู่เช่น Shopee Lazada TikTok Shop หรือ LINE SHOPPING',
    },
    '06_085': {
      t: 'ไม่สามารถดำเนินการต่อได้',
      m: 'กรุณาปิดแอปแล้วเปิดใหม่ (หรือรีโหลดหน้าสำหรับเว็บ) แล้วลองอีกครั้ง เนื่องจากมีผู้ใช้งานอื่นแก้ไขข้อมูลไปก่อนหน้านี้',
    },
    // DUPLICATED_EMAIL
    '01_075': {
      t: null,
      m: 'อีเมลนี้ถูกใช้งานไปแล้ว',
    },
    // DUPLICATED_USERNAME
    '01_076': {
      t: null,
      m: 'ชื่อผู้ใช้นี้ถูกใช้งานไปแล้ว',
    },
    // NOTIFICATION Failure
    '13_002': {
      m: 'หากคุณไม่ได้รับแจ้งเตือนจากระบบเช่นเมื่อลูกค้าแจ้งชำระมา',
    },
    // EDIT_NOTES_DOUBLY
    '23_041': {
      t: 'เกิดข้อผิดพลาด',
      m: 'มีผู้อื่นแก้ไขข้อมูล ในระหว่างที่คุณกำลังแก้ไขอยู่ กรุณากดตกลง เพื่อตรวจสอบและทำการายใหม่อีกครั้ง',
    },
    '07_041': {
      t: 'ไม่สามารถดำเนินการได้',
      m: 'กรุณารีโหลดหน้านี้และดำเนินการใหม่อีกครั้ง เนื่องจากมีการแก้ไขออเดอร์โดยผู้อื่นระหว่างที่คุณดูข้อมูลอยู่',
    },
    // SHIP_BEFORE_PAY_IS_NOT_ALLOW_MY_STORE
    '07_083': {
      t: 'ไม่สามารถแก้ไขออเดอร์',
      m:
        'ฉันตั้งค่าไม่อนุญาตการใช้งาน "จัดส่งก่อนชำระ" ไว้อยู่\n\nหากต้องการแก้ไขออเดอร์นี้ในส่วนอื่นๆ กรุณาปิดการใช้งาน "จัดส่งก่อนชำระ" ก่อน\n\nหรือหากต้องการใช้งาน สามารถเปิดการอนุญาตใช้งานสำหรับทุกออเดอร์ได้ที่ หน้าการขาย > ตั้งค่า > การชำระ',
    },
    // SHIP_BEFORE_PAY_IS_NOT_ALLOW_SELLER_STORE
    '07_007': {
      t: 'ไม่พบข้อมูลออเดอร์',
      m: 'กรุณาตรวจสอบเลขออเดอร์ แล้วลองใหม่อีกครั้ง',
    },
    '07_009': {
      t: 'ประเภทออเดอร์ไม่ถูกต้อง',
      m: 'หากคุณต้องการแก้ไขข้อมูลออเดอร์ เช่น ที่อยู่ผู้รับ กรุณาแจ้งให้ตัวแทนผู้เปิดออเดอร์เป็นผู้แก้ไขให้',
    },
    // SHIP_BEFORE_PAY_IS_NOT_ALLOW_SELLER_STORE
    '07_084': {
      t: 'ไม่สามารถแก้ไขออเดอร์',
      m:
        'ผู้ขายไม่อนุญาตให้เปิดการใช้งาน\n"จัดส่งก่อนชำระ"\n\nหากต้องการแก้ไขออเดอร์นี้ในส่วนอื่นๆ กรุณาปิดการใช้งาน "จัดส่งก่อนชำระ" ก่อน\n\nหรือติดต่อผู้ขาย',
    },
    // PAGEPICKER_TIME_OUT_OR_ORDER_FULL
    '06_090': {
      t: 'ไม่สามารถเปิดออเดอร์ได้',
      m: 'แพ็กเกจของคุณหมดอายุหรือใช้ออเดอร์เกินกำหนด กรุณาติดต่อทีมงาน',
    },
    // ORDER INVALID
    '07_033': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ข้อมูลออเดอร์ไม่ถูกต้อง กรุณาปิดแอปแล้วเปิดใหม่ แล้วลองอีกครั้ง',
    },
    // RELLSWE_CODE_NOT_FOUND
    '07_507': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ไม่พบรหัสตัวแทน',
    },
    // NOT_A_MEMBER
    '07_553': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ตัวแทนรายนี้ไม่ได้อยู่ในสายของท่าน',
    },
    '09_026': {
      t: 'เกิดข้อผิดพลาด ไม่สามารถดึงสินค้าจากร้านตัวเองได้',
      m: 'สินค้าที่มีต้นกำเนิด(คลัง)จากร้านตัวเอง จะไม่สามารถถูกดึงเพื่อมาสร้างเป็นสินค้าในร้านตัวเองได้อีก',
    },
    '11_031': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ยอดรวมออเดอร์ไม่ถูกต้อง กรุณาออกจากหน้านี้แล้วลองใหม่อีกครั้ง',
    },
    '12_002': {
      t: 'ไม่สามารถบันทึกจัดส่งได้',
      m: 'กรุณาตรวจสอบว่านสินค้าทุกรายการในออเดอร์มีจำนวน "คลัง" เพียงพอต่อการจัดส่ง',
    },
    '24_007': {
      t: 'เกิดข้อผิดพลาด',
      m: 'กรุณาออกจากหน้านี้ แล้วกดเข้ามาใหม่อีกครั้ง เพื่อดูข้อมูลล่าสุด',
    },
    // XSHIPPING_INVALID
    '24_033': {
      t: 'เกิดข้อผิดพลาด',
      m:
        'ข้อมูลเลขพัสดุของออเดอร์ไม่ถูกต้อง กรุณารีโหลดหน้านี้ใหม่ แล้วลองดูอีกครั้ง หรือ แคปหน้านี้แล้วส่งให้ทีมงานตรวจสอบที่ LINE: @XSelly (24_033)',
    },
    '25_033': {
      t: 'เกิดข้อผิดพลาด',
      m: 'กรุณารีโหลดหน้านี้ใหม่ แล้วลองดูอีกครั้ง',
    },
    // SELLER_CANT_RECEIVE_ORDER
    '30_090': {
      t: 'ไม่สามารถเปิดออเดอร์ได้',
      m: 'ร้านผู้ขายส่งไม่สามารถรับออเดอร์เพิ่มได้ กรุณาติดต่อทางร้าน',
    },
    // SELLER_CANT_RECEIVE_ORDER_2
    '030_090': {
      t: 'ไม่สามารถเปิดออเดอร์ได้',
      m: 'ร้านผู้ขายส่งไม่สามารถรับออเดอร์เพิ่มได้ กรุณาติดต่อทางร้าน',
    },
    // BODY_PERMISSION_OF_NULL
    '73_047': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ไม่สามารถอ่านรายละเอียดของสิทธิ์การใช้งานได้ กรุณาแคปหน้าจอนี้ไว้แล้วส่งต่อให้ทีมงานทาง LINE: @XSelly',
    },
    // USER_ID_OF_NULL
    '73_007': {
      t: 'ไม่พบชื่อผู้ใช้งาน',
      m: 'กรุณาตรวจสอบชื่อผู้ใช้งานแล้วลองใหม่อีกครั้ง',
    },
    // PERMISSION_HAVE_USER
    '73_064': {
      t: 'ไม่สามารถลบรายการสิทธิ์ได้',
      m: 'กรุณาลบผู้ช่วยก่อน แล้วทำรายการใหม่อีกครั้ง',
    },
    // PERMISSION_ID_OF_NULL
    '73_099': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ไม่สามารถระบุสิทธิ์การใช้งานได้ กรุณาแคปหน้าจอนี้ไว้แล้วส่งต่อให้ทีมงานทาง LINE: @XSelly',
    },
    // HELPER_HAVE_PERMISSION
    '73_044': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ผู้ช่วยนี้อยู่ในรายการสิทธิ์ของร้านอยู่แล้ว',
    },
    // HELPER_COUNT_FULL
    '73_087': {
      t: 'แพ็กเกจของคุณไม่สามารถเพิ่มผู้ช่วยได้แล้ว',
      m: 'กรุณาติดต่อทีมงาน XSelly',
    },
    // SELLER_DONT_SUPPORT
    '00_081': {
      t: 'ไม่สามารถสร้างออเดอร์ได้',
      m:
        'ผู้ขายไม่อนุญาตให้เลือกการจัดส่งแบบ COD (เก็บเงินปลายทาง) หากต้องการใช้งานกรุณาติดต่อผู้ขายให้เปิดการใช้งานนี้ที่ตั้งค่า ของร้านผู้ขาย',
    },
    // STORE_ID_NOT_FOUND
    '06_007': {
      t: 'ไม่พบร้านค้า',
      m: 'กรุณาปิดและเปิดแอปฯใหม่ แล้วลองอีกครั้ง',
    },
    // PARENT_CATEGORY_ID_NOT_FOUND
    '74_007': {
      t: 'ไม่พบหมวดหมู่เลือก',
      m: 'กรุณาปิดและเปิดแอปฯใหม่ แล้วลองอีกครั้ง',
    },
    // CATEGORY_SORT_ORDER_IS_EMPTY
    '74_005': {
      t: 'เกิดข้อผิดพลาด',
      m: 'กรุณาปิดและเปิดแอปฯใหม่ แล้วลองอีกครั้ง',
    },
    // CATEGORY_SORT_ORDER_IS_NOT_UP_TO_DATE
    '74_047': {
      t: 'ข้อมูลไม่ตรงกับปัจจุบัน',
      m: 'กรุณาปิดและเปิดแอปฯใหม่ แล้วลองอีกครั้ง',
    },
    // CATEGORY_NO_PERMISSION
    '15_004': {
      t: '',
      m: 'คุณไม่มีสิทธิ์ใช้งานฟีเจอร์นี้',
    },
    // NESTED_CHILDREN_ID
    '74_093': {
      t: '',
      m: 'คุณไม่สามารถย้ายหมวดหมู่ไปใส่ภายใต้หมวดหมู่ของตนเองได้',
    },
    // CATEGORY_SAME_PLACE
    '74_094': {
      t: '',
      m: 'คุณไม่สามารถย้ายหมวดหมู่ไปใส่ที่เดิมได้',
    },
    // CATEGORY_SAME_NAME
    '74_010': {
      t: '',
      m: 'กรุณาแก้ไขชื่อใหม่ เนื่องจากมีชื่อซ้ำกัน',
    },
    // ORGANIZATION_IS_EMPTY
    '76_005': {
      t: 'ไม่พบรหัสองค์กร',
      m: 'กรุณาเปิดหน้าต่างนี้แล้วลองใหม่อีกครั้ง',
    },
    // ORGANIZATION_IS_NOT_FOUND
    '76_007': {
      t: 'ไม่พบรหัสองค์กร',
      m: 'กรุณาเปิดหน้าต่างนี้แล้วลองใหม่อีกครั้ง',
    },
    // ORGANIZATION_IS_NOT_NUMBER
    '76_047': {
      t: 'ตรวจพบรหัสองค์กรไม่ถูกต้อง',
      m: 'กรุณาเปิดหน้าต่างนี้แล้วลองใหม่อีกครั้ง',
    },
    // FILE_IMPORT_NEW_USER_IS_HAVE_MERGE_CELL
    '78_017': {
      t: 'พบการ Merge Cell',
      m: 'กรุณาแก้ไขแล้วลองใหม่อีกครั้ง',
    },
    // FILE_IMPORT_NEW_USER_IS_NULL
    '78_005': {
      t: '',
      m: 'ไม่พบไฟล์ที่อัปโหลด กรุณาลองใหม่อีกครั้ง',
    },
    // NOT_EXCEL_FILE_IMPORT_NEW_USER
    '78_047': {
      t: '',
      m: 'ไฟล์ที่เลือก ไม่ใช่ Excel ไฟล์ (.xlsx)',
    },
    // INVITE_CODE_USER_GROUP_FAILED
    '20_007': {
      t: '',
      m: 'เกิดความผิดพลาดในการค้นหารหัสเข้าร่วมกลุ่ม กรุณาติดต่อทีมงาน',
    },
    // UG_QUOTA_EXCEEDED
    '20_033': {
      t: 'ไม่สามารถเพิ่มกลุ่มสมาชิกได้',
      m: 'แพ็กเกจของคุณ ไม่สามารถเพิ่มกลุ่มสมาชิกได้ หากสนใจเพิ่มกลุ่มสมาชิก กรุณาติดต่อทีมงาน',
    },
    '20_061': {
      t: 'รหัสการสมัครเป็นสมาชิกไม่ถูกต้อง',
      m: 'กรุณาติดต่อขอ "รหัสการสมัครเป็นสมาชิก" หรือ "ลิงก์ขอเข้ากลุ่ม" ใหม่จากทางร้านที่ต้องการเป็นสมาชิก (20_061)',
    },
    // Already joined the group
    '20_069': {
      t: 'รหัสหรือลิงก์ขอเข้ากลุ่มไม่ถูกต้อง',
      m:
        'กรุณาตรวจสอบรหัสขอเข้ากลุ่มใหม่ หรือติดต่อขอ "รหัสการสมัครเป็นสมาชิก" หรือ "ลิงก์ขอเข้ากลุ่ม" ใหม่จากทางร้านที่ต้องการเป็นสมาชิก โดยหากเป็นลิงก์ขอเข้ากลุ่ม (ขึ้นต้นด้วย https://) ให้เปิดลิงก์ผ่านเว็บบราวเซอร์เช่น Chrome หรือ Safari (20_069)',
    },
    // Already joined the group
    '20_070': {
      t: 'กรุณารีสตาร์ทแอป',
      m: 'คุณเป็นสมาชิกร้านนี้แล้ว กรุณาปิดแอปแล้วเปิดใหม่เพื่อรีโหลด (20_070)',
    },
    // EMAIL_IS_NOT_FORMAT
    '01_096': {
      t: 'เกิดข้อผิดพลาด',
      m: 'รูปแบบ Email ไม่ถูกต้อง',
    },
    // POSTAL_CODE_IS_NOT_FORMAT
    '05_097': {
      t: '',
      m: 'รูปแบบ รหัสไปรษณีย์ ไม่ถูกต้อง',
    },
    // ADVISER_CODE_NOT_FOUND
    '79_007': {
      t: '',
      m: 'ไม่พบรหัสตัวแทนของผู้แนะนำ',
    },
    // ADDRESS_IS_NOT_COMPLETE
    '05_005': {
      t: '',
      m: 'กรุณากรอกข้อมูลที่อยู่ให้ครบถ้วน',
    },
    // RESELLER_CODE_IS_NOT_FORMAT
    '79_098': {
      t: '',
      m: 'รหัสตัวแทนไม่ถูกต้อง',
    },
    // RESELLER_CODE_DUPLICATE
    '79_101': {
      t: '',
      m: 'รหัสตัวแทนซ้ำ',
    },
    // RESELLER_CODE_NOT_FOUND
    '81_007': {
      t: '',
      m: 'รหัสลูกทีมไม่ถูกต้อง หรือไม่ได้อยู่ในสายงานคุณ กรุณาตรวจสอบและพิมพ์ใหม่อีกครั้ง',
    },
    // MUMBER_TYPE_NOT_TRUE
    '79_102': {
      t: '',
      m: 'Member type ไม่ถูกต้อง กรุณาแก้ไขแล้วลองใหม่อีกครั้ง',
    },
    // COLUMN_HEADER_NAME_NOT_TRUE
    '78_103': {
      t: '',
      m: 'Column header name ไม่ถุกต้อง กรุณาแก้ไขแล้วลองใหม่อีกครั้ง',
    },
    // DONT_HAVE_PERMISSION_IMPORT_EXCEL
    '15_104': {
      t: '',
      m: 'ไม่มีสิทธิ์นำเข้าไฟล์',
    },
    // HELPER_NO_PERMISSION
    '15_023': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ผู้ช่วยไม่มีสิทธิ์เข้าถึงข้อมูลนี้',
    },
    // PROFILE_ID_IS_NULL
    '04_005': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ไม่พบข้อมูลโปรไฟล์',
    },
    // PROFILE_NOT_FOUND
    '04_007': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ไม่พบข้อมูลโปรไฟล์',
    },
    // REFERAL_RESELLER_CODE_NOT_FOUND
    '80_007': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ไม่พบข้อมูลโปรไฟล์ของแม่ทีม',
    },
    // HELPER_NO_PERMISSION_TO_EDIT
    '15_021': {
      t: '',
      m: 'ผู้ช่วยไม่มีสิทธิ์ในการแก้ไข',
    },
    // HELPER_NO_PERMISSION_TO_ADD_ADDRESS
    '15_020': {
      t: '',
      m: 'ผู้ช่วยไม่มีสิทธิ์ในการเพิ่มที่อยู่ได้',
    },
    // STORE_ID_IS_NULL_FROM_PROFILE_ID
    '06_047': {
      t: 'เกิดข้อผิดพลาด',
      m: 'กรุณาติดต่อทีมงาน',
    },
    '06_067': {
      t: 'ไม่สามารถขอเข้ากลุ่มของตัวเองได้',
      m: 'กรุณาขอและเปิด "ลิงก์ขอเข้ากลุ่่ม" จากร้านที่คุณต้องการสมัครเป็นสมาชิก',
    },
    // ADDRESS_IS_LIMIT
    '05_087': {
      t: '',
      m: 'คุณมีที่อยู่สุดถึงขีดจำกัดแล้ว',
    },
    // // HELPER_NO_PERMISSION_TO_DELETE_ADDRESS **** key ซ้ำ ?
    // '15_020': {
    //   t: '',
    //   m: 'ผู้ช่วยไม่มีสิทธิ์ในการลบที่อยู่ได้',
    // },
    // EMAIL_NO_INPUT
    '82_005': {
      t: '',
      m: 'คุณไม่ได้ระบุ Email',
    },
    // EMAIL_INVALID_FORMAT
    '03_096': {
      t: '',
      m: 'คุณระบุ Email ในรูปแบบที่ไม่ถูกต้อง',
    },
    // REFERAL_RESELLER_CODE_NO_INPUT
    '80_005': {
      t: '',
      m: 'กรุณาระบุรหัสผู้แนะนำ',
    },
    // PASSWORD_NO_INPUT
    '83_005': {
      t: '',
      m: 'กรุณาระบุรหัสผ่าน',
    },
    // POSTAL_CODE_NO_INPUT
    '05_106': {
      t: '',
      m: 'กรุณาระบุรหัสไปรษณีย์ของที่อยู่',
    },
    // RESELLER_CODE_NO_INPUT
    '81_005': {
      t: '',
      m: 'กรุณาระบุรหัสผู้แนะนำ',
    },
    // REFERAL_RESELLER_CODE_INVALID_FORMAT
    '80_098': {
      t: '',
      m: 'กรุณาระบุรหัสผู้แนะนำให้ถูกต้อง',
    },
    '81_110': {
      t: '',
      m: 'รหัสตัวแทนมีซ้ำอยู่ในระบบแล้ว กรุณาแก้ไขให้ถูกต้องแล้วลองใหม่อีกครั้ง',
    },
    // FIRST_NAME_NO_INPUT
    '01_107': {
      t: '',
      m: 'กรุณาระบุชื่อ',
    },
    // // UPDATE_EMAIL_INVALID_FORMAT  **** key ซ้ำ ?
    // '03_096': {
    //   t: '',
    //   m: 'ระบุ Email ไม่ถูกต้อง',
    // },
    // UPDATE_EMAIL_DUPLICATE
    '03_075': {
      t: 'Email ซ้ำในระบบ',
      m: 'กรุณาเปลี่ยน Email อื่น',
    },
    // RESELLER_CODE_IS_NOT_MEMBER
    '81_105': {
      t: '',
      m: 'รหัสตัวแทนที่ระบุไม่ได้อยู่ในสายของท่าน',
    },
    // RESELLER_DETAIL_IS_NOT_YOU_MEMBER
    '15_105': {
      t: '',
      m: 'รหัสตัวแทนที่ระบุไม่ได้อยู่ในสายของท่าน',
    },
    // TO_BE_TRANSFERRED_RESELLER_CODE
    '85_111': {
      t: '',
      m: 'Dealer แต่ยังมีสมาชิก VIP เป็นสมาชิกอยู่ กรุณาตรวจสอบและลองใหม่อีกครั้ง',
    },
    // NEW_PARENT_RESELLER_CODE_HAS_TO_BE_TRANSFERRED_RESELLER_CODE
    '85_112': {
      t: '',
      m: 'เป็นสมาชิกของสายที่จะย้ายไปอยู่แล้ว',
    },
    // RESELLER_TYPE_IS_WRONG
    '84_047': {
      t: '',
      m: 'เลือกประเภทตัวแทนไม่ถูกต้อง',
    },
    // RESELLER_TYPE_NO_INPUT
    '84_005': {
      t: '',
      m: 'กรุณาระบุประเภทตัวแทน',
    },
    // NEW_PARENT_RESELLER_CODE_IS_TO_BE_TRANSFERRED_RESELLER_CODE
    '85_113': {
      t: '',
      m: 'เป็นสมาชิกของแม่ทีมอยู่แล้ว',
    },
    // NEW_PARENT_RESELLER_CODE_IS_DUPLICATE
    '85_114': {
      t: '',
      m: 'ไม่สามารถย้ายเข้าไปอยู่ในรหัสตัวแทนตัวเองได้',
    },
    // HAVE_CHILDREN_OF_THE_TYPE_VIP_MEMBERSHIP
    '85_115': {
      t: '',
      m: 'ไม่สามารถย้ายเข้าไปอยู่ในรหัสตัวแทนตัวเองได้',
    },
    // STORE_ID_IS_NULL_FROM_REPORT_DEALER_COUNT_RESELLERS
    '06_005': {
      t: '',
      m: 'ไม่พบข้อมูลร้านค้า กรุณาปิดแอพฯ และลองใหม่อีกครั้ง',
    },
    // NOT_DEALER_TYPE
    '87_117': {
      t: '',
      m: 'คุณไม่ใช่ Dealer',
    },
    // RESELLER_CODE_DUPLICATE_DURING_APPROVE
    // '81_110': {
    //   t: 'เกิดข้อผิดพลาด',
    //   m: 'รหัสตัวแทนที่จะสร้างให้ใหม่ซ้ำกับที่มีอยู่ในระบบ {{CONTACT_STAFF_TO_HELP}}',
    // },
    // MKP_CHANNEL_ID_NO_INPUT
    '90_005': {
      t: 'ไม่พบช่องทางขาย',
      m: 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง',
    },
    // PRODUCT_TEMPLATE_ID_NOT_FOUND
    '09_005': {
      t: 'ไม่พบสินค้า',
      m: 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง',
    },
    // PRODUCT_DETAIL_MKP_NOT_FOUND
    '89_007': {
      t: 'ไม่พบสินค้า',
      m: 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง',
    },
    // MKP_CHANNEL_ID_NOT_FOUND
    '90_007': {
      t: 'ไม่พบช่องทางขาย',
      m: 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง',
    },
    // ITEM_ID_NOT_FOUND
    '92_007': {
      t: 'ไม่พบสินค้า',
      m: 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง',
    },
    // PRODUCT_PRODUCT_ID_NOT_FOUND
    '10_007': {
      t: 'ไม่พบสินค้า',
      m: 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง',
    },
    '09_125': {
      t: 'ไม่สามารถลบสินค้าได้',
      m:
        'สินค้านี้มีการผูกอย่างน้อย 1 ตัวเลือกสินค้าไว้กับสินค้าจากช่องทางขาย กรุณาสลับการผูกทุกตัวเลือกสินค้านี้ไปที่สินค้าอื่นก่อนลบสินค้านี้ โดยไปที่ เมนูหลัก > ช่องทางขาย > กดปุ่มตะกร้า > เปิดดูสินค้าที่ผูกอยู่ > กดปุ่ม"ผูกสินค้าใหม่"',
      cu: 'https://www.youtube.com/watch?v=CPBMspB6h1A',
      ct: 'ดูคลิปแนะนำ',
    },
    '10_125': {
      t: 'ไม่สามารถลบตัวเลือกสินค้าได้',
      m:
        'ตัวเลือกสินค้าที่คุณต้องการลบผูกอยู่กับสินค้าจากช่องทางขาย กรุณาสลับการผูกไปที่สินค้าอื่นก่อนลบสินค้านี้ โดยไปที่ เมนูหลัก > ช่องทางขาย > กดปุ่มตะกร้า > เปิดดูสินค้าที่ผูกอยู่ > กดปุ่ม"ผูกสินค้าใหม่"',
    },
    // PRODUCT_PRODUCT QTY > unshipped qty
    '10_027': {
      t: 'ไม่สามารถจัดส่งได้',
      m: 'ไม่มีจำนวนสินค้าที่รอจัดส่งสำหรับออเดอร์นี้',
    },
    // VD_NOT_ALLOW_TO_ADD
    '22_020': {
      t: 'ไม่สามารถสร้างส่วนลดขายส่งได้',
      m: 'แพ็กเกจของคุณไม่สามารถใช้งานฟีเจอร์นี้ได้ หากสนใจใช้งาน กรุณาติดต่อทีมงาน',
    },
    // VD_QUOTA_EXCEEDED
    '22_124': {
      t: 'ไม่สามารถเพิ่มลำดับขั้นส่วนลดขายส่งได้',
      m: 'แพ็กเกจของคุณ ไม่สามารถเพิ่มลำดับขั้นส่วนลดขายส่งได้อีก หากสนใจเพิ่มลำดับขั้น กรุณาติดต่อทีมงาน',
    },
    // PRODUCT_PRODUCT_OUT_OF_STOCK
    '10_028': {
      t: 'สินค้าหมดสต๊อก',
      // m: 'กรุณาตรวจสอบคลังสินค้าของแต่ละสินค้าในออเดอร์',
      m: 'กรุณาตรวจสอบสินค้าคงเหลือของแต่ละสินค้าในออเดอร์',
    },
    '10_029': {
      t: 'ไม่สามารถปรับจำนวนสินค้าในออเดอร์ให้น้อยกว่าที่จัดส่งไปแล้วได้',
      m:
        'กรุณาตรวจสอบการจัดส่งของออเดอร์นี้และปรับจำนวนสินค้าให้มากกว่าหรือเท่ากับที่จัดส่งไปแล้ว หรือหากต้องการปรับให้น้อยกว่าให้ยกเลิกการจัดส่งก่อนแล้วค่อยปรับจำนวนสินค้าใหม่',
    },
    // PRODUCT_PRODUCT_DUPLICATE_SKU
    '10_055': {
      t: 'SKU สินค้าซ้ำกัน',
      m: 'กรุณาแก้ไข SKU สินค้าแต่ละรายการให้ไม่ซ้ำกับที่มีอยู่แล้วในระบบ หรือที่กำลังแก้ไขอยู่ แล้วทำรายการใหม่อีกครั้ง',
    },
    // ORDER_LOCK
    '07_052': {
      t: 'ออเดอร์ถูกล็อก',
      m: 'กรุณาปลดล็อดออเดอร์ แล้วทำรายการใหม่อีกครั้ง',
    },
    // ORDER_ALREADY_CANCELLED
    '07_037': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ออเดอร์นี้ถูกยกเลิกไปแล้ว กรุณาตรวจสอบออเดอร์',
    },
    '07_078': {
      t: '',
      m: 'สวิตซ์ "จัดส่งก่อนชำระ" ถูกเปิดใช้งานอยู่แล้ว กรุณาตรวจสอบและลองใหม่อีกครั้ง',
    },
    '71_005': {
      t: 'เกิดข้อผิดพลาด',
      m: 'ไม่พบสินค้าที่ต้องเพิ่ม 71_005',
    },
    '71_047': {
      t: '',
      m: 'กรอกข้อมูลไม่ถูกต้อง 71_047',
    },
    '71_017': {
      t: '',
      m: 'กรอกข้อมูลไม่ถูกต้อง 71_017',
    },
    '71_089': {
      t: '',
      m: 'pg_id ในไฟล์ template ของ excel ไม่ถูกต้อง เมื่อเทียบกับ pg_id ทางร้าน (71_089)',
    },
    '10_013': {
      t: '',
      m: 'ไม่พบชื่อสินค้า (10_013)',
    },
    '10_088': {
      t: '',
      m: 'มีตัวเลขทศนิยมไม่ถูกต้อง (10_088)',
    },
    '71_007': {
      t: '',
      m: 'ไม่พบไฟล์ (71_007)',
    },
    '91_031': {
      t: '',
      m: 'Invalid amount (91_031)',
    },
    '91_033': {
      t: '',
      m: 'Invalid Verify Slip ID  (91_033)',
    },
    '91_555': {
      t: '',
      m: 'TransactionRejected  (91_555)',
    },
    '91_556': {
      t: '',
      m: 'InvalidReceivingPaymentAccount  (91_556)',
    },
    '91_557': {
      t: '',
      m: 'SlipAlreadyUsed  (91_557)',
    },
    '91_558': {
      t: '',
      m: 'SlipExpired  (91_558)',
    },
    '91_559': {
      t: '',
      m: 'VerifySlipDisabled  (91_559)',
    },
    '05_099': {
      t: '',
      m: 'ไม่พบที่อยู่ร้าน(ที่อยู่ผู้ส่ง)ที่ตั้งค่าไว้ กรุณาตั้งค่าที่อยู่ร้านใหม่',
    },

    // ---------------------------------------
    // Errors V2
    BadRequest: {
      m: 'การร้องขอข้อมูลไม่ถูกต้อง',
    },
    CreateKafkaEventFail: {
      m: 'เกิดข้อผิดพลาดในการจัดการคิวงาน',
    },
    PaymentAccountId: {
      m: 'ไม่พบบัญชีรับชำระ',
    },
    InternalServerError: {
      m: 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง หรือติดต่อทีมงาน (InternalServerError)',
    },
    InvalidCreateShipping: {
      m: 'บันทึกจัดส่งไม่ถูกต้อง',
    },
    InvalidOrderSegment: {
      m: 'ตัวกรองเซ็กเมนต์ของออเดอร์ไม่ถูกต้อง',
    },
    InvalidSegment: {
      m: 'ตัวกรองเซ็กเมนต์ของออเดอร์ไม่ถูกต้อง',
    },
    InvalidSkipJobDetailValue: {
      m: 'ระบุค่าการข้ามรายละเอียดคิวคำสั่งงานไม่ถูกต้อง',
    },
    JobUuIdIsRequired: {
      m: 'รหัสคำสั่งงานยังไม่ถูกระบุ',
    },
    JsonDecodeFail: {
      m: 'ไม่สามารถถอดรหัสข้อมูล (JSON) ได้',
    },
    NotOwnerOrAssistant: {
      m: 'คุณไม่ใช่เจ้าของร้านหรือผู้ช่วยร้านนี้',
    },
    OrderInvalid: {
      m: 'เลขออเดอร์ไม่ถูกต้อง',
    },
    Panic: {
      m: 'เกิดข้อผิดพลาดจากระบบ',
    },
    PaymentAccountNotFound: {
      m: 'ไม่พบบัญชีรับชำระ',
    },
    PayloadInvalid: {
      m: 'การส่งพารามิเตอร์ไม่ถูกต้อง',
    },
    PermissionAccessDenied: {
      m: 'คุณไม่มีสิทธิ์ในการใช้งาน',
    },
    PermissionDenied: {
      m: 'คุณไม่มีสิทธิ์ในการใช้งาน',
    },
    PermissionNotFound: {
      m: 'ไม่พบสิทธิ์การใช้งาน',
    },
    ProfileInvalid: {
      m: 'ข้อมูลผู้ใช้ไม่ถูกต้อง',
    },
    StoreNotFound: {
      m: 'ไม่พบข้อมูลร้านค้า',
    },
    UnmatchedCustomPackingTemplateError: {
      m: 'UnmatchedCustomPackingTemplateError',
    },
    InvalidPostDateColumn: {
      m: 'ข้อมูลวันที่โอนเงินไม่ถูกต้อง กรุณาตรวจสอบให้ตรงกับตัวอย่าง',
    },
    InvalidPostDateFormat: {
      m: 'รูปแบบวันที่โอนเงินไม่ถูกต้อง กรุณาตรวจสอบให้ตรงกับตัวอย่าง',
    },
    PermissionAccessDeniedBarcodeSku: {
      m: 'ไม่มีสิทธิ์ในการแก้ไข SKU',
    },
    PermissionAccessDeniedBarcodeUpc: {
      m: 'ไม่มีสิทธิ์ในการแก้ไข UPC',
    },
    NoChangeFound: {
      m: 'ไม่มีการเปลี่ยนแปลงของข้อมูล',
    },
    InvalidExcelTemplate: {
      m: 'ไฟล์ Excel ไม่ถูกต้อง กรุณาตรวจสอบ หรือโหลดไฟล์ Excel ใหม่เพื่อแก้ไขอีกครั้ง',
    },
    NoFileIsReceived: {
      m: 'ไม่ได้รับไฟล์ที่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
    },
    UnableFileTypeToSaveTheFile: {
      m: 'ประเภทไฟล์ไม่ถูกต้อง',
    },
    ProductQtyUpdateColumnNotFound: {
      // m: 'ไฟล์ Excel ไม่ถูกต้อง กรุณาตรวจสอบ หรือโหลดไฟล์ Excel สำหรับการ "แก้ไขจำนวนคลังแบบชุด" แล้วแก้ไขอีกครั้ง',
      m: 'ไฟล์ Excel ไม่ถูกต้อง กรุณาตรวจสอบ หรือโหลดไฟล์ Excel สำหรับการ "แก้ไขจำนวนคงเหลือแบบชุด" แล้วแก้ไขอีกครั้ง',
    },
    ChangeFilePermissionsFail: {
      m: 'เกิดข้อผิดพลาดในการตั้งค่าสิทธิ์ของไฟล์ กรุณาอัพโหลดอีกครั้ง หากยังติดปัญหากรุณาติดต่อทีมงานที่ LINE: @XSelly',
    },
    InvalidStoreUseProductBarcode: {
      m:
        'ร้านไม่ได้เปิดการใช้งาน SKU/UPC\nคุณสามารถเปิดการใช้งานได้ผ่านแอป หน้าแรก > ตั้งค่าร้าน (รูปเฟื่องขวาบน) > รหัสสินค้า SKU/UPC > เปิดการใช้งาน',
    },
    SheetNameFailed: {
      m: 'ชื่อไฟล์ไม่ถูกต้อง',
    },
    PermissionAccessDeniedBarcodUpc: {
      m: 'ไม่มีสิทธิ์ในการแก้ไข UPC',
    },
    PermissionAccessDeniedBarcodSku: {
      m: 'ไม่มีสิทธิ์ในการแก้ไข SKU',
    },
    StoreInvalidRole: {
      m: 'ไม่มีสิทธิ์ในกานใช้งาน (StoreInvalidRole)',
    },
    StoreSettingNotFound: {
      m: 'เกิดข้อผิดพลาด (StoreSettingNotFound)',
    },
    OrderNotFound: {
      m: 'ไม่พบออเดอร์',
    },
    TrackingCodeNotFoundInShippings: {
      m: 'ไม่พบออเดอร์ที่บันทึกจัดส่งด้วยเลขติดตามพัสดุนี้',
    },
    UnknownErrorWhenFindingMatchForTrackingCode: {
      m: 'ไม่สามารถดำเนินการได้ กรุณาลองใหม่ หรือติดต่อทีมงาน',
    },
    OrderIsNotCOD: {
      m: 'ออเดอร์นี้ไม่ได้ส่งแบบเก็บเงินปลายทาง',
    },
    NotInPayableState: {
      m: 'สถานะออเดอร์ไม่ถูกต้อง',
    },
    OrderHasAlreadyBeenCancelled: {
      m: 'ออเดอร์ถูกยกเลิกไปแล้ว',
    },
    OrderIsNotWaitingForPayment: {
      m: 'ออเดอร์ไม่มียอดค้างชำระ',
    },
    TrackingCodeMatchedMoreThanOneOrder: {
      m: 'พบมากกว่า 1 ออเดอร์ที่บันทึกจัดส่งด้วยเลขพัสดุนี้',
    },
    SearchOrderIdNotFound: {
      m: 'ไม่พบเลขออเดอร์นี้',
    },
    ExceedDateRangeLimit: {
      m: 'กรุณาระบุช่วงวันให้อยู่ไม่เกินจำนวนวันที่รองรับ',
    },
    OwnerCannotBeHelper: {
      m: 'ไม่สามารถเพิ่มบัญชีเจ้าของร้านเป็นผู้ช่วยร้านตัวเองได้',
    },
    AlreadyBeHelper: {
      t: 'เป็นผู้ช่วยร้านอยู่แล้ว',
      m:
        'ไม่สามารถเพิ่มซ้ำได้ หากไม่พบชื่อผู้ช่วยนี้ในร้านกรุณาปิดแอปแล้วเปิดใหม่เพื่อ reload ข้อมูล ' +
        'หรือหากต้องการย้ายสิทธิ์การใช้งานของผู้ช่วยสามารถแตะที่ชื่อผู้ช่วยได้',
    },
    InvalidOperator: {
      m: 'ข้อมูลไม่ถูกต้อง',
    },
    SkuNotFound: {
      m: 'ไม่พบรหัส SKU',
    },
    SKUNotFound: {
      m: 'ไม่พบรหัส SKU',
    },
    UpcNotFound: {
      m: 'ไม่พบรหัส UPC',
    },
    UPCNotFound: {
      m: 'ไม่พบรหัส UPC',
    },
    InvalidQty: {
      // m: 'จำนวนคลังต้องเป็นตัวเลขและไม่มีจุดทศนิยม',
      m: 'จำนวนคงเหลือต้องเป็นตัวเลขและไม่มีจุดทศนิยม',
    },
    InvalidExcelFormat: {
      m: 'รูปแบบ Excel ไม่ถูกต้อง',
    },
    ContactNameColumnInvalid: {
      m: 'รูปแบบ Excel ไม่ถูกต้อง (ContactNameColumnInvalid)',
    },
    AddressNameColumnInvalid: {
      m: 'รูปแบบ Excel ไม่ถูกต้อง (AddressNameColumnInvalid)',
    },
    FileSizeTooLarge: {
      m: 'รองรับไฟล์ขนาด 5 MB หรือน้อยกว่าเท่านั้น',
    },
    ExcelFormatInvalid: {
      m: 'ไฟล์ไม่ถูกต้อง (ExcelFormatInvalid)',
    },
    SupportOnlyXlsxFile: {
      m: 'รองรับแค่ไฟล์ .xlsx เท่านั้น',
    },
    InvalidImageURL: {
      m: 'ที่อยู่ของรูปภาพไม่ถูกต้อง (InvalidImageURL)',
    },
    warning_contact_to_line_xselly: {
      m: 'กรุณาติดต่อทีมงานที่ LINE: @XSelly',
    },
    has_error: {
      m: 'เกิดข้อผิดพลาด',
    },
    CodeInvalid: {
      m: 'รหัส Code ไม่ถูกต้อง (CodeInvalid)',
    },
    InvalidAccountType: {
      m: 'กรุณาระบุประเภทบัญชี หากไม่พบช่องระบุประเภทบัญชีให้ทำการอัปเดตแอปให้เป็นเวอร์ชันล่าสุดก่อน (InvalidAccountType)',
    },
    OrderNotPaid: {
      m: 'ออเดอร์ยังไม่ได้รับชำระครบถ้วน (OrderNotPaid)',
    },
    CannotGetErpChannel: {
      m: 'ไม่พบการเชื่อมต่อระบบบัญชี',
    },
    InvalidContactCodeSource: {
      m: 'การตั้งค่ารหัสผู้ติดต่อของลูกค้าไม่ถูกต้อง',
    },
    InvalidLegalEntityID: {
      m: 'ไม่พบเลขประจำตัวผู้เสียภาษี หรือ เลขบัตรประชาชนของผู้ซื้อ',
    },
    InvalidIssueDate: {
      m: 'วันที่เอกสารไม่ถูกต้อง',
    },
    InvalidErpShippingFeeVatType: {
      m: 'ประเภท VAT ของค่าจัดส่งไม่ถูกต้อง',
    },
    InvalidErpOtherFeeVatType: {
      m: 'ประเภท VAT ของค่าอื่นๆไม่ถูกต้อง',
    },
    InvalidProductSKU: {
      m: 'เลขที่สินค้า (SKU) ไม่ถูกต้อง',
    },
    InvalidProductUPC: {
      m: 'เลขที่สินค้า (UPC) ไม่ถูกต้อง',
    },
    InvalidProductCodeSource: {
      m: 'การตั้งค่าเลขที่สินค้าสำหรับระบบบัญชีไม่ถูกต้อง',
    },
    InvalidVatType: {
      m: 'ประเภท VAT ของออเดอร์ไม่ถูกต้อง',
    },
    InvalidPaymentAccountBankId: {
      m: 'รูปแบบบัญชีไม่ถูกต้อง',
    },
    InvalidPaymentAccountType: {
      m: 'ประเภทบัญชีไม่ถูกต้อง',
    },
    NotSuportDocumentType: {
      m: 'ยังไม่รองรับประเภทเอกสารนี้',
    },
    CannotCreateTaxInvoiceDueToNoProductWithVat: {
      m: 'ไม่สามารถสร้างใบกำกับภาษีเนื่องจากไม่มีรายการสินค้า ค่าส่ง หรือค่าอื่นๆ ที่มี VAT',
    },
    OrderErpAlreadyExist: {
      m: 'สร้างเอกสารใหม่ไม่สำเร็จเนื่องจากเอกสารนี้มีอยู่ที่ระบบบัญชีปลายทางแล้ว',
    },
    InvalidErpPayment: {
      m: 'ไม่สามารถสร้างเอกสารได้เนื่องจากยังไม่ได้ตั้งค่า”รหัสบัญชีรับชำระ” ของบัญชีที่รับชำระไว้ในออเดอร์นี้',
    },
    OrderErpNotFound: {
      m: 'ไม่พบเอกสารนี้ที่ระบบบัญชีปลายทาง',
    },
    InvalidErpDocType: {
      m: 'ประเภทเอกสารไม่ถูกต้อง',
    },
    ReceiverAddressNotFound: {
      m: 'ไม่พบชื่อที่อยู่ผู้รับสำหรับออเดอร์นี้',
    },
    OrderProductNotFound: {
      m: 'ไม่พบรายการสินค้าที่มีจำนวนมากกว่า 0',
    },
    PaymentNotFound: {
      m: 'ออเดอร์นี้ยังไม่มีการบันทึกและยืนยันการชำระที่ครบถ้วน 0',
    },
    InvalidErpSystemID: {
      m: 'กรุณาติดต่อทีมงาน (InvalidErpSystemID)',
    },
    PEAKInvalidPaymentMethodCodeFormat: {
      m: 'ไม่สามารถสร้างเอกสารได้เนื่องจาก ”เลขที่ช่องทางการเงิน” ที่ตั้งค่าไว้ไม่ถูกต้องตามข้อกำหนดของ PEAK',
    },
    PEAKTransactionDateNotFound: {
      m: 'สร้างเอกสารใหม่ไม่สำเร็จเนื่องจากไม่มี “วันที่ออกเอกสาร” ตามที่ตั้งค่าไว้ในระบบบัญชี',
    },
    ErpPaymentCodeNotSpecified: {
      m: 'ไม่สามารถสร้างเอกสารได้เนื่องจากยังไม่ได้ตั้งค่า ”รหัสบัญชีรับชำระ” ของบัญชีที่บันทึกรับชำระไว้ในออเดอร์นี้',
    },
    InvalidInvoiceDueDays: {
      m: 'ระบุจำนวนวันที่ครบกำหนดนับจากวันที่สร้างเอกสารไม่ถูกต้อง',
    },
    TaxInvoiceNotAllowedIfNoVatItem: {
      m:
        'ไม่สามารถสร้าง"ใบกำกับภาษี"ได้ เนื่องจากในออเดอร์นี้ไม่มีรายการสินค้า ค่าจัดส่ง หรือค่าอื่นๆ มีการตั้งค่าว่ามี "รวม VAT แล้ว" หรือ "VAT 0%"\n\nคุณยังคงสามารถสร้างใบแจ้งหนี้หรือใบเสร็จรับเงินแบบไม่ออกใบกำกับภาษีได้ หากไม่มีรายการข้างต้นไม่มี VAT',
    },
    OrderAlreadyExists: {
      m: 'มีออเดอร์ที่ถูกสร้างด้วยรหัสอ้างอิงนี้อยู่แล้ว',
    },
    ProductSkuNotMatch: {
      m: 'ไม่พบสินค้าที่มีรห้สสินค้า SKU ตามที่แจ้งมา',
    },
    ConvertDateOrderToTimeFailed: {
      m: 'รูปแบบของ "วันที่ออเดอร์" ไม่ถูกต้อง',
    },
    PostalCodeNotInt: {
      m: 'รูปแบบของ "รหัสไปรษณีย์" ไม่ถูกต้อง',
    },
    PostalCodeNotFound: {
      m: 'ไม่พบรหัสไปรษณีย์',
    },
    CodAmountNotDecimal: {
      m: 'ยอด "จำนวนเงิน COD" ต้องเป็นตัวเลข',
    },
    ShippingCostNotDecimal: {
      m: '"ค่าจัดส่ง" ต้องเป็นตัวเลข',
    },
    DiscountNotDecimal: {
      m: '"ส่วนลด" ต้องเป็นตัวเลข',
    },
    ProductPriceNotDecimal: {
      m: '"ราคาสินค้า" ต้องเป็นตัวเลข',
    },
    ProductQtyNotInt: {
      m: '"จำนวนสินค้า" ต้องเป็นตัวเลขเต็มจำนวน',
    },
    ProductInvalid: {
      m: 'ข้อมูลสินค้าไม่ถูกต้อง',
    },
    OrderProductSkuHasEmpty: {
      m: 'มีสินค้าที่ไม่ได้ระบุ "รหัสสินค้า SKU" กรุณาระบุให้ครบถ้วน',
    },
    SendCreateOrderFailed: {
      m: 'สร้างออเดอร์ไม่สำเร็จ กรุณาเช็กสต๊อกและข้อมูลออเดอร์ และลองใหม่อีกครั้ง',
    },
    GetStoreFailed: {
      m: 'ข้อมูลร้านค้าไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
    },
    OrderTotalAmountNotDecimal: {
      m: 'ยอดออเดอร์ต้องเป็นตัวเลข',
    },
    InsufficientCredit: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.InsufficientCredit,
    },
    InvalidReceivingPaymentAccount: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.InvalidReceivingPaymentAccount,
    },
    InvalidQRCode: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.InvalidQRCode,
    },
    MismatchAmount: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.MismatchAmount,
    },
    ReceivingPaymentAccountNotFound: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.ReceivingPaymentAccountNotFound,
    },
    RemoteServerError: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.RemoteServerError,
    },
    SlipAlreadyUsed: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.SlipAlreadyUsed,
    },
    SlipExpired: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.SlipExpired,
    },
    TransactionRejected: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.TransactionRejected,
    },
    VerifySlipCampaignUsed: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.VerifySlipCampaignUsed,
    },
    InvalidTotalAmount: {
      t: _TH.VerifySlip.TitleFailedSlipValidation,
      m: _TH.VerifySlip.InvalidTotalAmount,
    },
    InvalidMainPackageType: {
      t: 'ประเภทของแพ็กเกจหลักไม่ถูกต้อง',
      m: 'กรุณาเลือกแพ็กเกจใหม่ หรือติดต่อทีมงาน XSelly ที่ LINE: @XSelly',
    },
    NextPackageBillingCycleAlreadyExists: {
      t: 'ไม่สามารถต่อแพ็กเกจเพิ่มได้',
      m: 'คุณมีแพ็กเกจที่ได้ต่อไว้แล้ว กรุณารอให้ถึงรอบแพ็กเกจถัดไปก่อนต่ออีกครั้ง',
    },
  },
  warning: {
    no_right: 'คุณไม่มีสิทธิ์ในการใช้งาน',
  },
  // app specific
  warning_contact_to: {
    xselly: 'กรุณาติดต่อทีมงาน XSelly ที่ LINE: @XSelly (%{err})',
    somsai: 'กรุณาติดต่อทีมงาน',
  },
  dateRangeSelector: {
    options: {
      Today: 'วันนี้',
      Yesterday: 'เมื่อวานนี้',
      Last7Days: '7 วันที่แล้ว',
      Last30Days: '30 วันที่แล้ว',
      Last45Days: '45 วันที่แล้ว',
      Last60Days: '60 วันที่แล้ว',
      Last90Days: '90 วันที่แล้ว',
      ThisWeekStartWithSun: 'สัปดาห์นี้ (อา.-วันนี้)',
      ThisWeekStartWithMon: 'สัปดาห์นี้ (จ.-วันนี้)',
      LastWeekStartWithSun: 'สัปดาห์ที่แล้ว (อา.-ส.)',
      LastWeekStartWithMon: 'สัปดาห์ที่แล้ว (จ.-อา.)',
      ThisMonth: 'เดือนนี้',
      LastMonth: 'เดือนที่แล้ว',
      Custom: 'กำหนดเอง',
    },
  },
  postal_code_require: {
    m: 'กรุณาระบุรหัสไปรษณีย์',
  },

  verifySlipStatus: {
    SUCCESS: 'สลิปผ่านการตรวจสอบ\nยอดและบัญชีรับชำระถูกต้อง',
    ..._TH.VerifySlip,
  },
}
// th.e_msg_by_error_code['abc 123'] = 'TEST !!'
export default th
