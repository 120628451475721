import React from 'react'

import { View, TouchableOpacity } from 'react-native'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'

import { COLORS, STYLES } from 'x/config/styles'
// import ReportProductDailySales from '../report/ReportProductDailySales'
// import ReportPayment from '../report/ReportPayment'
// import ReportHelperSales from '../report/ReportHelperSales'
import XIcon from 'xui/components/XIcon'
import BaseImportProductBarcodeView from 'x/modules/importExcel/BaseImportProductBarcodeView'
import XCustomHeader from 'xui/components/XCustomHeader'
// FIXME: react-dropzone ไม่ควรมาอยู่บน xui เพราะ app ไม่รู้จัก
// import Dropzone from 'react-dropzone'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XFooterImportExcel from 'xui/components/XFooterImportExcel'
import XSpinner from 'xui/components/XSpinner'

export default abstract class BaseUIImportProductBarcodeView extends BaseImportProductBarcodeView {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  abstract _renderDropzone(): JSX.Element

  abstract onPressUpload(): void

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // const { mode } = this.state
    // const isEditMode = mode === EDIT
    const leftBtn = { backIcon: true, onPressItem: () => this._goBack() }
    // if (isEditMode) {
    //   // @ts-ignore
    //   leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    // }
    return (
      <XCustomHeader
        title='แก้ไขรหัสสินค้า SKU/UPC แบบชุด'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _delayDownloadTemplate = async () => {
    util.setStatePromise(this, {
      disabledDownloadTemplate: true,
    })
    await util.delay(5000)
    util.setStatePromise(this, {
      disabledDownloadTemplate: false,
    })
  }

  _downloadTemplate = async () => {
    this._delayDownloadTemplate()
    const storeId = this.props.selectedStore.get('id')
    const url = util.getProductBarcodeUpdateTemplate({
      store_id: storeId,
    })
    // console.log('url => ', url)
    const now = util.getTimeFormatYYYYMMDDhhmmss()
    const fileName = `mass_product_barcode_update_template_${storeId}_${now}.xlsx`
    await this._handleOnDownloadFile(url, fileName)
    // const res = await api.getV2(api.GET_EXCEL_PRODUCT_IMPORT_TEMPLATE, apiOptions)
    // console.log('res => ', res)
    // Linking.openURL(linkDownload)
  }

  _renderDownloadTemplateView = () => {
    const { disabledDownloadTemplate } = this.state
    let textBtnColor = COLORS.TEXT_ACTIVE
    let borderBtnColor = COLORS.APP_MAIN
    if (disabledDownloadTemplate) {
      textBtnColor = COLORS.TEXT_INACTIVE
      borderBtnColor = COLORS.TEXT_INACTIVE
    }
    return (
      <Box
        style={{
          width: '100%',
          justifyContent: 'center',
        }}>
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4 }}>
          ใช้เพื่อแก้ไขรหัสสินค้า SKU และ UPC สำหรับสินค้าทีละหลายรายการ
        </XText>
        <Box h='2' />
        <XText variant='active' bold>
          ขั้นตอน:
        </XText>

        <XText variant='active'>
          {`1) กดปุ่ม "ดาวน์โหลด Excel ${p.op.t('qty')} ณ ปัจจุบัน" เพื่อให้ได้รายการสินค้าและรหัสสินค้า SKU และ UPC ที่ล่าสุดก่อนแก้ไข`}
        </XText>
        <XText color={COLORS.APP_MAIN}>หมายเหตุ: ในทุกๆครั้งที่ต้องการแก้ไข ต้องโหลดไฟล์นี้ใหม่ทุกครั้งเพื่อข้อมูลที่ตรงกับปัจจุบัน</XText>
        <Box h='2' />
        <TouchableOpacity
          disabled={disabledDownloadTemplate}
          onPress={() => this._downloadTemplate()}
          style={{
            width: 280,
            flexDirection: 'row',
            height: 30,
            borderColor: borderBtnColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}>
          <XIcon
            name='exclefile1'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: borderBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <XText color={textBtnColor}>{`ดาวน์โหลด Excel จำนวน${p.op.t('qty')}สินค้า ณ ปัจจุบัน`}</XText>
        </TouchableOpacity>
        <Box h='2' />
        <XText variant='active'>
          {`2) เปิดไฟล์ Excel เพื่อแก้ไขรหัสสินค้า SKU และ UPC ในช่องสีเขียว เฉพาะรายการสินค้าที่ต้องการแก้ไข${p.op.t('qty')}`}
        </XText>
        <Box h='2' />
        <XText variant='active'>3) เลือก ไฟล์ Excel ที่แก้ไขแล้ว และกดปุ่ม "อัพโหลด"</XText>
        <Box h='2' />
      </Box>
    )
  }

  // _renderDropzone = () => {
  //   const { fileUpload } = this.state
  //   const lableDropZone = _.isNil(fileUpload) ? `วาง Excel ไฟล์ไว้ตรงนี้ หรือกดตรงนี้เพื่อเลือกไฟล์` : `ไฟล์ที่เลือก\n"${fileUpload.name}"`
  //   // const lableDropZone = `TEST`
  //   const borderStyle = _.isNil(fileUpload) ? 'dashed' : 'solid'
  //   return (
  //     <View style={{ width: '100%', height: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
  //       <Dropzone
  //         onDrop={(acceptedFiles) => {
  //           // @ts-ignore
  //           this._setFileUpload(acceptedFiles)
  //         }}
  //         accept='.xls, .xlsx'
  //         multiple={false}>
  //         {({ getRootProps, getInputProps }) => (
  //           <TouchableOpacity style={{ width: `100%`, paddingLeft: 4, paddingRight: 4, paddingTop: 4, paddingBottom: 4 }}>
  //             <div style={{ backgroundColor: COLORS.BG_LIGHT_GREY, borderRadius: 10 }} {...getRootProps()}>
  //               <input {...getInputProps()} />
  //               <View
  //                 style={{
  //                   height: 150,
  //                   width: '100%',
  //                   borderWidth: 0.8,
  //                   borderColor: COLORS.APP_MAIN,
  //                   borderStyle,
  //                   borderRadius: 10,
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   paddingLeft: 6,
  //                   paddingRight: 6,
  //                 }}>
  //                 <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{lableDropZone}</Text>
  //                 {_.isNil(fileUpload) ? null : (
  //                   <XIcon
  //                     name='checkcircle'
  //                     family='AntDesign'
  //                     style={{ flex: 0, minWidth: 25, width: 40, color: COLORS.GREEN, fontSize: STYLES.FONT_ICON_LARGER }}
  //                   />
  //                 )}
  //               </View>
  //             </div>
  //           </TouchableOpacity>
  //         )}
  //       </Dropzone>
  //     </View>
  //   )
  // }

  _renderBtnUpload = () => {
    const { fileUpload } = this.state
    const btnBGColor = _.isNil(fileUpload) ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN
    const btnBorderColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN
    const textBtnColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.WHITE
    return (
      <View style={{ width: '100%', height: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <TouchableOpacity
          onPress={() => this.onPressUpload()}
          disabled={_.isNil(fileUpload)}
          style={{
            width: 180,
            flexDirection: 'row',
            height: 30,
            borderColor: btnBorderColor,
            backgroundColor: btnBGColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XIcon
            name='upload'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <XText color={textBtnColor} pl='2'>
            อัพโหลด
          </XText>
        </TouchableOpacity>
        {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4, paddingRight: 4, paddingTop: 6 }}>
          {`(ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี ดังนั้นเมื่อกดปุ่มอัพโหลด กรุณาเปิดหน้าต่างนี้ทิ้งไว้จนกว่าจะเสร็จ)`}
        </Text> */}
      </View>
    )
  }

  renderLoading = () => {
    if (!this.state.isLoading) {
      return null
    }
    return (
      <View
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: '100%',
          height: 800,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}>
        <XSpinner />
      </View>
    )
  }

  callBackOnPressFooter = async (result: boolean) => {
    // console.log('result => ', result)
    if (!result) {
      this._stopLoading()
      return
    }
    await util.delay(500)
    this._stopLoading()
    this.onPressUpload()
  }

  render() {
    // console.log(` RENDER !! `, this.menuItems)
    const { fileUpload, isLoading } = this.state
    const fileSize = fileUpload && fileUpload.size ? fileUpload.size : null
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          <XCard p='2'>
            {this._renderDownloadTemplateView()}
            {this._renderDropzone()}
            {/* {this._renderBtnUpload()} */}
          </XCard>
        </XContent>
        <XFooterImportExcel
          fileSize={fileSize}
          // fileName={fileName}
          callBackOnPress={(result: boolean) => {
            this._startLoading()
            this.callBackOnPressFooter(result)
          }}
          isDisabled={isLoading}
        />
        {this.renderLoading()}
      </XContainer>
    )
  }
}

// <XContent
//   style={[
//     NO_MARGIN_PADDING,
//     {
//       flex: 1,
//       flexDirection: 'column',
//       // backgroundColor: 'tomato',
//     },
//   ]} />
