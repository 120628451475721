import React from 'react'
import { STYLES } from 'x/config/styles'

import { TouchableOpacity } from 'react-native'
import p from 'x/config/platform-specific'
import BaseReportShippingView from 'x/modules/report/BaseReportShippingView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Segment from 'xui/components/Segment'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import * as util from 'x/utils/util'

const { NO_MARGIN_PADDING } = STYLES

export default abstract class BaseUIReportShippingView extends BaseReportShippingView {
  static displayName = 'BaseUIReportShippingView'

  abstract _downloadReportShippingView(url: string, fileName: string): Promise<void>

  abstract _renderShippingDateRange: () => JSX.Element

  _handleOnDownloadFile = async (url: string, fileName: string) => {
    await this._downloadReportShippingView(url, fileName)
  }

  _getParams(): { store_id: number } {
    // return this.props.navigation.state.params
    const params = util.getNavParams(this.props)
    return params
  }

  _renderHeaderView = () => (
    <XCustomHeader title='รายงานออเดอร์ที่จัดส่งแล้ว' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
  )

  _renderSegment = (optKey: string) => {
    const txtTitle = this.TXT_TITLES[optKey]
    const options = this.OPTIONS[optKey]
    const selectedValue = this.state.selectedOpts[optKey]
    return (
      <Box mt={optKey === 'DATE_RANGE' ? '2' : '0'} w='full'>
        <Box>
          <XText variant='active' bold>
            {txtTitle}
          </XText>
        </Box>
        <Segment onChange={(newValue) => this._onChangeSegment(optKey, newValue)} options={options} selectedValue={selectedValue} />
      </Box>
    )
  }

  _renderTitleChackBoxView = (optKey: string) => {
    const txtTitle = this.TXT_CHECKBOX[optKey]
    return (
      <XText bold variant='active'>
        {txtTitle}
      </XText>
    )
  }

  _renderCheckBoxView = (optKey: string) => {
    const txtDetail = this.TXT_CHECKBOX[optKey]
    return (
      <TouchableOpacity
        key={optKey}
        onPress={() => {
          if (!this._canRequestMoreColumns()) {
            p.op.alert(
              p.op.t('Subscription.warning.insufficientTypeTitle'),
              `คุณยังสามารถดาวน์โหลดรายงานได้ แต่ไม่สามารถเลือกฟีเจอร์ตรงนี้ได้ ${p.op.t('Subscription.warning.insufficientTypeMsg')}`
            )
            return
          }
          // @ts-ignore
          this.setState({
            [optKey]: !this.state[optKey],
          })
        }}
        style={{ width: '100%', height: 30, flexDirection: 'row' }}>
        <Box style={{ width: 25 }}>
          <XIcon name={this.state[optKey] ? 'check-square-o' : 'square-o'} family='FontAwesome' />
        </Box>
        <Box style={{ flex: 1 }}>
          <XText textAlign='left' variant='active' allowFontScaling={false}>
            {txtDetail}
          </XText>
        </Box>
      </TouchableOpacity>
    )
  }

  _renderFooter() {
    const { loading } = this.state
    return (
      <HStack w='full' p='1'>
        <XButton
          w='full'
          py='3'
          disabled={loading}
          onPress={() => this._downloadReportShippingUrl()}
          leftIcon={<XIcon name='file-excel-o' family='FontAwesome' variant='primary' />}>
          ดาวน์โหลด Excel
        </XButton>
      </HStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeaderView()}
        <XContent p='2'>
          <XCard p='2' w='full'>
            {this._renderSegment(`LIST_BY`)}
            {this._renderSegment(`DATE_RANGE`)}
            {this._renderShippingDateRange()}
            <Box h='2' />
            {this._renderTitleChackBoxView(`TITLE`)}
            <HStack h='2' />
            {this._renderCheckBoxView(`SHOW_SHIPPING_TYPE`)}
            {this._renderCheckBoxView(`SHOW_TOTAL_AMOUNT`)}
            {this._renderCheckBoxView(`SHOW_TOTAL_QTY`)}
            {this._renderCheckBoxView(`SHOW_DATE_TIME`)}
            {this._renderCheckBoxView(`SHOW_HELPER_NAME`)}
          </XCard>
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
