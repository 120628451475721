import React from 'react'
import { HStack } from 'native-base'
import { View, TouchableOpacity, Linking } from 'react-native'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'

import { COLORS, STYLES } from 'x/config/styles'
// import ReportProductDailySales from '../report/ReportProductDailySales'
// import ReportPayment from '../report/ReportPayment'
// import ReportHelperSales from '../report/ReportHelperSales'
import XIcon from 'xui/components/XIcon'
import BaseImportWarehouseExcelFromShipjung from 'x/modules/importExcel/BaseImportWarehouseExcelFromShipjung'
import XCustomHeader from 'xui/components/XCustomHeader'

import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XFooterImportExcel from 'xui/components/XFooterImportExcel'
import XSpinner from 'xui/components/XSpinner'

export default abstract class BaseUIImportWarehouseExcelFromShipjung extends BaseImportWarehouseExcelFromShipjung {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  abstract _renderDropzone(): JSX.Element

  abstract onPressUpload(): void

  abstract renderCustomImgShipjung?: () => JSX.Element

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // const { mode } = this.state
    // const isEditMode = mode === EDIT
    const leftBtn = { backIcon: true, onPressItem: () => this._goBack() }
    // if (isEditMode) {
    //   // @ts-ignore
    //   leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    // }
    return (
      <XCustomHeader
        title={`เพิ่มจำนวน${p.op.t('qty')}ด้วย\nExcel จาก Shipjung`}
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _delayDownloadTemplate = async () => {
    util.setStatePromise(this, {
      disabledDownloadTemplate: true,
    })
    await util.delay(5000)
    util.setStatePromise(this, {
      disabledDownloadTemplate: false,
    })
  }

  _downloadTemplate = async () => {
    this._delayDownloadTemplate()
    // const storeId = this.props.selectedStore.get('id')
    // const url = util.getProductBarcodeUpdateTemplate({
    //   store_id: storeId,
    // })
    // // console.log('url => ', url)
    // const now = util.getTimeFormatYYYYMMDDhhmmss()
    // let fileName = `warehouse_update${storeId}_${now}.xlsx`
    // await this._handleOnDownloadFile(url, fileName)
    // const res = await api.getV2(api.GET_EXCEL_PRODUCT_IMPORT_TEMPLATE, apiOptions)
    // console.log('res => ', res)
    Linking.openURL('https://s1.xselly.com/x/samples/tp/shipjung_add_product_qty.xlsx')
  }

  _renderDownloadTemplateView = () => {
    const { disabledDownloadTemplate } = this.state
    let textBtnColor = COLORS.TEXT_ACTIVE
    let borderBtnColor = COLORS.APP_MAIN
    if (disabledDownloadTemplate) {
      textBtnColor = COLORS.TEXT_INACTIVE
      borderBtnColor = COLORS.TEXT_INACTIVE
    }
    return (
      <Box
        style={{
          width: '100%',
          justifyContent: 'center',
        }}>
        <XText variant='active'>
          {`ใช้เพิ่มจำนวน${p.op.t('qty')}สำหรับสินค้าทีละหลายรายการ ตาม SKU ที่ระบุไว้ใน Excel ข้อมูลการจัดส่งที่มาจาก Shipjung`}
        </XText>
        <XText variant='active' bold>
          ใช้ฟีเจอร์นี้ตอนไหน
        </XText>
        <XText variant='active'>
          {`หากคุณมีการส่งสินค้าออกจาก Shipjung เพื่อไปเติมจำนวน${p.op.t(
            'qty'
          )}ของร้านที่อยู่ใน XSelly คุณสามารถโหลด Excel สรุปรายการสินค้าและจำนวนที่จัดส่งได้จาก Shipjung และนำเข้าข้อมูลนี้ในร้านที่ต้องการเพิ่มจำนวน${p.op.t(
            'qty'
          )} `}
        </XText>
        <Box h='2' />
        <TouchableOpacity
          disabled={disabledDownloadTemplate}
          onPress={() => this._downloadTemplate()}
          style={{
            width: 260,
            flexDirection: 'row',
            height: 30,
            borderColor: borderBtnColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}>
          <XIcon
            name='exclefile1'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: borderBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <View style={{ width: 6 }} />
          {this.renderCustomImgShipjung()}
          <View style={{ width: 6 }} />
          <XText color={textBtnColor}>ดูตัวอย่างไฟล์ Excel</XText>
        </TouchableOpacity>
        <Box h='2' />
        <XText variant='active' bold>
          คำแนะนำในการงาน
        </XText>
        {/* <View style={{ height: 5 }} /> */}
        <XText variant='active'>
          {`- โปรดเช็กว่า SKU ที่ระบุใน Shipjung และ XSelly ตรงกัน เพราะ XSelly จะใช้ SKU ในการหาสินค้าที่จะเพิ่มจำนวน${p.op.t('qty')}ให้`}
        </XText>
        <XText variant='active'>{`- ไม่นำเข้าข้อมูลซ้ำจากไฟล์เดิม เพราะจะทำให้จำนวน${p.op.t('qty')}เพิ่มขึ้นเกินความเป็นจริง`}</XText>
        <XText variant='active' bold>
          ขั้นตอน
        </XText>
        <XText variant='active'>{`1) ที่หน้าเว็บ Shipjung โหลดไฟล์ Excel สำหรับการจัดส่งที่คุณต้องการมาใช้ในการเพิ่มจำนวน${p.op.t(
          'qty'
        )}`}</XText>
        <XText variant='active'>2) ที่ XSelly (หน้านี้) วาง Excel ไฟล์ไปบนกล่องเส้นประด้านล่าง หรือกดที่กล่องเพื่อเลือกไฟล์ Excel</XText>
        <XText variant='active'>3) กดปุ่ม "อัพโหลด"</XText>
      </Box>
    )
  }

  _renderBtnUpload = () => {
    const { fileUpload } = this.state
    const btnBGColor = _.isNil(fileUpload) ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN
    const btnBorderColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN
    const textBtnColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.WHITE
    return (
      <HStack flex={1} alignItems='center' justifyContent='center' mt='8' w='full'>
        <XButton
          disabled={_.isNil(fileUpload)}
          style={{
            flexDirection: 'row',
            borderColor: btnBorderColor,
            backgroundColor: btnBGColor,
            borderWidth: 1,
            marginRight: 4,
            marginLeft: 4,
            borderRadius: 7,
          }}
          onPress={() => this.onPressUpload()}>
          <XIcon
            name='upload'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER, fontWeight: 'bold' }}
          />
          <XText color={textBtnColor} bold>
            อัพโหลด
          </XText>
        </XButton>
      </HStack>
    )
  }

  renderLoading = () => {
    if (!this.state.isLoading) {
      return null
    }
    return (
      <View
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: '100%',
          height: 800,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}>
        <XSpinner />
      </View>
    )
  }

  callBackOnPressFooter = async (result: boolean) => {
    // console.log('result => ', result)
    if (!result) {
      this._stopLoading()
      return
    }
    await util.delay(500)
    this._stopLoading()
    this.onPressUpload()
  }

  render() {
    // console.log(` RENDER !! `, this.menuItems)
    const { fileUpload, isLoading } = this.state
    const fileSize = fileUpload && fileUpload.size ? fileUpload.size : null
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          <XCard p='2'>
            {this._renderDownloadTemplateView()}
            <Box h='2' />
            {this._renderDropzone()}
            {/* {this._renderBtnUpload()} */}
          </XCard>
        </XContent>
        <XFooterImportExcel
          fileSize={fileSize}
          // fileName={fileName}
          callBackOnPress={(result: boolean) => {
            this._startLoading()
            this.callBackOnPressFooter(result)
          }}
          isDisabled={isLoading}
        />
        {/* {this._renderFooter()} */}
        {this.renderLoading()}
      </XContainer>
    )
  }
}

// <Content
//   style={[
//     NO_MARGIN_PADDING,
//     {
//       flex: 1,
//       flexDirection: 'column',
//       // backgroundColor: 'tomato',
//     },
//   ]} />
