/* eslint-disable react/no-unused-state */
import React from 'react'
import { IProfile, IProfileMap, ISelectedStoreMap, ISubscriptionPackageList, IXSellyFile, SubscriptionPackageListItems } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import dayjs from 'dayjs'
import * as NavActions from 'x/utils/navigation'
import * as upload from 'x/utils/upload'

export interface ISelectedAddOn {
  sku: string
  billing_cycle: number
  qty: number
}

interface IBasePaymentSubscriptionViewProps {
  profile: IProfileMap
  selectedStore: ISelectedStoreMap
  subscription: any
  myStores: any
}

interface IBasePaymentSubscriptionViewState {
  subscriptionPackageList: ISubscriptionPackageList
  profile: IProfile

  selectedOptionRangePackageIndex: number

  selectedPackageItem: SubscriptionPackageListItems

  addOnPackages: ISelectedAddOn[]
}

export default abstract class BasePaymentSubscriptionView extends React.Component<
  IBasePaymentSubscriptionViewProps,
  IBasePaymentSubscriptionViewState
> {
  inProcess: boolean

  // eslint-disable-next-line react/no-unused-class-component-methods
  // @ts-ignore
  carouselRef: React.useRef<any>

  PACKAGE_SKU_CYCLE_1: string[]

  PACKAGE_SKU_CYCLE_12: string[]

  ADD_ON_COUNTSTANT: {
    title: string
    addOnKey: string
    cutSku: string
  }[]

  constructor(props) {
    super(props)
    this.state = {
      subscriptionPackageList: null,
      profile: null,
      selectedOptionRangePackageIndex: 3,
      selectedPackageItem: null,
      addOnPackages: null,
    }

    this.inProcess = false

    this.ADD_ON_COUNTSTANT = [
      {
        title: 'ออเดอร์',
        addOnKey: 'order_count_quota',
        cutSku: 'ADDON-QUOTA-ORDER',
      },
      {
        title: 'ช่องทางขาย',
        addOnKey: 'mkp_quota',
        cutSku: 'ADDON-QUOTA-MKP',
      },
      {
        title: 'กลุ่มสมาชิก',
        addOnKey: 'ug_quota',
        cutSku: 'ADDON-QUOTA-UG',
      },
      {
        title: 'รายการราคา',
        addOnKey: 'pg_quota',
        cutSku: 'ADDON-QUOTA-PG',
      },
      {
        title: 'ผู้ช่วย',
        addOnKey: 'helper_count',
        cutSku: 'ADDON-QUOTA-HELPER',
      },
      {
        title: 'ร้านค้า',
        addOnKey: 'store_count',
        cutSku: 'ADDON-QUOTA-STORE',
      },
      // {
      //   title: 'คลัง',
      //   addOnKey: 'wh_quota',
      //   cutSku: 'ADDON-QUOTA-WH',
      // },
    ]
  }

  async componentDidMount() {
    await this._getSubscriptionPackageList()
    await this._setProfile()
  }

  _getSubscriptionPackageList = async () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { subscriptionPackageList, sku } = params
    // console.log('subscriptionPackageList => ', subscriptionPackageList)
    // console.log('sku => ', sku)
    let selectedPackageItem = null
    const { items } = subscriptionPackageList
    if (!_.isNil(items)) {
      items.forEach((item) => {
        if (item.sku === sku) {
          selectedPackageItem = item
        }
      })
    }
    await util.setStatePromise(this, {
      subscriptionPackageList,
      selectedPackageItem,
    })
  }

  // _fetchSubscriptionPackageList = async () => {
  //   const apiOptions = {
  //     showSpinner: true,
  //   }
  //   let PURCHASABLES_ID = CONS.PURCHASABLES_ID.SCB
  //   if (p.op.isIOS()) {
  //     PURCHASABLES_ID = CONS.PURCHASABLES_ID.IOS
  //   }
  //   const body = {
  //     lang: 'th',
  //     purchase_channel_id: PURCHASABLES_ID,
  //   }
  //   try {
  //     const res = await api.postV2(api.POST_SUBSCRIPTION_PACKAGE_LIST, body, apiOptions)
  //     // console.log('_fetchSubscriptionPackageList res => ', res)
  //     await util.setStatePromise(this, {
  //       subscriptionPackageList: res,
  //       isLoading: false,
  //     })
  //     // if (!res || _.isEmpty(res)) {
  //     // }
  //   } catch (err) {}
  // }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _setProfile = async (): Promise<void> => {
    const { profile } = this.props
    const profileJS = profile?.toJS() || null
    await util.setStatePromise(this, {
      profile: profileJS,
    })
  }

  // _getAddOnDataByCutSku = (cutSku: string) => {
  //   const { subscriptionPackageList, addOnPackages } = this.state
  //   if (_.isNil(addOnPackages)) {
  //     return null
  //   }

  //   let fullSku = null
  //   addOnPackages.forEach((addOn) => {
  //     const { sku } = addOn
  //     const isPrefix = sku.startsWith(cutSku)
  //     if (isPrefix) {
  //       fullSku = sku
  //     }
  //   })

  //   if (_.isNil(fullSku)) {
  //     return null
  //   }

  //   const { items } = subscriptionPackageList
  //   let jsonData = null
  //   items.forEach((item) => {
  //     const { sku } = item
  //     if (sku === fullSku) {
  //       jsonData = item
  //     }
  //   })
  //   return jsonData
  // }

  _getAddOnDataByCutSku = (cutSku: string) => {
    const { subscriptionPackageList, addOnPackages } = this.state

    // If addOnPackages is null or undefined, return null.
    if (_.isNil(addOnPackages)) {
      return null
    }

    // Use the find method to locate the first add-on package whose SKU starts with cutSku.
    const fullSku = addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku))?.sku

    // If no matching SKU is found, return null.
    if (_.isNil(fullSku)) {
      return null
    }

    // Use the find method to locate the item in subscriptionPackageList with the matching SKU.
    const jsonData = subscriptionPackageList.items.find((item) => item.sku === fullSku) || null

    return jsonData
  }

  // This function retrieves the selected add-on package based on the provided cutSku.
  _getAddOnSelectedData = (cutSku: string) => {
    const { addOnPackages } = this.state

    // If addOnPackages is null or undefined, return null.
    if (_.isNil(addOnPackages)) {
      return null
    }

    // Use the find method to locate the first add-on package whose SKU starts with cutSku.
    // If no matching add-on is found, return null.
    return addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku)) || null
  }

  _getAddOnSelectedDataBySku = (sku: string) => {
    const { subscriptionPackageList } = this.state
    return subscriptionPackageList.items.find((item) => item.sku === sku) || null
  }

  _getStartAndEndBillingCycle = (billingCycle: number) => {
    const { subscriptionPackageList } = this.state
    const { future_billing_dates } = subscriptionPackageList
    const { start } = future_billing_dates[0]
    const { end } = future_billing_dates[billingCycle - 1]
    return `${dayjs(start, 'YYYY-MM-DD').format('D MMM YYYY')} - ${dayjs(end, 'YYYY-MM-DD').format('D MMM YYYY')}`
  }

  _callBackFormSelectedAddOn = (addOnSelectedList: ISelectedAddOn[], cutSku: string) => {
    // console.log('addOnSelectedList => ', addOnSelectedList)
    const { addOnPackages } = this.state
    if (_.isNil(addOnPackages) || addOnPackages.length === 0) {
      this.setState({ addOnPackages: addOnSelectedList })
    } else if (_.isNil(addOnSelectedList) || addOnSelectedList.length === 0) {
      const newAddOnPackages = addOnPackages.filter((addOn) => !addOn.sku.startsWith(cutSku))
      this.setState({ addOnPackages: newAddOnPackages })
    } else {
      const oldAddOnPackages = _.cloneDeep(addOnPackages)
      const newAddOnPackages = oldAddOnPackages.filter((addOn) => !addOn.sku.startsWith(cutSku))
      addOnSelectedList.forEach((addOnSelected) => {
        newAddOnPackages.push(addOnSelected)
      })
      this.setState({ addOnPackages: newAddOnPackages })
    }
  }

  _onPressAddOn = (shortSku: string) => {
    // console.log('shortSku => ', shortSku)
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionPackageList, selectedPackageItem } = this.state
    navigation.dispatch(
      NavActions.navToSelectAddOnOrderView({
        subscriptionPackageList,
        sku: shortSku,
        selectedPackage: selectedPackageItem,
        callBackFromSelectedAddOn: (addOnSelectedList, cutSku) => this._callBackFormSelectedAddOn(addOnSelectedList, cutSku),
        selectedAddOn: null,
      })
    )
  }

  _onPressEditAddOn = (shortSku: string) => {
    // console.log('shortSku => ', shortSku)
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionPackageList, selectedPackageItem } = this.state
    const addOnFromCutSku = this._getAddOnSelectedListData(shortSku)
    navigation.dispatch(
      NavActions.navToSelectAddOnOrderView({
        subscriptionPackageList,
        sku: shortSku,
        selectedPackage: selectedPackageItem,
        callBackFromSelectedAddOn: (addOnSelectedList, cutSku) => this._callBackFormSelectedAddOn(addOnSelectedList, cutSku),
        selectedAddOn: addOnFromCutSku,
      })
    )
  }

  _getSelectedAddOn = (sku: string) => {
    if (_.isNil(sku)) {
      return null
    }
    const { subscriptionPackageList } = this.state
    const { items } = subscriptionPackageList
    let selectedAddOn = null
    items.forEach((item) => {
      if (item.sku === sku) {
        selectedAddOn = item
      }
    })
    return selectedAddOn
  }

  _getAddOnSelectedListData = (cutSku: string) => {
    const { addOnPackages } = this.state

    if (_.isNil(addOnPackages)) {
      return null
    }

    const listAddOn = []
    // addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku)) || null
    addOnPackages.forEach((addOn) => {
      const { sku } = addOn
      const isPrefix = sku.startsWith(cutSku)
      if (isPrefix) {
        listAddOn.push(addOn)
      }
    })
    return listAddOn
  }

  _getAddOnCount = (addOn: { title: string; addOnKey: string; cutSku: string }) => {
    const { addOnPackages } = this.state
    let countAddOn = 0
    if (_.isNil(addOnPackages)) {
      return countAddOn
    }
    const addOnFromCutSku = this._getAddOnSelectedListData(addOn.cutSku)
    addOnFromCutSku.forEach((addOnSelected) => {
      const getAddOn = this._getAddOnSelectedDataBySku(addOnSelected.sku)
      const countQty = getAddOn.data_json[addOn.addOnKey]
      countAddOn += countQty
    })
    return countAddOn
  }

  _onTestUploadPress = async () => {
    // @ts-ignore มันคือ IXSellyFile แต่ไม่ได้ประกาศใน state
    const { selectedFile, uploadedUrl } = this.state
    console.log('_onTestUploadPress selectedFile', selectedFile)

    try {
      console.log('_onTestUploadPress file', selectedFile)
      const uploadResponse = await upload.uploadFile(selectedFile as IXSellyFile, { fileName: 'yoyoyo', folderPath: 'test/' })

      // @ts-ignore
      this.setState({ uploadedUrl: uploadResponse.url })
      console.log('_onTestUploadPress uploadResponse', uploadResponse)
    } catch (error) {
      console.log('_onTestUploadPress error', error)
    }
  }
}
