import React from 'react'
import _ from 'lodash'
import { HStack } from 'native-base'
import { COLORS } from 'x/config/styles'
import { TouchableOpacity } from 'react-native'
import XText from './XText'
import VStack from './VStack'

export interface IOptionsCapsuleSegment {
  text: string
  labelView?: JSX.Element
}

export interface IXCapsuleSegmentProps {
  disabled?: boolean
  sectedIndex: number
  options: IOptionsCapsuleSegment[]
  onSegmentChange: (selectedIndex: number) => void
}

function XCapsuleSegment(props: IXCapsuleSegmentProps) {
  const { options, onSegmentChange, disabled, sectedIndex } = props
  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(sectedIndex > options.length - 1 ? 0 : sectedIndex)
  const [disabledOnPress, setDisabledOnPress] = React.useState(disabled)

  React.useEffect(() => {
    setDisabledOnPress(disabled)
  }, [disabled])

  React.useEffect(() => {
    setSelectedSegmentIndex(sectedIndex)
  }, [sectedIndex])

  const onPressBtn = (index: number) => {
    if (disabledOnPress) {
      return
    }
    setSelectedSegmentIndex(index)
    onSegmentChange(index)
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const RENDER_SELECTED_VIEW = (option: IOptionsCapsuleSegment, index: number) => {
    const selected = index === selectedSegmentIndex
    const BG_COLOR = selected ? COLORS.APP_MAIN : COLORS.WHITE
    const FONT_COLOR = selected ? COLORS.WHITE : '#4365e1'
    return (
      <VStack flex={1} key={`RENDER_SELECTED_VIEW_${index}`}>
        <TouchableOpacity
          style={{
            backgroundColor: BG_COLOR,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 90,
            flex: 1,
            marginTop: 4,
            marginBottom: 4,
          }}
          onPress={() => onPressBtn(index)}>
          <XText color={FONT_COLOR}>{option.text}</XText>
        </TouchableOpacity>
        <VStack style={{ position: 'absolute', top: -30, right: 5 }}>{option?.labelView ? option.labelView : null}</VStack>
      </VStack>
    )
  }

  if (_.isNil(options) || !_.isArray(options)) {
    return null
  }

  return (
    <HStack w='full' h='12' borderRadius={90} px='2' bgColor={COLORS.WHITE} borderWidth='1' borderColor={COLORS.BG_LIGHT_GREY}>
      {options.map((option, index) => RENDER_SELECTED_VIEW(option, index))}
    </HStack>
  )
}

XCapsuleSegment.defaultProps = {
  // initialIndex: 0,
  disabled: false,
}

export default XCapsuleSegment
