import React from 'react'
import BaseUIimportReduceProductWarehouseBySKU from 'xui/modules/importExcel/BaseUIimportReduceProductWarehouseBySKU'
import { View, TouchableOpacity } from 'react-native'
import Dropzone from 'react-dropzone'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import _ from 'lodash'
import XIcon from 'xui/components/XIcon'
import { COLORS, STYLES } from 'x/config/styles'
import { getConfig } from 'x/config/mode'
import axios from 'axios'
import * as util from 'x/utils/util'
import XText from 'xui/components/XText'
import { downloadFileFromUrl } from '../../utils/util-web'

export default class importReduceProductWarehouseBySKU extends BaseUIimportReduceProductWarehouseBySKU {
  _handleOnDownloadFile = async (url: string, fileName: string) => {
    await downloadFileFromUrl({ url, fileName })
  }

  _renderDropzone = () => {
    const { fileUpload } = this.state
    const lableDropZone = _.isNil(fileUpload) ? `วาง Excel ไฟล์ไว้ตรงนี้\nหรือกดตรงนี้เพื่อเลือกไฟล์` : `ไฟล์ที่เลือก\n"${fileUpload.name}"`
    // const lableDropZone = `TEST`
    const borderStyle = _.isNil(fileUpload) ? 'dashed' : 'solid'
    return (
      <View style={{ width: '100%', height: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <Dropzone
          onDrop={(acceptedFiles) => {
            // @ts-ignore
            this._setFileUpload(acceptedFiles)
          }}
          accept='.xls, .xlsx'
          multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <TouchableOpacity style={{ width: `100%`, paddingLeft: 4, paddingRight: 4, paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ backgroundColor: COLORS.BG_LIGHT_GREY, borderRadius: 10 }} {...getRootProps()}>
                <input {...getInputProps()} />
                <View
                  style={{
                    height: 150,
                    width: '100%',
                    borderWidth: 0.8,
                    borderColor: COLORS.APP_MAIN,
                    borderStyle,
                    borderRadius: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}>
                  <XText variant='active' textAlign='center'>
                    {lableDropZone}
                  </XText>
                  {_.isNil(fileUpload) ? null : (
                    <XIcon
                      name='checkcircle'
                      family='AntDesign'
                      style={{ flex: 0, minWidth: 25, width: 40, color: COLORS.GREEN, fontSize: STYLES.FONT_ICON_LARGER }}
                    />
                  )}
                </View>
              </div>
            </TouchableOpacity>
          )}
        </Dropzone>
      </View>
    )
  }

  onPressUpload = async () => {
    const { selectedStore } = this.props
    const { fileUpload } = this.state
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const formData = new FormData()

    // Array.from(this.state.fileUpload).forEach(image => {
    // // @ts-ignore
    //   formData.append('file', image)
    // })
    // @ts-ignore
    formData.append('file', fileUpload)
    formData.append('store_id', `${selectedStore.get('id')}`)
    formData.append('o', 's')
    // formData.append('store_id', `99999999`)
    // console.log(`formData`, formData)
    const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
    // console.log(`TOKEN => `, `Bearer ${token}`)
    // return
    const path = `excel/import/product_stock_qty_edit`
    const url = getConfig()
    axios
      .post(`${url.app_goApiRoot}/${path}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        },
      })
      .then(async (res) => {
        // @ts-ignore res
        // console.log('bgjob_uuid => ', res.data.bgjob_uuid)
        // console.log(`res => `, res)
        if (res.data && res.data.bgjob_uuid) {
          await util.alertWithNavToBgJobListView({
            title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
            message: `คิวคำสั่งงาน สำหรับการ "ลดจำนวน${p.op.t(
              'qty'
            )}ตาม SKU" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์`,
            dispatch: this.props.navigation.dispatch,
            selectedStoreId: this.props.selectedStore.get('id'),
            onUserConfirm: () => this._goBack(),
            initBgJobUUID: res.data.bgjob_uuid,
          })
          this.inProcess = false
        } else {
          this.inProcess = false
          // p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${res}`)
          p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', p.op.t(`e_msg_by_error_code.${res.data.error.error_code}.m`))
        }
      })
      .catch(async (err) => {
        // console.log('err => ', JSON.stringify(err))
        // console.log('err => ', JSON.stringify(err.response.data))
        // console.log(`res 02 => `, err.response.data)
        const errorCode = !_.isNil(err.response) && !_.isNil(err.response.data) ? err.response.data.error.error_code : null
        if (!_.isNil(errorCode)) {
          const textErrorCode = util.getBgTaskErrorText(errorCode)
          p.op.showConfirmationOkOnly('', textErrorCode)
          this.inProcess = false
          return
        }
        p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${err}`)
        this.inProcess = false
      })
  }
}
