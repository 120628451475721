import React from 'react'
import { IXDocumentDropzoneProps, IXDocumentDropzoneState } from 'x/types'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import _ from 'lodash'
import p from 'x/config/platform-specific'

export default class XDocumentDropzone extends React.Component<IXDocumentDropzoneProps, IXDocumentDropzoneState> {
  static displayName = 'XDocumentDropzone'

  dropzoneRef: DropzoneRef

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
    }

    this.dropzoneRef = null
  }

  componentDidMount(): void {
    this.setState({ isInitialized: true })
  }

  _onFileDrop = (acceptedFiles: File[], rejectedFiles: File[]) => {
    this._doFileDrop(acceptedFiles, rejectedFiles)
  }

  _doFileDrop = async (acceptedFiles: File[], rejectedFiles: File[]) => {
    // console.log('_doFileDrop evt.target', evt.target)
    // console.log('_doFileDrop acceptedFiles', acceptedFiles)
    // console.log('_doFileDrop rejectedFiles', rejectedFiles)

    if (_.isArray(rejectedFiles) && rejectedFiles.length > 0) {
      const rejectedFileName = rejectedFiles[0].name ? rejectedFiles[0].name : null
      p.op.alert(`ไม่สามารถอ่านไฟล์ ${rejectedFileName}`, 'กรุณาเลือกไฟล์ใหม่')
      return
    }

    if (acceptedFiles.length > 0) {
      try {
        const { onFileSelect } = this.props
        const xFile = await this._getXFile(acceptedFiles[0])
        onFileSelect(xFile)
      } catch (error) {
        console.log('_doFileDrop error', error)
      }
    }
  }

  _openDocumentPicker = () => {
    try {
      this.dropzoneRef.open()
      // console.log('_openDocumentPicker open')
    } catch (err) {
      console.log('__openDocumentPicker err', err)
    }
  }

  _setDropzoneRef = (ref) => ref && (this.dropzoneRef = ref)

  _getXFile = async (file: File) => {
    const base64 = await this._getImageBase64FromFiles(file)
    if (!base64) {
      throw new Error('Cannot convert file to base64')
    }

    const mime = this._getbase64MimeType(base64)
    return { data: base64.split(',')[1], mime, name: file.name }
  }

  // Ref => https://miguelmota.com/bytes/base64-mime-regex/
  _getbase64MimeType = (encoded: string) => {
    const dataType = encoded.split(';')[0]
    return dataType && dataType.split(':')[1] ? dataType.split(':')[1] : null
  }

  _getImageBase64FromFiles = async (file: File): Promise<string | null> => {
    const reader = new FileReader()
    return new Promise<string | null>((resolve: any) => {
      reader.readAsDataURL(file)
      // @ts-ignore
      reader.onloadend = (rd, evt) => {
        // console.log('_getImageBase64FromFiles rd => ', rd)
        // console.log('_getImageBase64FromFiles evt => ', evt)
        resolve(reader.result)
      }

      reader.onerror = (error) => {
        console.log('_getImageBase64FromFiles error => ', error)
        resolve(null)
      }

      setTimeout(() => resolve(null), 12000) // Don't convert more than 12 sec
    })
  }

  _renderContent = ({ isDragActive }) => {
    const { renderContent } = this.props

    return renderContent({ isDragActive, openDocumentPicker: this._openDocumentPicker })
  }

  _getAccept = () => {
    const { acceptFileTypes } = this.props

    const acceptArr = []

    if (acceptFileTypes.indexOf('image') > -1) {
      acceptArr.push('image/*')
    }

    if (acceptFileTypes.indexOf('pdf') > -1) {
      acceptArr.push('application/pdf')
    }

    return acceptArr
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    // const { children } = this.props

    return (
      <Dropzone
        // key='SingleImageDropzone'
        ref={this._setDropzoneRef}
        noClick
        // accept='image/jpeg, image/jpg, image/png, image/bmp'
        accept={this._getAccept()}
        // noDrag={!canAddMore}
        onDrop={this._onFileDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} style={{ backgroundColor: isDragActive ? 'red' : 'white' }}>
            <input {...getInputProps()} type='file' style={{ display: 'none' }} multiple={false} />
            {/* {children} */}
            {this._renderContent({ isDragActive })}
          </div>
        )}
      </Dropzone>
    )

    // return <TouchableOpacity onPress={this._openDocumentPicker}>{children}</TouchableOpacity>
  }
}
