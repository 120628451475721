import React from 'react'
import _ from 'lodash'
import * as xNavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IXShippingConfig, IXShippingSystemAccount } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

interface IResponse {
  link_account_url: string
}

export interface BaseStoreSettingXShippingSpxViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
  getXshippingSystemAccountFromRedux: IXShippingSystemAccount[]
}

export interface BaseStoreSettingXShippingSpxViewState {
  // Flash X Shipjung
  useSpxxShipjung: boolean
  useAutoXShippingIsSpxxShipjungReadyToShip: boolean
  useSpxxShipjungCOD: boolean
  useAutoXShippingIsSpxxShipjungCODReadyToShip: boolean

  loadingSpxxShipjungWidth: number
  loadingSpxxShipjungHeight: number
  isSpxxShipjungLoading: boolean
  loadingSpxxShipjungCODWidth: number
  loadingSpxxShipjungCODHeight: number
  isSpxxShipjungCODLoading: boolean

  // Spx
  useSpx: boolean
  useAutoXShippingIsSpxReadyToShip: boolean
  useSpxCOD: boolean
  useAutoXShippingIsSpxCODReadyToShip: boolean
  useAutoCreateCODPaymentWhenDelivered: boolean

  loadingSpxWidth: number
  loadingSpxHeight: number
  isSpxLoading: boolean
  loadingSpxCODWidth: number
  loadingSpxCODHeight: number
  isSpxCODLoading: boolean

  isRenderWebView: boolean
  isSpxLinkAccount: string | null

  spxConnectedData: IXShippingSystemAccount
  userId: string | number
}

const STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE = {
  useSpxxShipjung: 'useSpxxShipjung',
  useAutoXShippingIsSpxxShipjungReadyToShip: 'useAutoXShippingIsSpxxShipjungReadyToShip',
  useSpxxShipjungCOD: 'useSpxxShipjungCOD',
  useAutoXShippingIsSpxxShipjungCODReadyToShip: 'useAutoXShippingIsSpxxShipjungCODReadyToShip',
}

const STATE_KEY_SPX_FOR_UPDATE = {
  useSpx: 'useSpx',
  useAutoXShippingIsSpxReadyToShip: 'useAutoXShippingIsSpxReadyToShip',
  useSpxCOD: 'useSpxCOD',
  useAutoXShippingIsSpxCODReadyToShip: 'useAutoXShippingIsSpxCODReadyToShip',
  useAutoCreateCODPaymentWhenDelivered: 'useAutoCreateCODPaymentWhenDelivered',
}

export default abstract class BaseStoreSettingXShippingSpxView extends React.Component<
  BaseStoreSettingXShippingSpxViewProps,
  BaseStoreSettingXShippingSpxViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  webview: React.RefObject<any>

  protected constructor(props) {
    super(props)

    this.state = {
      // Spx X Shipjung
      useSpxxShipjung: false,
      useAutoXShippingIsSpxxShipjungReadyToShip: true,
      loadingSpxxShipjungWidth: 0,
      loadingSpxxShipjungHeight: 0,
      isSpxxShipjungLoading: false,
      useSpxxShipjungCOD: false,
      useAutoXShippingIsSpxxShipjungCODReadyToShip: true,
      // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: true,
      loadingSpxxShipjungCODWidth: 0,
      loadingSpxxShipjungCODHeight: 0,
      isSpxxShipjungCODLoading: false,
      // Spx
      useSpx: false,
      useAutoXShippingIsSpxReadyToShip: false,
      useSpxCOD: false,
      useAutoXShippingIsSpxCODReadyToShip: false,
      useAutoCreateCODPaymentWhenDelivered: false,

      loadingSpxWidth: 0,
      loadingSpxHeight: 0,
      isSpxLoading: false,
      loadingSpxCODWidth: 0,
      loadingSpxCODHeight: 0,
      isSpxCODLoading: false,

      isRenderWebView: false,
      isSpxLinkAccount: null,

      spxConnectedData: null,
      userId: null,
    }
    this.webview = React.createRef()
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this._setIsConnectedSpx()
    await this.setSpxConfig(getXShippingConfigFromRedux)
  }

  _setIsConnectedSpx = async () => {
    const { getXshippingSystemAccountFromRedux } = this.props
    let spxConnectedData = null
    if (!_.isNil(getXshippingSystemAccountFromRedux)) {
      getXshippingSystemAccountFromRedux.forEach((config: IXShippingSystemAccount) => {
        if (config.system_id === 11) {
          spxConnectedData = config
        }
      })
    }
    await util.setStatePromise(this, { spxConnectedData })
  }

  setSpxConfig = async (config: IXShippingConfig[]) => {
    // console.log('config => ', config)
    if (_.isNil(config)) {
      return
    }

    if (_.isArray(config) && config.length > 0) {
      // Spx x Shipjung
      let useSpxxShipjung = false
      let useAutoXShippingIsSpxxShipjungReadyToShip = false
      let useSpxxShipjungCOD = false
      let useAutoXShippingIsSpxxShipjungCODReadyToShip = false
      // Spx
      let useSpx = false
      let useAutoXShippingIsSpxReadyToShip = false
      let useSpxCOD = false
      let useAutoXShippingIsSpxCODReadyToShip = false
      let useAutoCreateCODPaymentWhenDelivered = false

      const userId = null

      const shipjungSystem = 2
      // const flashSystem = 6

      config.map((config: IXShippingConfig) => {
        if (config.shipping_type_id === 58) {
          if (config.system_id === shipjungSystem) {
            // Spx x Shipjung
            useSpxxShipjung = true
            useAutoXShippingIsSpxxShipjungReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
          } else {
            // Flash
            useSpx = true
            useAutoXShippingIsSpxReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
          }
        } else if (config.shipping_type_id === 59) {
          if (config.system_id === shipjungSystem) {
            // Spx x Shipjung COD
            useSpxxShipjungCOD = true
            useAutoXShippingIsSpxxShipjungCODReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
          } else {
            // Flash
            useSpxCOD = true
            useAutoXShippingIsSpxCODReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
            useAutoCreateCODPaymentWhenDelivered = config.create_cod_payment_when_delivered
              ? config.create_cod_payment_when_delivered
              : false
          }
        }
      })
      await util.setStatePromise(this, {
        // Spx x Shipjung
        useSpxxShipjung,
        useAutoXShippingIsSpxxShipjungReadyToShip,
        useSpxxShipjungCOD,
        useAutoXShippingIsSpxxShipjungCODReadyToShip,
        // Spx
        useSpx,
        useAutoXShippingIsSpxReadyToShip,
        useSpxCOD,
        useAutoXShippingIsSpxCODReadyToShip,
        useAutoCreateCODPaymentWhenDelivered,

        userId,
      })
    }
  }

  useJAndTOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useJAndT: newValue,
      isJAntTLoading: true,
    })
  }

  // useAutoXShippingIsJAndTReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTReadyToShip: newValue,
  //   })
  // }

  // useJAndTCODOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useJAndTCOD: newValue,
  //   })
  // }

  // useAutoXShippingIsJAndTCODReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTCODReadyToShip: newValue,
  //   })
  // }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('newValue => ', newValue)
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const { useSpx, useSpxCOD, useSpxxShipjung, useSpxxShipjungCOD } = this.state
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    // console.log('STATE_KEY => ', STATE_KEY)
    if (STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjung) {
      // กดเปิดใช้งาน Spx x Shipjung
      if (useSpx && IS_VALUE) {
        p.op.showConfirmationOkOnly('', 'หากต้องการใช้งานเชื่อม SPX ผ่าน Shipjung กรุณาปิดการใช้งานเชื่อมตรง SPX')
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
    }
    if (STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSpxxShipjungReadyToShip) {
      await util.setStatePromise(this, {
        isSpxxShipjungLoading: true,
      })
      body.shipping_type_id = 59
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjungCOD) {
      // กดเปิดใช้งาน SPX COD x ShipjungCOD
      if (useSpxCOD && IS_VALUE) {
        p.op.showConfirmationOkOnly('', 'หากต้องการใช้งานเชื่อม SPX COD ผ่าน Shipjung กรุณาปิดการใช้งานเชื่อมตรง SPX COD')
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
    } else if (STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSpxxShipjungCODReadyToShip) {
      await util.setStatePromise(this, {
        isSpxxShipjungCODLoading: true,
      })
      body.shipping_type_id = 59
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
      // NEXT FOR SPX DIRECT
    } else if (STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpx) {
      // ถ้าเปิดใช้งาน SPX
      if (useSpxxShipjung && IS_VALUE) {
        p.op.showConfirmationOkOnly(
          '',
          'หากต้องการเปิดการใช้งานการเชื่อมต่อกับ\n"SPX Express โดยตรง"\n\nกรุณาปิดการใช้งานการเชื่อมต่อ\n"ขอเลขพัสดุจาก Shopee Express ผ่าน Shipjung" ก่อน'
        )
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isSpxLoading: true,
      })
      body.shipping_type_id = 58
      body.system_id = 11
    } else if (STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useAutoXShippingIsSpxReadyToShip) {
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isSpxLoading: true,
      })
      body.shipping_type_id = 58
      body.system_id = 11
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpxCOD) {
      // กดเปิดใช้งาน SPX COD
      if (useSpxxShipjungCOD && IS_VALUE) {
        p.op.showConfirmationOkOnly('', 'หากต้องการใช้งานเชื่อม SPX COD โดยตรง กรุณาปิดการใช้งาน ขอเลขพัสดุ SPX COD ผ่าน Shipjung')
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isSpxCODLoading: true,
      })
      body.shipping_type_id = 59
      body.system_id = 11
    } else if (STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useAutoXShippingIsSpxCODReadyToShip) {
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isSpxCODLoading: true,
      })
      body.shipping_type_id = 59
      body.system_id = 11
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
      // OH NOT FOUND
    } else if (STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isSpxCODLoading: true,
      })
      body.shipping_type_id = 59
      body.system_id = 11
      body.create_cod_payment_when_delivered = IS_VALUE
      // OH NOT FOUND
    } else {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่พบขนส่ง กรุณาติดต่อทีมงาน')
      return
    }
    const isMainBtn =
      STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpx ||
      STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpxCOD ||
      STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjung ||
      STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjungCOD
    // console.log('isMainBtn => ', isMainBtn)
    // console.log('STATE_KEY => ', STATE_KEY)
    // console.log('IS_VALUE => ', IS_VALUE)
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    } else {
      const { getXShippingConfigFromRedux } = this.props
      let createMode = true
      // ปุ่มที่กดคือ Spx โดยตรง
      const Spx = STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpx
      const SpxCOD = STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpxCOD
      // ปุ่มที่กดคือ Spx x Shipjung
      const SpxxShipjung = STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjung
      const SpxxShipjungCOD = STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjungCOD
      // ตรวจสอบว่าเป็น mode create หรือ edit
      getXShippingConfigFromRedux.map((config: IXShippingConfig) => {
        if (config.system_id === 11 && Spx && config.shipping_type_id === 58) {
          createMode = false
        } else if (config.system_id === 11 && SpxCOD && config.shipping_type_id === 59) {
          createMode = false
        } else if (config.system_id === 2 && SpxxShipjung && config.shipping_type_id === 58) {
          createMode = false
        } else if (config.system_id === 2 && SpxxShipjungCOD && config.shipping_type_id === 59) {
          createMode = false
        }
      })
      if (isMainBtn && createMode) {
        apiMethod = api.putV2
        body.set_auto_request_xshipping_on_create_order = true
        body.create_cod_payment_when_delivered = true
      }
    }
    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setSpxConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    if (
      STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjung ||
      STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSpxxShipjungReadyToShip
    ) {
      await util.setStatePromise(this, {
        isSpxxShipjungLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useSpxxShipjungCOD ||
      STATE_KEY === STATE_KEY_SPX_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSpxxShipjungCODReadyToShip
    ) {
      await util.setStatePromise(this, {
        isSpxxShipjungCODLoading: false,
      })
    } else if (STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpx || STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useAutoXShippingIsSpxReadyToShip) {
      await util.setStatePromise(this, {
        isSpxLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useSpxCOD ||
      STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useAutoXShippingIsSpxCODReadyToShip ||
      STATE_KEY === STATE_KEY_SPX_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered
    ) {
      await util.setStatePromise(this, {
        isSpxCODLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    await this._setNewConfig(newValue)
  }

  _setCallBackXshippingSystemAccount = async (systemAccounts) => {
    // console.log('systemAccounts xx => ', systemAccounts)
    let spxConnectedData = null
    if (!_.isNil(systemAccounts)) {
      systemAccounts.forEach((config: IXShippingSystemAccount) => {
        if (config.system_id === 11) {
          spxConnectedData = config
        }
      })
    }
    await util.setStatePromise(this, { spxConnectedData })
  }

  _navToStoreSettingSpx = () => {
    const { getXShippingConfigFromRedux } = this.props
    let spxConfig = getXShippingConfigFromRedux.filter((config: IXShippingConfig) => config.shipping_type_id === 58)
    if (_.isNil(spxConfig) || spxConfig.length === 0) {
      spxConfig = getXShippingConfigFromRedux.filter((config: IXShippingConfig) => config.shipping_type_id === 59)
    }
    if (_.isNil(spxConfig) || spxConfig.length === 0) {
      spxConfig = null
    }
    this.props.navigation.dispatch(
      xNavActions.navToStoreSettingSpx({
        spxConfig: !_.isNil(spxConfig) ? spxConfig[0] : null,
        succeedCallback: (newConfig, systemAccounts) => {
          this.setSpxConfig(newConfig)
          this._setCallBackXshippingSystemAccount(systemAccounts)
        },
      })
    )
  }

  // getLinkAccount = async () => {
  //   const { selectedStore } = this.props
  //   // const body: IAnyObject = { ... }
  //   const response = await api.get<IResponse>(`${api.GET_FLASH_LINK_ACCOUNT}?store_id=${selectedStore.get('id')}`, { isApiV2: true })
  //   // console.log('response => ', response)
  //   if (response.link_account_url) {
  //     p.op.showConfirmationOkOnly(
  //       'ผูกเบอร์โทรศัพท์เพื่อเชื่อมต่อ',
  //       'ในหน้าถัดไป กรุณากรอกเบอร์โทรศัพท์ที่คุณ*ลงทะเบียนไว้กับ Flash* (ซึ่งใช้ได้ทั้งจากบัญชีธุรกิจ หรือ บัญชีที่สร้างผ่านแอป Flash) เพื่อให้ XSelly ใช้รหัสบัญชีนี้ในการขอเลขติดตามพัสดุจาก Flash',
  //       () => Linking.openURL(response.link_account_url),
  //       'รับทราบ'
  //     )
  //     await util.setStatePromise(this, {
  //       isFlashLinkAccount: response.link_account_url,
  //       // isRenderWebView: true,
  //     })
  //   }
  // }
}
